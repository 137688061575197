/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
    EmployeeToJSONTyped,
} from './Employee';

/**
 * 
 * @export
 * @interface ListEmployeesResponse
 */
export interface ListEmployeesResponse {
    /**
     * 
     * @type {Array<Employee>}
     * @memberof ListEmployeesResponse
     */
    data: Array<Employee>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListEmployeesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListEmployeesResponse interface.
 */
export function instanceOfListEmployeesResponse(value: object): value is ListEmployeesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListEmployeesResponseFromJSON(json: any): ListEmployeesResponse {
    return ListEmployeesResponseFromJSONTyped(json, false);
}

export function ListEmployeesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEmployeesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(EmployeeFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListEmployeesResponseToJSON(json: any): ListEmployeesResponse {
      return ListEmployeesResponseToJSONTyped(json, false);
  }

  export function ListEmployeesResponseToJSONTyped(value?: ListEmployeesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(EmployeeToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

