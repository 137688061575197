/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Upload } from './Upload';
import {
    UploadFromJSON,
    UploadFromJSONTyped,
    UploadToJSON,
    UploadToJSONTyped,
} from './Upload';

/**
 * 
 * @export
 * @interface ConfirmUploadResponse
 */
export interface ConfirmUploadResponse {
    /**
     * 
     * @type {Upload}
     * @memberof ConfirmUploadResponse
     */
    data: Upload;
}

/**
 * Check if a given object implements the ConfirmUploadResponse interface.
 */
export function instanceOfConfirmUploadResponse(value: object): value is ConfirmUploadResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ConfirmUploadResponseFromJSON(json: any): ConfirmUploadResponse {
    return ConfirmUploadResponseFromJSONTyped(json, false);
}

export function ConfirmUploadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmUploadResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': UploadFromJSON(json['data']),
    };
}

  export function ConfirmUploadResponseToJSON(json: any): ConfirmUploadResponse {
      return ConfirmUploadResponseToJSONTyped(json, false);
  }

  export function ConfirmUploadResponseToJSONTyped(value?: ConfirmUploadResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': UploadToJSON(value['data']),
    };
}

