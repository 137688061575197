/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';

/**
 * Describes the association between an employee and a job role
 * @export
 * @interface EmployeesJobRole
 */
export interface EmployeesJobRole {
    /**
     * 
     * @type {number}
     * @memberof EmployeesJobRole
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeesJobRole
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeesJobRole
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeesJobRole
     */
    jobRoleId: number;
    /**
     * How much the employee is paid when working in this role
     * @type {number}
     * @memberof EmployeesJobRole
     */
    rate: number | null;
    /**
     * 
     * @type {Date}
     * @memberof EmployeesJobRole
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmployeesJobRole
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {JobRole}
     * @memberof EmployeesJobRole
     */
    jobRole?: JobRole;
}

/**
 * Check if a given object implements the EmployeesJobRole interface.
 */
export function instanceOfEmployeesJobRole(value: object): value is EmployeesJobRole {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('jobRoleId' in value) || value['jobRoleId'] === undefined) return false;
    if (!('rate' in value) || value['rate'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function EmployeesJobRoleFromJSON(json: any): EmployeesJobRole {
    return EmployeesJobRoleFromJSONTyped(json, false);
}

export function EmployeesJobRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeesJobRole {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'jobRoleId': json['jobRoleId'],
        'rate': json['rate'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'jobRole': json['jobRole'] == null ? undefined : JobRoleFromJSON(json['jobRole']),
    };
}

  export function EmployeesJobRoleToJSON(json: any): EmployeesJobRole {
      return EmployeesJobRoleToJSONTyped(json, false);
  }

  export function EmployeesJobRoleToJSONTyped(value?: EmployeesJobRole | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'jobRoleId': value['jobRoleId'],
        'rate': value['rate'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'jobRole': JobRoleToJSON(value['jobRole']),
    };
}

