/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DocumentRead } from './DocumentRead';
import {
    DocumentReadFromJSON,
    DocumentReadFromJSONTyped,
    DocumentReadToJSON,
    DocumentReadToJSONTyped,
} from './DocumentRead';

/**
 * 
 * @export
 * @interface CreateDocumentReadResponse
 */
export interface CreateDocumentReadResponse {
    /**
     * 
     * @type {DocumentRead}
     * @memberof CreateDocumentReadResponse
     */
    data: DocumentRead;
}

/**
 * Check if a given object implements the CreateDocumentReadResponse interface.
 */
export function instanceOfCreateDocumentReadResponse(value: object): value is CreateDocumentReadResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateDocumentReadResponseFromJSON(json: any): CreateDocumentReadResponse {
    return CreateDocumentReadResponseFromJSONTyped(json, false);
}

export function CreateDocumentReadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDocumentReadResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': DocumentReadFromJSON(json['data']),
    };
}

  export function CreateDocumentReadResponseToJSON(json: any): CreateDocumentReadResponse {
      return CreateDocumentReadResponseToJSONTyped(json, false);
  }

  export function CreateDocumentReadResponseToJSONTyped(value?: CreateDocumentReadResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': DocumentReadToJSON(value['data']),
    };
}

