/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClockInPortal } from './ClockInPortal';
import {
    ClockInPortalFromJSON,
    ClockInPortalFromJSONTyped,
    ClockInPortalToJSON,
    ClockInPortalToJSONTyped,
} from './ClockInPortal';

/**
 * 
 * @export
 * @interface ShowClockInPortalResponse
 */
export interface ShowClockInPortalResponse {
    /**
     * 
     * @type {ClockInPortal}
     * @memberof ShowClockInPortalResponse
     */
    data: ClockInPortal;
}

/**
 * Check if a given object implements the ShowClockInPortalResponse interface.
 */
export function instanceOfShowClockInPortalResponse(value: object): value is ShowClockInPortalResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowClockInPortalResponseFromJSON(json: any): ShowClockInPortalResponse {
    return ShowClockInPortalResponseFromJSONTyped(json, false);
}

export function ShowClockInPortalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowClockInPortalResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ClockInPortalFromJSON(json['data']),
    };
}

  export function ShowClockInPortalResponseToJSON(json: any): ShowClockInPortalResponse {
      return ShowClockInPortalResponseToJSONTyped(json, false);
  }

  export function ShowClockInPortalResponseToJSONTyped(value?: ShowClockInPortalResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ClockInPortalToJSON(value['data']),
    };
}

