/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CacheMeta } from './CacheMeta';
import {
    CacheMetaFromJSON,
    CacheMetaFromJSONTyped,
    CacheMetaToJSON,
    CacheMetaToJSONTyped,
} from './CacheMeta';
import type { WeatherReport } from './WeatherReport';
import {
    WeatherReportFromJSON,
    WeatherReportFromJSONTyped,
    WeatherReportToJSON,
    WeatherReportToJSONTyped,
} from './WeatherReport';

/**
 * 
 * @export
 * @interface ListWeatherForAreaResponse
 */
export interface ListWeatherForAreaResponse {
    /**
     * 
     * @type {CacheMeta}
     * @memberof ListWeatherForAreaResponse
     */
    cache: CacheMeta;
    /**
     * 
     * @type {WeatherReport}
     * @memberof ListWeatherForAreaResponse
     */
    data: WeatherReport;
}

/**
 * Check if a given object implements the ListWeatherForAreaResponse interface.
 */
export function instanceOfListWeatherForAreaResponse(value: object): value is ListWeatherForAreaResponse {
    if (!('cache' in value) || value['cache'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ListWeatherForAreaResponseFromJSON(json: any): ListWeatherForAreaResponse {
    return ListWeatherForAreaResponseFromJSONTyped(json, false);
}

export function ListWeatherForAreaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListWeatherForAreaResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'cache': CacheMetaFromJSON(json['cache']),
        'data': WeatherReportFromJSON(json['data']),
    };
}

  export function ListWeatherForAreaResponseToJSON(json: any): ListWeatherForAreaResponse {
      return ListWeatherForAreaResponseToJSONTyped(json, false);
  }

  export function ListWeatherForAreaResponseToJSONTyped(value?: ListWeatherForAreaResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cache': CacheMetaToJSON(value['cache']),
        'data': WeatherReportToJSON(value['data']),
    };
}

