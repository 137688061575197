/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
    TagToJSONTyped,
} from './Tag';

/**
 * 
 * @export
 * @interface ShowTagResponse
 */
export interface ShowTagResponse {
    /**
     * 
     * @type {Tag}
     * @memberof ShowTagResponse
     */
    data: Tag;
}

/**
 * Check if a given object implements the ShowTagResponse interface.
 */
export function instanceOfShowTagResponse(value: object): value is ShowTagResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowTagResponseFromJSON(json: any): ShowTagResponse {
    return ShowTagResponseFromJSONTyped(json, false);
}

export function ShowTagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowTagResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': TagFromJSON(json['data']),
    };
}

  export function ShowTagResponseToJSON(json: any): ShowTagResponse {
      return ShowTagResponseToJSONTyped(json, false);
  }

  export function ShowTagResponseToJSONTyped(value?: ShowTagResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': TagToJSON(value['data']),
    };
}

