/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeesJobRole } from './EmployeesJobRole';
import {
    EmployeesJobRoleFromJSON,
    EmployeesJobRoleFromJSONTyped,
    EmployeesJobRoleToJSON,
    EmployeesJobRoleToJSONTyped,
} from './EmployeesJobRole';

/**
 * 
 * @export
 * @interface ShowEmployeesJobRoleResponse
 */
export interface ShowEmployeesJobRoleResponse {
    /**
     * 
     * @type {EmployeesJobRole}
     * @memberof ShowEmployeesJobRoleResponse
     */
    data: EmployeesJobRole;
}

/**
 * Check if a given object implements the ShowEmployeesJobRoleResponse interface.
 */
export function instanceOfShowEmployeesJobRoleResponse(value: object): value is ShowEmployeesJobRoleResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowEmployeesJobRoleResponseFromJSON(json: any): ShowEmployeesJobRoleResponse {
    return ShowEmployeesJobRoleResponseFromJSONTyped(json, false);
}

export function ShowEmployeesJobRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeesJobRoleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeesJobRoleFromJSON(json['data']),
    };
}

  export function ShowEmployeesJobRoleResponseToJSON(json: any): ShowEmployeesJobRoleResponse {
      return ShowEmployeesJobRoleResponseToJSONTyped(json, false);
  }

  export function ShowEmployeesJobRoleResponseToJSONTyped(value?: ShowEmployeesJobRoleResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': EmployeesJobRoleToJSON(value['data']),
    };
}

