var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"autoCompleteInputContainer",staticClass:"w-full"},[_c('v-autocomplete',_vm._b({class:{ 'required-input': _vm.required },attrs:{"value":_vm.value,"items":_vm.items,"item-text":_vm.itemText,"item-value":_vm.itemValue,"item-color":'#AD1267',"color":'#AD1267',"label":_vm.labelText,"placeholder":_vm.placeholder ? _vm.$t(`placeholder.${_vm.placeholder}`) : undefined,"menu-props":{
      closeOnContentClick: !_vm.multiple,
      ...(_vm.inputWidth && { maxWidth: _vm.inputWidth }),
    },"error":_vm.hasError,"error-messages":_vm.error,"readonly":_vm.readOnly || _vm.isSecured,"disabled":_vm.disabled,"append-icon":!_vm.readOnly && !_vm.disabled ? '$dropdown' : '',"outlined":"","multiple":_vm.multiple,"required":_vm.required,"no-data-text":_vm.noDataText,"height":_vm.multiple ? 'auto' : _vm.height,"search-input":_vm.innerSearchValue,"hide-details":"auto","return-object":_vm.returnObject,"type":_vm.isSecured ? 'password' : '',"filter":_vm.filterFunction || undefined},on:{"update:searchInput":function($event){_vm.innerSearchValue=$event},"update:search-input":function($event){_vm.innerSearchValue=$event},"blur":_vm.blur,"input":_vm.handleInput},scopedSlots:_vm._u([(_vm.$slots['prepend-item'])?{key:"prepend-item",fn:function(){return [_vm._t("prepend-item")]},proxy:true}:null,(_vm.multiple || _vm.customLayout)?{key:"selection",fn:function(data){return [_vm._t("selection",function(){return [_c('div',{staticClass:"multi-selection",class:{
            '!cursor-auto': _vm.disabled,
          },on:{"click":function($event){$event.stopPropagation();!_vm.disabled && data.parent.selectItem(data.item)}}},[(!!_vm.itemColourIndicator && data.item[_vm.itemColourIndicator])?_c('span',{staticClass:"inline-block w-2 h-2 rounded-full mr-1",style:({
              backgroundColor: data.item[_vm.itemColourIndicator],
              minWidth: '8px',
            })}):_vm._e(),_c('span',{staticClass:"text-ellipsis overflow-hidden"},[_vm._v(" "+_vm._s(data.item[_vm.itemText])+" ")]),(!_vm.disabled)?_c('i',{class:_vm.Icon.Xmark}):_vm._e()])]},{"itemSlot":data})]}}:(!!_vm.itemColourIndicator)?{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"flex align-center min-w-0 !max-w-[99.9%]"},[(item[_vm.itemColourIndicator])?_c('span',{staticClass:"inline-block w-2 min-w-[.5rem] h-2 rounded-full mr-1",style:({ backgroundColor: item[_vm.itemColourIndicator] })}):_vm._e(),_c('span',{staticClass:"text-overflow-ellipsis"},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])])]}}:null,(_vm.customLayout)?{key:"item",fn:function({ item }){return [_vm._t("item",function(){return [_vm._v(" "+_vm._s(item[_vm.itemText])+" ")]},{"itemSlot":item})]}}:(!!_vm.itemColourIndicator)?{key:"item",fn:function({ item, attrs, on }){return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [(_vm.multiple)?_c('v-list-item-action',{staticClass:"m-0 mr-2"},[_c('Checkbox',{attrs:{"value":active}})],1):_vm._e(),(item && item[_vm.itemColourIndicator])?_c('span',{staticClass:"inline-block w-2 h-2 rounded-full mr-2",style:({ backgroundColor: item[_vm.itemColourIndicator] })}):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}:null,(_vm.$slots['append-item'])?{key:"append-item",fn:function(){return [_vm._t("append-item")]},proxy:true}:null],null,true)},'v-autocomplete',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }