/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimesheetEntry } from './TimesheetEntry';
import {
    TimesheetEntryFromJSON,
    TimesheetEntryFromJSONTyped,
    TimesheetEntryToJSON,
    TimesheetEntryToJSONTyped,
} from './TimesheetEntry';

/**
 * 
 * @export
 * @interface RecordBreakResponse
 */
export interface RecordBreakResponse {
    /**
     * 
     * @type {TimesheetEntry}
     * @memberof RecordBreakResponse
     */
    data: TimesheetEntry;
}

/**
 * Check if a given object implements the RecordBreakResponse interface.
 */
export function instanceOfRecordBreakResponse(value: object): value is RecordBreakResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function RecordBreakResponseFromJSON(json: any): RecordBreakResponse {
    return RecordBreakResponseFromJSONTyped(json, false);
}

export function RecordBreakResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordBreakResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': TimesheetEntryFromJSON(json['data']),
    };
}

  export function RecordBreakResponseToJSON(json: any): RecordBreakResponse {
      return RecordBreakResponseToJSONTyped(json, false);
  }

  export function RecordBreakResponseToJSONTyped(value?: RecordBreakResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': TimesheetEntryToJSON(value['data']),
    };
}

