/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListEntityScopesResponse
 */
export interface ListEntityScopesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListEntityScopesResponse
     */
    data: Array<ListEntityScopesResponseDataEnum>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListEntityScopesResponse
     */
    pagination: Pagination;
}

/**
* @export
* @enum {string}
*/
export enum ListEntityScopesResponseDataEnum {
    MfaDeviceWrite = 'api.auth.mfa-device.write',
    UserRead = 'api.auth.user.read',
    UserWrite = 'api.auth.user.write'
}


/**
 * Check if a given object implements the ListEntityScopesResponse interface.
 */
export function instanceOfListEntityScopesResponse(value: object): value is ListEntityScopesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListEntityScopesResponseFromJSON(json: any): ListEntityScopesResponse {
    return ListEntityScopesResponseFromJSONTyped(json, false);
}

export function ListEntityScopesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEntityScopesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListEntityScopesResponseToJSON(json: any): ListEntityScopesResponse {
      return ListEntityScopesResponseToJSONTyped(json, false);
  }

  export function ListEntityScopesResponseToJSONTyped(value?: ListEntityScopesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': value['data'],
        'pagination': PaginationToJSON(value['pagination']),
    };
}

