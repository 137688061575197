/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DigiticketsScheduleMap
 */
export interface DigiticketsScheduleMap {
    /**
     * 
     * @type {number}
     * @memberof DigiticketsScheduleMap
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsScheduleMap
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsScheduleMap
     */
    digiticketsConnectionId: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsScheduleMap
     */
    scheduleId: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsScheduleMap
     */
    digiticketsBranchId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof DigiticketsScheduleMap
     */
    digiticketsEventIds: Array<number>;
}

/**
 * Check if a given object implements the DigiticketsScheduleMap interface.
 */
export function instanceOfDigiticketsScheduleMap(value: object): value is DigiticketsScheduleMap {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('digiticketsConnectionId' in value) || value['digiticketsConnectionId'] === undefined) return false;
    if (!('scheduleId' in value) || value['scheduleId'] === undefined) return false;
    if (!('digiticketsBranchId' in value) || value['digiticketsBranchId'] === undefined) return false;
    if (!('digiticketsEventIds' in value) || value['digiticketsEventIds'] === undefined) return false;
    return true;
}

export function DigiticketsScheduleMapFromJSON(json: any): DigiticketsScheduleMap {
    return DigiticketsScheduleMapFromJSONTyped(json, false);
}

export function DigiticketsScheduleMapFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigiticketsScheduleMap {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'digiticketsConnectionId': json['digiticketsConnectionId'],
        'scheduleId': json['scheduleId'],
        'digiticketsBranchId': json['digiticketsBranchId'],
        'digiticketsEventIds': json['digiticketsEventIds'],
    };
}

  export function DigiticketsScheduleMapToJSON(json: any): DigiticketsScheduleMap {
      return DigiticketsScheduleMapToJSONTyped(json, false);
  }

  export function DigiticketsScheduleMapToJSONTyped(value?: DigiticketsScheduleMap | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'digiticketsConnectionId': value['digiticketsConnectionId'],
        'scheduleId': value['scheduleId'],
        'digiticketsBranchId': value['digiticketsBranchId'],
        'digiticketsEventIds': value['digiticketsEventIds'],
    };
}

