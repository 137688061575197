/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface DigiticketsVisitorSummary
 */
export interface DigiticketsVisitorSummary {
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof DigiticketsVisitorSummary
     */
    date: ShiftiePlainDate;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsVisitorSummary
     */
    count: number;
}

/**
 * Check if a given object implements the DigiticketsVisitorSummary interface.
 */
export function instanceOfDigiticketsVisitorSummary(value: object): value is DigiticketsVisitorSummary {
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function DigiticketsVisitorSummaryFromJSON(json: any): DigiticketsVisitorSummary {
    return DigiticketsVisitorSummaryFromJSONTyped(json, false);
}

export function DigiticketsVisitorSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigiticketsVisitorSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'date': ShiftiePlainDateFromJSON(json['date']),
        'count': json['count'],
    };
}

  export function DigiticketsVisitorSummaryToJSON(json: any): DigiticketsVisitorSummary {
      return DigiticketsVisitorSummaryToJSONTyped(json, false);
  }

  export function DigiticketsVisitorSummaryToJSONTyped(value?: DigiticketsVisitorSummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'date': ShiftiePlainDateToJSON(value['date']),
        'count': value['count'],
    };
}

