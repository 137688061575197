/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationSettings
 */
export interface NotificationSettings {
    /**
     * 
     * @type {number}
     * @memberof NotificationSettings
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationSettings
     */
    companyId: number;
    /**
     * Number of minutes to delay for a clock in / out to warn a manager of the missed action for an employee.
     * @type {number}
     * @memberof NotificationSettings
     */
    managerClockingWarnWindow: number;
    /**
     * Number of minutes to delay for a clock in / out to warn an employee of the missed action.
     * @type {number}
     * @memberof NotificationSettings
     */
    employeeClockingWarnWindow: number;
    /**
     * Defines whether the overtime threshold check is active.
     * @type {boolean}
     * @memberof NotificationSettings
     */
    warnManagerForEmployeeOvertime: boolean;
    /**
     * Number of work hours less than the employee overtime threshold to trigger a warning. . This check is triggered when a clock-in action is recorded.
     * @type {number}
     * @memberof NotificationSettings
     */
    warnManagerForEmployeeOvertimeWindow: number;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationSettings
     */
    enableOvertimeWindow: boolean;
    /**
     * How many minutes before a shift starts that the assigned employee should receive a reminder notification. Only applicable to published shifts. Setting to null disables the reminder.
     * @type {number}
     * @memberof NotificationSettings
     */
    shiftStartingReminderMinutes: number | null;
}

/**
 * Check if a given object implements the NotificationSettings interface.
 */
export function instanceOfNotificationSettings(value: object): value is NotificationSettings {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('managerClockingWarnWindow' in value) || value['managerClockingWarnWindow'] === undefined) return false;
    if (!('employeeClockingWarnWindow' in value) || value['employeeClockingWarnWindow'] === undefined) return false;
    if (!('warnManagerForEmployeeOvertime' in value) || value['warnManagerForEmployeeOvertime'] === undefined) return false;
    if (!('warnManagerForEmployeeOvertimeWindow' in value) || value['warnManagerForEmployeeOvertimeWindow'] === undefined) return false;
    if (!('enableOvertimeWindow' in value) || value['enableOvertimeWindow'] === undefined) return false;
    if (!('shiftStartingReminderMinutes' in value) || value['shiftStartingReminderMinutes'] === undefined) return false;
    return true;
}

export function NotificationSettingsFromJSON(json: any): NotificationSettings {
    return NotificationSettingsFromJSONTyped(json, false);
}

export function NotificationSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'managerClockingWarnWindow': json['managerClockingWarnWindow'],
        'employeeClockingWarnWindow': json['employeeClockingWarnWindow'],
        'warnManagerForEmployeeOvertime': json['warnManagerForEmployeeOvertime'],
        'warnManagerForEmployeeOvertimeWindow': json['warnManagerForEmployeeOvertimeWindow'],
        'enableOvertimeWindow': json['enableOvertimeWindow'],
        'shiftStartingReminderMinutes': json['shiftStartingReminderMinutes'],
    };
}

  export function NotificationSettingsToJSON(json: any): NotificationSettings {
      return NotificationSettingsToJSONTyped(json, false);
  }

  export function NotificationSettingsToJSONTyped(value?: NotificationSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'managerClockingWarnWindow': value['managerClockingWarnWindow'],
        'employeeClockingWarnWindow': value['employeeClockingWarnWindow'],
        'warnManagerForEmployeeOvertime': value['warnManagerForEmployeeOvertime'],
        'warnManagerForEmployeeOvertimeWindow': value['warnManagerForEmployeeOvertimeWindow'],
        'enableOvertimeWindow': value['enableOvertimeWindow'],
        'shiftStartingReminderMinutes': value['shiftStartingReminderMinutes'],
    };
}

