/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScheduleSettings } from './ScheduleSettings';
import {
    ScheduleSettingsFromJSON,
    ScheduleSettingsFromJSONTyped,
    ScheduleSettingsToJSON,
    ScheduleSettingsToJSONTyped,
} from './ScheduleSettings';

/**
 * 
 * @export
 * @interface ShowScheduleSettingsResponse
 */
export interface ShowScheduleSettingsResponse {
    /**
     * 
     * @type {ScheduleSettings}
     * @memberof ShowScheduleSettingsResponse
     */
    data: ScheduleSettings;
}

/**
 * Check if a given object implements the ShowScheduleSettingsResponse interface.
 */
export function instanceOfShowScheduleSettingsResponse(value: object): value is ShowScheduleSettingsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowScheduleSettingsResponseFromJSON(json: any): ShowScheduleSettingsResponse {
    return ShowScheduleSettingsResponseFromJSONTyped(json, false);
}

export function ShowScheduleSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowScheduleSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ScheduleSettingsFromJSON(json['data']),
    };
}

  export function ShowScheduleSettingsResponseToJSON(json: any): ShowScheduleSettingsResponse {
      return ShowScheduleSettingsResponseToJSONTyped(json, false);
  }

  export function ShowScheduleSettingsResponseToJSONTyped(value?: ShowScheduleSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ScheduleSettingsToJSON(value['data']),
    };
}

