/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeGroup } from './EmployeeGroup';
import {
    EmployeeGroupFromJSON,
    EmployeeGroupFromJSONTyped,
    EmployeeGroupToJSON,
    EmployeeGroupToJSONTyped,
} from './EmployeeGroup';

/**
 * 
 * @export
 * @interface ShowEmployeeGroupResponse
 */
export interface ShowEmployeeGroupResponse {
    /**
     * 
     * @type {EmployeeGroup}
     * @memberof ShowEmployeeGroupResponse
     */
    data: EmployeeGroup;
}

/**
 * Check if a given object implements the ShowEmployeeGroupResponse interface.
 */
export function instanceOfShowEmployeeGroupResponse(value: object): value is ShowEmployeeGroupResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowEmployeeGroupResponseFromJSON(json: any): ShowEmployeeGroupResponse {
    return ShowEmployeeGroupResponseFromJSONTyped(json, false);
}

export function ShowEmployeeGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeGroupResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeGroupFromJSON(json['data']),
    };
}

  export function ShowEmployeeGroupResponseToJSON(json: any): ShowEmployeeGroupResponse {
      return ShowEmployeeGroupResponseToJSONTyped(json, false);
  }

  export function ShowEmployeeGroupResponseToJSONTyped(value?: ShowEmployeeGroupResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': EmployeeGroupToJSON(value['data']),
    };
}

