/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateOAuthClientData
 */
export interface UpdateOAuthClientData {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateOAuthClientData
     */
    scopes: Array<string>;
}

/**
 * Check if a given object implements the UpdateOAuthClientData interface.
 */
export function instanceOfUpdateOAuthClientData(value: object): value is UpdateOAuthClientData {
    if (!('scopes' in value) || value['scopes'] === undefined) return false;
    return true;
}

export function UpdateOAuthClientDataFromJSON(json: any): UpdateOAuthClientData {
    return UpdateOAuthClientDataFromJSONTyped(json, false);
}

export function UpdateOAuthClientDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateOAuthClientData {
    if (json == null) {
        return json;
    }
    return {
        
        'scopes': json['scopes'],
    };
}

  export function UpdateOAuthClientDataToJSON(json: any): UpdateOAuthClientData {
      return UpdateOAuthClientDataToJSONTyped(json, false);
  }

  export function UpdateOAuthClientDataToJSONTyped(value?: UpdateOAuthClientData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'scopes': value['scopes'],
    };
}

