/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCompanyData
 */
export interface UpdateCompanyData {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyData
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyData
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyData
     */
    timezone?: string;
    /**
     * A series of national/public holidays that apply to this company
     * @type {number}
     * @memberof UpdateCompanyData
     */
    holidayRegionId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyData
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyData
     */
    longitude?: number;
}

/**
 * Check if a given object implements the UpdateCompanyData interface.
 */
export function instanceOfUpdateCompanyData(value: object): value is UpdateCompanyData {
    return true;
}

export function UpdateCompanyDataFromJSON(json: any): UpdateCompanyData {
    return UpdateCompanyDataFromJSONTyped(json, false);
}

export function UpdateCompanyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'address': json['address'] == null ? undefined : json['address'],
        'locale': json['locale'] == null ? undefined : json['locale'],
        'timezone': json['timezone'] == null ? undefined : json['timezone'],
        'holidayRegionId': json['holidayRegionId'] == null ? undefined : json['holidayRegionId'],
        'latitude': json['latitude'] == null ? undefined : json['latitude'],
        'longitude': json['longitude'] == null ? undefined : json['longitude'],
    };
}

  export function UpdateCompanyDataToJSON(json: any): UpdateCompanyData {
      return UpdateCompanyDataToJSONTyped(json, false);
  }

  export function UpdateCompanyDataToJSONTyped(value?: UpdateCompanyData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'address': value['address'],
        'locale': value['locale'],
        'timezone': value['timezone'],
        'holidayRegionId': value['holidayRegionId'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
    };
}

