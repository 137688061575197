/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDigiticketsScheduleMapData
 */
export interface UpdateDigiticketsScheduleMapData {
    /**
     * 
     * @type {number}
     * @memberof UpdateDigiticketsScheduleMapData
     */
    digiticketsBranchId?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateDigiticketsScheduleMapData
     */
    digiticketsEventIds?: Array<number>;
}

/**
 * Check if a given object implements the UpdateDigiticketsScheduleMapData interface.
 */
export function instanceOfUpdateDigiticketsScheduleMapData(value: object): value is UpdateDigiticketsScheduleMapData {
    return true;
}

export function UpdateDigiticketsScheduleMapDataFromJSON(json: any): UpdateDigiticketsScheduleMapData {
    return UpdateDigiticketsScheduleMapDataFromJSONTyped(json, false);
}

export function UpdateDigiticketsScheduleMapDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDigiticketsScheduleMapData {
    if (json == null) {
        return json;
    }
    return {
        
        'digiticketsBranchId': json['digiticketsBranchId'] == null ? undefined : json['digiticketsBranchId'],
        'digiticketsEventIds': json['digiticketsEventIds'] == null ? undefined : json['digiticketsEventIds'],
    };
}

  export function UpdateDigiticketsScheduleMapDataToJSON(json: any): UpdateDigiticketsScheduleMapData {
      return UpdateDigiticketsScheduleMapDataToJSONTyped(json, false);
  }

  export function UpdateDigiticketsScheduleMapDataToJSONTyped(value?: UpdateDigiticketsScheduleMapData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'digiticketsBranchId': value['digiticketsBranchId'],
        'digiticketsEventIds': value['digiticketsEventIds'],
    };
}

