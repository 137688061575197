/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShowOauthClientSecretResponse
 */
export interface ShowOauthClientSecretResponse {
    /**
     * 
     * @type {string}
     * @memberof ShowOauthClientSecretResponse
     */
    data: string;
}

/**
 * Check if a given object implements the ShowOauthClientSecretResponse interface.
 */
export function instanceOfShowOauthClientSecretResponse(value: object): value is ShowOauthClientSecretResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowOauthClientSecretResponseFromJSON(json: any): ShowOauthClientSecretResponse {
    return ShowOauthClientSecretResponseFromJSONTyped(json, false);
}

export function ShowOauthClientSecretResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowOauthClientSecretResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
    };
}

  export function ShowOauthClientSecretResponseToJSON(json: any): ShowOauthClientSecretResponse {
      return ShowOauthClientSecretResponseToJSONTyped(json, false);
  }

  export function ShowOauthClientSecretResponseToJSONTyped(value?: ShowOauthClientSecretResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': value['data'],
    };
}

