/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTagData
 */
export interface CreateTagData {
    /**
     * 
     * @type {string}
     * @memberof CreateTagData
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTagData
     */
    visibleToEmployees: boolean;
}

/**
 * Check if a given object implements the CreateTagData interface.
 */
export function instanceOfCreateTagData(value: object): value is CreateTagData {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('visibleToEmployees' in value) || value['visibleToEmployees'] === undefined) return false;
    return true;
}

export function CreateTagDataFromJSON(json: any): CreateTagData {
    return CreateTagDataFromJSONTyped(json, false);
}

export function CreateTagDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTagData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'visibleToEmployees': json['visibleToEmployees'],
    };
}

  export function CreateTagDataToJSON(json: any): CreateTagData {
      return CreateTagDataToJSONTyped(json, false);
  }

  export function CreateTagDataToJSONTyped(value?: CreateTagData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'visibleToEmployees': value['visibleToEmployees'],
    };
}

