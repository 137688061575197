/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Schedule } from './Schedule';
import {
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
    ScheduleToJSONTyped,
} from './Schedule';

/**
 * 
 * @export
 * @interface ShowScheduleResponse
 */
export interface ShowScheduleResponse {
    /**
     * 
     * @type {Schedule}
     * @memberof ShowScheduleResponse
     */
    data: Schedule;
}

/**
 * Check if a given object implements the ShowScheduleResponse interface.
 */
export function instanceOfShowScheduleResponse(value: object): value is ShowScheduleResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowScheduleResponseFromJSON(json: any): ShowScheduleResponse {
    return ShowScheduleResponseFromJSONTyped(json, false);
}

export function ShowScheduleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowScheduleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ScheduleFromJSON(json['data']),
    };
}

  export function ShowScheduleResponseToJSON(json: any): ShowScheduleResponse {
      return ShowScheduleResponseToJSONTyped(json, false);
  }

  export function ShowScheduleResponseToJSONTyped(value?: ShowScheduleResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ScheduleToJSON(value['data']),
    };
}

