/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDocumentData
 */
export interface CreateDocumentData {
    /**
     * The ID of the Upload entity, previously created via the /uploads endpoint
     * @type {number}
     * @memberof CreateDocumentData
     */
    uploadId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDocumentData
     */
    name: string;
    /**
     * Whether the document should be visible to all employees
     * @type {boolean}
     * @memberof CreateDocumentData
     */
    visibleToAll?: boolean | null;
    /**
     * These employees should be able to view this document
     * @type {Array<number>}
     * @memberof CreateDocumentData
     */
    employeeIds?: Array<number> | null;
    /**
     * Employees with these job roles should be able to view this document
     * @type {Array<number>}
     * @memberof CreateDocumentData
     */
    jobRoleIds?: Array<number> | null;
    /**
     * Indicates whether the document must be read by employees, and if so, by when
     * @type {Date}
     * @memberof CreateDocumentData
     */
    readDeadline?: Date | null;
}

/**
 * Check if a given object implements the CreateDocumentData interface.
 */
export function instanceOfCreateDocumentData(value: object): value is CreateDocumentData {
    if (!('uploadId' in value) || value['uploadId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function CreateDocumentDataFromJSON(json: any): CreateDocumentData {
    return CreateDocumentDataFromJSONTyped(json, false);
}

export function CreateDocumentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDocumentData {
    if (json == null) {
        return json;
    }
    return {
        
        'uploadId': json['uploadId'],
        'name': json['name'],
        'visibleToAll': json['visibleToAll'] == null ? undefined : json['visibleToAll'],
        'employeeIds': json['employeeIds'] == null ? undefined : json['employeeIds'],
        'jobRoleIds': json['jobRoleIds'] == null ? undefined : json['jobRoleIds'],
        'readDeadline': json['readDeadline'] == null ? undefined : (new Date(json['readDeadline'])),
    };
}

  export function CreateDocumentDataToJSON(json: any): CreateDocumentData {
      return CreateDocumentDataToJSONTyped(json, false);
  }

  export function CreateDocumentDataToJSONTyped(value?: CreateDocumentData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'uploadId': value['uploadId'],
        'name': value['name'],
        'visibleToAll': value['visibleToAll'],
        'employeeIds': value['employeeIds'],
        'jobRoleIds': value['jobRoleIds'],
        'readDeadline': value['readDeadline'] == null ? undefined : ((value['readDeadline'] as any).toISOString()),
    };
}

