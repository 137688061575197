/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';
import type { ShiftTemplateShiftAreaSession } from './ShiftTemplateShiftAreaSession';
import {
    ShiftTemplateShiftAreaSessionFromJSON,
    ShiftTemplateShiftAreaSessionFromJSONTyped,
    ShiftTemplateShiftAreaSessionToJSON,
    ShiftTemplateShiftAreaSessionToJSONTyped,
} from './ShiftTemplateShiftAreaSession';
import type { ShiftTemplateBreak } from './ShiftTemplateBreak';
import {
    ShiftTemplateBreakFromJSON,
    ShiftTemplateBreakFromJSONTyped,
    ShiftTemplateBreakToJSON,
    ShiftTemplateBreakToJSONTyped,
} from './ShiftTemplateBreak';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
    TagToJSONTyped,
} from './Tag';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    LocationToJSONTyped,
} from './Location';

/**
 * A saved template that can be used to quickly generate new shifts that share the same settings
 * @export
 * @interface ShiftTemplate
 */
export interface ShiftTemplate {
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    companyId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShiftTemplate
     */
    tagIds: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    locationId: number | null;
    /**
     * 
     * @type {Location}
     * @memberof ShiftTemplate
     */
    locations?: Location;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    jobRoleId: number | null;
    /**
     * 
     * @type {JobRole}
     * @memberof ShiftTemplate
     */
    jobRole?: JobRole;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    startHour: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    startMinute: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    endHour: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplate
     */
    endMinute: number;
    /**
     * Whether to show the shift end time to non-managerial staff
     * @type {boolean}
     * @memberof ShiftTemplate
     */
    showEndTime: boolean;
    /**
     * Whether to show the job role to non-managerial staff
     * @type {boolean}
     * @memberof ShiftTemplate
     */
    showJobRole: boolean;
    /**
     * 
     * @type {Array<ShiftTemplateBreak>}
     * @memberof ShiftTemplate
     */
    shiftTemplateBreaks: Array<ShiftTemplateBreak>;
    /**
     * 
     * @type {Array<ShiftTemplateShiftAreaSession>}
     * @memberof ShiftTemplate
     */
    shiftTemplateAreaSessions: Array<ShiftTemplateShiftAreaSession>;
    /**
     * The amount of paid break included in this shift (in minutes)
     * @type {number}
     * @memberof ShiftTemplate
     */
    paidBreakInMinutes: number;
    /**
     * The amount of unpaid break included in this shift (in minutes)
     * @type {number}
     * @memberof ShiftTemplate
     */
    unpaidBreakInMinutes: number;
    /**
     * 
     * @type {string}
     * @memberof ShiftTemplate
     */
    notes: string | null;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof ShiftTemplate
     */
    tags?: Array<Tag>;
}

/**
 * Check if a given object implements the ShiftTemplate interface.
 */
export function instanceOfShiftTemplate(value: object): value is ShiftTemplate {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('tagIds' in value) || value['tagIds'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('locationId' in value) || value['locationId'] === undefined) return false;
    if (!('jobRoleId' in value) || value['jobRoleId'] === undefined) return false;
    if (!('startHour' in value) || value['startHour'] === undefined) return false;
    if (!('startMinute' in value) || value['startMinute'] === undefined) return false;
    if (!('endHour' in value) || value['endHour'] === undefined) return false;
    if (!('endMinute' in value) || value['endMinute'] === undefined) return false;
    if (!('showEndTime' in value) || value['showEndTime'] === undefined) return false;
    if (!('showJobRole' in value) || value['showJobRole'] === undefined) return false;
    if (!('shiftTemplateBreaks' in value) || value['shiftTemplateBreaks'] === undefined) return false;
    if (!('shiftTemplateAreaSessions' in value) || value['shiftTemplateAreaSessions'] === undefined) return false;
    if (!('paidBreakInMinutes' in value) || value['paidBreakInMinutes'] === undefined) return false;
    if (!('unpaidBreakInMinutes' in value) || value['unpaidBreakInMinutes'] === undefined) return false;
    if (!('notes' in value) || value['notes'] === undefined) return false;
    return true;
}

export function ShiftTemplateFromJSON(json: any): ShiftTemplate {
    return ShiftTemplateFromJSONTyped(json, false);
}

export function ShiftTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftTemplate {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'tagIds': json['tagIds'],
        'name': json['name'],
        'locationId': json['locationId'],
        'locations': json['locations'] == null ? undefined : LocationFromJSON(json['locations']),
        'jobRoleId': json['jobRoleId'],
        'jobRole': json['jobRole'] == null ? undefined : JobRoleFromJSON(json['jobRole']),
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'endHour': json['endHour'],
        'endMinute': json['endMinute'],
        'showEndTime': json['showEndTime'],
        'showJobRole': json['showJobRole'],
        'shiftTemplateBreaks': ((json['shiftTemplateBreaks'] as Array<any>).map(ShiftTemplateBreakFromJSON)),
        'shiftTemplateAreaSessions': ((json['shiftTemplateAreaSessions'] as Array<any>).map(ShiftTemplateShiftAreaSessionFromJSON)),
        'paidBreakInMinutes': json['paidBreakInMinutes'],
        'unpaidBreakInMinutes': json['unpaidBreakInMinutes'],
        'notes': json['notes'],
        'tags': json['tags'] == null ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
    };
}

  export function ShiftTemplateToJSON(json: any): ShiftTemplate {
      return ShiftTemplateToJSONTyped(json, false);
  }

  export function ShiftTemplateToJSONTyped(value?: ShiftTemplate | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'tagIds': value['tagIds'],
        'name': value['name'],
        'locationId': value['locationId'],
        'locations': LocationToJSON(value['locations']),
        'jobRoleId': value['jobRoleId'],
        'jobRole': JobRoleToJSON(value['jobRole']),
        'startHour': value['startHour'],
        'startMinute': value['startMinute'],
        'endHour': value['endHour'],
        'endMinute': value['endMinute'],
        'showEndTime': value['showEndTime'],
        'showJobRole': value['showJobRole'],
        'shiftTemplateBreaks': ((value['shiftTemplateBreaks'] as Array<any>).map(ShiftTemplateBreakToJSON)),
        'shiftTemplateAreaSessions': ((value['shiftTemplateAreaSessions'] as Array<any>).map(ShiftTemplateShiftAreaSessionToJSON)),
        'paidBreakInMinutes': value['paidBreakInMinutes'],
        'unpaidBreakInMinutes': value['unpaidBreakInMinutes'],
        'notes': value['notes'],
        'tags': value['tags'] == null ? undefined : ((value['tags'] as Array<any>).map(TagToJSON)),
    };
}

