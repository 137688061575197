/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerifyPhoneNumberData
 */
export interface VerifyPhoneNumberData {
    /**
     * 
     * @type {string}
     * @memberof VerifyPhoneNumberData
     */
    otp: string;
}

/**
 * Check if a given object implements the VerifyPhoneNumberData interface.
 */
export function instanceOfVerifyPhoneNumberData(value: object): value is VerifyPhoneNumberData {
    if (!('otp' in value) || value['otp'] === undefined) return false;
    return true;
}

export function VerifyPhoneNumberDataFromJSON(json: any): VerifyPhoneNumberData {
    return VerifyPhoneNumberDataFromJSONTyped(json, false);
}

export function VerifyPhoneNumberDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyPhoneNumberData {
    if (json == null) {
        return json;
    }
    return {
        
        'otp': json['otp'],
    };
}

  export function VerifyPhoneNumberDataToJSON(json: any): VerifyPhoneNumberData {
      return VerifyPhoneNumberDataToJSONTyped(json, false);
  }

  export function VerifyPhoneNumberDataToJSONTyped(value?: VerifyPhoneNumberData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'otp': value['otp'],
    };
}

