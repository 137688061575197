/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Shift } from './Shift';
import {
    ShiftFromJSON,
    ShiftFromJSONTyped,
    ShiftToJSON,
    ShiftToJSONTyped,
} from './Shift';

/**
 * 
 * @export
 * @interface CreateShiftResponse
 */
export interface CreateShiftResponse {
    /**
     * 
     * @type {Shift}
     * @memberof CreateShiftResponse
     */
    data: Shift;
}

/**
 * Check if a given object implements the CreateShiftResponse interface.
 */
export function instanceOfCreateShiftResponse(value: object): value is CreateShiftResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateShiftResponseFromJSON(json: any): CreateShiftResponse {
    return CreateShiftResponseFromJSONTyped(json, false);
}

export function CreateShiftResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ShiftFromJSON(json['data']),
    };
}

  export function CreateShiftResponseToJSON(json: any): CreateShiftResponse {
      return CreateShiftResponseToJSONTyped(json, false);
  }

  export function CreateShiftResponseToJSONTyped(value?: CreateShiftResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ShiftToJSON(value['data']),
    };
}

