/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface BulkCreateWorkPatternOverrides
 */
export interface BulkCreateWorkPatternOverrides {
    /**
     * 
     * @type {number}
     * @memberof BulkCreateWorkPatternOverrides
     */
    employeeId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BulkCreateWorkPatternOverrides
     */
    date: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof BulkCreateWorkPatternOverrides
     */
    isWorkingDay?: boolean;
}

/**
 * Check if a given object implements the BulkCreateWorkPatternOverrides interface.
 */
export function instanceOfBulkCreateWorkPatternOverrides(value: object): value is BulkCreateWorkPatternOverrides {
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    return true;
}

export function BulkCreateWorkPatternOverridesFromJSON(json: any): BulkCreateWorkPatternOverrides {
    return BulkCreateWorkPatternOverridesFromJSONTyped(json, false);
}

export function BulkCreateWorkPatternOverridesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateWorkPatternOverrides {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'date': ShiftiePlainDateFromJSON(json['date']),
        'isWorkingDay': json['isWorkingDay'] == null ? undefined : json['isWorkingDay'],
    };
}

  export function BulkCreateWorkPatternOverridesToJSON(json: any): BulkCreateWorkPatternOverrides {
      return BulkCreateWorkPatternOverridesToJSONTyped(json, false);
  }

  export function BulkCreateWorkPatternOverridesToJSONTyped(value?: BulkCreateWorkPatternOverrides | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'date': ShiftiePlainDateToJSON(value['date']),
        'isWorkingDay': value['isWorkingDay'],
    };
}

