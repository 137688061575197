/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { CacheMeta } from './CacheMeta';
import {
    CacheMetaFromJSON,
    CacheMetaFromJSONTyped,
    CacheMetaToJSON,
    CacheMetaToJSONTyped,
} from './CacheMeta';
import type { DigiticketsVisitorSummary } from './DigiticketsVisitorSummary';
import {
    DigiticketsVisitorSummaryFromJSON,
    DigiticketsVisitorSummaryFromJSONTyped,
    DigiticketsVisitorSummaryToJSON,
    DigiticketsVisitorSummaryToJSONTyped,
} from './DigiticketsVisitorSummary';

/**
 * 
 * @export
 * @interface ListDigiticketsPrebookedVisitorsSummariesResponse
 */
export interface ListDigiticketsPrebookedVisitorsSummariesResponse {
    /**
     * 
     * @type {CacheMeta}
     * @memberof ListDigiticketsPrebookedVisitorsSummariesResponse
     */
    cache: CacheMeta;
    /**
     * 
     * @type {Array<DigiticketsVisitorSummary>}
     * @memberof ListDigiticketsPrebookedVisitorsSummariesResponse
     */
    data: Array<DigiticketsVisitorSummary>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListDigiticketsPrebookedVisitorsSummariesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListDigiticketsPrebookedVisitorsSummariesResponse interface.
 */
export function instanceOfListDigiticketsPrebookedVisitorsSummariesResponse(value: object): value is ListDigiticketsPrebookedVisitorsSummariesResponse {
    if (!('cache' in value) || value['cache'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListDigiticketsPrebookedVisitorsSummariesResponseFromJSON(json: any): ListDigiticketsPrebookedVisitorsSummariesResponse {
    return ListDigiticketsPrebookedVisitorsSummariesResponseFromJSONTyped(json, false);
}

export function ListDigiticketsPrebookedVisitorsSummariesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDigiticketsPrebookedVisitorsSummariesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'cache': CacheMetaFromJSON(json['cache']),
        'data': ((json['data'] as Array<any>).map(DigiticketsVisitorSummaryFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListDigiticketsPrebookedVisitorsSummariesResponseToJSON(json: any): ListDigiticketsPrebookedVisitorsSummariesResponse {
      return ListDigiticketsPrebookedVisitorsSummariesResponseToJSONTyped(json, false);
  }

  export function ListDigiticketsPrebookedVisitorsSummariesResponseToJSONTyped(value?: ListDigiticketsPrebookedVisitorsSummariesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cache': CacheMetaToJSON(value['cache']),
        'data': ((value['data'] as Array<any>).map(DigiticketsVisitorSummaryToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

