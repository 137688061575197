/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkPattern } from './WorkPattern';
import {
    WorkPatternFromJSON,
    WorkPatternFromJSONTyped,
    WorkPatternToJSON,
    WorkPatternToJSONTyped,
} from './WorkPattern';

/**
 * 
 * @export
 * @interface ShowWorkPatternResponse
 */
export interface ShowWorkPatternResponse {
    /**
     * 
     * @type {WorkPattern}
     * @memberof ShowWorkPatternResponse
     */
    data: WorkPattern;
}

/**
 * Check if a given object implements the ShowWorkPatternResponse interface.
 */
export function instanceOfShowWorkPatternResponse(value: object): value is ShowWorkPatternResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowWorkPatternResponseFromJSON(json: any): ShowWorkPatternResponse {
    return ShowWorkPatternResponseFromJSONTyped(json, false);
}

export function ShowWorkPatternResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowWorkPatternResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': WorkPatternFromJSON(json['data']),
    };
}

  export function ShowWorkPatternResponseToJSON(json: any): ShowWorkPatternResponse {
      return ShowWorkPatternResponseToJSONTyped(json, false);
  }

  export function ShowWorkPatternResponseToJSONTyped(value?: ShowWorkPatternResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': WorkPatternToJSON(value['data']),
    };
}

