import {
  AbsenceCategoryFromJSON,
  AbsenceFromJSON,
  AccessRoleFromJSON,
  BillingPlanFromJSON,
  BudgetSummaryFromJSON,
  ClockInPortalFromJSON,
  CompanyFromJSON,
  CompanyNotificationChannelFromJSON,
  CompanySettingFromJSON,
  DaySummaryFromJSON,
  DigiticketsConnectionFromJSON,
  DigiticketsScheduleMapFromJSON,
  DocumentFromJSON,
  DocumentReadFromJSON,
  EmergencyContactFromJSON,
  EmployeeAbsenceSummaryFromJSON,
  EmployeeAttributeFromJSON,
  EmployeeClockingProfileFromJSON,
  EmployeeFromJSON,
  EmployeeGroupFromJSON,
  EmployeeInviteFromJSON,
  EmployeeLeaveSummaryFromJSON,
  EmployeeMessageFromJSON,
  EmployeeMessageRecipientFromJSON,
  EmployeeNotificationChannelFromJSON,
  EmployeePayDetailsFromJSON,
  EmployeePayPeriodSummaryFromJSON,
  EmployeePersonalDetailsFromJSON,
  EmployeesJobRoleFromJSON,
  FeaturesEnabledFromJSON,
  HistoryFromJSON,
  IcalFeedFromJSON,
  JobRoleFromJSON,
  LeaveAdjustmentFromJSON,
  LeavePeriodFromJSON,
  LeavePolicyFromJSON,
  LeaveRequestFromJSON,
  LeaveTypeFromJSON,
  ListEventEntitiesResponseDataEnum,
  LocationFromJSON,
  NotificationSettingsFromJSON,
  OfferFromJSON,
  OffersBehaviourFromJSON,
  OffersQuotaFromJSON,
  OpenShiftResponseFromJSON,
  PayCycleFromJSON,
  PayPeriodFromJSON,
  PayPeriodsSummaryFromJSON,
  PublicHolidayFromJSON,
  ScheduleEventFromJSON,
  ScheduleFromJSON,
  ScheduleOverviewSummaryFromJSON,
  ScheduleTemplateFromJSON,
  ShiftAreaFromJSON,
  ShiftFromJSON,
  ShiftSwapFromJSON,
  ShiftTemplateFromJSON,
  TagFromJSON,
  TimesheetEntryFromJSON,
  TimesheetEntrySummaryFromJSON,
  TrekksoftClientCredentialFromJSON,
  UnavailabilityFromJSON,
  UploadFromJSON,
  UserFromJSON,
  WorkPatternFromJSON,
} from '@/../api/v1';
import {
  absenceApi,
  accessRoleApi,
  ApiListMethodName,
  ApiMethodParams,
  ApiRequest,
  ApiShowMethodName,
  billingApi,
  calendarApi,
  clockInPortalApi,
  companyApi,
  companySettingsApi,
  documentApi,
  emergencyContactApi,
  employeeApi,
  employeeAttributesApi,
  employeeGroupApi,
  employeeInviteApi,
  employeeMessageApi,
  employeesJobRoleApi,
  historyApi,
  integrationApi,
  jobRoleApi,
  leaveAdjustmentApi,
  leavePeriodApi,
  leavePolicyApi,
  leaveRequestApi,
  leaveTypeApi,
  locationApi,
  openShiftResponseApi,
  payCycleApi,
  payPeriodApi,
  publicHolidayApi,
  scheduleApi,
  scheduleEventApi,
  scheduleTemplateApi,
  settingsApi,
  shiftApi,
  shiftAreaApi,
  shiftSwapApi,
  shiftTemplateApi,
  summariesApi,
  tagsApi,
  timesheetApi,
  unavailabilityApi,
  uploadApi,
  userApi,
  workPatternApi,
} from '@/api';
import { ApiVersion, ApiVersionTypeMap } from '@/lib/api/types';
import { Where } from '@/lib/realtime/weak/realtimeFiltering';
import {
  BatchFetcher,
  CustomEntity,
  EntityConfig,
  EntityFetcherRequestMap,
  EntityInterfaceMap,
  Fetcher,
  OrderBy,
  PopulateStrategy,
  WSEntityName,
} from '@/lib/realtime/weak/realtimeTypes';

const Entity = ListEventEntitiesResponseDataEnum;

const genericFetcher =
  <
    Api extends ApiVersionTypeMap[ApiVersion]['BaseApi'],
    MethodName extends ApiListMethodName<ApiVersion, Api>,
    InterfaceMap extends Record<string, object> = EntityInterfaceMap,
    Key extends keyof InterfaceMap = keyof InterfaceMap,
    ParamsMap extends Record<string, object> = EntityFetcherRequestMap,
  >(
    api: Api,
    methodName: MethodName,
    options: {
      with?: string[];
      perPage?: number;
    } = {},
  ): Fetcher<InterfaceMap, Key, ParamsMap> =>
  async (
    params: Key extends keyof ParamsMap ? ParamsMap[Key] : never,
    where: Where<InterfaceMap[Key]>,
    orderBy: OrderBy<InterfaceMap[Key]> = [],
  ): Promise<InterfaceMap[Key][]> =>
    (
      await new ApiRequest(api, methodName, {
        where,
        ...(orderBy.length && { orderBy }),
        ...(options.with && { _with: options.with }),
        perPage: options.perPage ?? 250,
        ...params,
      } as ApiMethodParams<Api[MethodName]>).fetchAll()
    ).data as InterfaceMap[Key][];

const singleEntityFetcher =
  <
    Version extends ApiVersion,
    Api extends ApiVersionTypeMap[Version]['BaseApi'],
    MethodName extends ApiShowMethodName<Version, Api>,
    InterfaceMap extends Record<string, object> = EntityInterfaceMap,
    Key extends keyof InterfaceMap = keyof InterfaceMap,
    ParamsMap extends Record<string, object> = EntityFetcherRequestMap,
  >(
    api: Api,
    methodName: MethodName,
  ): Fetcher<InterfaceMap, Key, ParamsMap> =>
  async (
    params: Key extends keyof ParamsMap ? ParamsMap[Key] : never,
  ): Promise<InterfaceMap[Key][]> =>
    [(await (api[methodName] as Function)(params)).data] as InterfaceMap[Key][];

const genericBatchedFetcher =
  <
    Api extends ApiVersionTypeMap[ApiVersion]['BaseApi'],
    MethodName extends ApiListMethodName<ApiVersion, Api>,
    InterfaceMap extends Record<string, object> = EntityInterfaceMap,
    WSSupportName extends keyof InterfaceMap = WSEntityName,
    Key extends keyof InterfaceMap & WSSupportName = InterfaceMap &
      WSSupportName,
  >(
    api: Api,
    methodName: MethodName,
  ): BatchFetcher<InterfaceMap, WSSupportName, Key> =>
  async (ids: (number | string)[]): Promise<InterfaceMap[Key][]> =>
    (
      await new ApiRequest(api, methodName, {
        where: { id: { in: ids } },
        perPage: ids.length,
      } as ApiMethodParams<Api[MethodName]>).fetchAll()
    ).data as InterfaceMap[Key][];

/**
 * This is where we configure how to fetch each entity type from the API.
 *
 * IMPORTANT: When adding to this, you'll also need to update the type mapping in EntityInterfaceMap.
 */
export const entityConfig: EntityConfig<
  EntityInterfaceMap,
  ListEventEntitiesResponseDataEnum,
  EntityFetcherRequestMap
> = {
  [Entity.Absence]: {
    fetcher: genericFetcher(absenceApi, 'listAbsence'),
    entityMaker: AbsenceFromJSON,
    orderBy: ['startedOn'],
  },
  [Entity.AbsenceCategory]: {
    fetcher: genericFetcher(absenceApi, 'listAbsenceCategories'),
    entityMaker: AbsenceCategoryFromJSON,
    orderBy: ['name'],
  },
  [Entity.AccessRole]: {
    fetcher: genericFetcher(accessRoleApi, 'listAccessRoles', {
      with: ['permissions'],
    }),
    entityMaker: AccessRoleFromJSON,
    orderBy: ['name'],
  },
  [Entity.BillingPlan]: {
    fetcher: genericFetcher(billingApi, 'listBillingPlans'),
    entityMaker: BillingPlanFromJSON,
    orderBy: ['name'],
  },
  [Entity.BudgetSummary]: {
    fetcher: singleEntityFetcher(summariesApi, 'showBudgetSummary'),
    entityMaker: BudgetSummaryFromJSON,
    populateStrategy: PopulateStrategy.Disable,
  },
  [Entity.ClockInPortal]: {
    fetcher: genericFetcher(clockInPortalApi, 'listClockInPortals'),
    entityMaker: ClockInPortalFromJSON,
  },
  [Entity.Company]: {
    fetcher: genericFetcher(companyApi, 'listCompaniesBelongingToUser'),
    entityMaker: CompanyFromJSON,
    orderBy: ['name'],
  },
  [Entity.CompanyNotificationChannel]: {
    fetcher: genericFetcher(companyApi, 'listCompanyNotificationChannels'),
    entityMaker: CompanyNotificationChannelFromJSON,
  },
  [Entity.DaySummary]: {
    fetcher: singleEntityFetcher(summariesApi, 'showDaySummary'),
    entityMaker: DaySummaryFromJSON,
    populateStrategy: PopulateStrategy.Disable,
  },
  [Entity.DigiticketsConnection]: {
    fetcher: genericFetcher(integrationApi(), 'listDigiticketsConnections'),
    entityMaker: DigiticketsConnectionFromJSON,
  },
  [Entity.DigiticketsScheduleMap]: {
    fetcher: genericFetcher(integrationApi(), 'listDigiticketsScheduleMaps'),
    entityMaker: DigiticketsScheduleMapFromJSON,
  },
  [Entity.Document]: {
    fetcher: genericFetcher(documentApi, 'listDocuments'),
    entityMaker: DocumentFromJSON,
  },
  [Entity.DocumentRead]: {
    fetcher: genericFetcher(documentApi, 'listDocumentReads'),
    entityMaker: DocumentReadFromJSON,
    orderBy: ['createdAt'],
  },
  [Entity.EmergencyContact]: {
    fetcher: genericFetcher(emergencyContactApi, 'listEmergencyContacts'),
    entityMaker: EmergencyContactFromJSON,
    orderBy: ['name'],
  },
  [Entity.Employee]: {
    fetcher: genericFetcher(employeeApi, 'listEmployees', {
      with: ['photo'],
    }),
    entityMaker: EmployeeFromJSON,
    batchFetcher: genericBatchedFetcher(employeeApi, 'listEmployees'),
    orderBy: ['firstName', 'lastName'],
  },
  [Entity.EmployeeAbsenceSummary]: {
    fetcher: genericFetcher(summariesApi, 'listEmployeeAbsenceSummaries'),
    entityMaker: EmployeeAbsenceSummaryFromJSON,
    populateStrategy: PopulateStrategy.Disable,
  },
  [Entity.EmployeeClockingProfile]: {
    fetcher: genericFetcher(employeeApi, 'listEmployeeClockingProfiles'),
    entityMaker: EmployeeClockingProfileFromJSON,
  },
  [Entity.EmployeeInvite]: {
    fetcher: genericFetcher(employeeInviteApi, 'listEmployeeInvites'),
    entityMaker: EmployeeInviteFromJSON,
    orderBy: ['-createdAt'],
  },
  [Entity.EmployeesJobRole]: {
    fetcher: genericFetcher(employeesJobRoleApi, 'listEmployeesJobRoles', {
      with: ['jobRole'],
    }),
    entityMaker: EmployeesJobRoleFromJSON,
  },
  [Entity.EmployeeLeaveSummary]: {
    fetcher: genericFetcher(summariesApi, 'listEmployeeLeaveSummaries'),
    entityMaker: EmployeeLeaveSummaryFromJSON,
    triggers: [
      Entity.LeaveRequest,
      Entity.Employee,
      Entity.LeaveAdjustment,
      Entity.LeavePeriod,
    ],
    useWebsocket: false,
    populateStrategy: PopulateStrategy.Disable,
  },
  [Entity.EmployeeMessage]: {
    fetcher: genericFetcher(employeeMessageApi, 'listEmployeeMessages'),
    entityMaker: EmployeeMessageFromJSON,
    orderBy: ['sentAt'],
  },
  [Entity.EmployeeMessageRecipient]: {
    fetcher: genericFetcher(
      employeeMessageApi,
      'listEmployeeMessageRecipients',
    ),
    entityMaker: EmployeeMessageRecipientFromJSON,
  },
  [Entity.EmployeePayDetail]: {
    fetcher: genericFetcher(employeeApi, 'listEmployeePayDetail'),
    entityMaker: EmployeePayDetailsFromJSON,
  },
  [Entity.EmployeePersonalDetail]: {
    fetcher: genericFetcher(employeeApi, 'listEmployeePersonalDetails'),
    entityMaker: EmployeePersonalDetailsFromJSON,
  },
  [Entity.EmployeeAttribute]: {
    fetcher: genericFetcher(employeeAttributesApi, 'listEmployeeAttributes'),
    entityMaker: EmployeeAttributeFromJSON,
    orderBy: ['name'],
  },
  [Entity.EmployeeGroup]: {
    fetcher: genericFetcher(employeeGroupApi, 'listEmployeeGroups'),
    entityMaker: EmployeeGroupFromJSON,
  },
  [Entity.EmployeeNotificationChannel]: {
    fetcher: genericFetcher(employeeApi, 'listEmployeeNotificationChannels'),
    entityMaker: EmployeeNotificationChannelFromJSON,
  },
  [Entity.IcalFeed]: {
    fetcher: genericFetcher(calendarApi, 'listCalendarFeeds'),
    entityMaker: IcalFeedFromJSON,
  },
  [Entity.JobRole]: {
    fetcher: genericFetcher(jobRoleApi, 'listJobRoles'),
    entityMaker: JobRoleFromJSON,
    orderBy: ['name'],
  },
  [Entity.OpenShiftResponse]: {
    fetcher: genericFetcher(openShiftResponseApi, 'listOpenShiftResponses'),
    entityMaker: OpenShiftResponseFromJSON,
    orderBy: ['createdAt'],
  },
  [Entity.LeaveAdjustment]: {
    fetcher: genericFetcher(leaveAdjustmentApi, 'listLeaveAdjustments'),
    entityMaker: LeaveAdjustmentFromJSON,
    orderBy: ['createdAt'],
  },
  [Entity.LeavePeriod]: {
    fetcher: genericFetcher(leavePeriodApi, 'listLeavePeriods'),
    entityMaker: LeavePeriodFromJSON,
    triggers: [Entity.LeavePeriodType],
  },
  [Entity.LeavePolicy]: {
    fetcher: genericFetcher(leavePolicyApi, 'listLeavePolicies'),
    entityMaker: LeavePolicyFromJSON,
    orderBy: ['name'],
  },
  [Entity.LeaveRequest]: {
    fetcher: genericFetcher(leaveRequestApi, 'listLeaveRequests'),
    batchFetcher: genericBatchedFetcher(leaveRequestApi, 'listLeaveRequests'),
    entityMaker: LeaveRequestFromJSON,
    orderBy: ['startDate'],
  },
  [Entity.LeaveType]: {
    fetcher: genericFetcher(leaveTypeApi, 'listLeaveTypes'),
    entityMaker: LeaveTypeFromJSON,
    orderBy: ['name'],
  },
  [Entity.Location]: {
    fetcher: genericFetcher(locationApi, 'listLocations'),
    entityMaker: LocationFromJSON,
    orderBy: ['name'],
  },
  [Entity.Offer]: {
    fetcher: genericFetcher(billingApi, 'listOffers'),
    entityMaker: OfferFromJSON,
  },
  [Entity.OffersBehaviour]: {
    fetcher: genericFetcher(billingApi, 'listOffersBehaviours'),
    entityMaker: OffersBehaviourFromJSON,
  },
  [Entity.OffersQuota]: {
    fetcher: genericFetcher(billingApi, 'listOffersQuota'),
    entityMaker: OffersQuotaFromJSON,
  },
  [Entity.PayCycle]: {
    fetcher: genericFetcher(payCycleApi, 'listPayCycles'),
    entityMaker: PayCycleFromJSON,
    orderBy: ['startsOn'],
  },
  [Entity.PayPeriod]: {
    fetcher: genericFetcher(payPeriodApi, 'listPayPeriods'),
    entityMaker: PayPeriodFromJSON,
    orderBy: ['-startsOn'],
  },
  [Entity.PublicHoliday]: {
    fetcher: genericFetcher(publicHolidayApi, 'listPublicHolidays'),
    entityMaker: PublicHolidayFromJSON,
    orderBy: ['date'],
  },
  [Entity.Schedule]: {
    fetcher: genericFetcher(scheduleApi, 'listSchedules'),
    entityMaker: ScheduleFromJSON,
    orderBy: ['name'],
  },
  [Entity.ScheduleEvent]: {
    fetcher: genericFetcher(scheduleEventApi, 'listScheduleEvents'),
    entityMaker: ScheduleEventFromJSON,
    orderBy: ['dateStart'],
  },
  [Entity.ScheduleTemplate]: {
    fetcher: genericFetcher(scheduleTemplateApi, 'listScheduleTemplates'),
    entityMaker: ScheduleTemplateFromJSON,
  },
  [Entity.Shift]: {
    fetcher: genericFetcher(shiftApi, 'listShifts'),
    entityMaker: ShiftFromJSON,
    batchFetcher: genericBatchedFetcher(shiftApi, 'listShifts'),
    orderBy: ['startsAt'],
  },
  [Entity.ShiftSwap]: {
    fetcher: genericFetcher(shiftSwapApi, 'listShiftSwaps'),
    entityMaker: ShiftSwapFromJSON,
  },
  [Entity.ShiftTemplate]: {
    fetcher: genericFetcher(shiftTemplateApi, 'listShiftTemplates'),
    entityMaker: ShiftTemplateFromJSON,
    orderBy: ['startHour', 'startMinute'],
  },
  [Entity.Tag]: {
    fetcher: genericFetcher(tagsApi, 'listTags'),
    entityMaker: TagFromJSON,
    orderBy: ['name'],
  },
  [Entity.TimesheetEntry]: {
    fetcher: genericFetcher(timesheetApi, 'listTimesheetEntries'),
    entityMaker: TimesheetEntryFromJSON,
    batchFetcher: genericBatchedFetcher(timesheetApi, 'listTimesheetEntries'),
    orderBy: ['startedAt'],
  },
  [Entity.TimesheetEntrySummary]: {
    fetcher: genericFetcher(summariesApi, 'listTimesheetSummaries', {
      perPage: 50,
    }),
    entityMaker: TimesheetEntrySummaryFromJSON,
    triggers: [
      Entity.TimesheetEntry,
      Entity.Shift,
      Entity.PayPeriod,
      Entity.Employee,
      Entity.LeaveRequest,
    ],
    useWebsocket: false,
    populateStrategy: PopulateStrategy.Disable,
  },
  [Entity.TrekksoftClientCredential]: {
    fetcher: genericFetcher(integrationApi(), 'listTrekksoftClientCredentials'),
    entityMaker: TrekksoftClientCredentialFromJSON,
  },
  [Entity.Unavailability]: {
    fetcher: genericFetcher(unavailabilityApi, 'listUnavailability'),
    entityMaker: UnavailabilityFromJSON,
    orderBy: ['startsAt'],
  },
  [Entity.Upload]: {
    fetcher: genericFetcher(uploadApi, 'listUploads'),
    entityMaker: UploadFromJSON,
  },
  [Entity.User]: {
    fetcher: genericFetcher(userApi, 'listCompanyUsers'),
    entityMaker: UserFromJSON,
  },
  [Entity.WorkPattern]: {
    fetcher: genericFetcher(workPatternApi, 'listWorkPatterns'),
    entityMaker: WorkPatternFromJSON,
    orderBy: ['name'],
  },
  [Entity.ScheduleOverviewSummary]: {
    fetcher: singleEntityFetcher(summariesApi, 'showScheduleOverview'),
    entityMaker: ScheduleOverviewSummaryFromJSON,
    populateStrategy: PopulateStrategy.Disable,
  },
  [CustomEntity.FeaturesEnabled]: {
    fetcher: singleEntityFetcher(companyApi, 'showEnabledFeatures'),
    entityMaker: FeaturesEnabledFromJSON,
    useWebsocket: false,
    populateStrategy: PopulateStrategy.Disable,
  },
  [Entity.NotificationSetting]: {
    fetcher: singleEntityFetcher(settingsApi, 'showNotificationSettings'),
    entityMaker: NotificationSettingsFromJSON,
    populateStrategy: PopulateStrategy.Disable,
  },
  [Entity.CompanySetting]: {
    fetcher: singleEntityFetcher(companySettingsApi, 'showCompanySettings'),
    entityMaker: CompanySettingFromJSON,
    populateStrategy: PopulateStrategy.Disable,
  },
  [Entity.History]: {
    fetcher: genericFetcher(historyApi, 'listHistory'),
    entityMaker: HistoryFromJSON,
    orderBy: ['changedAt', 'id'],
  },
  [Entity.ShiftArea]: {
    fetcher: genericFetcher(shiftAreaApi, 'listShiftAreas'),
    entityMaker: ShiftAreaFromJSON,
    orderBy: ['name'],
  },
  [CustomEntity.PayPeriodsSummary]: {
    fetcher: singleEntityFetcher(summariesApi, 'showPayPeriodsSummary'),
    entityMaker: PayPeriodsSummaryFromJSON,
    triggers: [Entity.TimesheetEntry, Entity.PayPeriod],
    useWebsocket: false,
    populateStrategy: PopulateStrategy.Disable,
  },
  [CustomEntity.EmployeePayPeriodSummary]: {
    fetcher: genericFetcher(summariesApi, 'listEmployeePayPeriodSummaries'),
    entityMaker: EmployeePayPeriodSummaryFromJSON,
    triggers: [Entity.TimesheetEntry, Entity.PayPeriod],
    useWebsocket: false,
    populateStrategy: PopulateStrategy.Disable,
  },
};
