/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthenticationDataMeta } from './AuthenticationDataMeta';
import {
    AuthenticationDataMetaFromJSON,
    AuthenticationDataMetaFromJSONTyped,
    AuthenticationDataMetaToJSON,
    AuthenticationDataMetaToJSONTyped,
} from './AuthenticationDataMeta';

/**
 * 
 * @export
 * @interface AuthenticationData
 */
export interface AuthenticationData {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationData
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationData
     */
    refresh: string;
    /**
     * 
     * @type {AuthenticationDataMeta}
     * @memberof AuthenticationData
     */
    meta: AuthenticationDataMeta;
}

/**
 * Check if a given object implements the AuthenticationData interface.
 */
export function instanceOfAuthenticationData(value: object): value is AuthenticationData {
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('refresh' in value) || value['refresh'] === undefined) return false;
    if (!('meta' in value) || value['meta'] === undefined) return false;
    return true;
}

export function AuthenticationDataFromJSON(json: any): AuthenticationData {
    return AuthenticationDataFromJSONTyped(json, false);
}

export function AuthenticationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationData {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'refresh': json['refresh'],
        'meta': AuthenticationDataMetaFromJSON(json['meta']),
    };
}

  export function AuthenticationDataToJSON(json: any): AuthenticationData {
      return AuthenticationDataToJSONTyped(json, false);
  }

  export function AuthenticationDataToJSONTyped(value?: AuthenticationData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'token': value['token'],
        'refresh': value['refresh'],
        'meta': AuthenticationDataMetaToJSON(value['meta']),
    };
}

