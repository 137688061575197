/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingDetails } from './BillingDetails';
import {
    BillingDetailsFromJSON,
    BillingDetailsFromJSONTyped,
    BillingDetailsToJSON,
    BillingDetailsToJSONTyped,
} from './BillingDetails';

/**
 * 
 * @export
 * @interface CreateBillingDetailsResponse
 */
export interface CreateBillingDetailsResponse {
    /**
     * 
     * @type {BillingDetails}
     * @memberof CreateBillingDetailsResponse
     */
    data: BillingDetails;
}

/**
 * Check if a given object implements the CreateBillingDetailsResponse interface.
 */
export function instanceOfCreateBillingDetailsResponse(value: object): value is CreateBillingDetailsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateBillingDetailsResponseFromJSON(json: any): CreateBillingDetailsResponse {
    return CreateBillingDetailsResponseFromJSONTyped(json, false);
}

export function CreateBillingDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBillingDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BillingDetailsFromJSON(json['data']),
    };
}

  export function CreateBillingDetailsResponseToJSON(json: any): CreateBillingDetailsResponse {
      return CreateBillingDetailsResponseToJSONTyped(json, false);
  }

  export function CreateBillingDetailsResponseToJSONTyped(value?: CreateBillingDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': BillingDetailsToJSON(value['data']),
    };
}

