/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveRequest } from './LeaveRequest';
import {
    LeaveRequestFromJSON,
    LeaveRequestFromJSONTyped,
    LeaveRequestToJSON,
    LeaveRequestToJSONTyped,
} from './LeaveRequest';

/**
 * 
 * @export
 * @interface ApproveLeaveRequestResponse
 */
export interface ApproveLeaveRequestResponse {
    /**
     * 
     * @type {LeaveRequest}
     * @memberof ApproveLeaveRequestResponse
     */
    data: LeaveRequest;
}

/**
 * Check if a given object implements the ApproveLeaveRequestResponse interface.
 */
export function instanceOfApproveLeaveRequestResponse(value: object): value is ApproveLeaveRequestResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ApproveLeaveRequestResponseFromJSON(json: any): ApproveLeaveRequestResponse {
    return ApproveLeaveRequestResponseFromJSONTyped(json, false);
}

export function ApproveLeaveRequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApproveLeaveRequestResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeaveRequestFromJSON(json['data']),
    };
}

  export function ApproveLeaveRequestResponseToJSON(json: any): ApproveLeaveRequestResponse {
      return ApproveLeaveRequestResponseToJSONTyped(json, false);
  }

  export function ApproveLeaveRequestResponseToJSONTyped(value?: ApproveLeaveRequestResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': LeaveRequestToJSON(value['data']),
    };
}

