/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
    DocumentToJSONTyped,
} from './Document';

/**
 * 
 * @export
 * @interface CreateDocumentResponse
 */
export interface CreateDocumentResponse {
    /**
     * 
     * @type {Document}
     * @memberof CreateDocumentResponse
     */
    data: Document;
}

/**
 * Check if a given object implements the CreateDocumentResponse interface.
 */
export function instanceOfCreateDocumentResponse(value: object): value is CreateDocumentResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateDocumentResponseFromJSON(json: any): CreateDocumentResponse {
    return CreateDocumentResponseFromJSONTyped(json, false);
}

export function CreateDocumentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDocumentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': DocumentFromJSON(json['data']),
    };
}

  export function CreateDocumentResponseToJSON(json: any): CreateDocumentResponse {
      return CreateDocumentResponseToJSONTyped(json, false);
  }

  export function CreateDocumentResponseToJSONTyped(value?: CreateDocumentResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': DocumentToJSON(value['data']),
    };
}

