/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDocumentData
 */
export interface UpdateDocumentData {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentData
     */
    name?: string;
    /**
     * Whether the document should be visible to all employees
     * @type {boolean}
     * @memberof UpdateDocumentData
     */
    visibleToAll?: boolean;
    /**
     * These employees should be able to view this document
     * @type {Array<number>}
     * @memberof UpdateDocumentData
     */
    employeeIds?: Array<number> | null;
    /**
     * Employees with these job roles should be able to view this document
     * @type {Array<number>}
     * @memberof UpdateDocumentData
     */
    jobRoleIds?: Array<number> | null;
    /**
     * Indicates whether the document must be read by employees, and if so, by when
     * @type {Date}
     * @memberof UpdateDocumentData
     */
    readDeadline?: Date | null;
}

/**
 * Check if a given object implements the UpdateDocumentData interface.
 */
export function instanceOfUpdateDocumentData(value: object): value is UpdateDocumentData {
    return true;
}

export function UpdateDocumentDataFromJSON(json: any): UpdateDocumentData {
    return UpdateDocumentDataFromJSONTyped(json, false);
}

export function UpdateDocumentDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDocumentData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'visibleToAll': json['visibleToAll'] == null ? undefined : json['visibleToAll'],
        'employeeIds': json['employeeIds'] == null ? undefined : json['employeeIds'],
        'jobRoleIds': json['jobRoleIds'] == null ? undefined : json['jobRoleIds'],
        'readDeadline': json['readDeadline'] == null ? undefined : (new Date(json['readDeadline'])),
    };
}

  export function UpdateDocumentDataToJSON(json: any): UpdateDocumentData {
      return UpdateDocumentDataToJSONTyped(json, false);
  }

  export function UpdateDocumentDataToJSONTyped(value?: UpdateDocumentData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'visibleToAll': value['visibleToAll'],
        'employeeIds': value['employeeIds'],
        'jobRoleIds': value['jobRoleIds'],
        'readDeadline': value['readDeadline'] == null ? undefined : ((value['readDeadline'] as any).toISOString()),
    };
}

