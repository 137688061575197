import {
  AbsenceApi,
  AccessRoleApi,
  BillingApi,
  CacheMeta,
  CalendarApi,
  ClockingApi,
  ClockInPortalApi,
  CompanyApi,
  DocumentApi,
  EmergencyContactApi,
  EmployeeApi,
  EmployeeAttributeApi,
  EmployeeGroupApi,
  EmployeeInviteApi,
  EmployeeMessageApi,
  EmployeesJobRoleApi,
  ExternalApi,
  HistoryApi,
  IntegrationApi,
  JobRoleApi,
  LeaveAdjustmentApi,
  LeavePeriodApi,
  LeavePolicyApi,
  LeaveRequestApi,
  LeaveTypeApi,
  LocationApi,
  NotificationApi,
  OpenShiftResponseApi,
  Pagination,
  PayCycleApi,
  PayPeriodApi,
  PublicHolidayApi,
  PushApi,
  ReportCostsByDay,
  ReportCostsByDayRollup,
  ReportCostsByEmployee,
  ReportCostsByEmployeeRollup,
  ReportCostsByEmploymentType,
  ReportCostsByEmploymentTypeRollup,
  ReportCostsByGroup,
  ReportCostsByGroupRollup,
  ReportCostsByJobRole,
  ReportCostsByJobRoleRollup,
  ReportCostsByLocation,
  ReportCostsByLocationRollup,
  ReportsApi,
  ScheduleApi,
  ScheduleEventApi,
  ScheduleTemplateApi,
  SettingsApi,
  ShiftApi,
  ShiftAreaApi,
  ShiftSwapApi,
  ShiftTemplateApi,
  SummariesApi,
  TagsApi,
  TimesheetApi,
  TimezoneApi,
  UnavailabilityApi,
  UploadApi,
  UserApi,
  WorkPatternApi,
} from '@/../api/v1';
import { authApiFactory, v1ApiFactory } from '@/lib/api/factory';
import {
  addUriHeader,
  includeCredentials,
  recordApiVersion,
} from '@/lib/api/middleware';
import { ApiVersion, ApiVersionTypeMap } from '@/lib/api/types';
import { ConditionBuilder } from '@/lib/helpers/ConditionBuilder';
import { ItemType, KeysMatchingType } from '@/util/objectFunctions';
import {
  AuthenticationApi,
  OauthApi,
  TokenApi,
  UserApi as AuthUsersApi,
} from '../api/auth';

type GenericApiResponseRollupType<T> = T extends ReportCostsByDay
  ? ReportCostsByDayRollup
  : T extends ReportCostsByEmployee
  ? ReportCostsByEmployeeRollup
  : T extends ReportCostsByEmploymentType
  ? ReportCostsByEmploymentTypeRollup
  : T extends ReportCostsByGroup
  ? ReportCostsByGroupRollup
  : T extends ReportCostsByJobRole
  ? ReportCostsByJobRoleRollup
  : T extends ReportCostsByLocation
  ? ReportCostsByLocationRollup
  : never;

export interface GenericApiResponse<T> {
  data: Array<T>;
  pagination: Pagination;
  cache?: CacheMeta;
  rollup?: GenericApiResponseRollupType<T>;
}

export interface SingleEntityApiResponse<T> {
  data: T;
}

/**
 * Allows you to fetch all the results for a paginated API response (which is any list action!). Only use this when
 * you have a finite set of results (eg. list of countries, timezones, currencies, etc). Responses that could grow
 * over time (usually those that return user-data) should be handled via other approaches such as lazy-loading,
 * pagination controls, etc.
 *
 * To use this function replace:
 *   somethingApi.listSomething(params)
 *   ...with...
 *   fetchAll(somethingApi.listSomething.bind(somethingApi), params)
 */
export const fetchAll = async <T, P>(
  apiMethod: (query) => Promise<GenericApiResponse<T>>,
  params: P | object = {},
  page: number = 1,
): Promise<GenericApiResponse<T>> => {
  const { data, pagination, cache, rollup } = await apiMethod({
    ...params,
    page,
  });
  let results = [...data];
  const generalRollup = rollup ? { ...rollup } : null;

  if (pagination.lastPage > pagination.currentPage) {
    const promises = [];
    for (let i = pagination.currentPage + 1; i <= pagination.lastPage; i += 1) {
      promises.push(apiMethod({ ...params, page: i }));
    }
    const responses = await Promise.all(promises);
    responses.forEach((response) => {
      results = results.concat(response.data);

      if (generalRollup && response.rollup) {
        Object.keys(generalRollup).forEach((field) => {
          generalRollup[field] += response.rollup[field];
        });
      }
    });
  }

  const fakedPagination: Pagination = {
    from: 1,
    lastPage: 1,
    lastPageUrl: pagination.firstPageUrl,
    nextPageUrl: undefined,
    path: pagination.path,
    perPage: 0,
    prevPageUrl: undefined,
    total: results.length,
    currentPage: 1,
    firstPageUrl: pagination.firstPageUrl,
    to: results.length,
  };

  return {
    data: results,
    pagination: fakedPagination,
    ...(cache && { cache }),
    ...(generalRollup && { rollup: generalRollup }),
  };
};

export type VersionApiListResponse<
  Version extends ApiVersion = ApiVersion,
  Value extends any = any,
> = {
  data: Array<Value>;
  pagination: ApiVersionTypeMap[Version]['Pagination'];
  cache?: ApiVersionTypeMap[Version]['CacheMeta'];
  rollup?: Record<string, number>;
};

export type VersionApiShowResponse<Value extends unknown = unknown> = {
  data: Value;
};

export type ApiListMethod<
  Version extends ApiVersion,
  Params = any,
  Return extends VersionApiListResponse<Version> = VersionApiListResponse<Version>,
> = (a: Params, ...args: unknown[]) => Promise<Return>;

export type ApiShowMethod<
  Params = any,
  Return extends VersionApiShowResponse = VersionApiShowResponse,
> = (a: Params, ...args: unknown[]) => Promise<Return>;

export type ApiListMethodName<
  Version extends ApiVersion,
  Api extends ApiVersionTypeMap[Version]['BaseApi'],
> = Exclude<
  KeysMatchingType<Api, ApiListMethod<Version>> & `list${string}`,
  symbol | number
>;

export type ApiShowMethodName<
  Version extends ApiVersion,
  Api extends ApiVersionTypeMap[Version]['BaseApi'],
> = Exclude<
  KeysMatchingType<Api, ApiShowMethod> & `show${string}`,
  symbol | number
>;

export type ApiMethodParams<T> = T extends (...args: any) => any
  ? Parameters<T>[0]
  : never;

export type ApiMethodResponse<
  Version extends ApiVersion,
  Api extends ApiVersionTypeMap[Version]['BaseApi'],
  Key extends string,
> = Key extends keyof Api
  ? Api[Key] extends (...args: any) => Promise<VersionApiListResponse<Version>>
    ? ReturnType<Api[Key]>
    : never
  : never;

export class ApiRequest<
  Version extends ApiVersion = ApiVersion,
  Api extends ApiVersionTypeMap[Version]['BaseApi'] = ApiVersionTypeMap[Version]['BaseApi'],
  MethodName extends ApiListMethodName<Version, Api> = ApiListMethodName<
    Version,
    Api
  >,
  Params extends ApiMethodParams<Api[MethodName]> = ApiMethodParams<
    Api[MethodName]
  >,
  MethodReturn extends Awaited<
    ApiMethodResponse<Version, Api, MethodName>
  > = Awaited<ApiMethodResponse<Version, Api, MethodName>>,
  Entity extends ItemType<MethodReturn['data']> = ItemType<
    MethodReturn['data']
  >,
  ConditionEntity extends Entity extends object
    ? Entity
    : never = Entity extends object ? Entity : never,
> extends ConditionBuilder<ConditionEntity> {
  constructor(
    private readonly api: Api,
    private readonly methodName: MethodName,
    private readonly params: Params = {} as Params,
  ) {
    super();
  }

  #fetch(overrides?: object): Promise<MethodReturn> {
    return (
      this.api[this.methodName] as ApiListMethod<Version, Params, MethodReturn>
    )({
      where: this.conditions,
      ...(this.orderOptions.length && { orderBy: this.orderOptions }),
      ...this.params,
      ...overrides,
    });
  }

  fetch(): Promise<MethodReturn> {
    return this.#fetch();
  }

  /**
   *     Allows you to fetch all the results for a paginated API response (which is any list action!).
   *     Only use this when you have a finite set of results (e.g., list of countries, timezones, currencies, etc.).
   *     Responses that could grow over time (usually those that return user-data)
   *     should be handled via other approaches such as lazy-loading, pagination controls, etc.
   */
  async fetchAll(): Promise<MethodReturn> {
    const FIRST_PAGE = 1;

    const response = await this.#fetch({ page: FIRST_PAGE });
    const { pagination, data, rollup } = response;

    if (pagination.lastPage <= FIRST_PAGE) return response;

    const rollupKeys = Object.keys(rollup ?? {});
    (
      await Promise.all(
        Array.from({ length: pagination.lastPage - FIRST_PAGE }, (_, i) =>
          this.#fetch({ page: i + 1 + FIRST_PAGE }),
        ),
      )
    ).forEach((v) => {
      data.push(...v.data);
      if (rollup) {
        rollupKeys.forEach((k) => {
          rollup[k] += v.rollup[k];
        });
      }
    });

    pagination.lastPageUrl = pagination.firstPageUrl;
    pagination.nextPageUrl = undefined;
    pagination.perPage = data.length;
    pagination.total = data.length;
    pagination.to = data.length;

    return response;
  }
}

// @ToDo, these can all be converted into function calls now that we've got the ability to dynamically
// create APIs with the apiFactory. This should also reduce the amount of in-memory data we're holding
// as we aren't instantiating _ALL_ APIs for every client, rather only instantiating when they are needed.
//
// Example: export const absenceApi = (c: FactoryConfig = defaultFactoryConfig) => apiFactory.create(AbsenceApi, c)
// Usage: absenceApi().listAbsences() OR absenceApi({ foo: bar }).listAbsences()
//

// Register custom type mappings
authApiFactory.registerCustomDefaultConfiguration(
  AuthenticationApi,
  authApiFactory.makeConfigurationParameters({
    middleware: [
      authApiFactory.createMiddleware(includeCredentials),
      authApiFactory.createMiddleware(recordApiVersion),
      authApiFactory.createMiddleware(addUriHeader),
    ],
  }),
);
export const absenceApi = v1ApiFactory.create(AbsenceApi);
export const accessRoleApi = v1ApiFactory.create(AccessRoleApi);
export const authApi = authApiFactory.create(AuthenticationApi);
export const authUsersApi = authApiFactory.create(AuthUsersApi);
export const billingApi = v1ApiFactory.create(BillingApi);
export const calendarApi = v1ApiFactory.create(CalendarApi);
export const clockingsApi = v1ApiFactory.create(ClockingApi);
export const clockingSettingsApi = v1ApiFactory.create(SettingsApi);
export const clockInPortalApi = v1ApiFactory.create(ClockInPortalApi);
export const companyApi = v1ApiFactory.create(CompanyApi);
export const companySettingsApi = v1ApiFactory.create(SettingsApi);
export const documentApi = v1ApiFactory.create(DocumentApi);
export const emergencyContactApi = v1ApiFactory.create(EmergencyContactApi);
export const employeeApi = v1ApiFactory.create(EmployeeApi);
export const employeeAttributesApi = v1ApiFactory.create(EmployeeAttributeApi);
export const employeeGroupApi = v1ApiFactory.create(EmployeeGroupApi);
export const employeeInviteApi = v1ApiFactory.create(EmployeeInviteApi);
export const employeesJobRoleApi = v1ApiFactory.create(EmployeesJobRoleApi);
export const employeeMessageApi = v1ApiFactory.create(EmployeeMessageApi);
export const externalApi = v1ApiFactory.create(ExternalApi);
export const historyApi = v1ApiFactory.create(HistoryApi);
export const integrationApi = v1ApiFactory.prepare(IntegrationApi);
export const jobRoleApi = v1ApiFactory.create(JobRoleApi);
export const leaveAdjustmentApi = v1ApiFactory.create(LeaveAdjustmentApi);
export const leavePeriodApi = v1ApiFactory.create(LeavePeriodApi);
export const leavePolicyApi = v1ApiFactory.create(LeavePolicyApi);
export const leaveRequestApi = v1ApiFactory.create(LeaveRequestApi);
export const leaveSettingsApi = v1ApiFactory.create(SettingsApi);
export const leaveTypeApi = v1ApiFactory.create(LeaveTypeApi);
export const locationApi = v1ApiFactory.create(LocationApi);
export const notificationApi = v1ApiFactory.create(NotificationApi);
export const settingsApi = v1ApiFactory.create(SettingsApi);

export const oauthApi = authApiFactory.create(OauthApi);
export const openShiftResponseApi = v1ApiFactory.create(OpenShiftResponseApi);
export const payCycleApi = v1ApiFactory.create(PayCycleApi);
export const payPeriodApi = v1ApiFactory.create(PayPeriodApi);
export const publicHolidayApi = v1ApiFactory.create(PublicHolidayApi);
export const pushApi = v1ApiFactory.create(PushApi);
export const reportsApi = v1ApiFactory.create(ReportsApi);
export const scheduleApi = v1ApiFactory.create(ScheduleApi);
export const scheduleEventApi = v1ApiFactory.create(ScheduleEventApi);
export const scheduleSettingsApi = v1ApiFactory.create(SettingsApi);
export const scheduleTemplateApi = v1ApiFactory.create(ScheduleTemplateApi);
export const shiftApi = v1ApiFactory.create(ShiftApi);
export const shiftSwapApi = v1ApiFactory.create(ShiftSwapApi);
export const shiftTemplateApi = v1ApiFactory.create(ShiftTemplateApi);
export const shiftAreaApi = v1ApiFactory.create(ShiftAreaApi);
export const summariesApi = v1ApiFactory.create(SummariesApi);
export const tagsApi = v1ApiFactory.create(TagsApi);
export const timesheetApi = v1ApiFactory.create(TimesheetApi);
export const timezoneApi = v1ApiFactory.create(TimezoneApi);
export const tokenApi = authApiFactory.create(TokenApi);
export const unavailabilityApi = v1ApiFactory.create(UnavailabilityApi);
export const uploadApi = v1ApiFactory.create(UploadApi);
export const userApi = v1ApiFactory.create(UserApi);
export const workPatternApi = v1ApiFactory.create(WorkPatternApi);
