/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { ShiftSwap } from './ShiftSwap';
import {
    ShiftSwapFromJSON,
    ShiftSwapFromJSONTyped,
    ShiftSwapToJSON,
    ShiftSwapToJSONTyped,
} from './ShiftSwap';

/**
 * 
 * @export
 * @interface SearchShiftSwapsResponse
 */
export interface SearchShiftSwapsResponse {
    /**
     * 
     * @type {Array<ShiftSwap>}
     * @memberof SearchShiftSwapsResponse
     */
    data: Array<ShiftSwap>;
    /**
     * 
     * @type {Pagination}
     * @memberof SearchShiftSwapsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the SearchShiftSwapsResponse interface.
 */
export function instanceOfSearchShiftSwapsResponse(value: object): value is SearchShiftSwapsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function SearchShiftSwapsResponseFromJSON(json: any): SearchShiftSwapsResponse {
    return SearchShiftSwapsResponseFromJSONTyped(json, false);
}

export function SearchShiftSwapsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchShiftSwapsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ShiftSwapFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function SearchShiftSwapsResponseToJSON(json: any): SearchShiftSwapsResponse {
      return SearchShiftSwapsResponseToJSONTyped(json, false);
  }

  export function SearchShiftSwapsResponseToJSONTyped(value?: SearchShiftSwapsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ShiftSwapToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

