/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestPhoneVerificationData
 */
export interface RequestPhoneVerificationData {
    /**
     * Optionally specify a new phone number
     * @type {string}
     * @memberof RequestPhoneVerificationData
     */
    phoneNumber?: string;
}

/**
 * Check if a given object implements the RequestPhoneVerificationData interface.
 */
export function instanceOfRequestPhoneVerificationData(value: object): value is RequestPhoneVerificationData {
    return true;
}

export function RequestPhoneVerificationDataFromJSON(json: any): RequestPhoneVerificationData {
    return RequestPhoneVerificationDataFromJSONTyped(json, false);
}

export function RequestPhoneVerificationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestPhoneVerificationData {
    if (json == null) {
        return json;
    }
    return {
        
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
    };
}

  export function RequestPhoneVerificationDataToJSON(json: any): RequestPhoneVerificationData {
      return RequestPhoneVerificationDataToJSONTyped(json, false);
  }

  export function RequestPhoneVerificationDataToJSONTyped(value?: RequestPhoneVerificationData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'phoneNumber': value['phoneNumber'],
    };
}

