/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClockedInSummary } from './ClockedInSummary';
import {
    ClockedInSummaryFromJSON,
    ClockedInSummaryFromJSONTyped,
    ClockedInSummaryToJSON,
    ClockedInSummaryToJSONTyped,
} from './ClockedInSummary';

/**
 * 
 * @export
 * @interface ShowClockedInSummaryResponse
 */
export interface ShowClockedInSummaryResponse {
    /**
     * 
     * @type {ClockedInSummary}
     * @memberof ShowClockedInSummaryResponse
     */
    data: ClockedInSummary;
}

/**
 * Check if a given object implements the ShowClockedInSummaryResponse interface.
 */
export function instanceOfShowClockedInSummaryResponse(value: object): value is ShowClockedInSummaryResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowClockedInSummaryResponseFromJSON(json: any): ShowClockedInSummaryResponse {
    return ShowClockedInSummaryResponseFromJSONTyped(json, false);
}

export function ShowClockedInSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowClockedInSummaryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ClockedInSummaryFromJSON(json['data']),
    };
}

  export function ShowClockedInSummaryResponseToJSON(json: any): ShowClockedInSummaryResponse {
      return ShowClockedInSummaryResponseToJSONTyped(json, false);
  }

  export function ShowClockedInSummaryResponseToJSONTyped(value?: ShowClockedInSummaryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ClockedInSummaryToJSON(value['data']),
    };
}

