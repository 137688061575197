/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimesheetBreak
 */
export interface TimesheetBreak {
    /**
     * 
     * @type {number}
     * @memberof TimesheetBreak
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetBreak
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetBreak
     */
    scheduledBreakId: number | null;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetBreak
     */
    startedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetBreak
     */
    endedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetBreak
     */
    startedAtRounded: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetBreak
     */
    endedAtRounded: Date | null;
    /**
     * 
     * @type {number}
     * @memberof TimesheetBreak
     */
    durationInMinutes: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TimesheetBreak
     */
    paid: boolean;
}

/**
 * Check if a given object implements the TimesheetBreak interface.
 */
export function instanceOfTimesheetBreak(value: object): value is TimesheetBreak {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('scheduledBreakId' in value) || value['scheduledBreakId'] === undefined) return false;
    if (!('startedAt' in value) || value['startedAt'] === undefined) return false;
    if (!('endedAt' in value) || value['endedAt'] === undefined) return false;
    if (!('startedAtRounded' in value) || value['startedAtRounded'] === undefined) return false;
    if (!('endedAtRounded' in value) || value['endedAtRounded'] === undefined) return false;
    if (!('durationInMinutes' in value) || value['durationInMinutes'] === undefined) return false;
    if (!('paid' in value) || value['paid'] === undefined) return false;
    return true;
}

export function TimesheetBreakFromJSON(json: any): TimesheetBreak {
    return TimesheetBreakFromJSONTyped(json, false);
}

export function TimesheetBreakFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimesheetBreak {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'scheduledBreakId': json['scheduledBreakId'],
        'startedAt': (new Date(json['startedAt'])),
        'endedAt': (json['endedAt'] == null ? null : new Date(json['endedAt'])),
        'startedAtRounded': (new Date(json['startedAtRounded'])),
        'endedAtRounded': (json['endedAtRounded'] == null ? null : new Date(json['endedAtRounded'])),
        'durationInMinutes': json['durationInMinutes'],
        'paid': json['paid'],
    };
}

  export function TimesheetBreakToJSON(json: any): TimesheetBreak {
      return TimesheetBreakToJSONTyped(json, false);
  }

  export function TimesheetBreakToJSONTyped(value?: TimesheetBreak | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'scheduledBreakId': value['scheduledBreakId'],
        'startedAt': ((value['startedAt']).toISOString()),
        'endedAt': (value['endedAt'] == null ? null : (value['endedAt'] as any).toISOString()),
        'startedAtRounded': ((value['startedAtRounded']).toISOString()),
        'endedAtRounded': (value['endedAtRounded'] == null ? null : (value['endedAtRounded'] as any).toISOString()),
        'durationInMinutes': value['durationInMinutes'],
        'paid': value['paid'],
    };
}

