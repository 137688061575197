/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A geographic region for which we have national/public holiday data
 * @export
 * @interface HolidayRegion
 */
export interface HolidayRegion {
    /**
     * 
     * @type {number}
     * @memberof HolidayRegion
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof HolidayRegion
     */
    name: string;
}

/**
 * Check if a given object implements the HolidayRegion interface.
 */
export function instanceOfHolidayRegion(value: object): value is HolidayRegion {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function HolidayRegionFromJSON(json: any): HolidayRegion {
    return HolidayRegionFromJSONTyped(json, false);
}

export function HolidayRegionFromJSONTyped(json: any, ignoreDiscriminator: boolean): HolidayRegion {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
    };
}

  export function HolidayRegionToJSON(json: any): HolidayRegion {
      return HolidayRegionToJSONTyped(json, false);
  }

  export function HolidayRegionToJSONTyped(value?: HolidayRegion | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

