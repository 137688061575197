/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeAttribute } from './EmployeeAttribute';
import {
    EmployeeAttributeFromJSON,
    EmployeeAttributeFromJSONTyped,
    EmployeeAttributeToJSON,
    EmployeeAttributeToJSONTyped,
} from './EmployeeAttribute';

/**
 * 
 * @export
 * @interface ShowEmployeeAttributeResponse
 */
export interface ShowEmployeeAttributeResponse {
    /**
     * 
     * @type {EmployeeAttribute}
     * @memberof ShowEmployeeAttributeResponse
     */
    data: EmployeeAttribute;
}

/**
 * Check if a given object implements the ShowEmployeeAttributeResponse interface.
 */
export function instanceOfShowEmployeeAttributeResponse(value: object): value is ShowEmployeeAttributeResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowEmployeeAttributeResponseFromJSON(json: any): ShowEmployeeAttributeResponse {
    return ShowEmployeeAttributeResponseFromJSONTyped(json, false);
}

export function ShowEmployeeAttributeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeAttributeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeAttributeFromJSON(json['data']),
    };
}

  export function ShowEmployeeAttributeResponseToJSON(json: any): ShowEmployeeAttributeResponse {
      return ShowEmployeeAttributeResponseToJSONTyped(json, false);
  }

  export function ShowEmployeeAttributeResponseToJSONTyped(value?: ShowEmployeeAttributeResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': EmployeeAttributeToJSON(value['data']),
    };
}

