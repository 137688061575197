/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScheduleSettings } from './ScheduleSettings';
import {
    ScheduleSettingsFromJSON,
    ScheduleSettingsFromJSONTyped,
    ScheduleSettingsToJSON,
    ScheduleSettingsToJSONTyped,
} from './ScheduleSettings';

/**
 * 
 * @export
 * @interface UpdateScheduleSettingsResponse
 */
export interface UpdateScheduleSettingsResponse {
    /**
     * 
     * @type {ScheduleSettings}
     * @memberof UpdateScheduleSettingsResponse
     */
    data: ScheduleSettings;
}

/**
 * Check if a given object implements the UpdateScheduleSettingsResponse interface.
 */
export function instanceOfUpdateScheduleSettingsResponse(value: object): value is UpdateScheduleSettingsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateScheduleSettingsResponseFromJSON(json: any): UpdateScheduleSettingsResponse {
    return UpdateScheduleSettingsResponseFromJSONTyped(json, false);
}

export function UpdateScheduleSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateScheduleSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ScheduleSettingsFromJSON(json['data']),
    };
}

  export function UpdateScheduleSettingsResponseToJSON(json: any): UpdateScheduleSettingsResponse {
      return UpdateScheduleSettingsResponseToJSONTyped(json, false);
  }

  export function UpdateScheduleSettingsResponseToJSONTyped(value?: UpdateScheduleSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ScheduleSettingsToJSON(value['data']),
    };
}

