/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendEmployeeInvitesData
 */
export interface SendEmployeeInvitesData {
    /**
     * An optional list of employeeIds to invite - an empty list will invite all unconfirmed employees
     * @type {Array<number>}
     * @memberof SendEmployeeInvitesData
     */
    employeeIds?: Array<number>;
}

/**
 * Check if a given object implements the SendEmployeeInvitesData interface.
 */
export function instanceOfSendEmployeeInvitesData(value: object): value is SendEmployeeInvitesData {
    return true;
}

export function SendEmployeeInvitesDataFromJSON(json: any): SendEmployeeInvitesData {
    return SendEmployeeInvitesDataFromJSONTyped(json, false);
}

export function SendEmployeeInvitesDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendEmployeeInvitesData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeIds': json['employeeIds'] == null ? undefined : json['employeeIds'],
    };
}

  export function SendEmployeeInvitesDataToJSON(json: any): SendEmployeeInvitesData {
      return SendEmployeeInvitesDataToJSONTyped(json, false);
  }

  export function SendEmployeeInvitesDataToJSONTyped(value?: SendEmployeeInvitesData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeIds': value['employeeIds'],
    };
}

