/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeePayPeriodJobRoleRate
 */
export interface EmployeePayPeriodJobRoleRate {
    /**
     * 
     * @type {number}
     * @memberof EmployeePayPeriodJobRoleRate
     */
    jobRoleId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePayPeriodJobRoleRate
     */
    basePaidHours: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePayPeriodJobRoleRate
     */
    overtimePaidHours: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePayPeriodJobRoleRate
     */
    totalPaidHours: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePayPeriodJobRoleRate
     */
    rate: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePayPeriodJobRoleRate
     */
    estimatedTotal: number;
}

/**
 * Check if a given object implements the EmployeePayPeriodJobRoleRate interface.
 */
export function instanceOfEmployeePayPeriodJobRoleRate(value: object): value is EmployeePayPeriodJobRoleRate {
    if (!('jobRoleId' in value) || value['jobRoleId'] === undefined) return false;
    if (!('basePaidHours' in value) || value['basePaidHours'] === undefined) return false;
    if (!('overtimePaidHours' in value) || value['overtimePaidHours'] === undefined) return false;
    if (!('totalPaidHours' in value) || value['totalPaidHours'] === undefined) return false;
    if (!('rate' in value) || value['rate'] === undefined) return false;
    if (!('estimatedTotal' in value) || value['estimatedTotal'] === undefined) return false;
    return true;
}

export function EmployeePayPeriodJobRoleRateFromJSON(json: any): EmployeePayPeriodJobRoleRate {
    return EmployeePayPeriodJobRoleRateFromJSONTyped(json, false);
}

export function EmployeePayPeriodJobRoleRateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeePayPeriodJobRoleRate {
    if (json == null) {
        return json;
    }
    return {
        
        'jobRoleId': json['jobRoleId'],
        'basePaidHours': json['basePaidHours'],
        'overtimePaidHours': json['overtimePaidHours'],
        'totalPaidHours': json['totalPaidHours'],
        'rate': json['rate'],
        'estimatedTotal': json['estimatedTotal'],
    };
}

  export function EmployeePayPeriodJobRoleRateToJSON(json: any): EmployeePayPeriodJobRoleRate {
      return EmployeePayPeriodJobRoleRateToJSONTyped(json, false);
  }

  export function EmployeePayPeriodJobRoleRateToJSONTyped(value?: EmployeePayPeriodJobRoleRate | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'jobRoleId': value['jobRoleId'],
        'basePaidHours': value['basePaidHours'],
        'overtimePaidHours': value['overtimePaidHours'],
        'totalPaidHours': value['totalPaidHours'],
        'rate': value['rate'],
        'estimatedTotal': value['estimatedTotal'],
    };
}

