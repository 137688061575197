/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimesheetEntry } from './TimesheetEntry';
import {
    TimesheetEntryFromJSON,
    TimesheetEntryFromJSONTyped,
    TimesheetEntryToJSON,
    TimesheetEntryToJSONTyped,
} from './TimesheetEntry';

/**
 * 
 * @export
 * @interface CreateTimesheetEntryResponse
 */
export interface CreateTimesheetEntryResponse {
    /**
     * 
     * @type {TimesheetEntry}
     * @memberof CreateTimesheetEntryResponse
     */
    data: TimesheetEntry;
}

/**
 * Check if a given object implements the CreateTimesheetEntryResponse interface.
 */
export function instanceOfCreateTimesheetEntryResponse(value: object): value is CreateTimesheetEntryResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateTimesheetEntryResponseFromJSON(json: any): CreateTimesheetEntryResponse {
    return CreateTimesheetEntryResponseFromJSONTyped(json, false);
}

export function CreateTimesheetEntryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTimesheetEntryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': TimesheetEntryFromJSON(json['data']),
    };
}

  export function CreateTimesheetEntryResponseToJSON(json: any): CreateTimesheetEntryResponse {
      return CreateTimesheetEntryResponseToJSONTyped(json, false);
  }

  export function CreateTimesheetEntryResponseToJSONTyped(value?: CreateTimesheetEntryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': TimesheetEntryToJSON(value['data']),
    };
}

