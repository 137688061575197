/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PendingUpload
 */
export interface PendingUpload {
    /**
     * 
     * @type {number}
     * @memberof PendingUpload
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PendingUpload
     */
    uploadUrl: string;
}

/**
 * Check if a given object implements the PendingUpload interface.
 */
export function instanceOfPendingUpload(value: object): value is PendingUpload {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('uploadUrl' in value) || value['uploadUrl'] === undefined) return false;
    return true;
}

export function PendingUploadFromJSON(json: any): PendingUpload {
    return PendingUploadFromJSONTyped(json, false);
}

export function PendingUploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): PendingUpload {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'uploadUrl': json['uploadUrl'],
    };
}

  export function PendingUploadToJSON(json: any): PendingUpload {
      return PendingUploadToJSONTyped(json, false);
  }

  export function PendingUploadToJSONTyped(value?: PendingUpload | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'uploadUrl': value['uploadUrl'],
    };
}

