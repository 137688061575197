/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * Update a Work Pattern Override for an employee.
 * @export
 * @interface UpdateWorkPatternOverrideData
 */
export interface UpdateWorkPatternOverrideData {
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof UpdateWorkPatternOverrideData
     */
    date?: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateWorkPatternOverrideData
     */
    isWorkingDay?: boolean;
}

/**
 * Check if a given object implements the UpdateWorkPatternOverrideData interface.
 */
export function instanceOfUpdateWorkPatternOverrideData(value: object): value is UpdateWorkPatternOverrideData {
    return true;
}

export function UpdateWorkPatternOverrideDataFromJSON(json: any): UpdateWorkPatternOverrideData {
    return UpdateWorkPatternOverrideDataFromJSONTyped(json, false);
}

export function UpdateWorkPatternOverrideDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkPatternOverrideData {
    if (json == null) {
        return json;
    }
    return {
        
        'date': json['date'] == null ? undefined : ShiftiePlainDateFromJSON(json['date']),
        'isWorkingDay': json['isWorkingDay'] == null ? undefined : json['isWorkingDay'],
    };
}

  export function UpdateWorkPatternOverrideDataToJSON(json: any): UpdateWorkPatternOverrideData {
      return UpdateWorkPatternOverrideDataToJSONTyped(json, false);
  }

  export function UpdateWorkPatternOverrideDataToJSONTyped(value?: UpdateWorkPatternOverrideData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'date': ShiftiePlainDateToJSON(value['date']),
        'isWorkingDay': value['isWorkingDay'],
    };
}

