/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
    EmployeeToJSONTyped,
} from './Employee';

/**
 * 
 * @export
 * @interface ShowEmployeeResponse
 */
export interface ShowEmployeeResponse {
    /**
     * 
     * @type {Employee}
     * @memberof ShowEmployeeResponse
     */
    data: Employee;
}

/**
 * Check if a given object implements the ShowEmployeeResponse interface.
 */
export function instanceOfShowEmployeeResponse(value: object): value is ShowEmployeeResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowEmployeeResponseFromJSON(json: any): ShowEmployeeResponse {
    return ShowEmployeeResponseFromJSONTyped(json, false);
}

export function ShowEmployeeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeFromJSON(json['data']),
    };
}

  export function ShowEmployeeResponseToJSON(json: any): ShowEmployeeResponse {
      return ShowEmployeeResponseToJSONTyped(json, false);
  }

  export function ShowEmployeeResponseToJSONTyped(value?: ShowEmployeeResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': EmployeeToJSON(value['data']),
    };
}

