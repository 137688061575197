/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IcalFeed
 */
export interface IcalFeed {
    /**
     * 
     * @type {number}
     * @memberof IcalFeed
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof IcalFeed
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof IcalFeed
     */
    employeeId: number;
    /**
     * 
     * @type {string}
     * @memberof IcalFeed
     */
    feedUrl: string;
}

/**
 * Check if a given object implements the IcalFeed interface.
 */
export function instanceOfIcalFeed(value: object): value is IcalFeed {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('feedUrl' in value) || value['feedUrl'] === undefined) return false;
    return true;
}

export function IcalFeedFromJSON(json: any): IcalFeed {
    return IcalFeedFromJSONTyped(json, false);
}

export function IcalFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): IcalFeed {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'feedUrl': json['feedUrl'],
    };
}

  export function IcalFeedToJSON(json: any): IcalFeed {
      return IcalFeedToJSONTyped(json, false);
  }

  export function IcalFeedToJSONTyped(value?: IcalFeed | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'feedUrl': value['feedUrl'],
    };
}

