/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * Create a new leave request usage calculation
 * @export
 * @interface CreateLeaveRequestUsageCalculationData
 */
export interface CreateLeaveRequestUsageCalculationData {
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveRequestUsageCalculationData
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveRequestUsageCalculationData
     */
    leaveTypeId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateLeaveRequestUsageCalculationData
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateLeaveRequestUsageCalculationData
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveRequestUsageCalculationData
     */
    startsMidday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveRequestUsageCalculationData
     */
    endsMidday?: boolean;
}

/**
 * Check if a given object implements the CreateLeaveRequestUsageCalculationData interface.
 */
export function instanceOfCreateLeaveRequestUsageCalculationData(value: object): value is CreateLeaveRequestUsageCalculationData {
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('leaveTypeId' in value) || value['leaveTypeId'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    return true;
}

export function CreateLeaveRequestUsageCalculationDataFromJSON(json: any): CreateLeaveRequestUsageCalculationData {
    return CreateLeaveRequestUsageCalculationDataFromJSONTyped(json, false);
}

export function CreateLeaveRequestUsageCalculationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveRequestUsageCalculationData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'leaveTypeId': json['leaveTypeId'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'startsMidday': json['startsMidday'] == null ? undefined : json['startsMidday'],
        'endsMidday': json['endsMidday'] == null ? undefined : json['endsMidday'],
    };
}

  export function CreateLeaveRequestUsageCalculationDataToJSON(json: any): CreateLeaveRequestUsageCalculationData {
      return CreateLeaveRequestUsageCalculationDataToJSONTyped(json, false);
  }

  export function CreateLeaveRequestUsageCalculationDataToJSONTyped(value?: CreateLeaveRequestUsageCalculationData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'leaveTypeId': value['leaveTypeId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'startsMidday': value['startsMidday'],
        'endsMidday': value['endsMidday'],
    };
}

