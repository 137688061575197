/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateEmployeeAttributeData
 */
export interface UpdateEmployeeAttributeData {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeAttributeData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeeAttributeData
     */
    status?: UpdateEmployeeAttributeDataStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateEmployeeAttributeDataStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the UpdateEmployeeAttributeData interface.
 */
export function instanceOfUpdateEmployeeAttributeData(value: object): value is UpdateEmployeeAttributeData {
    return true;
}

export function UpdateEmployeeAttributeDataFromJSON(json: any): UpdateEmployeeAttributeData {
    return UpdateEmployeeAttributeDataFromJSONTyped(json, false);
}

export function UpdateEmployeeAttributeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeeAttributeData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

  export function UpdateEmployeeAttributeDataToJSON(json: any): UpdateEmployeeAttributeData {
      return UpdateEmployeeAttributeDataToJSONTyped(json, false);
  }

  export function UpdateEmployeeAttributeDataToJSONTyped(value?: UpdateEmployeeAttributeData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'status': value['status'],
    };
}

