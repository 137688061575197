/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { BillingPlan } from './BillingPlan';
import {
    BillingPlanFromJSON,
    BillingPlanFromJSONTyped,
    BillingPlanToJSON,
    BillingPlanToJSONTyped,
} from './BillingPlan';

/**
 * 
 * @export
 * @interface ListBillingPlansResponse
 */
export interface ListBillingPlansResponse {
    /**
     * 
     * @type {Array<BillingPlan>}
     * @memberof ListBillingPlansResponse
     */
    data: Array<BillingPlan>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListBillingPlansResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListBillingPlansResponse interface.
 */
export function instanceOfListBillingPlansResponse(value: object): value is ListBillingPlansResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListBillingPlansResponseFromJSON(json: any): ListBillingPlansResponse {
    return ListBillingPlansResponseFromJSONTyped(json, false);
}

export function ListBillingPlansResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListBillingPlansResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BillingPlanFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListBillingPlansResponseToJSON(json: any): ListBillingPlansResponse {
      return ListBillingPlansResponseToJSONTyped(json, false);
  }

  export function ListBillingPlansResponseToJSONTyped(value?: ListBillingPlansResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(BillingPlanToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

