/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScheduleEvent } from './ScheduleEvent';
import {
    ScheduleEventFromJSON,
    ScheduleEventFromJSONTyped,
    ScheduleEventToJSON,
    ScheduleEventToJSONTyped,
} from './ScheduleEvent';

/**
 * 
 * @export
 * @interface CreateScheduleEventResponse
 */
export interface CreateScheduleEventResponse {
    /**
     * 
     * @type {ScheduleEvent}
     * @memberof CreateScheduleEventResponse
     */
    data: ScheduleEvent;
}

/**
 * Check if a given object implements the CreateScheduleEventResponse interface.
 */
export function instanceOfCreateScheduleEventResponse(value: object): value is CreateScheduleEventResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateScheduleEventResponseFromJSON(json: any): CreateScheduleEventResponse {
    return CreateScheduleEventResponseFromJSONTyped(json, false);
}

export function CreateScheduleEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateScheduleEventResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ScheduleEventFromJSON(json['data']),
    };
}

  export function CreateScheduleEventResponseToJSON(json: any): CreateScheduleEventResponse {
      return CreateScheduleEventResponseToJSONTyped(json, false);
  }

  export function CreateScheduleEventResponseToJSONTyped(value?: CreateScheduleEventResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ScheduleEventToJSON(value['data']),
    };
}

