/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeletePushSubscriptionDataByEndpoint
 */
export interface DeletePushSubscriptionDataByEndpoint {
    /**
     * 
     * @type {string}
     * @memberof DeletePushSubscriptionDataByEndpoint
     */
    endpoint: string;
}

/**
 * Check if a given object implements the DeletePushSubscriptionDataByEndpoint interface.
 */
export function instanceOfDeletePushSubscriptionDataByEndpoint(value: object): value is DeletePushSubscriptionDataByEndpoint {
    if (!('endpoint' in value) || value['endpoint'] === undefined) return false;
    return true;
}

export function DeletePushSubscriptionDataByEndpointFromJSON(json: any): DeletePushSubscriptionDataByEndpoint {
    return DeletePushSubscriptionDataByEndpointFromJSONTyped(json, false);
}

export function DeletePushSubscriptionDataByEndpointFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeletePushSubscriptionDataByEndpoint {
    if (json == null) {
        return json;
    }
    return {
        
        'endpoint': json['endpoint'],
    };
}

  export function DeletePushSubscriptionDataByEndpointToJSON(json: any): DeletePushSubscriptionDataByEndpoint {
      return DeletePushSubscriptionDataByEndpointToJSONTyped(json, false);
  }

  export function DeletePushSubscriptionDataByEndpointToJSONTyped(value?: DeletePushSubscriptionDataByEndpoint | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'endpoint': value['endpoint'],
    };
}

