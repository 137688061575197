/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateShiftSwapData
 */
export interface CreateShiftSwapData {
    /**
     * 
     * @type {number}
     * @memberof CreateShiftSwapData
     */
    shiftId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftSwapData
     */
    replacementShiftId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftSwapData
     */
    message?: string;
}

/**
 * Check if a given object implements the CreateShiftSwapData interface.
 */
export function instanceOfCreateShiftSwapData(value: object): value is CreateShiftSwapData {
    if (!('shiftId' in value) || value['shiftId'] === undefined) return false;
    if (!('replacementShiftId' in value) || value['replacementShiftId'] === undefined) return false;
    return true;
}

export function CreateShiftSwapDataFromJSON(json: any): CreateShiftSwapData {
    return CreateShiftSwapDataFromJSONTyped(json, false);
}

export function CreateShiftSwapDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftSwapData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftId': json['shiftId'],
        'replacementShiftId': json['replacementShiftId'],
        'message': json['message'] == null ? undefined : json['message'],
    };
}

  export function CreateShiftSwapDataToJSON(json: any): CreateShiftSwapData {
      return CreateShiftSwapDataToJSONTyped(json, false);
  }

  export function CreateShiftSwapDataToJSONTyped(value?: CreateShiftSwapData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'shiftId': value['shiftId'],
        'replacementShiftId': value['replacementShiftId'],
        'message': value['message'],
    };
}

