/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkPatternOverride } from './WorkPatternOverride';
import {
    WorkPatternOverrideFromJSON,
    WorkPatternOverrideFromJSONTyped,
    WorkPatternOverrideToJSON,
    WorkPatternOverrideToJSONTyped,
} from './WorkPatternOverride';

/**
 * 
 * @export
 * @interface UpdateWorkPatternOverrideResponse
 */
export interface UpdateWorkPatternOverrideResponse {
    /**
     * 
     * @type {WorkPatternOverride}
     * @memberof UpdateWorkPatternOverrideResponse
     */
    data: WorkPatternOverride;
}

/**
 * Check if a given object implements the UpdateWorkPatternOverrideResponse interface.
 */
export function instanceOfUpdateWorkPatternOverrideResponse(value: object): value is UpdateWorkPatternOverrideResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateWorkPatternOverrideResponseFromJSON(json: any): UpdateWorkPatternOverrideResponse {
    return UpdateWorkPatternOverrideResponseFromJSONTyped(json, false);
}

export function UpdateWorkPatternOverrideResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateWorkPatternOverrideResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': WorkPatternOverrideFromJSON(json['data']),
    };
}

  export function UpdateWorkPatternOverrideResponseToJSON(json: any): UpdateWorkPatternOverrideResponse {
      return UpdateWorkPatternOverrideResponseToJSONTyped(json, false);
  }

  export function UpdateWorkPatternOverrideResponseToJSONTyped(value?: UpdateWorkPatternOverrideResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': WorkPatternOverrideToJSON(value['data']),
    };
}

