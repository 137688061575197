/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateEmployeeNotificationChannelData
 */
export interface UpdateEmployeeNotificationChannelData {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeNotificationChannelData
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the UpdateEmployeeNotificationChannelData interface.
 */
export function instanceOfUpdateEmployeeNotificationChannelData(value: object): value is UpdateEmployeeNotificationChannelData {
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    return true;
}

export function UpdateEmployeeNotificationChannelDataFromJSON(json: any): UpdateEmployeeNotificationChannelData {
    return UpdateEmployeeNotificationChannelDataFromJSONTyped(json, false);
}

export function UpdateEmployeeNotificationChannelDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeeNotificationChannelData {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
    };
}

  export function UpdateEmployeeNotificationChannelDataToJSON(json: any): UpdateEmployeeNotificationChannelData {
      return UpdateEmployeeNotificationChannelDataToJSONTyped(json, false);
  }

  export function UpdateEmployeeNotificationChannelDataToJSONTyped(value?: UpdateEmployeeNotificationChannelData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'enabled': value['enabled'],
    };
}

