/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { LeavePolicy } from './LeavePolicy';
import {
    LeavePolicyFromJSON,
    LeavePolicyFromJSONTyped,
    LeavePolicyToJSON,
    LeavePolicyToJSONTyped,
} from './LeavePolicy';

/**
 * 
 * @export
 * @interface ListLeavePoliciesResponse
 */
export interface ListLeavePoliciesResponse {
    /**
     * 
     * @type {Array<LeavePolicy>}
     * @memberof ListLeavePoliciesResponse
     */
    data: Array<LeavePolicy>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListLeavePoliciesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListLeavePoliciesResponse interface.
 */
export function instanceOfListLeavePoliciesResponse(value: object): value is ListLeavePoliciesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListLeavePoliciesResponseFromJSON(json: any): ListLeavePoliciesResponse {
    return ListLeavePoliciesResponseFromJSONTyped(json, false);
}

export function ListLeavePoliciesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListLeavePoliciesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(LeavePolicyFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListLeavePoliciesResponseToJSON(json: any): ListLeavePoliciesResponse {
      return ListLeavePoliciesResponseToJSONTyped(json, false);
  }

  export function ListLeavePoliciesResponseToJSONTyped(value?: ListLeavePoliciesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(LeavePolicyToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

