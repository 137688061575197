/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeMessage } from './EmployeeMessage';
import {
    EmployeeMessageFromJSON,
    EmployeeMessageFromJSONTyped,
    EmployeeMessageToJSON,
    EmployeeMessageToJSONTyped,
} from './EmployeeMessage';

/**
 * 
 * @export
 * @interface CreateEmployeeMessageResponse
 */
export interface CreateEmployeeMessageResponse {
    /**
     * 
     * @type {EmployeeMessage}
     * @memberof CreateEmployeeMessageResponse
     */
    data: EmployeeMessage;
}

/**
 * Check if a given object implements the CreateEmployeeMessageResponse interface.
 */
export function instanceOfCreateEmployeeMessageResponse(value: object): value is CreateEmployeeMessageResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateEmployeeMessageResponseFromJSON(json: any): CreateEmployeeMessageResponse {
    return CreateEmployeeMessageResponseFromJSONTyped(json, false);
}

export function CreateEmployeeMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeMessageResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeMessageFromJSON(json['data']),
    };
}

  export function CreateEmployeeMessageResponseToJSON(json: any): CreateEmployeeMessageResponse {
      return CreateEmployeeMessageResponseToJSONTyped(json, false);
  }

  export function CreateEmployeeMessageResponseToJSONTyped(value?: CreateEmployeeMessageResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': EmployeeMessageToJSON(value['data']),
    };
}

