/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClockingSettings } from './ClockingSettings';
import {
    ClockingSettingsFromJSON,
    ClockingSettingsFromJSONTyped,
    ClockingSettingsToJSON,
    ClockingSettingsToJSONTyped,
} from './ClockingSettings';

/**
 * 
 * @export
 * @interface UpdateClockingSettingsResponse
 */
export interface UpdateClockingSettingsResponse {
    /**
     * 
     * @type {ClockingSettings}
     * @memberof UpdateClockingSettingsResponse
     */
    data: ClockingSettings;
}

/**
 * Check if a given object implements the UpdateClockingSettingsResponse interface.
 */
export function instanceOfUpdateClockingSettingsResponse(value: object): value is UpdateClockingSettingsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateClockingSettingsResponseFromJSON(json: any): UpdateClockingSettingsResponse {
    return UpdateClockingSettingsResponseFromJSONTyped(json, false);
}

export function UpdateClockingSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateClockingSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ClockingSettingsFromJSON(json['data']),
    };
}

  export function UpdateClockingSettingsResponseToJSON(json: any): UpdateClockingSettingsResponse {
      return UpdateClockingSettingsResponseToJSONTyped(json, false);
  }

  export function UpdateClockingSettingsResponseToJSONTyped(value?: UpdateClockingSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ClockingSettingsToJSON(value['data']),
    };
}

