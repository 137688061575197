/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { LeaveRequest } from './LeaveRequest';
import {
    LeaveRequestFromJSON,
    LeaveRequestFromJSONTyped,
    LeaveRequestToJSON,
    LeaveRequestToJSONTyped,
} from './LeaveRequest';

/**
 * 
 * @export
 * @interface ListLeaveRequestsResponse
 */
export interface ListLeaveRequestsResponse {
    /**
     * 
     * @type {Array<LeaveRequest>}
     * @memberof ListLeaveRequestsResponse
     */
    data: Array<LeaveRequest>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListLeaveRequestsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListLeaveRequestsResponse interface.
 */
export function instanceOfListLeaveRequestsResponse(value: object): value is ListLeaveRequestsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListLeaveRequestsResponseFromJSON(json: any): ListLeaveRequestsResponse {
    return ListLeaveRequestsResponseFromJSONTyped(json, false);
}

export function ListLeaveRequestsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListLeaveRequestsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(LeaveRequestFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListLeaveRequestsResponseToJSON(json: any): ListLeaveRequestsResponse {
      return ListLeaveRequestsResponseToJSONTyped(json, false);
  }

  export function ListLeaveRequestsResponseToJSONTyped(value?: ListLeaveRequestsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(LeaveRequestToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

