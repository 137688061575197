/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { CacheMeta } from './CacheMeta';
import {
    CacheMetaFromJSON,
    CacheMetaFromJSONTyped,
    CacheMetaToJSON,
    CacheMetaToJSONTyped,
} from './CacheMeta';
import type { DigiticketsSession } from './DigiticketsSession';
import {
    DigiticketsSessionFromJSON,
    DigiticketsSessionFromJSONTyped,
    DigiticketsSessionToJSON,
    DigiticketsSessionToJSONTyped,
} from './DigiticketsSession';

/**
 * 
 * @export
 * @interface ListDigiticketsSessionsResponse
 */
export interface ListDigiticketsSessionsResponse {
    /**
     * 
     * @type {CacheMeta}
     * @memberof ListDigiticketsSessionsResponse
     */
    cache: CacheMeta;
    /**
     * 
     * @type {Array<DigiticketsSession>}
     * @memberof ListDigiticketsSessionsResponse
     */
    data: Array<DigiticketsSession>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListDigiticketsSessionsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListDigiticketsSessionsResponse interface.
 */
export function instanceOfListDigiticketsSessionsResponse(value: object): value is ListDigiticketsSessionsResponse {
    if (!('cache' in value) || value['cache'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListDigiticketsSessionsResponseFromJSON(json: any): ListDigiticketsSessionsResponse {
    return ListDigiticketsSessionsResponseFromJSONTyped(json, false);
}

export function ListDigiticketsSessionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDigiticketsSessionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'cache': CacheMetaFromJSON(json['cache']),
        'data': ((json['data'] as Array<any>).map(DigiticketsSessionFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListDigiticketsSessionsResponseToJSON(json: any): ListDigiticketsSessionsResponse {
      return ListDigiticketsSessionsResponseToJSONTyped(json, false);
  }

  export function ListDigiticketsSessionsResponseToJSONTyped(value?: ListDigiticketsSessionsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cache': CacheMetaToJSON(value['cache']),
        'data': ((value['data'] as Array<any>).map(DigiticketsSessionToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

