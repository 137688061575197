/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PayType
 */
export interface PayType {
    /**
     * 
     * @type {number}
     * @memberof PayType
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PayType
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof PayType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PayType
     */
    mode: PayTypeModeEnum;
    /**
     * 
     * @type {number}
     * @memberof PayType
     */
    hourlyRate: number;
}

/**
* @export
* @enum {string}
*/
export enum PayTypeModeEnum {
    Fixed = 'Fixed',
    Percentage = 'Percentage'
}


/**
 * Check if a given object implements the PayType interface.
 */
export function instanceOfPayType(value: object): value is PayType {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('mode' in value) || value['mode'] === undefined) return false;
    if (!('hourlyRate' in value) || value['hourlyRate'] === undefined) return false;
    return true;
}

export function PayTypeFromJSON(json: any): PayType {
    return PayTypeFromJSONTyped(json, false);
}

export function PayTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayType {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'mode': json['mode'],
        'hourlyRate': json['hourlyRate'],
    };
}

  export function PayTypeToJSON(json: any): PayType {
      return PayTypeToJSONTyped(json, false);
  }

  export function PayTypeToJSONTyped(value?: PayType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'mode': value['mode'],
        'hourlyRate': value['hourlyRate'],
    };
}

