import i18n from '@/i18n';
import { belongsToEmployee } from '@/lib/filters/employeeFilters';
import { isAssigned, isLocked } from '@/lib/schedule-v2/shiftV2Status';
import { sortBy } from '@/util/arrayFunctions';
import { isPresent } from '@/util/dateArithmetic';
import { getEntityName, minutesRemaining } from '@/util/entityFunctions';
import {
  Employee,
  EmployeePayDetails,
  EmployeesJobRole,
  Shift,
  ShiftConflictTypeEnum,
  ShiftSwap,
  ShiftSwapStatusEnum,
  Tag,
  TimesheetEntry,
  TimesheetEntryStatusEnum,
} from '../../../api/v1';
import { fullName } from '../employee/employeeFunctions';
import { userCan } from '../permission/userCan';

export enum MinutesPresentationFormat {
  Narrow = 'Narrow',
  Short = 'Short',
}
export const presentTotalDuration = (
  totalMinutes: number,
  format: MinutesPresentationFormat = MinutesPresentationFormat.Narrow,
): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes - hours * 60);
  if (format === MinutesPresentationFormat.Narrow) {
    return `${hours}h ${minutes}m`;
  }
  if (format === MinutesPresentationFormat.Short) {
    return `${i18n.tc('unitWithAmount.hr', hours)} ${
      minutes ? i18n.tc('unitWithAmount.min', minutes) : ''
    }`.trim();
  }
};

export const totalDurationOfShiftsInMinutes = (shifts: Shift[]): number =>
  shifts.reduce(
    (total, shift) => total + shift.expectedWorkingTimeInMinutes,
    0,
  );

export const totalDurationOfShiftsInHours = (shifts: Shift[]): number => {
  return totalDurationOfShiftsInMinutes(shifts) / 60;
};

// Used for assign dialogs to check if we need to create a replacement or just reassign
export const isReplacementShift = (shift: Shift, timezone: string) =>
  isLocked(shift) ||
  (isPresent(shift.startsAt, shift.endsAt) &&
    minutesRemaining(timezone, shift) > 15);

export const getShiftTagNames = ({ tagIds }: Shift, tags: Tag[]) =>
  tags
    .filter((t) => tagIds.includes(t.id))
    .map(({ id }) => getEntityName(id, tags));

export const isPendingShiftSwap = (s: ShiftSwap) =>
  [
    ShiftSwapStatusEnum.PendingAcceptance,
    ShiftSwapStatusEnum.PendingApproval,
  ].includes(s.status);

export const getEmployeeCost = (
  employeePay: EmployeePayDetails,
  employeeJobRole: EmployeesJobRole,
  shift: Shift,
  includePaidBreaks: Boolean = false,
): string => {
  if (!employeePay || !employeeJobRole || !shift) {
    return '';
  }

  const rate = employeeJobRole.rate ?? (employeePay.baseRate || 0);
  const workingHoursCost = rate * (shift.expectedWorkingTimeInMinutes / 60);

  if (includePaidBreaks) {
    const paidBreakCosts = rate * (shift.paidBreakInMinutes / 60);
    return (workingHoursCost + paidBreakCosts).toFixed(2);
  }

  return workingHoursCost.toFixed(2);
};

export const getEmployeesNextShift = (
  employeeId: number,
  shifts: Shift[],
  timesheets: TimesheetEntry[],
  now: Date,
): Shift | null => {
  const filteredShifts = sortBy(
    shifts.filter((s) => belongsToEmployee(employeeId)(s)),
    'startsAt',
  );
  return (
    filteredShifts.find((s) => {
      const timesheet = timesheets.find((t) => t.shiftId === s.id);
      return (
        // Shift with an in-progress timesheet
        (timesheet &&
          timesheet.status !== TimesheetEntryStatusEnum.Completed) ||
        // Shift which ends in future and doesn't have a timesheet
        (s.endsAt > now && !timesheet)
      );
    }) ?? null
  );
};

export const tagVisibilityFilter =
  (userCanManageShifts: boolean) =>
  (tag: Tag): boolean => {
    return userCanManageShifts || tag.visibleToEmployees;
  };

export const totalShiftBreakMinutes = (shift: Shift): number =>
  shift.unpaidBreakInMinutes + shift.paidBreakInMinutes;

export const totalShiftBreakDuration = (shift: Shift): number =>
  shift.scheduledBreaks.reduce(
    (total, { durationInMinutes }) => total + durationInMinutes,
    0,
  );

export const shiftJobRoleIsVisible = (showJobRole: boolean): boolean =>
  showJobRole || userCan.manageShifts();

export const shiftHasConflict = (shift: Shift): boolean =>
  !!shift.shiftConflicts.length;

export const shiftHasConflictType = (
  shift: Shift,
  conflictType: ShiftConflictTypeEnum,
) => shift.shiftConflicts.some((conflict) => conflict.type === conflictType);

export const conflictWarningMessage = (
  shift: Shift,
  employee: Employee | null,
) => {
  if (
    !userCan.manageShifts() ||
    !isAssigned(shift) ||
    !shiftHasConflict(shift)
  ) {
    return '';
  }
  if (shiftHasConflictType(shift, ShiftConflictTypeEnum.Shift)) {
    return i18n.tc('tooltip.employeeShiftAtThisTime', 0, {
      employee: fullName(employee),
    });
  }
  if (shiftHasConflictType(shift, ShiftConflictTypeEnum.Unavailability)) {
    return i18n.tc('tooltip.employeeUnavailabilityAtThisTime', 0, {
      employee: fullName(employee),
    });
  }
  if (shiftHasConflictType(shift, ShiftConflictTypeEnum.Absence)) {
    return i18n.tc('tooltip.employeeSickAtThisTime', 0, {
      employee: fullName(employee),
    });
  }
  if (shiftHasConflictType(shift, ShiftConflictTypeEnum.Leave)) {
    return i18n.tc('tooltip.employeeLeaveAtThisTime', 0, {
      employee: fullName(employee),
    });
  }
  return '';
};
