/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { EmployeeGroup } from './EmployeeGroup';
import {
    EmployeeGroupFromJSON,
    EmployeeGroupFromJSONTyped,
    EmployeeGroupToJSON,
    EmployeeGroupToJSONTyped,
} from './EmployeeGroup';

/**
 * 
 * @export
 * @interface ListEmployeeGroupsResponse
 */
export interface ListEmployeeGroupsResponse {
    /**
     * 
     * @type {Array<EmployeeGroup>}
     * @memberof ListEmployeeGroupsResponse
     */
    data: Array<EmployeeGroup>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListEmployeeGroupsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListEmployeeGroupsResponse interface.
 */
export function instanceOfListEmployeeGroupsResponse(value: object): value is ListEmployeeGroupsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListEmployeeGroupsResponseFromJSON(json: any): ListEmployeeGroupsResponse {
    return ListEmployeeGroupsResponseFromJSONTyped(json, false);
}

export function ListEmployeeGroupsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEmployeeGroupsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(EmployeeGroupFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListEmployeeGroupsResponseToJSON(json: any): ListEmployeeGroupsResponse {
      return ListEmployeeGroupsResponseToJSONTyped(json, false);
  }

  export function ListEmployeeGroupsResponseToJSONTyped(value?: ListEmployeeGroupsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(EmployeeGroupToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

