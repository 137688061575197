/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OffersQuota } from './OffersQuota';
import {
    OffersQuotaFromJSON,
    OffersQuotaFromJSONTyped,
    OffersQuotaToJSON,
    OffersQuotaToJSONTyped,
} from './OffersQuota';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListOffersQuotaResponse
 */
export interface ListOffersQuotaResponse {
    /**
     * 
     * @type {Array<OffersQuota>}
     * @memberof ListOffersQuotaResponse
     */
    data: Array<OffersQuota>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListOffersQuotaResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListOffersQuotaResponse interface.
 */
export function instanceOfListOffersQuotaResponse(value: object): value is ListOffersQuotaResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListOffersQuotaResponseFromJSON(json: any): ListOffersQuotaResponse {
    return ListOffersQuotaResponseFromJSONTyped(json, false);
}

export function ListOffersQuotaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOffersQuotaResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(OffersQuotaFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListOffersQuotaResponseToJSON(json: any): ListOffersQuotaResponse {
      return ListOffersQuotaResponseToJSONTyped(json, false);
  }

  export function ListOffersQuotaResponseToJSONTyped(value?: ListOffersQuotaResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(OffersQuotaToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

