/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { WorkPatternOverride } from './WorkPatternOverride';
import {
    WorkPatternOverrideFromJSON,
    WorkPatternOverrideFromJSONTyped,
    WorkPatternOverrideToJSON,
    WorkPatternOverrideToJSONTyped,
} from './WorkPatternOverride';

/**
 * 
 * @export
 * @interface ListEmployeeWorkPatternOverridesResponse
 */
export interface ListEmployeeWorkPatternOverridesResponse {
    /**
     * 
     * @type {Array<WorkPatternOverride>}
     * @memberof ListEmployeeWorkPatternOverridesResponse
     */
    data: Array<WorkPatternOverride>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListEmployeeWorkPatternOverridesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListEmployeeWorkPatternOverridesResponse interface.
 */
export function instanceOfListEmployeeWorkPatternOverridesResponse(value: object): value is ListEmployeeWorkPatternOverridesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListEmployeeWorkPatternOverridesResponseFromJSON(json: any): ListEmployeeWorkPatternOverridesResponse {
    return ListEmployeeWorkPatternOverridesResponseFromJSONTyped(json, false);
}

export function ListEmployeeWorkPatternOverridesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEmployeeWorkPatternOverridesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(WorkPatternOverrideFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListEmployeeWorkPatternOverridesResponseToJSON(json: any): ListEmployeeWorkPatternOverridesResponse {
      return ListEmployeeWorkPatternOverridesResponseToJSONTyped(json, false);
  }

  export function ListEmployeeWorkPatternOverridesResponseToJSONTyped(value?: ListEmployeeWorkPatternOverridesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(WorkPatternOverrideToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

