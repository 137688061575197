/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePasswordResetData
 */
export interface CreatePasswordResetData {
    /**
     * 
     * @type {string}
     * @memberof CreatePasswordResetData
     */
    email: string;
}

/**
 * Check if a given object implements the CreatePasswordResetData interface.
 */
export function instanceOfCreatePasswordResetData(value: object): value is CreatePasswordResetData {
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function CreatePasswordResetDataFromJSON(json: any): CreatePasswordResetData {
    return CreatePasswordResetDataFromJSONTyped(json, false);
}

export function CreatePasswordResetDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePasswordResetData {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

  export function CreatePasswordResetDataToJSON(json: any): CreatePasswordResetData {
      return CreatePasswordResetDataToJSONTyped(json, false);
  }

  export function CreatePasswordResetDataToJSONTyped(value?: CreatePasswordResetData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
    };
}

