/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingSubscriptionCancellation
 */
export interface BillingSubscriptionCancellation {
    /**
     * 
     * @type {Date}
     * @memberof BillingSubscriptionCancellation
     */
    cancelAt: Date;
}

/**
 * Check if a given object implements the BillingSubscriptionCancellation interface.
 */
export function instanceOfBillingSubscriptionCancellation(value: object): value is BillingSubscriptionCancellation {
    if (!('cancelAt' in value) || value['cancelAt'] === undefined) return false;
    return true;
}

export function BillingSubscriptionCancellationFromJSON(json: any): BillingSubscriptionCancellation {
    return BillingSubscriptionCancellationFromJSONTyped(json, false);
}

export function BillingSubscriptionCancellationFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingSubscriptionCancellation {
    if (json == null) {
        return json;
    }
    return {
        
        'cancelAt': (new Date(json['cancelAt'])),
    };
}

  export function BillingSubscriptionCancellationToJSON(json: any): BillingSubscriptionCancellation {
      return BillingSubscriptionCancellationToJSONTyped(json, false);
  }

  export function BillingSubscriptionCancellationToJSONTyped(value?: BillingSubscriptionCancellation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cancelAt': ((value['cancelAt']).toISOString()),
    };
}

