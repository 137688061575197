/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * Create a new leave request
 * @export
 * @interface CreateLeaveRequestData
 */
export interface CreateLeaveRequestData {
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveRequestData
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveRequestData
     */
    leaveTypeId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateLeaveRequestData
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateLeaveRequestData
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveRequestData
     */
    startsMidday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveRequestData
     */
    endsMidday?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveRequestData
     */
    requestNotes?: string;
}

/**
 * Check if a given object implements the CreateLeaveRequestData interface.
 */
export function instanceOfCreateLeaveRequestData(value: object): value is CreateLeaveRequestData {
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('leaveTypeId' in value) || value['leaveTypeId'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    return true;
}

export function CreateLeaveRequestDataFromJSON(json: any): CreateLeaveRequestData {
    return CreateLeaveRequestDataFromJSONTyped(json, false);
}

export function CreateLeaveRequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveRequestData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'leaveTypeId': json['leaveTypeId'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'startsMidday': json['startsMidday'] == null ? undefined : json['startsMidday'],
        'endsMidday': json['endsMidday'] == null ? undefined : json['endsMidday'],
        'requestNotes': json['requestNotes'] == null ? undefined : json['requestNotes'],
    };
}

  export function CreateLeaveRequestDataToJSON(json: any): CreateLeaveRequestData {
      return CreateLeaveRequestDataToJSONTyped(json, false);
  }

  export function CreateLeaveRequestDataToJSONTyped(value?: CreateLeaveRequestData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'leaveTypeId': value['leaveTypeId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'startsMidday': value['startsMidday'],
        'endsMidday': value['endsMidday'],
        'requestNotes': value['requestNotes'],
    };
}

