/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Entity-agnostic recurrence rule following the iCalendar RRULE format. See https://icalendar.org/iCalendar-RFC-5545/3-8-5-3-recurrence-rule.html for some practical examples.
 * @export
 * @interface CreateRecurrenceRuleData
 */
export interface CreateRecurrenceRuleData {
    /**
     * Event recurrence frequencies, according to the iCalendar RRULE spec
     * @type {string}
     * @memberof CreateRecurrenceRuleData
     */
    freq: CreateRecurrenceRuleDataFreqEnum;
    /**
     * When the pattern should end. Treated as *inclusive*.
     * @type {Date}
     * @memberof CreateRecurrenceRuleData
     */
    until?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof CreateRecurrenceRuleData
     */
    count?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateRecurrenceRuleData
     */
    interval?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurrenceRuleData
     */
    bySecond?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurrenceRuleData
     */
    byMinute?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurrenceRuleData
     */
    byHour?: string | null;
    /**
     * Comma-separated list of 2-character day names
     * @type {string}
     * @memberof CreateRecurrenceRuleData
     */
    byDay?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurrenceRuleData
     */
    byMonthDay?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurrenceRuleData
     */
    byYearDay?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurrenceRuleData
     */
    byWeekNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurrenceRuleData
     */
    byMonth?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRecurrenceRuleData
     */
    bySetPos?: string | null;
}

/**
* @export
* @enum {string}
*/
export enum CreateRecurrenceRuleDataFreqEnum {
    Daily = 'DAILY',
    Hourly = 'HOURLY',
    Minutely = 'MINUTELY',
    Monthly = 'MONTHLY',
    Secondly = 'SECONDLY',
    Weekly = 'WEEKLY',
    Yearly = 'YEARLY'
}


/**
 * Check if a given object implements the CreateRecurrenceRuleData interface.
 */
export function instanceOfCreateRecurrenceRuleData(value: object): value is CreateRecurrenceRuleData {
    if (!('freq' in value) || value['freq'] === undefined) return false;
    return true;
}

export function CreateRecurrenceRuleDataFromJSON(json: any): CreateRecurrenceRuleData {
    return CreateRecurrenceRuleDataFromJSONTyped(json, false);
}

export function CreateRecurrenceRuleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateRecurrenceRuleData {
    if (json == null) {
        return json;
    }
    return {
        
        'freq': json['freq'],
        'until': json['until'] == null ? undefined : (new Date(json['until'])),
        'count': json['count'] == null ? undefined : json['count'],
        'interval': json['interval'] == null ? undefined : json['interval'],
        'bySecond': json['bySecond'] == null ? undefined : json['bySecond'],
        'byMinute': json['byMinute'] == null ? undefined : json['byMinute'],
        'byHour': json['byHour'] == null ? undefined : json['byHour'],
        'byDay': json['byDay'] == null ? undefined : json['byDay'],
        'byMonthDay': json['byMonthDay'] == null ? undefined : json['byMonthDay'],
        'byYearDay': json['byYearDay'] == null ? undefined : json['byYearDay'],
        'byWeekNo': json['byWeekNo'] == null ? undefined : json['byWeekNo'],
        'byMonth': json['byMonth'] == null ? undefined : json['byMonth'],
        'bySetPos': json['bySetPos'] == null ? undefined : json['bySetPos'],
    };
}

  export function CreateRecurrenceRuleDataToJSON(json: any): CreateRecurrenceRuleData {
      return CreateRecurrenceRuleDataToJSONTyped(json, false);
  }

  export function CreateRecurrenceRuleDataToJSONTyped(value?: CreateRecurrenceRuleData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'freq': value['freq'],
        'until': value['until'] == null ? undefined : ((value['until'] as any).toISOString()),
        'count': value['count'],
        'interval': value['interval'],
        'bySecond': value['bySecond'],
        'byMinute': value['byMinute'],
        'byHour': value['byHour'],
        'byDay': value['byDay'],
        'byMonthDay': value['byMonthDay'],
        'byYearDay': value['byYearDay'],
        'byWeekNo': value['byWeekNo'],
        'byMonth': value['byMonth'],
        'bySetPos': value['bySetPos'],
    };
}

