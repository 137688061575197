/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * Personal/private details about an employee that shouldn't be visible to everyone
 * @export
 * @interface EmployeePersonalDetails
 */
export interface EmployeePersonalDetails {
    /**
     * 
     * @type {number}
     * @memberof EmployeePersonalDetails
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePersonalDetails
     */
    employeeId: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    address1: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    address2: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    city: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    postalCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    region: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    country: string | null;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeePersonalDetails
     */
    dateOfBirth: ShiftiePlainDate | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    gender: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    nationality: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    ethnicity: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    maritalStatus: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    nationalInsuranceNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    drivingLicenceNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    passportNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    notes: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeePersonalDetails
     */
    medicalDetails: string | null;
}

/**
 * Check if a given object implements the EmployeePersonalDetails interface.
 */
export function instanceOfEmployeePersonalDetails(value: object): value is EmployeePersonalDetails {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('address1' in value) || value['address1'] === undefined) return false;
    if (!('address2' in value) || value['address2'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('postalCode' in value) || value['postalCode'] === undefined) return false;
    if (!('region' in value) || value['region'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('dateOfBirth' in value) || value['dateOfBirth'] === undefined) return false;
    if (!('gender' in value) || value['gender'] === undefined) return false;
    if (!('nationality' in value) || value['nationality'] === undefined) return false;
    if (!('ethnicity' in value) || value['ethnicity'] === undefined) return false;
    if (!('maritalStatus' in value) || value['maritalStatus'] === undefined) return false;
    if (!('nationalInsuranceNumber' in value) || value['nationalInsuranceNumber'] === undefined) return false;
    if (!('drivingLicenceNumber' in value) || value['drivingLicenceNumber'] === undefined) return false;
    if (!('passportNumber' in value) || value['passportNumber'] === undefined) return false;
    if (!('notes' in value) || value['notes'] === undefined) return false;
    if (!('medicalDetails' in value) || value['medicalDetails'] === undefined) return false;
    return true;
}

export function EmployeePersonalDetailsFromJSON(json: any): EmployeePersonalDetails {
    return EmployeePersonalDetailsFromJSONTyped(json, false);
}

export function EmployeePersonalDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeePersonalDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'employeeId': json['employeeId'],
        'address1': json['address1'],
        'address2': json['address2'],
        'city': json['city'],
        'postalCode': json['postalCode'],
        'region': json['region'],
        'country': json['country'],
        'dateOfBirth': ShiftiePlainDateFromJSON(json['dateOfBirth']),
        'gender': json['gender'],
        'nationality': json['nationality'],
        'ethnicity': json['ethnicity'],
        'maritalStatus': json['maritalStatus'],
        'nationalInsuranceNumber': json['nationalInsuranceNumber'],
        'drivingLicenceNumber': json['drivingLicenceNumber'],
        'passportNumber': json['passportNumber'],
        'notes': json['notes'],
        'medicalDetails': json['medicalDetails'],
    };
}

  export function EmployeePersonalDetailsToJSON(json: any): EmployeePersonalDetails {
      return EmployeePersonalDetailsToJSONTyped(json, false);
  }

  export function EmployeePersonalDetailsToJSONTyped(value?: EmployeePersonalDetails | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'employeeId': value['employeeId'],
        'address1': value['address1'],
        'address2': value['address2'],
        'city': value['city'],
        'postalCode': value['postalCode'],
        'region': value['region'],
        'country': value['country'],
        'dateOfBirth': ShiftiePlainDateToJSON(value['dateOfBirth']),
        'gender': value['gender'],
        'nationality': value['nationality'],
        'ethnicity': value['ethnicity'],
        'maritalStatus': value['maritalStatus'],
        'nationalInsuranceNumber': value['nationalInsuranceNumber'],
        'drivingLicenceNumber': value['drivingLicenceNumber'],
        'passportNumber': value['passportNumber'],
        'notes': value['notes'],
        'medicalDetails': value['medicalDetails'],
    };
}

