/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WeatherSummary
 */
export interface WeatherSummary {
    /**
     * 
     * @type {string}
     * @memberof WeatherSummary
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherSummary
     */
    summary: string;
    /**
     * 
     * @type {string}
     * @memberof WeatherSummary
     */
    extraInfo: string;
    /**
     * 
     * @type {number}
     * @memberof WeatherSummary
     */
    dailyMinTemp: number;
    /**
     * 
     * @type {number}
     * @memberof WeatherSummary
     */
    dailyMaxTemp: number;
    /**
     * 
     * @type {number}
     * @memberof WeatherSummary
     */
    windSpeed: number;
    /**
     * A URL pointing to the Open Weather API's icon for a given weather event.
     * @type {string}
     * @memberof WeatherSummary
     */
    icon: string;
}

/**
 * Check if a given object implements the WeatherSummary interface.
 */
export function instanceOfWeatherSummary(value: object): value is WeatherSummary {
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('extraInfo' in value) || value['extraInfo'] === undefined) return false;
    if (!('dailyMinTemp' in value) || value['dailyMinTemp'] === undefined) return false;
    if (!('dailyMaxTemp' in value) || value['dailyMaxTemp'] === undefined) return false;
    if (!('windSpeed' in value) || value['windSpeed'] === undefined) return false;
    if (!('icon' in value) || value['icon'] === undefined) return false;
    return true;
}

export function WeatherSummaryFromJSON(json: any): WeatherSummary {
    return WeatherSummaryFromJSONTyped(json, false);
}

export function WeatherSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeatherSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'date': json['date'],
        'summary': json['summary'],
        'extraInfo': json['extraInfo'],
        'dailyMinTemp': json['dailyMinTemp'],
        'dailyMaxTemp': json['dailyMaxTemp'],
        'windSpeed': json['windSpeed'],
        'icon': json['icon'],
    };
}

  export function WeatherSummaryToJSON(json: any): WeatherSummary {
      return WeatherSummaryToJSONTyped(json, false);
  }

  export function WeatherSummaryToJSONTyped(value?: WeatherSummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'date': value['date'],
        'summary': value['summary'],
        'extraInfo': value['extraInfo'],
        'dailyMinTemp': value['dailyMinTemp'],
        'dailyMaxTemp': value['dailyMaxTemp'],
        'windSpeed': value['windSpeed'],
        'icon': value['icon'],
    };
}

