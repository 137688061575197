/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEmployeeImportData
 */
export interface CreateEmployeeImportData {
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeImportData
     */
    uploadId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeImportData
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeImportData
     */
    employeeCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeImportData
     */
    sendInviteEmails: boolean;
}

/**
 * Check if a given object implements the CreateEmployeeImportData interface.
 */
export function instanceOfCreateEmployeeImportData(value: object): value is CreateEmployeeImportData {
    if (!('uploadId' in value) || value['uploadId'] === undefined) return false;
    if (!('locationId' in value) || value['locationId'] === undefined) return false;
    if (!('employeeCount' in value) || value['employeeCount'] === undefined) return false;
    if (!('sendInviteEmails' in value) || value['sendInviteEmails'] === undefined) return false;
    return true;
}

export function CreateEmployeeImportDataFromJSON(json: any): CreateEmployeeImportData {
    return CreateEmployeeImportDataFromJSONTyped(json, false);
}

export function CreateEmployeeImportDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeImportData {
    if (json == null) {
        return json;
    }
    return {
        
        'uploadId': json['uploadId'],
        'locationId': json['locationId'],
        'employeeCount': json['employeeCount'],
        'sendInviteEmails': json['sendInviteEmails'],
    };
}

  export function CreateEmployeeImportDataToJSON(json: any): CreateEmployeeImportData {
      return CreateEmployeeImportDataToJSONTyped(json, false);
  }

  export function CreateEmployeeImportDataToJSONTyped(value?: CreateEmployeeImportData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'uploadId': value['uploadId'],
        'locationId': value['locationId'],
        'employeeCount': value['employeeCount'],
        'sendInviteEmails': value['sendInviteEmails'],
    };
}

