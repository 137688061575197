/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEmployeeGroupData
 */
export interface CreateEmployeeGroupData {
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeGroupData
     */
    name: string;
}

/**
 * Check if a given object implements the CreateEmployeeGroupData interface.
 */
export function instanceOfCreateEmployeeGroupData(value: object): value is CreateEmployeeGroupData {
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function CreateEmployeeGroupDataFromJSON(json: any): CreateEmployeeGroupData {
    return CreateEmployeeGroupDataFromJSONTyped(json, false);
}

export function CreateEmployeeGroupDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeGroupData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
    };
}

  export function CreateEmployeeGroupDataToJSON(json: any): CreateEmployeeGroupData {
      return CreateEmployeeGroupDataToJSONTyped(json, false);
  }

  export function CreateEmployeeGroupDataToJSONTyped(value?: CreateEmployeeGroupData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
    };
}

