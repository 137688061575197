/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmTimesheetEntryData
 */
export interface ConfirmTimesheetEntryData {
    /**
     * Whether the the timesheet entry should be confirmed and locked for further updates.
     * @type {boolean}
     * @memberof ConfirmTimesheetEntryData
     */
    confirm: boolean;
}

/**
 * Check if a given object implements the ConfirmTimesheetEntryData interface.
 */
export function instanceOfConfirmTimesheetEntryData(value: object): value is ConfirmTimesheetEntryData {
    if (!('confirm' in value) || value['confirm'] === undefined) return false;
    return true;
}

export function ConfirmTimesheetEntryDataFromJSON(json: any): ConfirmTimesheetEntryData {
    return ConfirmTimesheetEntryDataFromJSONTyped(json, false);
}

export function ConfirmTimesheetEntryDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmTimesheetEntryData {
    if (json == null) {
        return json;
    }
    return {
        
        'confirm': json['confirm'],
    };
}

  export function ConfirmTimesheetEntryDataToJSON(json: any): ConfirmTimesheetEntryData {
      return ConfirmTimesheetEntryDataToJSONTyped(json, false);
  }

  export function ConfirmTimesheetEntryDataToJSONTyped(value?: ConfirmTimesheetEntryData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'confirm': value['confirm'],
    };
}

