/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingPaymentMethod } from './BillingPaymentMethod';
import {
    BillingPaymentMethodFromJSON,
    BillingPaymentMethodFromJSONTyped,
    BillingPaymentMethodToJSON,
    BillingPaymentMethodToJSONTyped,
} from './BillingPaymentMethod';

/**
 * 
 * @export
 * @interface ShowPaymentMethodResponse
 */
export interface ShowPaymentMethodResponse {
    /**
     * 
     * @type {BillingPaymentMethod}
     * @memberof ShowPaymentMethodResponse
     */
    data: BillingPaymentMethod;
}

/**
 * Check if a given object implements the ShowPaymentMethodResponse interface.
 */
export function instanceOfShowPaymentMethodResponse(value: object): value is ShowPaymentMethodResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowPaymentMethodResponseFromJSON(json: any): ShowPaymentMethodResponse {
    return ShowPaymentMethodResponseFromJSONTyped(json, false);
}

export function ShowPaymentMethodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowPaymentMethodResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BillingPaymentMethodFromJSON(json['data']),
    };
}

  export function ShowPaymentMethodResponseToJSON(json: any): ShowPaymentMethodResponse {
      return ShowPaymentMethodResponseToJSONTyped(json, false);
  }

  export function ShowPaymentMethodResponseToJSONTyped(value?: ShowPaymentMethodResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': BillingPaymentMethodToJSON(value['data']),
    };
}

