/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    UserToJSONTyped,
} from './User';

/**
 * 
 * @export
 * @interface ListCompanyUsersResponse
 */
export interface ListCompanyUsersResponse {
    /**
     * 
     * @type {Array<User>}
     * @memberof ListCompanyUsersResponse
     */
    data: Array<User>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListCompanyUsersResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListCompanyUsersResponse interface.
 */
export function instanceOfListCompanyUsersResponse(value: object): value is ListCompanyUsersResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListCompanyUsersResponseFromJSON(json: any): ListCompanyUsersResponse {
    return ListCompanyUsersResponseFromJSONTyped(json, false);
}

export function ListCompanyUsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCompanyUsersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListCompanyUsersResponseToJSON(json: any): ListCompanyUsersResponse {
      return ListCompanyUsersResponseToJSONTyped(json, false);
  }

  export function ListCompanyUsersResponseToJSONTyped(value?: ListCompanyUsersResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(UserToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

