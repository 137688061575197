/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { Upload } from './Upload';
import {
    UploadFromJSON,
    UploadFromJSONTyped,
    UploadToJSON,
    UploadToJSONTyped,
} from './Upload';

/**
 * 
 * @export
 * @interface ListUploadsResponse
 */
export interface ListUploadsResponse {
    /**
     * 
     * @type {Array<Upload>}
     * @memberof ListUploadsResponse
     */
    data: Array<Upload>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListUploadsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListUploadsResponse interface.
 */
export function instanceOfListUploadsResponse(value: object): value is ListUploadsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListUploadsResponseFromJSON(json: any): ListUploadsResponse {
    return ListUploadsResponseFromJSONTyped(json, false);
}

export function ListUploadsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListUploadsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UploadFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListUploadsResponseToJSON(json: any): ListUploadsResponse {
      return ListUploadsResponseToJSONTyped(json, false);
  }

  export function ListUploadsResponseToJSONTyped(value?: ListUploadsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(UploadToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

