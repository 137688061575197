/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScheduleTemplate } from './ScheduleTemplate';
import {
    ScheduleTemplateFromJSON,
    ScheduleTemplateFromJSONTyped,
    ScheduleTemplateToJSON,
    ScheduleTemplateToJSONTyped,
} from './ScheduleTemplate';

/**
 * 
 * @export
 * @interface ShowScheduleTemplateResponse
 */
export interface ShowScheduleTemplateResponse {
    /**
     * 
     * @type {ScheduleTemplate}
     * @memberof ShowScheduleTemplateResponse
     */
    data: ScheduleTemplate;
}

/**
 * Check if a given object implements the ShowScheduleTemplateResponse interface.
 */
export function instanceOfShowScheduleTemplateResponse(value: object): value is ShowScheduleTemplateResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowScheduleTemplateResponseFromJSON(json: any): ShowScheduleTemplateResponse {
    return ShowScheduleTemplateResponseFromJSONTyped(json, false);
}

export function ShowScheduleTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowScheduleTemplateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ScheduleTemplateFromJSON(json['data']),
    };
}

  export function ShowScheduleTemplateResponseToJSON(json: any): ShowScheduleTemplateResponse {
      return ShowScheduleTemplateResponseToJSONTyped(json, false);
  }

  export function ShowScheduleTemplateResponseToJSONTyped(value?: ShowScheduleTemplateResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ScheduleTemplateToJSON(value['data']),
    };
}

