/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { ReportCostsByGroup } from './ReportCostsByGroup';
import {
    ReportCostsByGroupFromJSON,
    ReportCostsByGroupFromJSONTyped,
    ReportCostsByGroupToJSON,
    ReportCostsByGroupToJSONTyped,
} from './ReportCostsByGroup';
import type { ReportCostsByGroupRollup } from './ReportCostsByGroupRollup';
import {
    ReportCostsByGroupRollupFromJSON,
    ReportCostsByGroupRollupFromJSONTyped,
    ReportCostsByGroupRollupToJSON,
    ReportCostsByGroupRollupToJSONTyped,
} from './ReportCostsByGroupRollup';

/**
 * 
 * @export
 * @interface ListShiftCostReportsByGroupResponse
 */
export interface ListShiftCostReportsByGroupResponse {
    /**
     * 
     * @type {Array<ReportCostsByGroup>}
     * @memberof ListShiftCostReportsByGroupResponse
     */
    data: Array<ReportCostsByGroup>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListShiftCostReportsByGroupResponse
     */
    pagination: Pagination;
    /**
     * 
     * @type {ReportCostsByGroupRollup}
     * @memberof ListShiftCostReportsByGroupResponse
     */
    rollup: ReportCostsByGroupRollup;
}

/**
 * Check if a given object implements the ListShiftCostReportsByGroupResponse interface.
 */
export function instanceOfListShiftCostReportsByGroupResponse(value: object): value is ListShiftCostReportsByGroupResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    if (!('rollup' in value) || value['rollup'] === undefined) return false;
    return true;
}

export function ListShiftCostReportsByGroupResponseFromJSON(json: any): ListShiftCostReportsByGroupResponse {
    return ListShiftCostReportsByGroupResponseFromJSONTyped(json, false);
}

export function ListShiftCostReportsByGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListShiftCostReportsByGroupResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ReportCostsByGroupFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
        'rollup': ReportCostsByGroupRollupFromJSON(json['rollup']),
    };
}

  export function ListShiftCostReportsByGroupResponseToJSON(json: any): ListShiftCostReportsByGroupResponse {
      return ListShiftCostReportsByGroupResponseToJSONTyped(json, false);
  }

  export function ListShiftCostReportsByGroupResponseToJSONTyped(value?: ListShiftCostReportsByGroupResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ReportCostsByGroupToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
        'rollup': ReportCostsByGroupRollupToJSON(value['rollup']),
    };
}

