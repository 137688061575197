/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Invoice } from './Invoice';
import {
    InvoiceFromJSON,
    InvoiceFromJSONTyped,
    InvoiceToJSON,
    InvoiceToJSONTyped,
} from './Invoice';

/**
 * 
 * @export
 * @interface ShowInvoiceResponse
 */
export interface ShowInvoiceResponse {
    /**
     * 
     * @type {Invoice}
     * @memberof ShowInvoiceResponse
     */
    data: Invoice;
}

/**
 * Check if a given object implements the ShowInvoiceResponse interface.
 */
export function instanceOfShowInvoiceResponse(value: object): value is ShowInvoiceResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowInvoiceResponseFromJSON(json: any): ShowInvoiceResponse {
    return ShowInvoiceResponseFromJSONTyped(json, false);
}

export function ShowInvoiceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowInvoiceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': InvoiceFromJSON(json['data']),
    };
}

  export function ShowInvoiceResponseToJSON(json: any): ShowInvoiceResponse {
      return ShowInvoiceResponseToJSONTyped(json, false);
  }

  export function ShowInvoiceResponseToJSONTyped(value?: ShowInvoiceResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': InvoiceToJSON(value['data']),
    };
}

