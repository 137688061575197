/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { ReportCostsByLocationRollup } from './ReportCostsByLocationRollup';
import {
    ReportCostsByLocationRollupFromJSON,
    ReportCostsByLocationRollupFromJSONTyped,
    ReportCostsByLocationRollupToJSON,
    ReportCostsByLocationRollupToJSONTyped,
} from './ReportCostsByLocationRollup';
import type { ReportCostsByLocation } from './ReportCostsByLocation';
import {
    ReportCostsByLocationFromJSON,
    ReportCostsByLocationFromJSONTyped,
    ReportCostsByLocationToJSON,
    ReportCostsByLocationToJSONTyped,
} from './ReportCostsByLocation';

/**
 * 
 * @export
 * @interface ListShiftCostReportsByLocationResponse
 */
export interface ListShiftCostReportsByLocationResponse {
    /**
     * 
     * @type {Array<ReportCostsByLocation>}
     * @memberof ListShiftCostReportsByLocationResponse
     */
    data: Array<ReportCostsByLocation>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListShiftCostReportsByLocationResponse
     */
    pagination: Pagination;
    /**
     * 
     * @type {ReportCostsByLocationRollup}
     * @memberof ListShiftCostReportsByLocationResponse
     */
    rollup: ReportCostsByLocationRollup;
}

/**
 * Check if a given object implements the ListShiftCostReportsByLocationResponse interface.
 */
export function instanceOfListShiftCostReportsByLocationResponse(value: object): value is ListShiftCostReportsByLocationResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    if (!('rollup' in value) || value['rollup'] === undefined) return false;
    return true;
}

export function ListShiftCostReportsByLocationResponseFromJSON(json: any): ListShiftCostReportsByLocationResponse {
    return ListShiftCostReportsByLocationResponseFromJSONTyped(json, false);
}

export function ListShiftCostReportsByLocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListShiftCostReportsByLocationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ReportCostsByLocationFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
        'rollup': ReportCostsByLocationRollupFromJSON(json['rollup']),
    };
}

  export function ListShiftCostReportsByLocationResponseToJSON(json: any): ListShiftCostReportsByLocationResponse {
      return ListShiftCostReportsByLocationResponseToJSONTyped(json, false);
  }

  export function ListShiftCostReportsByLocationResponseToJSONTyped(value?: ListShiftCostReportsByLocationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ReportCostsByLocationToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
        'rollup': ReportCostsByLocationRollupToJSON(value['rollup']),
    };
}

