/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScheduleEvent } from './ScheduleEvent';
import {
    ScheduleEventFromJSON,
    ScheduleEventFromJSONTyped,
    ScheduleEventToJSON,
    ScheduleEventToJSONTyped,
} from './ScheduleEvent';

/**
 * 
 * @export
 * @interface UpdateScheduleEventResponse
 */
export interface UpdateScheduleEventResponse {
    /**
     * 
     * @type {ScheduleEvent}
     * @memberof UpdateScheduleEventResponse
     */
    data: ScheduleEvent;
}

/**
 * Check if a given object implements the UpdateScheduleEventResponse interface.
 */
export function instanceOfUpdateScheduleEventResponse(value: object): value is UpdateScheduleEventResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateScheduleEventResponseFromJSON(json: any): UpdateScheduleEventResponse {
    return UpdateScheduleEventResponseFromJSONTyped(json, false);
}

export function UpdateScheduleEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateScheduleEventResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ScheduleEventFromJSON(json['data']),
    };
}

  export function UpdateScheduleEventResponseToJSON(json: any): UpdateScheduleEventResponse {
      return UpdateScheduleEventResponseToJSONTyped(json, false);
  }

  export function UpdateScheduleEventResponseToJSONTyped(value?: UpdateScheduleEventResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ScheduleEventToJSON(value['data']),
    };
}

