/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCostsByEmploymentType
 */
export interface ReportCostsByEmploymentType {
    /**
     * 
     * @type {string}
     * @memberof ReportCostsByEmploymentType
     */
    employmentType: string;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmploymentType
     */
    employees: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ReportCostsByEmploymentType
     */
    employeeIds: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmploymentType
     */
    shifts: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmploymentType
     */
    hoursScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmploymentType
     */
    hoursWorked: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmploymentType
     */
    costsScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmploymentType
     */
    costsWorked: number | null;
}

/**
 * Check if a given object implements the ReportCostsByEmploymentType interface.
 */
export function instanceOfReportCostsByEmploymentType(value: object): value is ReportCostsByEmploymentType {
    if (!('employmentType' in value) || value['employmentType'] === undefined) return false;
    if (!('employees' in value) || value['employees'] === undefined) return false;
    if (!('employeeIds' in value) || value['employeeIds'] === undefined) return false;
    if (!('shifts' in value) || value['shifts'] === undefined) return false;
    if (!('hoursScheduled' in value) || value['hoursScheduled'] === undefined) return false;
    if (!('hoursWorked' in value) || value['hoursWorked'] === undefined) return false;
    if (!('costsScheduled' in value) || value['costsScheduled'] === undefined) return false;
    if (!('costsWorked' in value) || value['costsWorked'] === undefined) return false;
    return true;
}

export function ReportCostsByEmploymentTypeFromJSON(json: any): ReportCostsByEmploymentType {
    return ReportCostsByEmploymentTypeFromJSONTyped(json, false);
}

export function ReportCostsByEmploymentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCostsByEmploymentType {
    if (json == null) {
        return json;
    }
    return {
        
        'employmentType': json['employmentType'],
        'employees': json['employees'],
        'employeeIds': json['employeeIds'],
        'shifts': json['shifts'],
        'hoursScheduled': json['hoursScheduled'],
        'hoursWorked': json['hoursWorked'],
        'costsScheduled': json['costsScheduled'],
        'costsWorked': json['costsWorked'],
    };
}

  export function ReportCostsByEmploymentTypeToJSON(json: any): ReportCostsByEmploymentType {
      return ReportCostsByEmploymentTypeToJSONTyped(json, false);
  }

  export function ReportCostsByEmploymentTypeToJSONTyped(value?: ReportCostsByEmploymentType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employmentType': value['employmentType'],
        'employees': value['employees'],
        'employeeIds': value['employeeIds'],
        'shifts': value['shifts'],
        'hoursScheduled': value['hoursScheduled'],
        'hoursWorked': value['hoursWorked'],
        'costsScheduled': value['costsScheduled'],
        'costsWorked': value['costsWorked'],
    };
}

