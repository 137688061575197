/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BulkClockInDataClockInDataInner } from './BulkClockInDataClockInDataInner';
import {
    BulkClockInDataClockInDataInnerFromJSON,
    BulkClockInDataClockInDataInnerFromJSONTyped,
    BulkClockInDataClockInDataInnerToJSON,
    BulkClockInDataClockInDataInnerToJSONTyped,
} from './BulkClockInDataClockInDataInner';

/**
 * 
 * @export
 * @interface BulkClockInData
 */
export interface BulkClockInData {
    /**
     * 
     * @type {Array<BulkClockInDataClockInDataInner>}
     * @memberof BulkClockInData
     */
    clockInData: Array<BulkClockInDataClockInDataInner>;
}

/**
 * Check if a given object implements the BulkClockInData interface.
 */
export function instanceOfBulkClockInData(value: object): value is BulkClockInData {
    if (!('clockInData' in value) || value['clockInData'] === undefined) return false;
    return true;
}

export function BulkClockInDataFromJSON(json: any): BulkClockInData {
    return BulkClockInDataFromJSONTyped(json, false);
}

export function BulkClockInDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkClockInData {
    if (json == null) {
        return json;
    }
    return {
        
        'clockInData': ((json['clockInData'] as Array<any>).map(BulkClockInDataClockInDataInnerFromJSON)),
    };
}

  export function BulkClockInDataToJSON(json: any): BulkClockInData {
      return BulkClockInDataToJSONTyped(json, false);
  }

  export function BulkClockInDataToJSONTyped(value?: BulkClockInData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'clockInData': ((value['clockInData'] as Array<any>).map(BulkClockInDataClockInDataInnerToJSON)),
    };
}

