/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface DaySummary
 */
export interface DaySummary {
    /**
     * 
     * @type {string}
     * @memberof DaySummary
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof DaySummary
     */
    companyId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof DaySummary
     */
    date: ShiftiePlainDate;
    /**
     * List of employeeIds who have an approved leave reqeust for the summary's date.
     * @type {Array<number>}
     * @memberof DaySummary
     */
    holidayEmployeeIds: Array<number>;
    /**
     * List of employeeIds who have either finished an absence period on the summary's date OR have an open absence.
     * @type {Array<number>}
     * @memberof DaySummary
     */
    absentEmployeeIds: Array<number>;
    /**
     * List of employeeIds who have an open timesheet.
     * @type {Array<number>}
     * @memberof DaySummary
     */
    workingEmployeeIds: Array<number>;
    /**
     * List of employeeIds who have a timesheet which closed on the summary's date.
     * @type {Array<number>}
     * @memberof DaySummary
     */
    finishedEmployeeIds: Array<number>;
    /**
     * List of employeeIds who have an in-progress shift for the summary's date but have not clocked into it.
     * @type {Array<number>}
     * @memberof DaySummary
     */
    missingEmployeeIds?: Array<number>;
}

/**
 * Check if a given object implements the DaySummary interface.
 */
export function instanceOfDaySummary(value: object): value is DaySummary {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('holidayEmployeeIds' in value) || value['holidayEmployeeIds'] === undefined) return false;
    if (!('absentEmployeeIds' in value) || value['absentEmployeeIds'] === undefined) return false;
    if (!('workingEmployeeIds' in value) || value['workingEmployeeIds'] === undefined) return false;
    if (!('finishedEmployeeIds' in value) || value['finishedEmployeeIds'] === undefined) return false;
    return true;
}

export function DaySummaryFromJSON(json: any): DaySummary {
    return DaySummaryFromJSONTyped(json, false);
}

export function DaySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaySummary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'date': ShiftiePlainDateFromJSON(json['date']),
        'holidayEmployeeIds': json['holidayEmployeeIds'],
        'absentEmployeeIds': json['absentEmployeeIds'],
        'workingEmployeeIds': json['workingEmployeeIds'],
        'finishedEmployeeIds': json['finishedEmployeeIds'],
        'missingEmployeeIds': json['missingEmployeeIds'] == null ? undefined : json['missingEmployeeIds'],
    };
}

  export function DaySummaryToJSON(json: any): DaySummary {
      return DaySummaryToJSONTyped(json, false);
  }

  export function DaySummaryToJSONTyped(value?: DaySummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'date': ShiftiePlainDateToJSON(value['date']),
        'holidayEmployeeIds': value['holidayEmployeeIds'],
        'absentEmployeeIds': value['absentEmployeeIds'],
        'workingEmployeeIds': value['workingEmployeeIds'],
        'finishedEmployeeIds': value['finishedEmployeeIds'],
        'missingEmployeeIds': value['missingEmployeeIds'],
    };
}

