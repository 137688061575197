/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface SubscriptionQuote
 */
export interface SubscriptionQuote {
    /**
     * The expected date when the new subscription would be applied.
     * @type {ShiftiePlainDate}
     * @memberof SubscriptionQuote
     */
    expectedStartDate?: ShiftiePlainDate;
    /**
     * The expected date of first full renewal, without pro-rata charges.
     * @type {ShiftiePlainDate}
     * @memberof SubscriptionQuote
     */
    expectedRenewalDate?: ShiftiePlainDate;
    /**
     * Offers on account that were used to calculate the quote.
     * @type {Array<string>}
     * @memberof SubscriptionQuote
     */
    appliedOffers: Array<string>;
    /**
     * Offers on account that weren't used to calculate the quote.
     * @type {Array<string>}
     * @memberof SubscriptionQuote
     */
    unusedOffers: Array<string>;
    /**
     * Total due before discounts and VAT.
     * @type {number}
     * @memberof SubscriptionQuote
     */
    subTotal: number;
    /**
     * Total discounts.
     * @type {number}
     * @memberof SubscriptionQuote
     */
    discounts: number;
    /**
     * The total amount payable for this quote.
     * @type {number}
     * @memberof SubscriptionQuote
     */
    totalDue: number;
}

/**
 * Check if a given object implements the SubscriptionQuote interface.
 */
export function instanceOfSubscriptionQuote(value: object): value is SubscriptionQuote {
    if (!('appliedOffers' in value) || value['appliedOffers'] === undefined) return false;
    if (!('unusedOffers' in value) || value['unusedOffers'] === undefined) return false;
    if (!('subTotal' in value) || value['subTotal'] === undefined) return false;
    if (!('discounts' in value) || value['discounts'] === undefined) return false;
    if (!('totalDue' in value) || value['totalDue'] === undefined) return false;
    return true;
}

export function SubscriptionQuoteFromJSON(json: any): SubscriptionQuote {
    return SubscriptionQuoteFromJSONTyped(json, false);
}

export function SubscriptionQuoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionQuote {
    if (json == null) {
        return json;
    }
    return {
        
        'expectedStartDate': json['expectedStartDate'] == null ? undefined : ShiftiePlainDateFromJSON(json['expectedStartDate']),
        'expectedRenewalDate': json['expectedRenewalDate'] == null ? undefined : ShiftiePlainDateFromJSON(json['expectedRenewalDate']),
        'appliedOffers': json['appliedOffers'],
        'unusedOffers': json['unusedOffers'],
        'subTotal': json['subTotal'],
        'discounts': json['discounts'],
        'totalDue': json['totalDue'],
    };
}

  export function SubscriptionQuoteToJSON(json: any): SubscriptionQuote {
      return SubscriptionQuoteToJSONTyped(json, false);
  }

  export function SubscriptionQuoteToJSONTyped(value?: SubscriptionQuote | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'expectedStartDate': ShiftiePlainDateToJSON(value['expectedStartDate']),
        'expectedRenewalDate': ShiftiePlainDateToJSON(value['expectedRenewalDate']),
        'appliedOffers': value['appliedOffers'],
        'unusedOffers': value['unusedOffers'],
        'subTotal': value['subTotal'],
        'discounts': value['discounts'],
        'totalDue': value['totalDue'],
    };
}

