/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveType } from './LeaveType';
import {
    LeaveTypeFromJSON,
    LeaveTypeFromJSONTyped,
    LeaveTypeToJSON,
    LeaveTypeToJSONTyped,
} from './LeaveType';

/**
 * 
 * @export
 * @interface CreateLeaveTypeResponse
 */
export interface CreateLeaveTypeResponse {
    /**
     * 
     * @type {LeaveType}
     * @memberof CreateLeaveTypeResponse
     */
    data: LeaveType;
}

/**
 * Check if a given object implements the CreateLeaveTypeResponse interface.
 */
export function instanceOfCreateLeaveTypeResponse(value: object): value is CreateLeaveTypeResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateLeaveTypeResponseFromJSON(json: any): CreateLeaveTypeResponse {
    return CreateLeaveTypeResponseFromJSONTyped(json, false);
}

export function CreateLeaveTypeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveTypeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeaveTypeFromJSON(json['data']),
    };
}

  export function CreateLeaveTypeResponseToJSON(json: any): CreateLeaveTypeResponse {
      return CreateLeaveTypeResponseToJSONTyped(json, false);
  }

  export function CreateLeaveTypeResponseToJSONTyped(value?: CreateLeaveTypeResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': LeaveTypeToJSON(value['data']),
    };
}

