/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyToJSONTyped,
} from './Company';

/**
 * 
 * @export
 * @interface CreateCompanyResponse
 */
export interface CreateCompanyResponse {
    /**
     * 
     * @type {Company}
     * @memberof CreateCompanyResponse
     */
    data: Company;
}

/**
 * Check if a given object implements the CreateCompanyResponse interface.
 */
export function instanceOfCreateCompanyResponse(value: object): value is CreateCompanyResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateCompanyResponseFromJSON(json: any): CreateCompanyResponse {
    return CreateCompanyResponseFromJSONTyped(json, false);
}

export function CreateCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCompanyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CompanyFromJSON(json['data']),
    };
}

  export function CreateCompanyResponseToJSON(json: any): CreateCompanyResponse {
      return CreateCompanyResponseToJSONTyped(json, false);
  }

  export function CreateCompanyResponseToJSONTyped(value?: CreateCompanyResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': CompanyToJSON(value['data']),
    };
}

