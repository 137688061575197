/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OffersQuota
 */
export interface OffersQuota {
    /**
     * 
     * @type {number}
     * @memberof OffersQuota
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof OffersQuota
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof OffersQuota
     */
    offerId: number;
    /**
     * 
     * @type {number}
     * @memberof OffersQuota
     */
    offerBehaviourId: number;
    /**
     * 
     * @type {number}
     * @memberof OffersQuota
     */
    offerRedemptionId: number | null;
    /**
     * 
     * @type {number}
     * @memberof OffersQuota
     */
    originalQuota: number;
    /**
     * 
     * @type {number}
     * @memberof OffersQuota
     */
    remainingQuota: number;
    /**
     * 
     * @type {Date}
     * @memberof OffersQuota
     */
    fullyRedeemedAt: Date | null;
}

/**
 * Check if a given object implements the OffersQuota interface.
 */
export function instanceOfOffersQuota(value: object): value is OffersQuota {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('offerId' in value) || value['offerId'] === undefined) return false;
    if (!('offerBehaviourId' in value) || value['offerBehaviourId'] === undefined) return false;
    if (!('offerRedemptionId' in value) || value['offerRedemptionId'] === undefined) return false;
    if (!('originalQuota' in value) || value['originalQuota'] === undefined) return false;
    if (!('remainingQuota' in value) || value['remainingQuota'] === undefined) return false;
    if (!('fullyRedeemedAt' in value) || value['fullyRedeemedAt'] === undefined) return false;
    return true;
}

export function OffersQuotaFromJSON(json: any): OffersQuota {
    return OffersQuotaFromJSONTyped(json, false);
}

export function OffersQuotaFromJSONTyped(json: any, ignoreDiscriminator: boolean): OffersQuota {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'offerId': json['offerId'],
        'offerBehaviourId': json['offerBehaviourId'],
        'offerRedemptionId': json['offerRedemptionId'],
        'originalQuota': json['originalQuota'],
        'remainingQuota': json['remainingQuota'],
        'fullyRedeemedAt': (json['fullyRedeemedAt'] == null ? null : new Date(json['fullyRedeemedAt'])),
    };
}

  export function OffersQuotaToJSON(json: any): OffersQuota {
      return OffersQuotaToJSONTyped(json, false);
  }

  export function OffersQuotaToJSONTyped(value?: OffersQuota | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'offerId': value['offerId'],
        'offerBehaviourId': value['offerBehaviourId'],
        'offerRedemptionId': value['offerRedemptionId'],
        'originalQuota': value['originalQuota'],
        'remainingQuota': value['remainingQuota'],
        'fullyRedeemedAt': (value['fullyRedeemedAt'] == null ? null : (value['fullyRedeemedAt'] as any).toISOString()),
    };
}

