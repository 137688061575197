/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';

/**
 * 
 * @export
 * @interface ShowJobRoleResponse
 */
export interface ShowJobRoleResponse {
    /**
     * 
     * @type {JobRole}
     * @memberof ShowJobRoleResponse
     */
    data: JobRole;
}

/**
 * Check if a given object implements the ShowJobRoleResponse interface.
 */
export function instanceOfShowJobRoleResponse(value: object): value is ShowJobRoleResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowJobRoleResponseFromJSON(json: any): ShowJobRoleResponse {
    return ShowJobRoleResponseFromJSONTyped(json, false);
}

export function ShowJobRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowJobRoleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': JobRoleFromJSON(json['data']),
    };
}

  export function ShowJobRoleResponseToJSON(json: any): ShowJobRoleResponse {
      return ShowJobRoleResponseToJSONTyped(json, false);
  }

  export function ShowJobRoleResponseToJSONTyped(value?: ShowJobRoleResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': JobRoleToJSON(value['data']),
    };
}

