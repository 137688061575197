/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Schedule } from './Schedule';
import {
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
    ScheduleToJSONTyped,
} from './Schedule';

/**
 * A label and description that can be applied to a period of time for one or more Schedules
 * @export
 * @interface ScheduleEvent
 */
export interface ScheduleEvent {
    /**
     * 
     * @type {number}
     * @memberof ScheduleEvent
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleEvent
     */
    companyId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ScheduleEvent
     */
    scheduleIds: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleEvent
     */
    dateStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleEvent
     */
    dateEnd: Date;
    /**
     * 
     * @type {string}
     * @memberof ScheduleEvent
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ScheduleEvent
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduleEvent
     */
    scheduleEventType: ScheduleEventScheduleEventTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleEvent
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleEvent
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof ScheduleEvent
     */
    schedules?: Array<Schedule>;
}

/**
* @export
* @enum {string}
*/
export enum ScheduleEventScheduleEventTypeEnum {
    Announcement = 'Announcement',
    BusinessClosed = 'BusinessClosed',
    LeaveRestricted = 'LeaveRestricted'
}


/**
 * Check if a given object implements the ScheduleEvent interface.
 */
export function instanceOfScheduleEvent(value: object): value is ScheduleEvent {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('scheduleIds' in value) || value['scheduleIds'] === undefined) return false;
    if (!('dateStart' in value) || value['dateStart'] === undefined) return false;
    if (!('dateEnd' in value) || value['dateEnd'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('description' in value) || value['description'] === undefined) return false;
    if (!('scheduleEventType' in value) || value['scheduleEventType'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function ScheduleEventFromJSON(json: any): ScheduleEvent {
    return ScheduleEventFromJSONTyped(json, false);
}

export function ScheduleEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'scheduleIds': json['scheduleIds'],
        'dateStart': (new Date(json['dateStart'])),
        'dateEnd': (new Date(json['dateEnd'])),
        'title': json['title'],
        'description': json['description'],
        'scheduleEventType': json['scheduleEventType'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'schedules': json['schedules'] == null ? undefined : ((json['schedules'] as Array<any>).map(ScheduleFromJSON)),
    };
}

  export function ScheduleEventToJSON(json: any): ScheduleEvent {
      return ScheduleEventToJSONTyped(json, false);
  }

  export function ScheduleEventToJSONTyped(value?: ScheduleEvent | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'scheduleIds': value['scheduleIds'],
        'dateStart': ((value['dateStart']).toISOString()),
        'dateEnd': ((value['dateEnd']).toISOString()),
        'title': value['title'],
        'description': value['description'],
        'scheduleEventType': value['scheduleEventType'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'schedules': value['schedules'] == null ? undefined : ((value['schedules'] as Array<any>).map(ScheduleToJSON)),
    };
}

