/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TriggerCreditRenewalData
 */
export interface TriggerCreditRenewalData {
    /**
     * 
     * @type {string}
     * @memberof TriggerCreditRenewalData
     */
    type: TriggerCreditRenewalDataTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum TriggerCreditRenewalDataTypeEnum {
    Referral = 'Referral',
    Sms = 'SMS'
}


/**
 * Check if a given object implements the TriggerCreditRenewalData interface.
 */
export function instanceOfTriggerCreditRenewalData(value: object): value is TriggerCreditRenewalData {
    if (!('type' in value) || value['type'] === undefined) return false;
    return true;
}

export function TriggerCreditRenewalDataFromJSON(json: any): TriggerCreditRenewalData {
    return TriggerCreditRenewalDataFromJSONTyped(json, false);
}

export function TriggerCreditRenewalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): TriggerCreditRenewalData {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
    };
}

  export function TriggerCreditRenewalDataToJSON(json: any): TriggerCreditRenewalData {
      return TriggerCreditRenewalDataToJSONTyped(json, false);
  }

  export function TriggerCreditRenewalDataToJSONTyped(value?: TriggerCreditRenewalData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'type': value['type'],
    };
}

