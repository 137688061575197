/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCompanyNotificationChannelData
 */
export interface UpdateCompanyNotificationChannelData {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyNotificationChannelData
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the UpdateCompanyNotificationChannelData interface.
 */
export function instanceOfUpdateCompanyNotificationChannelData(value: object): value is UpdateCompanyNotificationChannelData {
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    return true;
}

export function UpdateCompanyNotificationChannelDataFromJSON(json: any): UpdateCompanyNotificationChannelData {
    return UpdateCompanyNotificationChannelDataFromJSONTyped(json, false);
}

export function UpdateCompanyNotificationChannelDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyNotificationChannelData {
    if (json == null) {
        return json;
    }
    return {
        
        'enabled': json['enabled'],
    };
}

  export function UpdateCompanyNotificationChannelDataToJSON(json: any): UpdateCompanyNotificationChannelData {
      return UpdateCompanyNotificationChannelDataToJSONTyped(json, false);
  }

  export function UpdateCompanyNotificationChannelDataToJSONTyped(value?: UpdateCompanyNotificationChannelData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'enabled': value['enabled'],
    };
}

