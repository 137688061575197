/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeMessageStats } from './EmployeeMessageStats';
import {
    EmployeeMessageStatsFromJSON,
    EmployeeMessageStatsFromJSONTyped,
    EmployeeMessageStatsToJSON,
    EmployeeMessageStatsToJSONTyped,
} from './EmployeeMessageStats';

/**
 * 
 * @export
 * @interface ShowEmployeeMessageStatsResponse
 */
export interface ShowEmployeeMessageStatsResponse {
    /**
     * 
     * @type {EmployeeMessageStats}
     * @memberof ShowEmployeeMessageStatsResponse
     */
    data: EmployeeMessageStats;
}

/**
 * Check if a given object implements the ShowEmployeeMessageStatsResponse interface.
 */
export function instanceOfShowEmployeeMessageStatsResponse(value: object): value is ShowEmployeeMessageStatsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowEmployeeMessageStatsResponseFromJSON(json: any): ShowEmployeeMessageStatsResponse {
    return ShowEmployeeMessageStatsResponseFromJSONTyped(json, false);
}

export function ShowEmployeeMessageStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeMessageStatsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeMessageStatsFromJSON(json['data']),
    };
}

  export function ShowEmployeeMessageStatsResponseToJSON(json: any): ShowEmployeeMessageStatsResponse {
      return ShowEmployeeMessageStatsResponseToJSONTyped(json, false);
  }

  export function ShowEmployeeMessageStatsResponseToJSONTyped(value?: ShowEmployeeMessageStatsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': EmployeeMessageStatsToJSON(value['data']),
    };
}

