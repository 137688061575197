/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { Absence } from './Absence';
import {
    AbsenceFromJSON,
    AbsenceFromJSONTyped,
    AbsenceToJSON,
    AbsenceToJSONTyped,
} from './Absence';

/**
 * 
 * @export
 * @interface ListAbsenceResponse
 */
export interface ListAbsenceResponse {
    /**
     * 
     * @type {Array<Absence>}
     * @memberof ListAbsenceResponse
     */
    data: Array<Absence>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListAbsenceResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListAbsenceResponse interface.
 */
export function instanceOfListAbsenceResponse(value: object): value is ListAbsenceResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListAbsenceResponseFromJSON(json: any): ListAbsenceResponse {
    return ListAbsenceResponseFromJSONTyped(json, false);
}

export function ListAbsenceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAbsenceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(AbsenceFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListAbsenceResponseToJSON(json: any): ListAbsenceResponse {
      return ListAbsenceResponseToJSONTyped(json, false);
  }

  export function ListAbsenceResponseToJSONTyped(value?: ListAbsenceResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(AbsenceToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

