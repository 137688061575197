/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Unavailability } from './Unavailability';
import {
    UnavailabilityFromJSON,
    UnavailabilityFromJSONTyped,
    UnavailabilityToJSON,
    UnavailabilityToJSONTyped,
} from './Unavailability';

/**
 * 
 * @export
 * @interface UpdateUnavailabilityResponse
 */
export interface UpdateUnavailabilityResponse {
    /**
     * 
     * @type {Unavailability}
     * @memberof UpdateUnavailabilityResponse
     */
    data: Unavailability;
}

/**
 * Check if a given object implements the UpdateUnavailabilityResponse interface.
 */
export function instanceOfUpdateUnavailabilityResponse(value: object): value is UpdateUnavailabilityResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateUnavailabilityResponseFromJSON(json: any): UpdateUnavailabilityResponse {
    return UpdateUnavailabilityResponseFromJSONTyped(json, false);
}

export function UpdateUnavailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateUnavailabilityResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': UnavailabilityFromJSON(json['data']),
    };
}

  export function UpdateUnavailabilityResponseToJSON(json: any): UpdateUnavailabilityResponse {
      return UpdateUnavailabilityResponseToJSONTyped(json, false);
  }

  export function UpdateUnavailabilityResponseToJSONTyped(value?: UpdateUnavailabilityResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': UnavailabilityToJSON(value['data']),
    };
}

