/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingSubscriptionQuota } from './BillingSubscriptionQuota';
import {
    BillingSubscriptionQuotaFromJSON,
    BillingSubscriptionQuotaFromJSONTyped,
    BillingSubscriptionQuotaToJSON,
    BillingSubscriptionQuotaToJSONTyped,
} from './BillingSubscriptionQuota';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';
import type { BillingPlan } from './BillingPlan';
import {
    BillingPlanFromJSON,
    BillingPlanFromJSONTyped,
    BillingPlanToJSON,
    BillingPlanToJSONTyped,
} from './BillingPlan';

/**
 * Represents a companies subscription to a billing plan.
 * @export
 * @interface BillingSubscription
 */
export interface BillingSubscription {
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    billingPlanId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BillingSubscription
     */
    startsOn: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BillingSubscription
     */
    endsOn: ShiftiePlainDate | null;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    status: BillingSubscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    type: BillingSubscriptionTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof BillingSubscription
     */
    terminatedAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    terminationReason: BillingSubscriptionTerminationReasonEnum;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BillingSubscription
     */
    nextRenewalAt: ShiftiePlainDate;
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    additionalEmployeePrice: number;
    /**
     * 
     * @type {BillingPlan}
     * @memberof BillingSubscription
     */
    billingPlan: BillingPlan;
    /**
     * 
     * @type {BillingSubscriptionQuota}
     * @memberof BillingSubscription
     */
    billingSubscriptionQuota?: BillingSubscriptionQuota;
}

/**
* @export
* @enum {string}
*/
export enum BillingSubscriptionStatusEnum {
    Active = 'Active',
    Terminated = 'Terminated'
}
/**
* @export
* @enum {string}
*/
export enum BillingSubscriptionTypeEnum {
    Paid = 'Paid',
    Trial = 'Trial'
}
/**
* @export
* @enum {string}
*/
export enum BillingSubscriptionTerminationReasonEnum {
    AccountCancelled = 'Account Cancelled',
    BillingFailed = 'Billing Failed',
    CompanyAnonymised = 'Company Anonymised',
    NewPlan = 'New Plan',
    SubscriptionExpired = 'Subscription Expired'
}


/**
 * Check if a given object implements the BillingSubscription interface.
 */
export function instanceOfBillingSubscription(value: object): value is BillingSubscription {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('billingPlanId' in value) || value['billingPlanId'] === undefined) return false;
    if (!('startsOn' in value) || value['startsOn'] === undefined) return false;
    if (!('endsOn' in value) || value['endsOn'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('terminatedAt' in value) || value['terminatedAt'] === undefined) return false;
    if (!('terminationReason' in value) || value['terminationReason'] === undefined) return false;
    if (!('nextRenewalAt' in value) || value['nextRenewalAt'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('additionalEmployeePrice' in value) || value['additionalEmployeePrice'] === undefined) return false;
    if (!('billingPlan' in value) || value['billingPlan'] === undefined) return false;
    return true;
}

export function BillingSubscriptionFromJSON(json: any): BillingSubscription {
    return BillingSubscriptionFromJSONTyped(json, false);
}

export function BillingSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingSubscription {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'billingPlanId': json['billingPlanId'],
        'startsOn': ShiftiePlainDateFromJSON(json['startsOn']),
        'endsOn': ShiftiePlainDateFromJSON(json['endsOn']),
        'status': json['status'],
        'type': json['type'],
        'terminatedAt': (json['terminatedAt'] == null ? null : new Date(json['terminatedAt'])),
        'terminationReason': json['terminationReason'],
        'nextRenewalAt': ShiftiePlainDateFromJSON(json['nextRenewalAt']),
        'price': json['price'],
        'additionalEmployeePrice': json['additionalEmployeePrice'],
        'billingPlan': BillingPlanFromJSON(json['billingPlan']),
        'billingSubscriptionQuota': json['billingSubscriptionQuota'] == null ? undefined : BillingSubscriptionQuotaFromJSON(json['billingSubscriptionQuota']),
    };
}

  export function BillingSubscriptionToJSON(json: any): BillingSubscription {
      return BillingSubscriptionToJSONTyped(json, false);
  }

  export function BillingSubscriptionToJSONTyped(value?: BillingSubscription | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'billingPlanId': value['billingPlanId'],
        'startsOn': ShiftiePlainDateToJSON(value['startsOn']),
        'endsOn': ShiftiePlainDateToJSON(value['endsOn']),
        'status': value['status'],
        'type': value['type'],
        'terminatedAt': (value['terminatedAt'] == null ? null : (value['terminatedAt'] as any).toISOString()),
        'terminationReason': value['terminationReason'],
        'nextRenewalAt': ShiftiePlainDateToJSON(value['nextRenewalAt']),
        'price': value['price'],
        'additionalEmployeePrice': value['additionalEmployeePrice'],
        'billingPlan': BillingPlanToJSON(value['billingPlan']),
        'billingSubscriptionQuota': BillingSubscriptionQuotaToJSON(value['billingSubscriptionQuota']),
    };
}

