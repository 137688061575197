/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeePersonalDetails } from './EmployeePersonalDetails';
import {
    EmployeePersonalDetailsFromJSON,
    EmployeePersonalDetailsFromJSONTyped,
    EmployeePersonalDetailsToJSON,
    EmployeePersonalDetailsToJSONTyped,
} from './EmployeePersonalDetails';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListEmployeePersonalDetailsResponse
 */
export interface ListEmployeePersonalDetailsResponse {
    /**
     * 
     * @type {Array<EmployeePersonalDetails>}
     * @memberof ListEmployeePersonalDetailsResponse
     */
    data: Array<EmployeePersonalDetails>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListEmployeePersonalDetailsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListEmployeePersonalDetailsResponse interface.
 */
export function instanceOfListEmployeePersonalDetailsResponse(value: object): value is ListEmployeePersonalDetailsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListEmployeePersonalDetailsResponseFromJSON(json: any): ListEmployeePersonalDetailsResponse {
    return ListEmployeePersonalDetailsResponseFromJSONTyped(json, false);
}

export function ListEmployeePersonalDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEmployeePersonalDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(EmployeePersonalDetailsFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListEmployeePersonalDetailsResponseToJSON(json: any): ListEmployeePersonalDetailsResponse {
      return ListEmployeePersonalDetailsResponseToJSONTyped(json, false);
  }

  export function ListEmployeePersonalDetailsResponseToJSONTyped(value?: ListEmployeePersonalDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(EmployeePersonalDetailsToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

