/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateShiftTemplateAreaSessionData
 */
export interface CreateShiftTemplateAreaSessionData {
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateAreaSessionData
     */
    shiftAreaId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateAreaSessionData
     */
    startHour: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateAreaSessionData
     */
    startMinute: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateAreaSessionData
     */
    endHour: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateAreaSessionData
     */
    endMinute: number;
}

/**
 * Check if a given object implements the CreateShiftTemplateAreaSessionData interface.
 */
export function instanceOfCreateShiftTemplateAreaSessionData(value: object): value is CreateShiftTemplateAreaSessionData {
    if (!('shiftAreaId' in value) || value['shiftAreaId'] === undefined) return false;
    if (!('startHour' in value) || value['startHour'] === undefined) return false;
    if (!('startMinute' in value) || value['startMinute'] === undefined) return false;
    if (!('endHour' in value) || value['endHour'] === undefined) return false;
    if (!('endMinute' in value) || value['endMinute'] === undefined) return false;
    return true;
}

export function CreateShiftTemplateAreaSessionDataFromJSON(json: any): CreateShiftTemplateAreaSessionData {
    return CreateShiftTemplateAreaSessionDataFromJSONTyped(json, false);
}

export function CreateShiftTemplateAreaSessionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftTemplateAreaSessionData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftAreaId': json['shiftAreaId'],
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'endHour': json['endHour'],
        'endMinute': json['endMinute'],
    };
}

  export function CreateShiftTemplateAreaSessionDataToJSON(json: any): CreateShiftTemplateAreaSessionData {
      return CreateShiftTemplateAreaSessionDataToJSONTyped(json, false);
  }

  export function CreateShiftTemplateAreaSessionDataToJSONTyped(value?: CreateShiftTemplateAreaSessionData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'shiftAreaId': value['shiftAreaId'],
        'startHour': value['startHour'],
        'startMinute': value['startMinute'],
        'endHour': value['endHour'],
        'endMinute': value['endMinute'],
    };
}

