/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DigiticketsEventSessionsReport
 */
export interface DigiticketsEventSessionsReport {
    /**
     * 
     * @type {string}
     * @memberof DigiticketsEventSessionsReport
     */
    eventName: string;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsEventSessionsReport
     */
    totalSpacesSold: number;
}

/**
 * Check if a given object implements the DigiticketsEventSessionsReport interface.
 */
export function instanceOfDigiticketsEventSessionsReport(value: object): value is DigiticketsEventSessionsReport {
    if (!('eventName' in value) || value['eventName'] === undefined) return false;
    if (!('totalSpacesSold' in value) || value['totalSpacesSold'] === undefined) return false;
    return true;
}

export function DigiticketsEventSessionsReportFromJSON(json: any): DigiticketsEventSessionsReport {
    return DigiticketsEventSessionsReportFromJSONTyped(json, false);
}

export function DigiticketsEventSessionsReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigiticketsEventSessionsReport {
    if (json == null) {
        return json;
    }
    return {
        
        'eventName': json['eventName'],
        'totalSpacesSold': json['totalSpacesSold'],
    };
}

  export function DigiticketsEventSessionsReportToJSON(json: any): DigiticketsEventSessionsReport {
      return DigiticketsEventSessionsReportToJSONTyped(json, false);
  }

  export function DigiticketsEventSessionsReportToJSONTyped(value?: DigiticketsEventSessionsReport | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'eventName': value['eventName'],
        'totalSpacesSold': value['totalSpacesSold'],
    };
}

