/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * An emergency contact for an employee
 * @export
 * @interface EmergencyContact
 */
export interface EmergencyContact {
    /**
     * 
     * @type {number}
     * @memberof EmergencyContact
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof EmergencyContact
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof EmergencyContact
     */
    employeeId: number;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContact
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContact
     */
    relationship: EmergencyContactRelationshipEnum;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContact
     */
    telephoneNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmergencyContact
     */
    mobileNumber: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EmergencyContact
     */
    isDefault: boolean;
}

/**
* @export
* @enum {string}
*/
export enum EmergencyContactRelationshipEnum {
    Other = 'Other',
    Parent = 'Parent',
    Partner = 'Partner',
    Relative = 'Relative',
    Sibling = 'Sibling',
    Spouse = 'Spouse'
}


/**
 * Check if a given object implements the EmergencyContact interface.
 */
export function instanceOfEmergencyContact(value: object): value is EmergencyContact {
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('relationship' in value) || value['relationship'] === undefined) return false;
    if (!('telephoneNumber' in value) || value['telephoneNumber'] === undefined) return false;
    if (!('mobileNumber' in value) || value['mobileNumber'] === undefined) return false;
    if (!('isDefault' in value) || value['isDefault'] === undefined) return false;
    return true;
}

export function EmergencyContactFromJSON(json: any): EmergencyContact {
    return EmergencyContactFromJSONTyped(json, false);
}

export function EmergencyContactFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmergencyContact {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'name': json['name'],
        'relationship': json['relationship'],
        'telephoneNumber': json['telephoneNumber'],
        'mobileNumber': json['mobileNumber'],
        'isDefault': json['isDefault'],
    };
}

  export function EmergencyContactToJSON(json: any): EmergencyContact {
      return EmergencyContactToJSONTyped(json, false);
  }

  export function EmergencyContactToJSONTyped(value?: EmergencyContact | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'name': value['name'],
        'relationship': value['relationship'],
        'telephoneNumber': value['telephoneNumber'],
        'mobileNumber': value['mobileNumber'],
        'isDefault': value['isDefault'],
    };
}

