/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';
import type { Shift } from './Shift';
import {
    ShiftFromJSON,
    ShiftFromJSONTyped,
    ShiftToJSON,
    ShiftToJSONTyped,
} from './Shift';
import type { ClockInEvent } from './ClockInEvent';
import {
    ClockInEventFromJSON,
    ClockInEventFromJSONTyped,
    ClockInEventToJSON,
    ClockInEventToJSONTyped,
} from './ClockInEvent';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
    EmployeeToJSONTyped,
} from './Employee';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
    TagToJSONTyped,
} from './Tag';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    LocationToJSONTyped,
} from './Location';
import type { TimesheetBreak } from './TimesheetBreak';
import {
    TimesheetBreakFromJSON,
    TimesheetBreakFromJSONTyped,
    TimesheetBreakToJSON,
    TimesheetBreakToJSONTyped,
} from './TimesheetBreak';

/**
 * A record of an employee clocking in and out.
 * @export
 * @interface TimesheetEntry
 */
export interface TimesheetEntry {
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    employeeId: number | null;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    locationId: number | null;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    jobRoleId: number | null;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    shiftId: number | null;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    confirmedBy: number | null;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntry
     */
    status: TimesheetEntryStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntry
     */
    type: TimesheetEntryTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetEntry
     */
    startedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetEntry
     */
    endedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetEntry
     */
    startedAtRounded: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetEntry
     */
    endedAtRounded: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetEntry
     */
    confirmedAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    rate: number;
    /**
     * A flag to show whether the entry was automatically approved.
     * @type {boolean}
     * @memberof TimesheetEntry
     */
    autoApproved: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TimesheetEntry
     */
    late: boolean;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    allocatedPaidBreakInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    allocatedUnpaidBreakInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    actualPaidBreakInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    actualUnpaidBreakInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    expectedWorkingTimeInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    actualTimeWorkedInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntry
     */
    overtimeInMinutes: number;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntry
     */
    notes: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetEntry
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetEntry
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {Employee}
     * @memberof TimesheetEntry
     */
    employee?: Employee;
    /**
     * 
     * @type {Location}
     * @memberof TimesheetEntry
     */
    location?: Location;
    /**
     * 
     * @type {Shift}
     * @memberof TimesheetEntry
     */
    shift?: Shift;
    /**
     * 
     * @type {JobRole}
     * @memberof TimesheetEntry
     */
    jobRole?: JobRole;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof TimesheetEntry
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {Array<number>}
     * @memberof TimesheetEntry
     */
    tagIds: Array<number>;
    /**
     * 
     * @type {Array<TimesheetBreak>}
     * @memberof TimesheetEntry
     */
    breaks: Array<TimesheetBreak>;
    /**
     * Clocking events related to this timesheet entry. Note: system generated actions will not produce an event, e.g. automatic clock-outs.
     * @type {Array<ClockInEvent>}
     * @memberof TimesheetEntry
     */
    clockingEvents: Array<ClockInEvent>;
}

/**
* @export
* @enum {string}
*/
export enum TimesheetEntryStatusEnum {
    Break = 'Break',
    Completed = 'Completed',
    Started = 'Started'
}
/**
* @export
* @enum {string}
*/
export enum TimesheetEntryTypeEnum {
    Scheduled = 'Scheduled',
    Unscheduled = 'Unscheduled'
}


/**
 * Check if a given object implements the TimesheetEntry interface.
 */
export function instanceOfTimesheetEntry(value: object): value is TimesheetEntry {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('locationId' in value) || value['locationId'] === undefined) return false;
    if (!('jobRoleId' in value) || value['jobRoleId'] === undefined) return false;
    if (!('shiftId' in value) || value['shiftId'] === undefined) return false;
    if (!('confirmedBy' in value) || value['confirmedBy'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('startedAt' in value) || value['startedAt'] === undefined) return false;
    if (!('endedAt' in value) || value['endedAt'] === undefined) return false;
    if (!('startedAtRounded' in value) || value['startedAtRounded'] === undefined) return false;
    if (!('endedAtRounded' in value) || value['endedAtRounded'] === undefined) return false;
    if (!('confirmedAt' in value) || value['confirmedAt'] === undefined) return false;
    if (!('rate' in value) || value['rate'] === undefined) return false;
    if (!('autoApproved' in value) || value['autoApproved'] === undefined) return false;
    if (!('late' in value) || value['late'] === undefined) return false;
    if (!('allocatedPaidBreakInMinutes' in value) || value['allocatedPaidBreakInMinutes'] === undefined) return false;
    if (!('allocatedUnpaidBreakInMinutes' in value) || value['allocatedUnpaidBreakInMinutes'] === undefined) return false;
    if (!('actualPaidBreakInMinutes' in value) || value['actualPaidBreakInMinutes'] === undefined) return false;
    if (!('actualUnpaidBreakInMinutes' in value) || value['actualUnpaidBreakInMinutes'] === undefined) return false;
    if (!('expectedWorkingTimeInMinutes' in value) || value['expectedWorkingTimeInMinutes'] === undefined) return false;
    if (!('actualTimeWorkedInMinutes' in value) || value['actualTimeWorkedInMinutes'] === undefined) return false;
    if (!('overtimeInMinutes' in value) || value['overtimeInMinutes'] === undefined) return false;
    if (!('notes' in value) || value['notes'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('tagIds' in value) || value['tagIds'] === undefined) return false;
    if (!('breaks' in value) || value['breaks'] === undefined) return false;
    if (!('clockingEvents' in value) || value['clockingEvents'] === undefined) return false;
    return true;
}

export function TimesheetEntryFromJSON(json: any): TimesheetEntry {
    return TimesheetEntryFromJSONTyped(json, false);
}

export function TimesheetEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimesheetEntry {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'locationId': json['locationId'],
        'jobRoleId': json['jobRoleId'],
        'shiftId': json['shiftId'],
        'confirmedBy': json['confirmedBy'],
        'status': json['status'],
        'type': json['type'],
        'startedAt': (new Date(json['startedAt'])),
        'endedAt': (json['endedAt'] == null ? null : new Date(json['endedAt'])),
        'startedAtRounded': (new Date(json['startedAtRounded'])),
        'endedAtRounded': (json['endedAtRounded'] == null ? null : new Date(json['endedAtRounded'])),
        'confirmedAt': (json['confirmedAt'] == null ? null : new Date(json['confirmedAt'])),
        'rate': json['rate'],
        'autoApproved': json['autoApproved'],
        'late': json['late'],
        'allocatedPaidBreakInMinutes': json['allocatedPaidBreakInMinutes'],
        'allocatedUnpaidBreakInMinutes': json['allocatedUnpaidBreakInMinutes'],
        'actualPaidBreakInMinutes': json['actualPaidBreakInMinutes'],
        'actualUnpaidBreakInMinutes': json['actualUnpaidBreakInMinutes'],
        'expectedWorkingTimeInMinutes': json['expectedWorkingTimeInMinutes'],
        'actualTimeWorkedInMinutes': json['actualTimeWorkedInMinutes'],
        'overtimeInMinutes': json['overtimeInMinutes'],
        'notes': json['notes'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'employee': json['employee'] == null ? undefined : EmployeeFromJSON(json['employee']),
        'location': json['location'] == null ? undefined : LocationFromJSON(json['location']),
        'shift': json['shift'] == null ? undefined : ShiftFromJSON(json['shift']),
        'jobRole': json['jobRole'] == null ? undefined : JobRoleFromJSON(json['jobRole']),
        'tags': json['tags'] == null ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'tagIds': json['tagIds'],
        'breaks': ((json['breaks'] as Array<any>).map(TimesheetBreakFromJSON)),
        'clockingEvents': ((json['clockingEvents'] as Array<any>).map(ClockInEventFromJSON)),
    };
}

  export function TimesheetEntryToJSON(json: any): TimesheetEntry {
      return TimesheetEntryToJSONTyped(json, false);
  }

  export function TimesheetEntryToJSONTyped(value?: TimesheetEntry | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'locationId': value['locationId'],
        'jobRoleId': value['jobRoleId'],
        'shiftId': value['shiftId'],
        'confirmedBy': value['confirmedBy'],
        'status': value['status'],
        'type': value['type'],
        'startedAt': ((value['startedAt']).toISOString()),
        'endedAt': (value['endedAt'] == null ? null : (value['endedAt'] as any).toISOString()),
        'startedAtRounded': ((value['startedAtRounded']).toISOString()),
        'endedAtRounded': (value['endedAtRounded'] == null ? null : (value['endedAtRounded'] as any).toISOString()),
        'confirmedAt': (value['confirmedAt'] == null ? null : (value['confirmedAt'] as any).toISOString()),
        'rate': value['rate'],
        'autoApproved': value['autoApproved'],
        'late': value['late'],
        'allocatedPaidBreakInMinutes': value['allocatedPaidBreakInMinutes'],
        'allocatedUnpaidBreakInMinutes': value['allocatedUnpaidBreakInMinutes'],
        'actualPaidBreakInMinutes': value['actualPaidBreakInMinutes'],
        'actualUnpaidBreakInMinutes': value['actualUnpaidBreakInMinutes'],
        'expectedWorkingTimeInMinutes': value['expectedWorkingTimeInMinutes'],
        'actualTimeWorkedInMinutes': value['actualTimeWorkedInMinutes'],
        'overtimeInMinutes': value['overtimeInMinutes'],
        'notes': value['notes'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'employee': EmployeeToJSON(value['employee']),
        'location': LocationToJSON(value['location']),
        'shift': ShiftToJSON(value['shift']),
        'jobRole': JobRoleToJSON(value['jobRole']),
        'tags': value['tags'] == null ? undefined : ((value['tags'] as Array<any>).map(TagToJSON)),
        'tagIds': value['tagIds'],
        'breaks': ((value['breaks'] as Array<any>).map(TimesheetBreakToJSON)),
        'clockingEvents': ((value['clockingEvents'] as Array<any>).map(ClockInEventToJSON)),
    };
}

