/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeavePeriodType } from './LeavePeriodType';
import {
    LeavePeriodTypeFromJSON,
    LeavePeriodTypeFromJSONTyped,
    LeavePeriodTypeToJSON,
    LeavePeriodTypeToJSONTyped,
} from './LeavePeriodType';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface LeavePeriod
 */
export interface LeavePeriod {
    /**
     * 
     * @type {number}
     * @memberof LeavePeriod
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof LeavePeriod
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof LeavePeriod
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof LeavePeriod
     */
    leavePolicyId: number;
    /**
     * 
     * @type {number}
     * @memberof LeavePeriod
     */
    defaultLeaveTypeId: number;
    /**
     * 
     * @type {boolean}
     * @memberof LeavePeriod
     */
    active: boolean;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof LeavePeriod
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof LeavePeriod
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof LeavePeriod
     */
    leaveAllowanceCanBeExceeded: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeavePeriod
     */
    excludePublicHolidaysFromWorkingTimeLost: boolean;
    /**
     * 
     * @type {Date}
     * @memberof LeavePeriod
     */
    confirmedAt: Date | null;
    /**
     * 
     * @type {Array<LeavePeriodType>}
     * @memberof LeavePeriod
     */
    leavePeriodTypes?: Array<LeavePeriodType>;
}

/**
 * Check if a given object implements the LeavePeriod interface.
 */
export function instanceOfLeavePeriod(value: object): value is LeavePeriod {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('leavePolicyId' in value) || value['leavePolicyId'] === undefined) return false;
    if (!('defaultLeaveTypeId' in value) || value['defaultLeaveTypeId'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    if (!('leaveAllowanceCanBeExceeded' in value) || value['leaveAllowanceCanBeExceeded'] === undefined) return false;
    if (!('excludePublicHolidaysFromWorkingTimeLost' in value) || value['excludePublicHolidaysFromWorkingTimeLost'] === undefined) return false;
    if (!('confirmedAt' in value) || value['confirmedAt'] === undefined) return false;
    return true;
}

export function LeavePeriodFromJSON(json: any): LeavePeriod {
    return LeavePeriodFromJSONTyped(json, false);
}

export function LeavePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeavePeriod {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'leavePolicyId': json['leavePolicyId'],
        'defaultLeaveTypeId': json['defaultLeaveTypeId'],
        'active': json['active'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'leaveAllowanceCanBeExceeded': json['leaveAllowanceCanBeExceeded'],
        'excludePublicHolidaysFromWorkingTimeLost': json['excludePublicHolidaysFromWorkingTimeLost'],
        'confirmedAt': (json['confirmedAt'] == null ? null : new Date(json['confirmedAt'])),
        'leavePeriodTypes': json['leavePeriodTypes'] == null ? undefined : ((json['leavePeriodTypes'] as Array<any>).map(LeavePeriodTypeFromJSON)),
    };
}

  export function LeavePeriodToJSON(json: any): LeavePeriod {
      return LeavePeriodToJSONTyped(json, false);
  }

  export function LeavePeriodToJSONTyped(value?: LeavePeriod | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'leavePolicyId': value['leavePolicyId'],
        'defaultLeaveTypeId': value['defaultLeaveTypeId'],
        'active': value['active'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'leaveAllowanceCanBeExceeded': value['leaveAllowanceCanBeExceeded'],
        'excludePublicHolidaysFromWorkingTimeLost': value['excludePublicHolidaysFromWorkingTimeLost'],
        'confirmedAt': (value['confirmedAt'] == null ? null : (value['confirmedAt'] as any).toISOString()),
        'leavePeriodTypes': value['leavePeriodTypes'] == null ? undefined : ((value['leavePeriodTypes'] as Array<any>).map(LeavePeriodTypeToJSON)),
    };
}

