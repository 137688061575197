/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePushSubscriptionData
 */
export interface CreatePushSubscriptionData {
    /**
     * 
     * @type {string}
     * @memberof CreatePushSubscriptionData
     */
    endpoint: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePushSubscriptionData
     */
    p256dh: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePushSubscriptionData
     */
    auth: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePushSubscriptionData
     */
    deviceIdentifier: string;
}

/**
 * Check if a given object implements the CreatePushSubscriptionData interface.
 */
export function instanceOfCreatePushSubscriptionData(value: object): value is CreatePushSubscriptionData {
    if (!('endpoint' in value) || value['endpoint'] === undefined) return false;
    if (!('p256dh' in value) || value['p256dh'] === undefined) return false;
    if (!('auth' in value) || value['auth'] === undefined) return false;
    if (!('deviceIdentifier' in value) || value['deviceIdentifier'] === undefined) return false;
    return true;
}

export function CreatePushSubscriptionDataFromJSON(json: any): CreatePushSubscriptionData {
    return CreatePushSubscriptionDataFromJSONTyped(json, false);
}

export function CreatePushSubscriptionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePushSubscriptionData {
    if (json == null) {
        return json;
    }
    return {
        
        'endpoint': json['endpoint'],
        'p256dh': json['p256dh'],
        'auth': json['auth'],
        'deviceIdentifier': json['deviceIdentifier'],
    };
}

  export function CreatePushSubscriptionDataToJSON(json: any): CreatePushSubscriptionData {
      return CreatePushSubscriptionDataToJSONTyped(json, false);
  }

  export function CreatePushSubscriptionDataToJSONTyped(value?: CreatePushSubscriptionData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'endpoint': value['endpoint'],
        'p256dh': value['p256dh'],
        'auth': value['auth'],
        'deviceIdentifier': value['deviceIdentifier'],
    };
}

