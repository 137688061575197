/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { ReportCostsByJobRoleRollup } from './ReportCostsByJobRoleRollup';
import {
    ReportCostsByJobRoleRollupFromJSON,
    ReportCostsByJobRoleRollupFromJSONTyped,
    ReportCostsByJobRoleRollupToJSON,
    ReportCostsByJobRoleRollupToJSONTyped,
} from './ReportCostsByJobRoleRollup';
import type { ReportCostsByJobRole } from './ReportCostsByJobRole';
import {
    ReportCostsByJobRoleFromJSON,
    ReportCostsByJobRoleFromJSONTyped,
    ReportCostsByJobRoleToJSON,
    ReportCostsByJobRoleToJSONTyped,
} from './ReportCostsByJobRole';

/**
 * 
 * @export
 * @interface ListShiftCostReportsByJobRoleResponse
 */
export interface ListShiftCostReportsByJobRoleResponse {
    /**
     * 
     * @type {Array<ReportCostsByJobRole>}
     * @memberof ListShiftCostReportsByJobRoleResponse
     */
    data: Array<ReportCostsByJobRole>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListShiftCostReportsByJobRoleResponse
     */
    pagination: Pagination;
    /**
     * 
     * @type {ReportCostsByJobRoleRollup}
     * @memberof ListShiftCostReportsByJobRoleResponse
     */
    rollup: ReportCostsByJobRoleRollup;
}

/**
 * Check if a given object implements the ListShiftCostReportsByJobRoleResponse interface.
 */
export function instanceOfListShiftCostReportsByJobRoleResponse(value: object): value is ListShiftCostReportsByJobRoleResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    if (!('rollup' in value) || value['rollup'] === undefined) return false;
    return true;
}

export function ListShiftCostReportsByJobRoleResponseFromJSON(json: any): ListShiftCostReportsByJobRoleResponse {
    return ListShiftCostReportsByJobRoleResponseFromJSONTyped(json, false);
}

export function ListShiftCostReportsByJobRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListShiftCostReportsByJobRoleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ReportCostsByJobRoleFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
        'rollup': ReportCostsByJobRoleRollupFromJSON(json['rollup']),
    };
}

  export function ListShiftCostReportsByJobRoleResponseToJSON(json: any): ListShiftCostReportsByJobRoleResponse {
      return ListShiftCostReportsByJobRoleResponseToJSONTyped(json, false);
  }

  export function ListShiftCostReportsByJobRoleResponseToJSONTyped(value?: ListShiftCostReportsByJobRoleResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ReportCostsByJobRoleToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
        'rollup': ReportCostsByJobRoleRollupToJSON(value['rollup']),
    };
}

