/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetPasswordData
 */
export interface SetPasswordData {
    /**
     * 
     * @type {string}
     * @memberof SetPasswordData
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof SetPasswordData
     */
    password: string;
}

/**
 * Check if a given object implements the SetPasswordData interface.
 */
export function instanceOfSetPasswordData(value: object): value is SetPasswordData {
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    return true;
}

export function SetPasswordDataFromJSON(json: any): SetPasswordData {
    return SetPasswordDataFromJSONTyped(json, false);
}

export function SetPasswordDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetPasswordData {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'password': json['password'],
    };
}

  export function SetPasswordDataToJSON(json: any): SetPasswordData {
      return SetPasswordDataToJSONTyped(json, false);
  }

  export function SetPasswordDataToJSONTyped(value?: SetPasswordData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'token': value['token'],
        'password': value['password'],
    };
}

