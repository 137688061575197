/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkPatternOverride } from './WorkPatternOverride';
import {
    WorkPatternOverrideFromJSON,
    WorkPatternOverrideFromJSONTyped,
    WorkPatternOverrideToJSON,
    WorkPatternOverrideToJSONTyped,
} from './WorkPatternOverride';

/**
 * 
 * @export
 * @interface ShowWorkPatternOverrideResponse
 */
export interface ShowWorkPatternOverrideResponse {
    /**
     * 
     * @type {WorkPatternOverride}
     * @memberof ShowWorkPatternOverrideResponse
     */
    data: WorkPatternOverride;
}

/**
 * Check if a given object implements the ShowWorkPatternOverrideResponse interface.
 */
export function instanceOfShowWorkPatternOverrideResponse(value: object): value is ShowWorkPatternOverrideResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowWorkPatternOverrideResponseFromJSON(json: any): ShowWorkPatternOverrideResponse {
    return ShowWorkPatternOverrideResponseFromJSONTyped(json, false);
}

export function ShowWorkPatternOverrideResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowWorkPatternOverrideResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': WorkPatternOverrideFromJSON(json['data']),
    };
}

  export function ShowWorkPatternOverrideResponseToJSON(json: any): ShowWorkPatternOverrideResponse {
      return ShowWorkPatternOverrideResponseToJSONTyped(json, false);
  }

  export function ShowWorkPatternOverrideResponseToJSONTyped(value?: ShowWorkPatternOverrideResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': WorkPatternOverrideToJSON(value['data']),
    };
}

