/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteUnavailabilityData
 */
export interface DeleteUnavailabilityData {
    /**
     * When deleting an event (Unavailability or Shift) that is part of a recurring series, users can choose whether to delete just the one they've selected, or delete any future events that are part of the same series.
     * @type {string}
     * @memberof DeleteUnavailabilityData
     */
    mode?: DeleteUnavailabilityDataModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum DeleteUnavailabilityDataModeEnum {
    Future = 'DELETE_FUTURE',
    Single = 'DELETE_SINGLE'
}


/**
 * Check if a given object implements the DeleteUnavailabilityData interface.
 */
export function instanceOfDeleteUnavailabilityData(value: object): value is DeleteUnavailabilityData {
    return true;
}

export function DeleteUnavailabilityDataFromJSON(json: any): DeleteUnavailabilityData {
    return DeleteUnavailabilityDataFromJSONTyped(json, false);
}

export function DeleteUnavailabilityDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteUnavailabilityData {
    if (json == null) {
        return json;
    }
    return {
        
        'mode': json['mode'] == null ? undefined : json['mode'],
    };
}

  export function DeleteUnavailabilityDataToJSON(json: any): DeleteUnavailabilityData {
      return DeleteUnavailabilityDataToJSONTyped(json, false);
  }

  export function DeleteUnavailabilityDataToJSONTyped(value?: DeleteUnavailabilityData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'mode': value['mode'],
    };
}

