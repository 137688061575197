/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingOverview } from './BillingOverview';
import {
    BillingOverviewFromJSON,
    BillingOverviewFromJSONTyped,
    BillingOverviewToJSON,
    BillingOverviewToJSONTyped,
} from './BillingOverview';

/**
 * 
 * @export
 * @interface ShowBillingOverviewResponse
 */
export interface ShowBillingOverviewResponse {
    /**
     * 
     * @type {BillingOverview}
     * @memberof ShowBillingOverviewResponse
     */
    data: BillingOverview;
}

/**
 * Check if a given object implements the ShowBillingOverviewResponse interface.
 */
export function instanceOfShowBillingOverviewResponse(value: object): value is ShowBillingOverviewResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowBillingOverviewResponseFromJSON(json: any): ShowBillingOverviewResponse {
    return ShowBillingOverviewResponseFromJSONTyped(json, false);
}

export function ShowBillingOverviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowBillingOverviewResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BillingOverviewFromJSON(json['data']),
    };
}

  export function ShowBillingOverviewResponseToJSON(json: any): ShowBillingOverviewResponse {
      return ShowBillingOverviewResponseToJSONTyped(json, false);
  }

  export function ShowBillingOverviewResponseToJSONTyped(value?: ShowBillingOverviewResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': BillingOverviewToJSON(value['data']),
    };
}

