/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreatePayTypeData
 */
export interface CreatePayTypeData {
    /**
     * 
     * @type {string}
     * @memberof CreatePayTypeData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePayTypeData
     */
    mode: CreatePayTypeDataModeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreatePayTypeData
     */
    hourlyRate: number;
}

/**
* @export
* @enum {string}
*/
export enum CreatePayTypeDataModeEnum {
    Fixed = 'Fixed',
    Percentage = 'Percentage'
}


/**
 * Check if a given object implements the CreatePayTypeData interface.
 */
export function instanceOfCreatePayTypeData(value: object): value is CreatePayTypeData {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('mode' in value) || value['mode'] === undefined) return false;
    if (!('hourlyRate' in value) || value['hourlyRate'] === undefined) return false;
    return true;
}

export function CreatePayTypeDataFromJSON(json: any): CreatePayTypeData {
    return CreatePayTypeDataFromJSONTyped(json, false);
}

export function CreatePayTypeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePayTypeData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'mode': json['mode'],
        'hourlyRate': json['hourlyRate'],
    };
}

  export function CreatePayTypeDataToJSON(json: any): CreatePayTypeData {
      return CreatePayTypeDataToJSONTyped(json, false);
  }

  export function CreatePayTypeDataToJSONTyped(value?: CreatePayTypeData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'mode': value['mode'],
        'hourlyRate': value['hourlyRate'],
    };
}

