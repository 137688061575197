/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Shift } from './Shift';
import {
    ShiftFromJSON,
    ShiftFromJSONTyped,
    ShiftToJSON,
    ShiftToJSONTyped,
} from './Shift';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
    EmployeeToJSONTyped,
} from './Employee';

/**
 * 
 * @export
 * @interface ShiftSwap
 */
export interface ShiftSwap {
    /**
     * 
     * @type {number}
     * @memberof ShiftSwap
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftSwap
     */
    shiftId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftSwap
     */
    replacementShiftId: number;
    /**
     * 
     * @type {string}
     * @memberof ShiftSwap
     */
    status: ShiftSwapStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ShiftSwap
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof ShiftSwap
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShiftSwap
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ShiftSwap
     */
    reviewerId: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShiftSwap
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftSwap
     */
    recipientId: number;
    /**
     * 
     * @type {Shift}
     * @memberof ShiftSwap
     */
    shift?: Shift;
    /**
     * 
     * @type {Shift}
     * @memberof ShiftSwap
     */
    replacementShift?: Shift;
    /**
     * 
     * @type {Employee}
     * @memberof ShiftSwap
     */
    reviewer?: Employee;
    /**
     * 
     * @type {Date}
     * @memberof ShiftSwap
     */
    reviewedAt: Date | null;
    /**
     * 
     * @type {Employee}
     * @memberof ShiftSwap
     */
    employee?: Employee;
    /**
     * 
     * @type {Employee}
     * @memberof ShiftSwap
     */
    recipient?: Employee;
}

/**
* @export
* @enum {string}
*/
export enum ShiftSwapStatusEnum {
    Cancelled = 'Cancelled',
    Complete = 'Complete',
    Declined = 'Declined',
    PendingAcceptance = 'Pending Acceptance',
    PendingApproval = 'Pending Approval',
    Rejected = 'Rejected'
}


/**
 * Check if a given object implements the ShiftSwap interface.
 */
export function instanceOfShiftSwap(value: object): value is ShiftSwap {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('shiftId' in value) || value['shiftId'] === undefined) return false;
    if (!('replacementShiftId' in value) || value['replacementShiftId'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('reviewerId' in value) || value['reviewerId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('recipientId' in value) || value['recipientId'] === undefined) return false;
    if (!('reviewedAt' in value) || value['reviewedAt'] === undefined) return false;
    return true;
}

export function ShiftSwapFromJSON(json: any): ShiftSwap {
    return ShiftSwapFromJSONTyped(json, false);
}

export function ShiftSwapFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftSwap {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'shiftId': json['shiftId'],
        'replacementShiftId': json['replacementShiftId'],
        'status': json['status'],
        'message': json['message'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'reviewerId': json['reviewerId'],
        'employeeId': json['employeeId'],
        'recipientId': json['recipientId'],
        'shift': json['shift'] == null ? undefined : ShiftFromJSON(json['shift']),
        'replacementShift': json['replacementShift'] == null ? undefined : ShiftFromJSON(json['replacementShift']),
        'reviewer': json['reviewer'] == null ? undefined : EmployeeFromJSON(json['reviewer']),
        'reviewedAt': (json['reviewedAt'] == null ? null : new Date(json['reviewedAt'])),
        'employee': json['employee'] == null ? undefined : EmployeeFromJSON(json['employee']),
        'recipient': json['recipient'] == null ? undefined : EmployeeFromJSON(json['recipient']),
    };
}

  export function ShiftSwapToJSON(json: any): ShiftSwap {
      return ShiftSwapToJSONTyped(json, false);
  }

  export function ShiftSwapToJSONTyped(value?: ShiftSwap | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'shiftId': value['shiftId'],
        'replacementShiftId': value['replacementShiftId'],
        'status': value['status'],
        'message': value['message'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'reviewerId': value['reviewerId'],
        'employeeId': value['employeeId'],
        'recipientId': value['recipientId'],
        'shift': ShiftToJSON(value['shift']),
        'replacementShift': ShiftToJSON(value['replacementShift']),
        'reviewer': EmployeeToJSON(value['reviewer']),
        'reviewedAt': (value['reviewedAt'] == null ? null : (value['reviewedAt'] as any).toISOString()),
        'employee': EmployeeToJSON(value['employee']),
        'recipient': EmployeeToJSON(value['recipient']),
    };
}

