/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeavePolicy } from './LeavePolicy';
import {
    LeavePolicyFromJSON,
    LeavePolicyFromJSONTyped,
    LeavePolicyToJSON,
    LeavePolicyToJSONTyped,
} from './LeavePolicy';

/**
 * 
 * @export
 * @interface UpdateLeavePolicyResponse
 */
export interface UpdateLeavePolicyResponse {
    /**
     * 
     * @type {LeavePolicy}
     * @memberof UpdateLeavePolicyResponse
     */
    data: LeavePolicy;
}

/**
 * Check if a given object implements the UpdateLeavePolicyResponse interface.
 */
export function instanceOfUpdateLeavePolicyResponse(value: object): value is UpdateLeavePolicyResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateLeavePolicyResponseFromJSON(json: any): UpdateLeavePolicyResponse {
    return UpdateLeavePolicyResponseFromJSONTyped(json, false);
}

export function UpdateLeavePolicyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeavePolicyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeavePolicyFromJSON(json['data']),
    };
}

  export function UpdateLeavePolicyResponseToJSON(json: any): UpdateLeavePolicyResponse {
      return UpdateLeavePolicyResponseToJSONTyped(json, false);
  }

  export function UpdateLeavePolicyResponseToJSONTyped(value?: UpdateLeavePolicyResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': LeavePolicyToJSON(value['data']),
    };
}

