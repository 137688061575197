/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PayPeriodsSummary
 */
export interface PayPeriodsSummary {
    /**
     * List of payPeriodIds where at least one timesheet entry within the period is not yet confirmed.
     * @type {Array<number>}
     * @memberof PayPeriodsSummary
     */
    payPeriodIdsWithUnconfirmedEntries: Array<number>;
    /**
     * List of payPeriodIds where all timesheet entries within the period have been confirmed.
     * @type {Array<number>}
     * @memberof PayPeriodsSummary
     */
    payPeriodIdsWithAllConfirmedEntries: Array<number>;
}

/**
 * Check if a given object implements the PayPeriodsSummary interface.
 */
export function instanceOfPayPeriodsSummary(value: object): value is PayPeriodsSummary {
    if (!('payPeriodIdsWithUnconfirmedEntries' in value) || value['payPeriodIdsWithUnconfirmedEntries'] === undefined) return false;
    if (!('payPeriodIdsWithAllConfirmedEntries' in value) || value['payPeriodIdsWithAllConfirmedEntries'] === undefined) return false;
    return true;
}

export function PayPeriodsSummaryFromJSON(json: any): PayPeriodsSummary {
    return PayPeriodsSummaryFromJSONTyped(json, false);
}

export function PayPeriodsSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayPeriodsSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'payPeriodIdsWithUnconfirmedEntries': json['payPeriodIdsWithUnconfirmedEntries'],
        'payPeriodIdsWithAllConfirmedEntries': json['payPeriodIdsWithAllConfirmedEntries'],
    };
}

  export function PayPeriodsSummaryToJSON(json: any): PayPeriodsSummary {
      return PayPeriodsSummaryToJSONTyped(json, false);
  }

  export function PayPeriodsSummaryToJSONTyped(value?: PayPeriodsSummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'payPeriodIdsWithUnconfirmedEntries': value['payPeriodIdsWithUnconfirmedEntries'],
        'payPeriodIdsWithAllConfirmedEntries': value['payPeriodIdsWithAllConfirmedEntries'],
    };
}

