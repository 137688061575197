/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WeatherReportData } from './WeatherReportData';
import {
    WeatherReportDataFromJSON,
    WeatherReportDataFromJSONTyped,
    WeatherReportDataToJSON,
    WeatherReportDataToJSONTyped,
} from './WeatherReportData';

/**
 * 
 * @export
 * @interface WeatherReport
 */
export interface WeatherReport {
    /**
     * 
     * @type {number}
     * @memberof WeatherReport
     */
    dt: number;
    /**
     * 
     * @type {number}
     * @memberof WeatherReport
     */
    temp: number;
    /**
     * 
     * @type {WeatherReportData}
     * @memberof WeatherReport
     */
    weather: WeatherReportData;
}

/**
 * Check if a given object implements the WeatherReport interface.
 */
export function instanceOfWeatherReport(value: object): value is WeatherReport {
    if (!('dt' in value) || value['dt'] === undefined) return false;
    if (!('temp' in value) || value['temp'] === undefined) return false;
    if (!('weather' in value) || value['weather'] === undefined) return false;
    return true;
}

export function WeatherReportFromJSON(json: any): WeatherReport {
    return WeatherReportFromJSONTyped(json, false);
}

export function WeatherReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeatherReport {
    if (json == null) {
        return json;
    }
    return {
        
        'dt': json['dt'],
        'temp': json['temp'],
        'weather': WeatherReportDataFromJSON(json['weather']),
    };
}

  export function WeatherReportToJSON(json: any): WeatherReport {
      return WeatherReportToJSONTyped(json, false);
  }

  export function WeatherReportToJSONTyped(value?: WeatherReport | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'dt': value['dt'],
        'temp': value['temp'],
        'weather': WeatherReportDataToJSON(value['weather']),
    };
}

