/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FeaturesEnabled
 */
export interface FeaturesEnabled {
    /**
     * 
     * @type {boolean}
     * @memberof FeaturesEnabled
     */
    smsEnabled: boolean;
}

/**
 * Check if a given object implements the FeaturesEnabled interface.
 */
export function instanceOfFeaturesEnabled(value: object): value is FeaturesEnabled {
    if (!('smsEnabled' in value) || value['smsEnabled'] === undefined) return false;
    return true;
}

export function FeaturesEnabledFromJSON(json: any): FeaturesEnabled {
    return FeaturesEnabledFromJSONTyped(json, false);
}

export function FeaturesEnabledFromJSONTyped(json: any, ignoreDiscriminator: boolean): FeaturesEnabled {
    if (json == null) {
        return json;
    }
    return {
        
        'smsEnabled': json['smsEnabled'],
    };
}

  export function FeaturesEnabledToJSON(json: any): FeaturesEnabled {
      return FeaturesEnabledToJSONTyped(json, false);
  }

  export function FeaturesEnabledToJSONTyped(value?: FeaturesEnabled | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'smsEnabled': value['smsEnabled'],
    };
}

