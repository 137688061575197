var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"selectInputContainer",staticClass:"w-full mt-[-4px] pt-[4px] overflow-hidden",class:{ 'mb-[-2rem] pb-[2rem]': !_vm.readOnly && _vm.hasError && _vm.error.length }},[_c('v-select',_vm._g(_vm._b({class:{ 'required-input': _vm.required, readOnly: _vm.readOnly || _vm.disabled },attrs:{"clearable":_vm.clearable && !!_vm.value && !_vm.readOnly,"value":_vm.getValue,"items":_vm.items,"label":_vm.labelText,"placeholder":_vm.getPlaceholder,"item-text":_vm.itemText,"item-value":_vm.itemValue,"item-color":'#AD1267',"color":'#AD1267',"no-data-text":_vm.noDataText,"menu-props":{
      closeOnContentClick: !_vm.multiple,
      ...(_vm.selectWidth && { maxWidth: _vm.selectWidth }),
      offsetY: true,
    },"error":!_vm.readOnly ? _vm.hasError : false,"error-messages":!_vm.readOnly ? _vm.error : null,"outlined":"","append-icon":!_vm.readOnly && !_vm.disabled && (!_vm.clearable || !_vm.getValue) ? '$dropdown' : '',"multiple":_vm.multiple,"required":_vm.required,"disabled":_vm.disabled,"readonly":_vm.readOnly || _vm.isSecured,"hide-details":"auto","height":_vm.multiple ? 'auto' : _vm.height,"clear-icon":_vm.Icon.Xmark},on:{"click:clear":function($event){return _vm.handleInput(_vm.multiple ? [] : null)},"blur":_vm.blur,"input":_vm.handleInput},scopedSlots:_vm._u([(_vm.isSecured)?{key:"selection",fn:function(){return [_c('div',{staticClass:"font-bold text-20"},[_c('span',{staticClass:"inline-block relative top-[-5px]"},[_vm._v(".....")])])]},proxy:true}:(_vm.multiple || _vm.employee || _vm.customLayout)?{key:"selection",fn:function(data){return [_vm._t("selection",function(){return [_c('div',{staticClass:"multi-selection",class:{ 'multi-selection--outline': _vm.disabled },on:{"click":function($event){$event.stopPropagation();return data.parent.selectItem(data.item)}}},[(!!_vm.itemColourIndicator && data.item[_vm.itemColourIndicator])?_c('span',{staticClass:"inline-block w-2 h-2 rounded-full mr-1",style:({
              backgroundColor: data.item[_vm.itemColourIndicator],
              minWidth: '8px',
            })}):_vm._e(),_c('span',{staticClass:"text-ellipsis overflow-hidden"},[_vm._v(" "+_vm._s(data.item[_vm.itemText])+" ")]),(!_vm.disabled)?_c('i',{class:_vm.Icon.Xmark}):_vm._e()])]},{"itemSlot":data.item})]}}:(!!_vm.itemColourIndicator)?{key:"selection",fn:function({ item }){return [_c('div',{staticClass:"flex align-center min-w-0 !max-w-[99.9%]"},[(item[_vm.itemColourIndicator])?_c('span',{staticClass:"inline-block w-2 min-w-[.5rem] h-2 rounded-full mr-1",style:({ backgroundColor: item[_vm.itemColourIndicator] })}):_vm._e(),_c('span',{staticClass:"text-overflow-ellipsis"},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])])]}}:null,(_vm.employee)?{key:"item",fn:function({ item }){return [_vm._t("item",null,{"itemSlot":item})]}}:(!!_vm.itemColourIndicator)?{key:"item",fn:function({ item, attrs, on }){return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function({ active }){return [(_vm.multiple)?_c('v-list-item-action',{staticClass:"m-0 mr-2"},[_c('Checkbox',{attrs:{"value":active}})],1):_vm._e(),(item && item[_vm.itemColourIndicator])?_c('span',{staticClass:"inline-block w-2 h-2 rounded-full mr-2",style:({ backgroundColor: item[_vm.itemColourIndicator] })}):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}:null,(_vm.$slots.append)?{key:"append",fn:function(){return [_vm._t("append")]},proxy:true}:null,(_vm.$slots['append-item'])?{key:"append-item",fn:function(){return [_vm._t("append-item")]},proxy:true}:null],null,true)},'v-select',_vm.$attrs,false),_vm.getListeners))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }