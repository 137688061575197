/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeClockingProfile
 */
export interface EmployeeClockingProfile {
    /**
     * 
     * @type {number}
     * @memberof EmployeeClockingProfile
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeClockingProfile
     */
    employeeId: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeeClockingProfile
     */
    clockInPin: string | null;
}

/**
 * Check if a given object implements the EmployeeClockingProfile interface.
 */
export function instanceOfEmployeeClockingProfile(value: object): value is EmployeeClockingProfile {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('clockInPin' in value) || value['clockInPin'] === undefined) return false;
    return true;
}

export function EmployeeClockingProfileFromJSON(json: any): EmployeeClockingProfile {
    return EmployeeClockingProfileFromJSONTyped(json, false);
}

export function EmployeeClockingProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeClockingProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'employeeId': json['employeeId'],
        'clockInPin': json['clockInPin'],
    };
}

  export function EmployeeClockingProfileToJSON(json: any): EmployeeClockingProfile {
      return EmployeeClockingProfileToJSONTyped(json, false);
  }

  export function EmployeeClockingProfileToJSONTyped(value?: EmployeeClockingProfile | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'employeeId': value['employeeId'],
        'clockInPin': value['clockInPin'],
    };
}

