/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveAdjustment } from './LeaveAdjustment';
import {
    LeaveAdjustmentFromJSON,
    LeaveAdjustmentFromJSONTyped,
    LeaveAdjustmentToJSON,
    LeaveAdjustmentToJSONTyped,
} from './LeaveAdjustment';

/**
 * 
 * @export
 * @interface CreateLeaveAdjustmentResponse
 */
export interface CreateLeaveAdjustmentResponse {
    /**
     * 
     * @type {LeaveAdjustment}
     * @memberof CreateLeaveAdjustmentResponse
     */
    data: LeaveAdjustment;
}

/**
 * Check if a given object implements the CreateLeaveAdjustmentResponse interface.
 */
export function instanceOfCreateLeaveAdjustmentResponse(value: object): value is CreateLeaveAdjustmentResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateLeaveAdjustmentResponseFromJSON(json: any): CreateLeaveAdjustmentResponse {
    return CreateLeaveAdjustmentResponseFromJSONTyped(json, false);
}

export function CreateLeaveAdjustmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveAdjustmentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeaveAdjustmentFromJSON(json['data']),
    };
}

  export function CreateLeaveAdjustmentResponseToJSON(json: any): CreateLeaveAdjustmentResponse {
      return CreateLeaveAdjustmentResponseToJSONTyped(json, false);
  }

  export function CreateLeaveAdjustmentResponseToJSONTyped(value?: CreateLeaveAdjustmentResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': LeaveAdjustmentToJSON(value['data']),
    };
}

