/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShiftTemplateShiftAreaSession
 */
export interface ShiftTemplateShiftAreaSession {
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateShiftAreaSession
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateShiftAreaSession
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateShiftAreaSession
     */
    shiftTemplateId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateShiftAreaSession
     */
    shiftAreaId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateShiftAreaSession
     */
    startHour: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateShiftAreaSession
     */
    startMinute: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateShiftAreaSession
     */
    endHour: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftTemplateShiftAreaSession
     */
    endMinute: number;
}

/**
 * Check if a given object implements the ShiftTemplateShiftAreaSession interface.
 */
export function instanceOfShiftTemplateShiftAreaSession(value: object): value is ShiftTemplateShiftAreaSession {
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('shiftTemplateId' in value) || value['shiftTemplateId'] === undefined) return false;
    if (!('shiftAreaId' in value) || value['shiftAreaId'] === undefined) return false;
    if (!('startHour' in value) || value['startHour'] === undefined) return false;
    if (!('startMinute' in value) || value['startMinute'] === undefined) return false;
    if (!('endHour' in value) || value['endHour'] === undefined) return false;
    if (!('endMinute' in value) || value['endMinute'] === undefined) return false;
    return true;
}

export function ShiftTemplateShiftAreaSessionFromJSON(json: any): ShiftTemplateShiftAreaSession {
    return ShiftTemplateShiftAreaSessionFromJSONTyped(json, false);
}

export function ShiftTemplateShiftAreaSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftTemplateShiftAreaSession {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'companyId': json['companyId'],
        'shiftTemplateId': json['shiftTemplateId'],
        'shiftAreaId': json['shiftAreaId'],
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'endHour': json['endHour'],
        'endMinute': json['endMinute'],
    };
}

  export function ShiftTemplateShiftAreaSessionToJSON(json: any): ShiftTemplateShiftAreaSession {
      return ShiftTemplateShiftAreaSessionToJSONTyped(json, false);
  }

  export function ShiftTemplateShiftAreaSessionToJSONTyped(value?: ShiftTemplateShiftAreaSession | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'shiftTemplateId': value['shiftTemplateId'],
        'shiftAreaId': value['shiftAreaId'],
        'startHour': value['startHour'],
        'startMinute': value['startMinute'],
        'endHour': value['endHour'],
        'endMinute': value['endMinute'],
    };
}

