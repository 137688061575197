/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCostsByJobRoleRollup
 */
export interface ReportCostsByJobRoleRollup {
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRoleRollup
     */
    employees: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRoleRollup
     */
    shifts: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRoleRollup
     */
    hoursScheduled: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRoleRollup
     */
    hoursWorked: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRoleRollup
     */
    costsScheduled: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByJobRoleRollup
     */
    costsWorked: number;
}

/**
 * Check if a given object implements the ReportCostsByJobRoleRollup interface.
 */
export function instanceOfReportCostsByJobRoleRollup(value: object): value is ReportCostsByJobRoleRollup {
    if (!('employees' in value) || value['employees'] === undefined) return false;
    if (!('shifts' in value) || value['shifts'] === undefined) return false;
    if (!('hoursScheduled' in value) || value['hoursScheduled'] === undefined) return false;
    if (!('hoursWorked' in value) || value['hoursWorked'] === undefined) return false;
    if (!('costsScheduled' in value) || value['costsScheduled'] === undefined) return false;
    if (!('costsWorked' in value) || value['costsWorked'] === undefined) return false;
    return true;
}

export function ReportCostsByJobRoleRollupFromJSON(json: any): ReportCostsByJobRoleRollup {
    return ReportCostsByJobRoleRollupFromJSONTyped(json, false);
}

export function ReportCostsByJobRoleRollupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCostsByJobRoleRollup {
    if (json == null) {
        return json;
    }
    return {
        
        'employees': json['employees'],
        'shifts': json['shifts'],
        'hoursScheduled': json['hoursScheduled'],
        'hoursWorked': json['hoursWorked'],
        'costsScheduled': json['costsScheduled'],
        'costsWorked': json['costsWorked'],
    };
}

  export function ReportCostsByJobRoleRollupToJSON(json: any): ReportCostsByJobRoleRollup {
      return ReportCostsByJobRoleRollupToJSONTyped(json, false);
  }

  export function ReportCostsByJobRoleRollupToJSONTyped(value?: ReportCostsByJobRoleRollup | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employees': value['employees'],
        'shifts': value['shifts'],
        'hoursScheduled': value['hoursScheduled'],
        'hoursWorked': value['hoursWorked'],
        'costsScheduled': value['costsScheduled'],
        'costsWorked': value['costsWorked'],
    };
}

