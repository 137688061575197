/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateWorkPatternData
 */
export interface CreateWorkPatternData {
    /**
     * 
     * @type {string}
     * @memberof CreateWorkPatternData
     */
    name: string;
    /**
     * An array representing which days of the week are working days. 0 = Sunday, 6 = Saturday.
     * @type {Array<boolean>}
     * @memberof CreateWorkPatternData
     */
    pattern: Array<boolean>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkPatternData
     */
    isPrimary?: boolean;
}

/**
 * Check if a given object implements the CreateWorkPatternData interface.
 */
export function instanceOfCreateWorkPatternData(value: object): value is CreateWorkPatternData {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('pattern' in value) || value['pattern'] === undefined) return false;
    return true;
}

export function CreateWorkPatternDataFromJSON(json: any): CreateWorkPatternData {
    return CreateWorkPatternDataFromJSONTyped(json, false);
}

export function CreateWorkPatternDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWorkPatternData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'pattern': json['pattern'],
        'isPrimary': json['isPrimary'] == null ? undefined : json['isPrimary'],
    };
}

  export function CreateWorkPatternDataToJSON(json: any): CreateWorkPatternData {
      return CreateWorkPatternDataToJSONTyped(json, false);
  }

  export function CreateWorkPatternDataToJSONTyped(value?: CreateWorkPatternData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'pattern': value['pattern'],
        'isPrimary': value['isPrimary'],
    };
}

