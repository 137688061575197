/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeLeaveSummary } from './EmployeeLeaveSummary';
import {
    EmployeeLeaveSummaryFromJSON,
    EmployeeLeaveSummaryFromJSONTyped,
    EmployeeLeaveSummaryToJSON,
    EmployeeLeaveSummaryToJSONTyped,
} from './EmployeeLeaveSummary';

/**
 * 
 * @export
 * @interface ShowEmployeeLeaveSummaryResponse
 */
export interface ShowEmployeeLeaveSummaryResponse {
    /**
     * 
     * @type {EmployeeLeaveSummary}
     * @memberof ShowEmployeeLeaveSummaryResponse
     */
    data: EmployeeLeaveSummary;
}

/**
 * Check if a given object implements the ShowEmployeeLeaveSummaryResponse interface.
 */
export function instanceOfShowEmployeeLeaveSummaryResponse(value: object): value is ShowEmployeeLeaveSummaryResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowEmployeeLeaveSummaryResponseFromJSON(json: any): ShowEmployeeLeaveSummaryResponse {
    return ShowEmployeeLeaveSummaryResponseFromJSONTyped(json, false);
}

export function ShowEmployeeLeaveSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeLeaveSummaryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeLeaveSummaryFromJSON(json['data']),
    };
}

  export function ShowEmployeeLeaveSummaryResponseToJSON(json: any): ShowEmployeeLeaveSummaryResponse {
      return ShowEmployeeLeaveSummaryResponseToJSONTyped(json, false);
  }

  export function ShowEmployeeLeaveSummaryResponseToJSONTyped(value?: ShowEmployeeLeaveSummaryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': EmployeeLeaveSummaryToJSON(value['data']),
    };
}

