/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { OffersBehaviour } from './OffersBehaviour';
import {
    OffersBehaviourFromJSON,
    OffersBehaviourFromJSONTyped,
    OffersBehaviourToJSON,
    OffersBehaviourToJSONTyped,
} from './OffersBehaviour';

/**
 * 
 * @export
 * @interface ListOffersBehavioursResponse
 */
export interface ListOffersBehavioursResponse {
    /**
     * 
     * @type {Array<OffersBehaviour>}
     * @memberof ListOffersBehavioursResponse
     */
    data: Array<OffersBehaviour>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListOffersBehavioursResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListOffersBehavioursResponse interface.
 */
export function instanceOfListOffersBehavioursResponse(value: object): value is ListOffersBehavioursResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListOffersBehavioursResponseFromJSON(json: any): ListOffersBehavioursResponse {
    return ListOffersBehavioursResponseFromJSONTyped(json, false);
}

export function ListOffersBehavioursResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOffersBehavioursResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(OffersBehaviourFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListOffersBehavioursResponseToJSON(json: any): ListOffersBehavioursResponse {
      return ListOffersBehavioursResponseToJSONTyped(json, false);
  }

  export function ListOffersBehavioursResponseToJSONTyped(value?: ListOffersBehavioursResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(OffersBehaviourToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

