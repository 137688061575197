/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveRequest } from './LeaveRequest';
import {
    LeaveRequestFromJSON,
    LeaveRequestFromJSONTyped,
    LeaveRequestToJSON,
    LeaveRequestToJSONTyped,
} from './LeaveRequest';

/**
 * 
 * @export
 * @interface CancelLeaveRequestResponse
 */
export interface CancelLeaveRequestResponse {
    /**
     * 
     * @type {LeaveRequest}
     * @memberof CancelLeaveRequestResponse
     */
    data: LeaveRequest;
}

/**
 * Check if a given object implements the CancelLeaveRequestResponse interface.
 */
export function instanceOfCancelLeaveRequestResponse(value: object): value is CancelLeaveRequestResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CancelLeaveRequestResponseFromJSON(json: any): CancelLeaveRequestResponse {
    return CancelLeaveRequestResponseFromJSONTyped(json, false);
}

export function CancelLeaveRequestResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CancelLeaveRequestResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeaveRequestFromJSON(json['data']),
    };
}

  export function CancelLeaveRequestResponseToJSON(json: any): CancelLeaveRequestResponse {
      return CancelLeaveRequestResponseToJSONTyped(json, false);
  }

  export function CancelLeaveRequestResponseToJSONTyped(value?: CancelLeaveRequestResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': LeaveRequestToJSON(value['data']),
    };
}

