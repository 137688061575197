/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ScheduleSettings
 */
export interface ScheduleSettings {
    /**
     * 
     * @type {number}
     * @memberof ScheduleSettings
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleSettings
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduleSettings
     */
    defaultBreakLength: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    enableBreaksWhileClockedIn: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    deductAllocatedBreaksFromWorkingTime: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSettings
     */
    clockInUnscheduledShifts: ScheduleSettingsClockInUnscheduledShiftsEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    employeeSwitchAndRejectShifts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    employeesMustAcknowledgeShifts: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    employeeShiftChangeNeedsApproval: boolean;
    /**
     * Allows employees to view all a companies shifts.
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    employeesCanViewAllShifts: boolean;
    /**
     * Prevents employees from viewing shifts from different locations they aren't assigned to.
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    employeesCanOnlyViewShiftsForTheirLocations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    employeesCanClaimOpenShiftsOnNonWorkingDays: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    employeesCanViewShiftTags: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    shiftSwappingEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    shiftSwapsRequireApproval: boolean;
    /**
     * 
     * @type {number}
     * @memberof ScheduleSettings
     */
    swapCutOffHoursBeforeShift: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduleSettings
     */
    enableOpenShifts: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSettings
     */
    paidBreakName: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduleSettings
     */
    unpaidBreakName: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleSettings
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ScheduleSettings
     */
    updatedAt: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum ScheduleSettingsClockInUnscheduledShiftsEnum {
    Always = 'Always',
    IfNoShiftsToday = 'If No Shifts Today',
    Never = 'Never'
}


/**
 * Check if a given object implements the ScheduleSettings interface.
 */
export function instanceOfScheduleSettings(value: object): value is ScheduleSettings {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('defaultBreakLength' in value) || value['defaultBreakLength'] === undefined) return false;
    if (!('enableBreaksWhileClockedIn' in value) || value['enableBreaksWhileClockedIn'] === undefined) return false;
    if (!('deductAllocatedBreaksFromWorkingTime' in value) || value['deductAllocatedBreaksFromWorkingTime'] === undefined) return false;
    if (!('clockInUnscheduledShifts' in value) || value['clockInUnscheduledShifts'] === undefined) return false;
    if (!('employeeSwitchAndRejectShifts' in value) || value['employeeSwitchAndRejectShifts'] === undefined) return false;
    if (!('employeesMustAcknowledgeShifts' in value) || value['employeesMustAcknowledgeShifts'] === undefined) return false;
    if (!('employeeShiftChangeNeedsApproval' in value) || value['employeeShiftChangeNeedsApproval'] === undefined) return false;
    if (!('employeesCanViewAllShifts' in value) || value['employeesCanViewAllShifts'] === undefined) return false;
    if (!('employeesCanOnlyViewShiftsForTheirLocations' in value) || value['employeesCanOnlyViewShiftsForTheirLocations'] === undefined) return false;
    if (!('employeesCanClaimOpenShiftsOnNonWorkingDays' in value) || value['employeesCanClaimOpenShiftsOnNonWorkingDays'] === undefined) return false;
    if (!('employeesCanViewShiftTags' in value) || value['employeesCanViewShiftTags'] === undefined) return false;
    if (!('shiftSwappingEnabled' in value) || value['shiftSwappingEnabled'] === undefined) return false;
    if (!('shiftSwapsRequireApproval' in value) || value['shiftSwapsRequireApproval'] === undefined) return false;
    if (!('swapCutOffHoursBeforeShift' in value) || value['swapCutOffHoursBeforeShift'] === undefined) return false;
    if (!('enableOpenShifts' in value) || value['enableOpenShifts'] === undefined) return false;
    if (!('paidBreakName' in value) || value['paidBreakName'] === undefined) return false;
    if (!('unpaidBreakName' in value) || value['unpaidBreakName'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function ScheduleSettingsFromJSON(json: any): ScheduleSettings {
    return ScheduleSettingsFromJSONTyped(json, false);
}

export function ScheduleSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'defaultBreakLength': json['defaultBreakLength'],
        'enableBreaksWhileClockedIn': json['enableBreaksWhileClockedIn'],
        'deductAllocatedBreaksFromWorkingTime': json['deductAllocatedBreaksFromWorkingTime'],
        'clockInUnscheduledShifts': json['clockInUnscheduledShifts'],
        'employeeSwitchAndRejectShifts': json['employeeSwitchAndRejectShifts'],
        'employeesMustAcknowledgeShifts': json['employeesMustAcknowledgeShifts'],
        'employeeShiftChangeNeedsApproval': json['employeeShiftChangeNeedsApproval'],
        'employeesCanViewAllShifts': json['employeesCanViewAllShifts'],
        'employeesCanOnlyViewShiftsForTheirLocations': json['employeesCanOnlyViewShiftsForTheirLocations'],
        'employeesCanClaimOpenShiftsOnNonWorkingDays': json['employeesCanClaimOpenShiftsOnNonWorkingDays'],
        'employeesCanViewShiftTags': json['employeesCanViewShiftTags'],
        'shiftSwappingEnabled': json['shiftSwappingEnabled'],
        'shiftSwapsRequireApproval': json['shiftSwapsRequireApproval'],
        'swapCutOffHoursBeforeShift': json['swapCutOffHoursBeforeShift'],
        'enableOpenShifts': json['enableOpenShifts'],
        'paidBreakName': json['paidBreakName'],
        'unpaidBreakName': json['unpaidBreakName'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

  export function ScheduleSettingsToJSON(json: any): ScheduleSettings {
      return ScheduleSettingsToJSONTyped(json, false);
  }

  export function ScheduleSettingsToJSONTyped(value?: ScheduleSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'defaultBreakLength': value['defaultBreakLength'],
        'enableBreaksWhileClockedIn': value['enableBreaksWhileClockedIn'],
        'deductAllocatedBreaksFromWorkingTime': value['deductAllocatedBreaksFromWorkingTime'],
        'clockInUnscheduledShifts': value['clockInUnscheduledShifts'],
        'employeeSwitchAndRejectShifts': value['employeeSwitchAndRejectShifts'],
        'employeesMustAcknowledgeShifts': value['employeesMustAcknowledgeShifts'],
        'employeeShiftChangeNeedsApproval': value['employeeShiftChangeNeedsApproval'],
        'employeesCanViewAllShifts': value['employeesCanViewAllShifts'],
        'employeesCanOnlyViewShiftsForTheirLocations': value['employeesCanOnlyViewShiftsForTheirLocations'],
        'employeesCanClaimOpenShiftsOnNonWorkingDays': value['employeesCanClaimOpenShiftsOnNonWorkingDays'],
        'employeesCanViewShiftTags': value['employeesCanViewShiftTags'],
        'shiftSwappingEnabled': value['shiftSwappingEnabled'],
        'shiftSwapsRequireApproval': value['shiftSwapsRequireApproval'],
        'swapCutOffHoursBeforeShift': value['swapCutOffHoursBeforeShift'],
        'enableOpenShifts': value['enableOpenShifts'],
        'paidBreakName': value['paidBreakName'],
        'unpaidBreakName': value['unpaidBreakName'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

