/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TrekksoftClientCredential } from './TrekksoftClientCredential';
import {
    TrekksoftClientCredentialFromJSON,
    TrekksoftClientCredentialFromJSONTyped,
    TrekksoftClientCredentialToJSON,
    TrekksoftClientCredentialToJSONTyped,
} from './TrekksoftClientCredential';

/**
 * 
 * @export
 * @interface ShowTrekksoftClientCredentialsResponse
 */
export interface ShowTrekksoftClientCredentialsResponse {
    /**
     * 
     * @type {TrekksoftClientCredential}
     * @memberof ShowTrekksoftClientCredentialsResponse
     */
    data: TrekksoftClientCredential;
}

/**
 * Check if a given object implements the ShowTrekksoftClientCredentialsResponse interface.
 */
export function instanceOfShowTrekksoftClientCredentialsResponse(value: object): value is ShowTrekksoftClientCredentialsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowTrekksoftClientCredentialsResponseFromJSON(json: any): ShowTrekksoftClientCredentialsResponse {
    return ShowTrekksoftClientCredentialsResponseFromJSONTyped(json, false);
}

export function ShowTrekksoftClientCredentialsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowTrekksoftClientCredentialsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': TrekksoftClientCredentialFromJSON(json['data']),
    };
}

  export function ShowTrekksoftClientCredentialsResponseToJSON(json: any): ShowTrekksoftClientCredentialsResponse {
      return ShowTrekksoftClientCredentialsResponseToJSONTyped(json, false);
  }

  export function ShowTrekksoftClientCredentialsResponseToJSONTyped(value?: ShowTrekksoftClientCredentialsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': TrekksoftClientCredentialToJSON(value['data']),
    };
}

