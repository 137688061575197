/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SentEmail
 */
export interface SentEmail {
    /**
     * 
     * @type {string}
     * @memberof SentEmail
     */
    status: SentEmailStatusEnum;
    /**
     * The recipient's email address.
     * @type {string}
     * @memberof SentEmail
     */
    recipient: string;
}

/**
* @export
* @enum {string}
*/
export enum SentEmailStatusEnum {
    Bounced = 'Bounced',
    Complained = 'Complained',
    Delivered = 'Delivered',
    Rejected = 'Rejected',
    Sent = 'Sent'
}


/**
 * Check if a given object implements the SentEmail interface.
 */
export function instanceOfSentEmail(value: object): value is SentEmail {
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('recipient' in value) || value['recipient'] === undefined) return false;
    return true;
}

export function SentEmailFromJSON(json: any): SentEmail {
    return SentEmailFromJSONTyped(json, false);
}

export function SentEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): SentEmail {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'],
        'recipient': json['recipient'],
    };
}

  export function SentEmailToJSON(json: any): SentEmail {
      return SentEmailToJSONTyped(json, false);
  }

  export function SentEmailToJSONTyped(value?: SentEmail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'status': value['status'],
        'recipient': value['recipient'],
    };
}

