/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeClockingProfile } from './EmployeeClockingProfile';
import {
    EmployeeClockingProfileFromJSON,
    EmployeeClockingProfileFromJSONTyped,
    EmployeeClockingProfileToJSON,
    EmployeeClockingProfileToJSONTyped,
} from './EmployeeClockingProfile';

/**
 * 
 * @export
 * @interface ShowEmployeeClockingProfileResponse
 */
export interface ShowEmployeeClockingProfileResponse {
    /**
     * 
     * @type {EmployeeClockingProfile}
     * @memberof ShowEmployeeClockingProfileResponse
     */
    data: EmployeeClockingProfile;
}

/**
 * Check if a given object implements the ShowEmployeeClockingProfileResponse interface.
 */
export function instanceOfShowEmployeeClockingProfileResponse(value: object): value is ShowEmployeeClockingProfileResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowEmployeeClockingProfileResponseFromJSON(json: any): ShowEmployeeClockingProfileResponse {
    return ShowEmployeeClockingProfileResponseFromJSONTyped(json, false);
}

export function ShowEmployeeClockingProfileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowEmployeeClockingProfileResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeClockingProfileFromJSON(json['data']),
    };
}

  export function ShowEmployeeClockingProfileResponseToJSON(json: any): ShowEmployeeClockingProfileResponse {
      return ShowEmployeeClockingProfileResponseToJSONTyped(json, false);
  }

  export function ShowEmployeeClockingProfileResponseToJSONTyped(value?: ShowEmployeeClockingProfileResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': EmployeeClockingProfileToJSON(value['data']),
    };
}

