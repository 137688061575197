/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkPattern } from './WorkPattern';
import {
    WorkPatternFromJSON,
    WorkPatternFromJSONTyped,
    WorkPatternToJSON,
    WorkPatternToJSONTyped,
} from './WorkPattern';

/**
 * 
 * @export
 * @interface CreateWorkPatternResponse
 */
export interface CreateWorkPatternResponse {
    /**
     * 
     * @type {WorkPattern}
     * @memberof CreateWorkPatternResponse
     */
    data: WorkPattern;
}

/**
 * Check if a given object implements the CreateWorkPatternResponse interface.
 */
export function instanceOfCreateWorkPatternResponse(value: object): value is CreateWorkPatternResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateWorkPatternResponseFromJSON(json: any): CreateWorkPatternResponse {
    return CreateWorkPatternResponseFromJSONTyped(json, false);
}

export function CreateWorkPatternResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWorkPatternResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': WorkPatternFromJSON(json['data']),
    };
}

  export function CreateWorkPatternResponseToJSON(json: any): CreateWorkPatternResponse {
      return CreateWorkPatternResponseToJSONTyped(json, false);
  }

  export function CreateWorkPatternResponseToJSONTyped(value?: CreateWorkPatternResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': WorkPatternToJSON(value['data']),
    };
}

