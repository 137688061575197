/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a billing subscription quota
 * @export
 * @interface BillingSubscriptionQuota
 */
export interface BillingSubscriptionQuota {
    /**
     * 
     * @type {number}
     * @memberof BillingSubscriptionQuota
     */
    id: number;
    /**
     * The number of employees included in the price of the plan.
     * @type {number}
     * @memberof BillingSubscriptionQuota
     */
    employeeSoftLimit: number;
    /**
     * The maximum number of employees allowed on the plan.
     * @type {number}
     * @memberof BillingSubscriptionQuota
     */
    employeeHardLimit: number;
    /**
     * The maximum number of messages an employee can send.
     * @type {number}
     * @memberof BillingSubscriptionQuota
     */
    dailyEmployeeMessageLimit: number;
    /**
     * The maximum number of bytes an employee can download in a single file.
     * @type {number}
     * @memberof BillingSubscriptionQuota
     */
    maxSingleFileBytes: number;
    /**
     * The maximum number of bytes an employee can download in total.
     * @type {number}
     * @memberof BillingSubscriptionQuota
     */
    maxTotalFileBytes: number;
}

/**
 * Check if a given object implements the BillingSubscriptionQuota interface.
 */
export function instanceOfBillingSubscriptionQuota(value: object): value is BillingSubscriptionQuota {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('employeeSoftLimit' in value) || value['employeeSoftLimit'] === undefined) return false;
    if (!('employeeHardLimit' in value) || value['employeeHardLimit'] === undefined) return false;
    if (!('dailyEmployeeMessageLimit' in value) || value['dailyEmployeeMessageLimit'] === undefined) return false;
    if (!('maxSingleFileBytes' in value) || value['maxSingleFileBytes'] === undefined) return false;
    if (!('maxTotalFileBytes' in value) || value['maxTotalFileBytes'] === undefined) return false;
    return true;
}

export function BillingSubscriptionQuotaFromJSON(json: any): BillingSubscriptionQuota {
    return BillingSubscriptionQuotaFromJSONTyped(json, false);
}

export function BillingSubscriptionQuotaFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingSubscriptionQuota {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'employeeSoftLimit': json['employeeSoftLimit'],
        'employeeHardLimit': json['employeeHardLimit'],
        'dailyEmployeeMessageLimit': json['dailyEmployeeMessageLimit'],
        'maxSingleFileBytes': json['maxSingleFileBytes'],
        'maxTotalFileBytes': json['maxTotalFileBytes'],
    };
}

  export function BillingSubscriptionQuotaToJSON(json: any): BillingSubscriptionQuota {
      return BillingSubscriptionQuotaToJSONTyped(json, false);
  }

  export function BillingSubscriptionQuotaToJSONTyped(value?: BillingSubscriptionQuota | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'employeeSoftLimit': value['employeeSoftLimit'],
        'employeeHardLimit': value['employeeHardLimit'],
        'dailyEmployeeMessageLimit': value['dailyEmployeeMessageLimit'],
        'maxSingleFileBytes': value['maxSingleFileBytes'],
        'maxTotalFileBytes': value['maxTotalFileBytes'],
    };
}

