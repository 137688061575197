/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateShiftTemplateAreaSessionData } from './CreateShiftTemplateAreaSessionData';
import {
    CreateShiftTemplateAreaSessionDataFromJSON,
    CreateShiftTemplateAreaSessionDataFromJSONTyped,
    CreateShiftTemplateAreaSessionDataToJSON,
    CreateShiftTemplateAreaSessionDataToJSONTyped,
} from './CreateShiftTemplateAreaSessionData';
import type { CreateShiftTemplateBreakData } from './CreateShiftTemplateBreakData';
import {
    CreateShiftTemplateBreakDataFromJSON,
    CreateShiftTemplateBreakDataFromJSONTyped,
    CreateShiftTemplateBreakDataToJSON,
    CreateShiftTemplateBreakDataToJSONTyped,
} from './CreateShiftTemplateBreakData';

/**
 * 
 * @export
 * @interface CreateShiftTemplateData
 */
export interface CreateShiftTemplateData {
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTemplateData
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    locationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    jobRoleId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    startHour: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    startMinute: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    endHour: number;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateData
     */
    endMinute: number;
    /**
     * Whether to show the shift end time to non-managerial staff
     * @type {boolean}
     * @memberof CreateShiftTemplateData
     */
    showEndTime?: boolean;
    /**
     * Whether to show the job role to non-managerial staff
     * @type {boolean}
     * @memberof CreateShiftTemplateData
     */
    showJobRole?: boolean;
    /**
     * 
     * @type {Array<CreateShiftTemplateBreakData>}
     * @memberof CreateShiftTemplateData
     */
    shiftTemplateBreaks?: Array<CreateShiftTemplateBreakData>;
    /**
     * 
     * @type {Array<CreateShiftTemplateAreaSessionData>}
     * @memberof CreateShiftTemplateData
     */
    shiftTemplateAreaSessions?: Array<CreateShiftTemplateAreaSessionData>;
    /**
     * 
     * @type {string}
     * @memberof CreateShiftTemplateData
     */
    notes?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateShiftTemplateData
     */
    tags?: Array<number> | null;
}

/**
 * Check if a given object implements the CreateShiftTemplateData interface.
 */
export function instanceOfCreateShiftTemplateData(value: object): value is CreateShiftTemplateData {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('startHour' in value) || value['startHour'] === undefined) return false;
    if (!('startMinute' in value) || value['startMinute'] === undefined) return false;
    if (!('endHour' in value) || value['endHour'] === undefined) return false;
    if (!('endMinute' in value) || value['endMinute'] === undefined) return false;
    return true;
}

export function CreateShiftTemplateDataFromJSON(json: any): CreateShiftTemplateData {
    return CreateShiftTemplateDataFromJSONTyped(json, false);
}

export function CreateShiftTemplateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftTemplateData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
        'jobRoleId': json['jobRoleId'] == null ? undefined : json['jobRoleId'],
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'endHour': json['endHour'],
        'endMinute': json['endMinute'],
        'showEndTime': json['showEndTime'] == null ? undefined : json['showEndTime'],
        'showJobRole': json['showJobRole'] == null ? undefined : json['showJobRole'],
        'shiftTemplateBreaks': json['shiftTemplateBreaks'] == null ? undefined : ((json['shiftTemplateBreaks'] as Array<any>).map(CreateShiftTemplateBreakDataFromJSON)),
        'shiftTemplateAreaSessions': json['shiftTemplateAreaSessions'] == null ? undefined : ((json['shiftTemplateAreaSessions'] as Array<any>).map(CreateShiftTemplateAreaSessionDataFromJSON)),
        'notes': json['notes'] == null ? undefined : json['notes'],
        'tags': json['tags'] == null ? undefined : json['tags'],
    };
}

  export function CreateShiftTemplateDataToJSON(json: any): CreateShiftTemplateData {
      return CreateShiftTemplateDataToJSONTyped(json, false);
  }

  export function CreateShiftTemplateDataToJSONTyped(value?: CreateShiftTemplateData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'locationId': value['locationId'],
        'jobRoleId': value['jobRoleId'],
        'startHour': value['startHour'],
        'startMinute': value['startMinute'],
        'endHour': value['endHour'],
        'endMinute': value['endMinute'],
        'showEndTime': value['showEndTime'],
        'showJobRole': value['showJobRole'],
        'shiftTemplateBreaks': value['shiftTemplateBreaks'] == null ? undefined : ((value['shiftTemplateBreaks'] as Array<any>).map(CreateShiftTemplateBreakDataToJSON)),
        'shiftTemplateAreaSessions': value['shiftTemplateAreaSessions'] == null ? undefined : ((value['shiftTemplateAreaSessions'] as Array<any>).map(CreateShiftTemplateAreaSessionDataToJSON)),
        'notes': value['notes'],
        'tags': value['tags'],
    };
}

