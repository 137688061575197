/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface TrekksoftVisitorSummary
 */
export interface TrekksoftVisitorSummary {
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof TrekksoftVisitorSummary
     */
    date: ShiftiePlainDate;
    /**
     * 
     * @type {number}
     * @memberof TrekksoftVisitorSummary
     */
    count: number;
}

/**
 * Check if a given object implements the TrekksoftVisitorSummary interface.
 */
export function instanceOfTrekksoftVisitorSummary(value: object): value is TrekksoftVisitorSummary {
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    return true;
}

export function TrekksoftVisitorSummaryFromJSON(json: any): TrekksoftVisitorSummary {
    return TrekksoftVisitorSummaryFromJSONTyped(json, false);
}

export function TrekksoftVisitorSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrekksoftVisitorSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'date': ShiftiePlainDateFromJSON(json['date']),
        'count': json['count'],
    };
}

  export function TrekksoftVisitorSummaryToJSON(json: any): TrekksoftVisitorSummary {
      return TrekksoftVisitorSummaryToJSONTyped(json, false);
  }

  export function TrekksoftVisitorSummaryToJSONTyped(value?: TrekksoftVisitorSummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'date': ShiftiePlainDateToJSON(value['date']),
        'count': value['count'],
    };
}

