/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Unavailability } from './Unavailability';
import {
    UnavailabilityFromJSON,
    UnavailabilityFromJSONTyped,
    UnavailabilityToJSON,
    UnavailabilityToJSONTyped,
} from './Unavailability';

/**
 * 
 * @export
 * @interface ShowUnavailabilityResponse
 */
export interface ShowUnavailabilityResponse {
    /**
     * 
     * @type {Unavailability}
     * @memberof ShowUnavailabilityResponse
     */
    data: Unavailability;
}

/**
 * Check if a given object implements the ShowUnavailabilityResponse interface.
 */
export function instanceOfShowUnavailabilityResponse(value: object): value is ShowUnavailabilityResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowUnavailabilityResponseFromJSON(json: any): ShowUnavailabilityResponse {
    return ShowUnavailabilityResponseFromJSONTyped(json, false);
}

export function ShowUnavailabilityResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowUnavailabilityResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': UnavailabilityFromJSON(json['data']),
    };
}

  export function ShowUnavailabilityResponseToJSON(json: any): ShowUnavailabilityResponse {
      return ShowUnavailabilityResponseToJSONTyped(json, false);
  }

  export function ShowUnavailabilityResponseToJSONTyped(value?: ShowUnavailabilityResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': UnavailabilityToJSON(value['data']),
    };
}

