import { PlainDate } from '@/lib/date-time/PlainDate';
import spacetime from 'spacetime';
import { CompanySettingStartOfWeekEnum } from '../../api/v1';

export const addMinutes = (
  date: Date,
  minutesToAdd: number,
  timezone: string,
): Date => spacetime(date, timezone).add(minutesToAdd, 'minute').toNativeDate();

export const addHours = (
  date: Date,
  hoursToAdd: number,
  timezone: string,
): Date => spacetime(date, timezone).add(hoursToAdd, 'hour').toNativeDate();

export const addDays = (
  date: Date,
  daysToAdd: number,
  timezone: string,
): Date => spacetime(date, timezone).add(daysToAdd, 'day').toNativeDate();

export const subtractHours = (
  date: Date,
  hoursToSubtract: number,
  timezone: string,
): Date =>
  spacetime(date, timezone).subtract(hoursToSubtract, 'hour').toNativeDate();

export const subtractDays = (
  date: Date,
  daysToSubtract: number,
  timezone: string,
): Date =>
  spacetime(date, timezone).subtract(daysToSubtract, 'day').toNativeDate();

// accepts Date and string in the Date format (examples listed below)
// ISO Date "2015-03-25"; Short Date "03/25/2015" or "2015/03/25"; Long Date "Mar 25 2015"
// Full Date "Wednesday March 25 2015"; Epoch Time 1493042538
export const startOfDay = (date: Date | string, timezone: string): Date =>
  spacetime(date, timezone).startOf('day').toNativeDate();

// accepts Date and string in the Date format (examples listed below)
// ISO Date "2015-03-25"; Short Date "03/25/2015" or "2015/03/25"; Long Date "Mar 25 2015"
// Full Date "Wednesday March 25 2015"; Epoch Time 1493042538
export const endOfDay = (date: Date | string, timezone: string): Date =>
  spacetime(date, timezone).endOf('day').toNativeDate();

export const startOfNextDay = (date: Date | string, timezone: string): Date =>
  spacetime(date, timezone).add(1, 'day').startOf('day').toNativeDate();

export const startOfWeek = (
  date: Date,
  weekStart: CompanySettingStartOfWeekEnum,
  timezone: string,
): Date =>
  spacetime(date, timezone)
    .weekStart(weekStart)
    .startOf('week')
    .startOf('day')
    .toNativeDate();

export const startOfNextWeek = (
  date: Date,
  weekStart: CompanySettingStartOfWeekEnum,
  timezone: string,
): Date =>
  spacetime(date, timezone)
    .weekStart(weekStart)
    .add(1, 'week')
    .startOf('week')
    .startOf('day')
    .toNativeDate();

export const isToday = (date: Date | PlainDate, timezone: string): boolean => {
  const today = spacetime.now(timezone).format('iso-short');
  const calendarDate =
    date instanceof PlainDate
      ? date.toString()
      : spacetime(date, timezone).format('iso-short');
  return today === calendarDate;
};

export const isTodayOrBefore = (date: Date, timezone: string): boolean =>
  date <= spacetime.now(timezone).endOf('day').toNativeDate();

export const isTodayOrAfter = (date: Date, timezone: string): boolean =>
  date >= spacetime.now(timezone).startOf('day').toNativeDate();

/** Determines whether a UTC string represents midnight for a given timezone */
export const isMidnight = (timezone: string, utcString: string): boolean => {
  const pointInTime = spacetime(utcString, 'utc').goto(timezone);
  return pointInTime.isEqual(pointInTime.startOf('day'));
};

export const isPast = (date: Date) => date < new Date();

// Now is between start and end
export const isPresent = (startDate: Date, endDate: Date) =>
  startDate < new Date() && new Date() < endDate;

export const isFuture = (date: Date) => date > new Date();

export const isBeforeToday = (date: Date, timezone: string) =>
  date < spacetime.now(timezone).startOf('day').toNativeDate();

export const latestDate = <T extends Date | PlainDate>(dates: T[]): T =>
  dates.reduce((prev, curr) => (curr > prev ? curr : prev));

export const earliestDate = (dates: Date[]): Date =>
  dates.reduce((prev, curr) => (curr < prev ? curr : prev));

/**
 * Determines whether an event covers "all day" ie. midnight -> midnight.
 * This may still be true for multi-day events, as long as the start and end time are both midnight.
 */
export const isAllDayEvent = (
  timezone: string,
  utcStart: string,
  utcEnd: string,
): boolean => {
  const start = spacetime(utcStart, 'utc').goto(timezone);
  const end = spacetime(utcEnd, 'utc').goto(timezone);
  const startIsMidnight = isMidnight(timezone, utcStart);
  const endIsMidnight = isMidnight(timezone, utcEnd);
  const atLeastOneDayLong = start.diff(end).days >= 1;

  return startIsMidnight && endIsMidnight && atLeastOneDayLong;
};
/**
 * Determine whether a date period covers more than one calendar day for a given timezone
 * - i.e. whether this rolls over a midnight boundary.
 */
export const periodCoversMoreThanOneCalendarDay = (
  timezone: string,
  start: Date,
  end: Date,
): boolean =>
  spacetime(end, timezone).isAfter(spacetime(start, timezone).add(1, 'day'));

/** Get a ceiled number of minutes between now and the provided date */
export const minutesBetweenDates = (
  comparator: Date,
  target: Date,
  timezone: string,
): number => {
  const spaceComparator = spacetime(comparator, timezone);
  const spaceTarget = spacetime(target, timezone);
  return Math.ceil(spaceTarget.diff(spaceComparator, 'minute'));
};

export const getTimezoneDateFromUTC = (date: string, timezone: string) =>
  spacetime(date, 'utc').goto(timezone).toNativeDate();
