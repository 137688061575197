/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOAuthClientData
 */
export interface CreateOAuthClientData {
    /**
     * 
     * @type {string}
     * @memberof CreateOAuthClientData
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateOAuthClientData
     */
    scopes: Array<string>;
}

/**
 * Check if a given object implements the CreateOAuthClientData interface.
 */
export function instanceOfCreateOAuthClientData(value: object): value is CreateOAuthClientData {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('scopes' in value) || value['scopes'] === undefined) return false;
    return true;
}

export function CreateOAuthClientDataFromJSON(json: any): CreateOAuthClientData {
    return CreateOAuthClientDataFromJSONTyped(json, false);
}

export function CreateOAuthClientDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateOAuthClientData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'scopes': json['scopes'],
    };
}

  export function CreateOAuthClientDataToJSON(json: any): CreateOAuthClientData {
      return CreateOAuthClientDataToJSONTyped(json, false);
  }

  export function CreateOAuthClientDataToJSONTyped(value?: CreateOAuthClientData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'scopes': value['scopes'],
    };
}

