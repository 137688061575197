/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { ReportCostsByEmployee } from './ReportCostsByEmployee';
import {
    ReportCostsByEmployeeFromJSON,
    ReportCostsByEmployeeFromJSONTyped,
    ReportCostsByEmployeeToJSON,
    ReportCostsByEmployeeToJSONTyped,
} from './ReportCostsByEmployee';
import type { ReportCostsByEmployeeRollup } from './ReportCostsByEmployeeRollup';
import {
    ReportCostsByEmployeeRollupFromJSON,
    ReportCostsByEmployeeRollupFromJSONTyped,
    ReportCostsByEmployeeRollupToJSON,
    ReportCostsByEmployeeRollupToJSONTyped,
} from './ReportCostsByEmployeeRollup';

/**
 * 
 * @export
 * @interface ListShiftCostReportsByEmployeeResponse
 */
export interface ListShiftCostReportsByEmployeeResponse {
    /**
     * 
     * @type {Array<ReportCostsByEmployee>}
     * @memberof ListShiftCostReportsByEmployeeResponse
     */
    data: Array<ReportCostsByEmployee>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListShiftCostReportsByEmployeeResponse
     */
    pagination: Pagination;
    /**
     * 
     * @type {ReportCostsByEmployeeRollup}
     * @memberof ListShiftCostReportsByEmployeeResponse
     */
    rollup: ReportCostsByEmployeeRollup;
}

/**
 * Check if a given object implements the ListShiftCostReportsByEmployeeResponse interface.
 */
export function instanceOfListShiftCostReportsByEmployeeResponse(value: object): value is ListShiftCostReportsByEmployeeResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    if (!('rollup' in value) || value['rollup'] === undefined) return false;
    return true;
}

export function ListShiftCostReportsByEmployeeResponseFromJSON(json: any): ListShiftCostReportsByEmployeeResponse {
    return ListShiftCostReportsByEmployeeResponseFromJSONTyped(json, false);
}

export function ListShiftCostReportsByEmployeeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListShiftCostReportsByEmployeeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ReportCostsByEmployeeFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
        'rollup': ReportCostsByEmployeeRollupFromJSON(json['rollup']),
    };
}

  export function ListShiftCostReportsByEmployeeResponseToJSON(json: any): ListShiftCostReportsByEmployeeResponse {
      return ListShiftCostReportsByEmployeeResponseToJSONTyped(json, false);
  }

  export function ListShiftCostReportsByEmployeeResponseToJSONTyped(value?: ListShiftCostReportsByEmployeeResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ReportCostsByEmployeeToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
        'rollup': ReportCostsByEmployeeRollupToJSON(value['rollup']),
    };
}

