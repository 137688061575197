/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeWeeklyShiftsTotal
 */
export interface EmployeeWeeklyShiftsTotal {
    /**
     * 
     * @type {number}
     * @memberof EmployeeWeeklyShiftsTotal
     */
    cost: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeWeeklyShiftsTotal
     */
    mins: number;
}

/**
 * Check if a given object implements the EmployeeWeeklyShiftsTotal interface.
 */
export function instanceOfEmployeeWeeklyShiftsTotal(value: object): value is EmployeeWeeklyShiftsTotal {
    if (!('cost' in value) || value['cost'] === undefined) return false;
    if (!('mins' in value) || value['mins'] === undefined) return false;
    return true;
}

export function EmployeeWeeklyShiftsTotalFromJSON(json: any): EmployeeWeeklyShiftsTotal {
    return EmployeeWeeklyShiftsTotalFromJSONTyped(json, false);
}

export function EmployeeWeeklyShiftsTotalFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeWeeklyShiftsTotal {
    if (json == null) {
        return json;
    }
    return {
        
        'cost': json['cost'],
        'mins': json['mins'],
    };
}

  export function EmployeeWeeklyShiftsTotalToJSON(json: any): EmployeeWeeklyShiftsTotal {
      return EmployeeWeeklyShiftsTotalToJSONTyped(json, false);
  }

  export function EmployeeWeeklyShiftsTotalToJSONTyped(value?: EmployeeWeeklyShiftsTotal | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cost': value['cost'],
        'mins': value['mins'],
    };
}

