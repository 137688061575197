/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateScheduleData
 */
export interface UpdateScheduleData {
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleData
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateScheduleData
     */
    defaultLocationId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateScheduleData
     */
    status?: UpdateScheduleDataStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum UpdateScheduleDataStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the UpdateScheduleData interface.
 */
export function instanceOfUpdateScheduleData(value: object): value is UpdateScheduleData {
    return true;
}

export function UpdateScheduleDataFromJSON(json: any): UpdateScheduleData {
    return UpdateScheduleDataFromJSONTyped(json, false);
}

export function UpdateScheduleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateScheduleData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'defaultLocationId': json['defaultLocationId'] == null ? undefined : json['defaultLocationId'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

  export function UpdateScheduleDataToJSON(json: any): UpdateScheduleData {
      return UpdateScheduleDataToJSONTyped(json, false);
  }

  export function UpdateScheduleDataToJSONTyped(value?: UpdateScheduleData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'defaultLocationId': value['defaultLocationId'],
        'status': value['status'],
    };
}

