/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftSwap } from './ShiftSwap';
import {
    ShiftSwapFromJSON,
    ShiftSwapFromJSONTyped,
    ShiftSwapToJSON,
    ShiftSwapToJSONTyped,
} from './ShiftSwap';

/**
 * 
 * @export
 * @interface AcceptShiftSwapResponse
 */
export interface AcceptShiftSwapResponse {
    /**
     * 
     * @type {ShiftSwap}
     * @memberof AcceptShiftSwapResponse
     */
    data: ShiftSwap;
}

/**
 * Check if a given object implements the AcceptShiftSwapResponse interface.
 */
export function instanceOfAcceptShiftSwapResponse(value: object): value is AcceptShiftSwapResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function AcceptShiftSwapResponseFromJSON(json: any): AcceptShiftSwapResponse {
    return AcceptShiftSwapResponseFromJSONTyped(json, false);
}

export function AcceptShiftSwapResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcceptShiftSwapResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ShiftSwapFromJSON(json['data']),
    };
}

  export function AcceptShiftSwapResponseToJSON(json: any): AcceptShiftSwapResponse {
      return AcceptShiftSwapResponseToJSONTyped(json, false);
  }

  export function AcceptShiftSwapResponseToJSONTyped(value?: AcceptShiftSwapResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ShiftSwapToJSON(value['data']),
    };
}

