/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { HolidayRegion } from './HolidayRegion';
import {
    HolidayRegionFromJSON,
    HolidayRegionFromJSONTyped,
    HolidayRegionToJSON,
    HolidayRegionToJSONTyped,
} from './HolidayRegion';

/**
 * 
 * @export
 * @interface ListHolidayRegionsResponse
 */
export interface ListHolidayRegionsResponse {
    /**
     * 
     * @type {Array<HolidayRegion>}
     * @memberof ListHolidayRegionsResponse
     */
    data: Array<HolidayRegion>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListHolidayRegionsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListHolidayRegionsResponse interface.
 */
export function instanceOfListHolidayRegionsResponse(value: object): value is ListHolidayRegionsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListHolidayRegionsResponseFromJSON(json: any): ListHolidayRegionsResponse {
    return ListHolidayRegionsResponseFromJSONTyped(json, false);
}

export function ListHolidayRegionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListHolidayRegionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(HolidayRegionFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListHolidayRegionsResponseToJSON(json: any): ListHolidayRegionsResponse {
      return ListHolidayRegionsResponseToJSONTyped(json, false);
  }

  export function ListHolidayRegionsResponseToJSONTyped(value?: ListHolidayRegionsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(HolidayRegionToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

