/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanySimplified } from './CompanySimplified';
import {
    CompanySimplifiedFromJSON,
    CompanySimplifiedFromJSONTyped,
    CompanySimplifiedToJSON,
    CompanySimplifiedToJSONTyped,
} from './CompanySimplified';

/**
 * 
 * @export
 * @interface ShowReferrerDetailsResponse
 */
export interface ShowReferrerDetailsResponse {
    /**
     * 
     * @type {CompanySimplified}
     * @memberof ShowReferrerDetailsResponse
     */
    data: CompanySimplified;
}

/**
 * Check if a given object implements the ShowReferrerDetailsResponse interface.
 */
export function instanceOfShowReferrerDetailsResponse(value: object): value is ShowReferrerDetailsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowReferrerDetailsResponseFromJSON(json: any): ShowReferrerDetailsResponse {
    return ShowReferrerDetailsResponseFromJSONTyped(json, false);
}

export function ShowReferrerDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowReferrerDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CompanySimplifiedFromJSON(json['data']),
    };
}

  export function ShowReferrerDetailsResponseToJSON(json: any): ShowReferrerDetailsResponse {
      return ShowReferrerDetailsResponseToJSONTyped(json, false);
  }

  export function ShowReferrerDetailsResponseToJSONTyped(value?: ShowReferrerDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': CompanySimplifiedToJSON(value['data']),
    };
}

