
import Checkbox from '@/components/inputs/Checkbox.vue';
import { Icon } from '@/lib/enum/Icon';
import store from '@/store';
import { showFixedElement } from '@/util/displayFunctions';
import { viewport } from '@/util/windowFunctions';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

/**
 * This is the base auto complete component where the value is `required`. The component handles all
 * basic validations as a required value. The `value` property should match the `id` of the item if you
 * would like to prefill the component.
 *
 * The `items` property should mimick `{id: 1, name: 'Some string'}`. If we need to update this we can modify
 * the `item-text` and `item-value` properties to be computed.
 */
export default {
  name: 'AutoComplete',
  components: { Checkbox },

  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number, Object, Array],
      default: '',
    },

    items: {
      type: Array,
      required: true,
    },

    itemText: {
      type: String,
      default: 'name',
    },

    itemValue: {
      type: String,
      default: 'id',
    },

    itemColourIndicator: {
      type: String,
      default: '',
    },

    labelText: {
      type: [String, TranslateResult],
      default: '',
    },

    placeholder: {
      type: String,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    error: {
      type: Array,
      default: null,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    // Marks this input as sensitive so hides data when impersonating and forces readonly
    sensitive: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    customLayout: {
      type: Boolean,
      default: false,
    },

    noDataText: {
      type: String,
      default: 'No data found',
    },

    height: {
      type: [Number, String],
      default: 56,
    },

    searchValue: {
      type: [Number, String],
      default: null,
    },

    returnObject: {
      type: Boolean,
      default: false,
    },

    filterFunction: {
      type: Function,
      default: null,
    },

    fixedWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      Icon,
      innerSearchValue: null,
      inputWidth: null,
    };
  },

  computed: {
    isImpersonationSession: (): boolean => store.state.isImpersonationSession,
    isEnhancedImpersonationSession: (): boolean =>
      store.state.isEnhancedImpersonationSession,

    isSecured(): boolean {
      return (
        this.sensitive &&
        this.isImpersonationSession &&
        !this.isEnhancedImpersonationSession
      );
    },
  },

  watch: {
    searchValue(val) {
      this.innerSearchValue = val;
    },
    innerSearchValue(val) {
      this.$emit('search', val);
    },
  },

  mounted() {
    // for smaller devices, there is a special styling when a select menu being in the center of the screen
    if (this.fixedWidth && viewport.lg) {
      this.inputWidth = this.$refs.autoCompleteInputContainer?.clientWidth;
    }
  },

  methods: {
    handleInput(event) {
      this.$emit('input', event);
    },

    blur() {
      this.$emit('blur');
      this.innerSearchValue = '';
      showFixedElement('#fixed-button');
    },
  },
};
