/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BulkCreateWorkPatternOverrides } from './BulkCreateWorkPatternOverrides';
import {
    BulkCreateWorkPatternOverridesFromJSON,
    BulkCreateWorkPatternOverridesFromJSONTyped,
    BulkCreateWorkPatternOverridesToJSON,
    BulkCreateWorkPatternOverridesToJSONTyped,
} from './BulkCreateWorkPatternOverrides';

/**
 * Create Work Pattern Overrides for an employees.
 * @export
 * @interface BulkCreateWorkPatternOverrideData
 */
export interface BulkCreateWorkPatternOverrideData {
    /**
     * 
     * @type {Array<BulkCreateWorkPatternOverrides>}
     * @memberof BulkCreateWorkPatternOverrideData
     */
    workPatternOverrides: Array<BulkCreateWorkPatternOverrides>;
}

/**
 * Check if a given object implements the BulkCreateWorkPatternOverrideData interface.
 */
export function instanceOfBulkCreateWorkPatternOverrideData(value: object): value is BulkCreateWorkPatternOverrideData {
    if (!('workPatternOverrides' in value) || value['workPatternOverrides'] === undefined) return false;
    return true;
}

export function BulkCreateWorkPatternOverrideDataFromJSON(json: any): BulkCreateWorkPatternOverrideData {
    return BulkCreateWorkPatternOverrideDataFromJSONTyped(json, false);
}

export function BulkCreateWorkPatternOverrideDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateWorkPatternOverrideData {
    if (json == null) {
        return json;
    }
    return {
        
        'workPatternOverrides': ((json['workPatternOverrides'] as Array<any>).map(BulkCreateWorkPatternOverridesFromJSON)),
    };
}

  export function BulkCreateWorkPatternOverrideDataToJSON(json: any): BulkCreateWorkPatternOverrideData {
      return BulkCreateWorkPatternOverrideDataToJSONTyped(json, false);
  }

  export function BulkCreateWorkPatternOverrideDataToJSONTyped(value?: BulkCreateWorkPatternOverrideData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'workPatternOverrides': ((value['workPatternOverrides'] as Array<any>).map(BulkCreateWorkPatternOverridesToJSON)),
    };
}

