/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListTimezonesResponse
 */
export interface ListTimezonesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListTimezonesResponse
     */
    data: Array<string>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListTimezonesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListTimezonesResponse interface.
 */
export function instanceOfListTimezonesResponse(value: object): value is ListTimezonesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListTimezonesResponseFromJSON(json: any): ListTimezonesResponse {
    return ListTimezonesResponseFromJSONTyped(json, false);
}

export function ListTimezonesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTimezonesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListTimezonesResponseToJSON(json: any): ListTimezonesResponse {
      return ListTimezonesResponseToJSONTyped(json, false);
  }

  export function ListTimezonesResponseToJSONTyped(value?: ListTimezonesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': value['data'],
        'pagination': PaginationToJSON(value['pagination']),
    };
}

