/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeavePeriod } from './LeavePeriod';
import {
    LeavePeriodFromJSON,
    LeavePeriodFromJSONTyped,
    LeavePeriodToJSON,
    LeavePeriodToJSONTyped,
} from './LeavePeriod';

/**
 * 
 * @export
 * @interface ShowLeavePeriodResponse
 */
export interface ShowLeavePeriodResponse {
    /**
     * 
     * @type {LeavePeriod}
     * @memberof ShowLeavePeriodResponse
     */
    data: LeavePeriod;
}

/**
 * Check if a given object implements the ShowLeavePeriodResponse interface.
 */
export function instanceOfShowLeavePeriodResponse(value: object): value is ShowLeavePeriodResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowLeavePeriodResponseFromJSON(json: any): ShowLeavePeriodResponse {
    return ShowLeavePeriodResponseFromJSONTyped(json, false);
}

export function ShowLeavePeriodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowLeavePeriodResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeavePeriodFromJSON(json['data']),
    };
}

  export function ShowLeavePeriodResponseToJSON(json: any): ShowLeavePeriodResponse {
      return ShowLeavePeriodResponseToJSONTyped(json, false);
  }

  export function ShowLeavePeriodResponseToJSONTyped(value?: ShowLeavePeriodResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': LeavePeriodToJSON(value['data']),
    };
}

