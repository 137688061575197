/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OAuthClient } from './OAuthClient';
import {
    OAuthClientFromJSON,
    OAuthClientFromJSONTyped,
    OAuthClientToJSON,
    OAuthClientToJSONTyped,
} from './OAuthClient';

/**
 * 
 * @export
 * @interface ShowOauthClientResponse
 */
export interface ShowOauthClientResponse {
    /**
     * 
     * @type {OAuthClient}
     * @memberof ShowOauthClientResponse
     */
    data: OAuthClient;
}

/**
 * Check if a given object implements the ShowOauthClientResponse interface.
 */
export function instanceOfShowOauthClientResponse(value: object): value is ShowOauthClientResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowOauthClientResponseFromJSON(json: any): ShowOauthClientResponse {
    return ShowOauthClientResponseFromJSONTyped(json, false);
}

export function ShowOauthClientResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowOauthClientResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': OAuthClientFromJSON(json['data']),
    };
}

  export function ShowOauthClientResponseToJSON(json: any): ShowOauthClientResponse {
      return ShowOauthClientResponseToJSONTyped(json, false);
  }

  export function ShowOauthClientResponseToJSONTyped(value?: ShowOauthClientResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': OAuthClientToJSON(value['data']),
    };
}

