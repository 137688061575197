/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Credit } from './Credit';
import {
    CreditFromJSON,
    CreditFromJSONTyped,
    CreditToJSON,
    CreditToJSONTyped,
} from './Credit';

/**
 * 
 * @export
 * @interface EnableCreditRenewalsResponse
 */
export interface EnableCreditRenewalsResponse {
    /**
     * 
     * @type {Credit}
     * @memberof EnableCreditRenewalsResponse
     */
    data: Credit;
}

/**
 * Check if a given object implements the EnableCreditRenewalsResponse interface.
 */
export function instanceOfEnableCreditRenewalsResponse(value: object): value is EnableCreditRenewalsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function EnableCreditRenewalsResponseFromJSON(json: any): EnableCreditRenewalsResponse {
    return EnableCreditRenewalsResponseFromJSONTyped(json, false);
}

export function EnableCreditRenewalsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnableCreditRenewalsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CreditFromJSON(json['data']),
    };
}

  export function EnableCreditRenewalsResponseToJSON(json: any): EnableCreditRenewalsResponse {
      return EnableCreditRenewalsResponseToJSONTyped(json, false);
  }

  export function EnableCreditRenewalsResponseToJSONTyped(value?: EnableCreditRenewalsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': CreditToJSON(value['data']),
    };
}

