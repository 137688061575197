/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
    EmployeeToJSONTyped,
} from './Employee';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';
import type { LeaveType } from './LeaveType';
import {
    LeaveTypeFromJSON,
    LeaveTypeFromJSONTyped,
    LeaveTypeToJSON,
    LeaveTypeToJSONTyped,
} from './LeaveType';
import type { LeaveAllowanceChunk } from './LeaveAllowanceChunk';
import {
    LeaveAllowanceChunkFromJSON,
    LeaveAllowanceChunkFromJSONTyped,
    LeaveAllowanceChunkToJSON,
    LeaveAllowanceChunkToJSONTyped,
} from './LeaveAllowanceChunk';

/**
 * When an employee wants to book time off work, they need to submit a leave request
 * @export
 * @interface LeaveRequest
 */
export interface LeaveRequest {
    /**
     * 
     * @type {number}
     * @memberof LeaveRequest
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveRequest
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveRequest
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveRequest
     */
    leaveTypeId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof LeaveRequest
     */
    startDate: ShiftiePlainDate;
    /**
     * The last day of leave (inclusive). Treated as the company's timezone, *not UTC!*.
     * @type {ShiftiePlainDate}
     * @memberof LeaveRequest
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof LeaveRequest
     */
    startsMidday: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeaveRequest
     */
    endsMidday: boolean;
    /**
     * A breakdown of how much leave allowance has been used, across one or more leave periods.
     * @type {Array<LeaveAllowanceChunk>}
     * @memberof LeaveRequest
     */
    leaveAllowanceChunks: Array<LeaveAllowanceChunk>;
    /**
     * 
     * @type {string}
     * @memberof LeaveRequest
     */
    status: LeaveRequestStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LeaveRequest
     */
    paid: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeaveRequest
     */
    payCode: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LeaveRequest
     */
    reviewedAt: Date | null;
    /**
     * The ID of the manager who reviewed the request
     * @type {number}
     * @memberof LeaveRequest
     */
    reviewedBy: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeaveRequest
     */
    requestNotes: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeaveRequest
     */
    reviewerNotes: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LeaveRequest
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof LeaveRequest
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {LeaveType}
     * @memberof LeaveRequest
     */
    leaveType?: LeaveType;
    /**
     * 
     * @type {Employee}
     * @memberof LeaveRequest
     */
    employee?: Employee;
    /**
     * 
     * @type {Employee}
     * @memberof LeaveRequest
     */
    reviewer?: Employee;
}

/**
* @export
* @enum {string}
*/
export enum LeaveRequestStatusEnum {
    PendingApproval = 'Pending Approval',
    Approved = 'Approved',
    Denied = 'Denied',
    PendingCancellation = 'Pending Cancellation',
    Cancelled = 'Cancelled'
}


/**
 * Check if a given object implements the LeaveRequest interface.
 */
export function instanceOfLeaveRequest(value: object): value is LeaveRequest {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('leaveTypeId' in value) || value['leaveTypeId'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    if (!('startsMidday' in value) || value['startsMidday'] === undefined) return false;
    if (!('endsMidday' in value) || value['endsMidday'] === undefined) return false;
    if (!('leaveAllowanceChunks' in value) || value['leaveAllowanceChunks'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('paid' in value) || value['paid'] === undefined) return false;
    if (!('payCode' in value) || value['payCode'] === undefined) return false;
    if (!('reviewedAt' in value) || value['reviewedAt'] === undefined) return false;
    if (!('reviewedBy' in value) || value['reviewedBy'] === undefined) return false;
    if (!('requestNotes' in value) || value['requestNotes'] === undefined) return false;
    if (!('reviewerNotes' in value) || value['reviewerNotes'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function LeaveRequestFromJSON(json: any): LeaveRequest {
    return LeaveRequestFromJSONTyped(json, false);
}

export function LeaveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'leaveTypeId': json['leaveTypeId'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'startsMidday': json['startsMidday'],
        'endsMidday': json['endsMidday'],
        'leaveAllowanceChunks': ((json['leaveAllowanceChunks'] as Array<any>).map(LeaveAllowanceChunkFromJSON)),
        'status': json['status'],
        'paid': json['paid'],
        'payCode': json['payCode'],
        'reviewedAt': (json['reviewedAt'] == null ? null : new Date(json['reviewedAt'])),
        'reviewedBy': json['reviewedBy'],
        'requestNotes': json['requestNotes'],
        'reviewerNotes': json['reviewerNotes'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'leaveType': json['leaveType'] == null ? undefined : LeaveTypeFromJSON(json['leaveType']),
        'employee': json['employee'] == null ? undefined : EmployeeFromJSON(json['employee']),
        'reviewer': json['reviewer'] == null ? undefined : EmployeeFromJSON(json['reviewer']),
    };
}

  export function LeaveRequestToJSON(json: any): LeaveRequest {
      return LeaveRequestToJSONTyped(json, false);
  }

  export function LeaveRequestToJSONTyped(value?: LeaveRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'leaveTypeId': value['leaveTypeId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'startsMidday': value['startsMidday'],
        'endsMidday': value['endsMidday'],
        'leaveAllowanceChunks': ((value['leaveAllowanceChunks'] as Array<any>).map(LeaveAllowanceChunkToJSON)),
        'status': value['status'],
        'paid': value['paid'],
        'payCode': value['payCode'],
        'reviewedAt': (value['reviewedAt'] == null ? null : (value['reviewedAt'] as any).toISOString()),
        'reviewedBy': value['reviewedBy'],
        'requestNotes': value['requestNotes'],
        'reviewerNotes': value['reviewerNotes'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'leaveType': LeaveTypeToJSON(value['leaveType']),
        'employee': EmployeeToJSON(value['employee']),
        'reviewer': EmployeeToJSON(value['reviewer']),
    };
}

