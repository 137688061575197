/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Entity-agnostic recurrence rule following the iCalendar RRULE format
 * @export
 * @interface RecurrenceRule
 */
export interface RecurrenceRule {
    /**
     * 
     * @type {number}
     * @memberof RecurrenceRule
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceRule
     */
    companyId: number;
    /**
     * Event recurrence frequencies, according to the iCalendar RRULE spec
     * @type {string}
     * @memberof RecurrenceRule
     */
    freq: RecurrenceRuleFreqEnum;
    /**
     * When the pattern should start. Treated as *inclusive*.
     * @type {Date}
     * @memberof RecurrenceRule
     */
    from: Date;
    /**
     * When the pattern should end. Treated as *inclusive*.
     * @type {Date}
     * @memberof RecurrenceRule
     */
    until: Date | null;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceRule
     */
    count: number | null;
    /**
     * 
     * @type {number}
     * @memberof RecurrenceRule
     */
    interval: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceRule
     */
    bySecond: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceRule
     */
    byMinute: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceRule
     */
    byHour: string | null;
    /**
     * Comma-separated list of 2-character day names
     * @type {string}
     * @memberof RecurrenceRule
     */
    byDay: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceRule
     */
    byMonthDay: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceRule
     */
    byYearDay: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceRule
     */
    byWeekNo: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceRule
     */
    byMonth: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceRule
     */
    bySetPos: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecurrenceRule
     */
    wkSt: string | null;
    /**
     * 
     * @type {Date}
     * @memberof RecurrenceRule
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof RecurrenceRule
     */
    updatedAt: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum RecurrenceRuleFreqEnum {
    Daily = 'DAILY',
    Hourly = 'HOURLY',
    Minutely = 'MINUTELY',
    Monthly = 'MONTHLY',
    Secondly = 'SECONDLY',
    Weekly = 'WEEKLY',
    Yearly = 'YEARLY'
}


/**
 * Check if a given object implements the RecurrenceRule interface.
 */
export function instanceOfRecurrenceRule(value: object): value is RecurrenceRule {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('freq' in value) || value['freq'] === undefined) return false;
    if (!('from' in value) || value['from'] === undefined) return false;
    if (!('until' in value) || value['until'] === undefined) return false;
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('interval' in value) || value['interval'] === undefined) return false;
    if (!('bySecond' in value) || value['bySecond'] === undefined) return false;
    if (!('byMinute' in value) || value['byMinute'] === undefined) return false;
    if (!('byHour' in value) || value['byHour'] === undefined) return false;
    if (!('byDay' in value) || value['byDay'] === undefined) return false;
    if (!('byMonthDay' in value) || value['byMonthDay'] === undefined) return false;
    if (!('byYearDay' in value) || value['byYearDay'] === undefined) return false;
    if (!('byWeekNo' in value) || value['byWeekNo'] === undefined) return false;
    if (!('byMonth' in value) || value['byMonth'] === undefined) return false;
    if (!('bySetPos' in value) || value['bySetPos'] === undefined) return false;
    if (!('wkSt' in value) || value['wkSt'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function RecurrenceRuleFromJSON(json: any): RecurrenceRule {
    return RecurrenceRuleFromJSONTyped(json, false);
}

export function RecurrenceRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecurrenceRule {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'freq': json['freq'],
        'from': (new Date(json['from'])),
        'until': (json['until'] == null ? null : new Date(json['until'])),
        'count': json['count'],
        'interval': json['interval'],
        'bySecond': json['bySecond'],
        'byMinute': json['byMinute'],
        'byHour': json['byHour'],
        'byDay': json['byDay'],
        'byMonthDay': json['byMonthDay'],
        'byYearDay': json['byYearDay'],
        'byWeekNo': json['byWeekNo'],
        'byMonth': json['byMonth'],
        'bySetPos': json['bySetPos'],
        'wkSt': json['wkSt'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

  export function RecurrenceRuleToJSON(json: any): RecurrenceRule {
      return RecurrenceRuleToJSONTyped(json, false);
  }

  export function RecurrenceRuleToJSONTyped(value?: RecurrenceRule | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'freq': value['freq'],
        'from': ((value['from']).toISOString()),
        'until': (value['until'] == null ? null : (value['until'] as any).toISOString()),
        'count': value['count'],
        'interval': value['interval'],
        'bySecond': value['bySecond'],
        'byMinute': value['byMinute'],
        'byHour': value['byHour'],
        'byDay': value['byDay'],
        'byMonthDay': value['byMonthDay'],
        'byYearDay': value['byYearDay'],
        'byWeekNo': value['byWeekNo'],
        'byMonth': value['byMonth'],
        'bySetPos': value['bySetPos'],
        'wkSt': value['wkSt'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

