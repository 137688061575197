/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * Update an existing (unapproved) leave request. Updating approved requests is forbidden.
 * @export
 * @interface UpdateLeaveRequestData
 */
export interface UpdateLeaveRequestData {
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveRequestData
     */
    leaveTypeId?: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof UpdateLeaveRequestData
     */
    startDate?: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof UpdateLeaveRequestData
     */
    endDate?: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveRequestData
     */
    startsMidday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveRequestData
     */
    endsMidday?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveRequestData
     */
    requestNotes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveRequestData
     */
    reviewerNotes?: string | null;
}

/**
 * Check if a given object implements the UpdateLeaveRequestData interface.
 */
export function instanceOfUpdateLeaveRequestData(value: object): value is UpdateLeaveRequestData {
    return true;
}

export function UpdateLeaveRequestDataFromJSON(json: any): UpdateLeaveRequestData {
    return UpdateLeaveRequestDataFromJSONTyped(json, false);
}

export function UpdateLeaveRequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeaveRequestData {
    if (json == null) {
        return json;
    }
    return {
        
        'leaveTypeId': json['leaveTypeId'] == null ? undefined : json['leaveTypeId'],
        'startDate': json['startDate'] == null ? undefined : ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': json['endDate'] == null ? undefined : ShiftiePlainDateFromJSON(json['endDate']),
        'startsMidday': json['startsMidday'] == null ? undefined : json['startsMidday'],
        'endsMidday': json['endsMidday'] == null ? undefined : json['endsMidday'],
        'requestNotes': json['requestNotes'] == null ? undefined : json['requestNotes'],
        'reviewerNotes': json['reviewerNotes'] == null ? undefined : json['reviewerNotes'],
    };
}

  export function UpdateLeaveRequestDataToJSON(json: any): UpdateLeaveRequestData {
      return UpdateLeaveRequestDataToJSONTyped(json, false);
  }

  export function UpdateLeaveRequestDataToJSONTyped(value?: UpdateLeaveRequestData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'leaveTypeId': value['leaveTypeId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'startsMidday': value['startsMidday'],
        'endsMidday': value['endsMidday'],
        'requestNotes': value['requestNotes'],
        'reviewerNotes': value['reviewerNotes'],
    };
}

