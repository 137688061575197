/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeMessage
 */
export interface EmployeeMessage {
    /**
     * 
     * @type {string}
     * @memberof EmployeeMessage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeMessage
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeMessage
     */
    channel: EmployeeMessageChannelEnum;
    /**
     * 
     * @type {string}
     * @memberof EmployeeMessage
     */
    mailReplyAddress: string | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMessage
     */
    senderId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeMessage
     */
    recipientCount: number;
    /**
     * 
     * @type {Date}
     * @memberof EmployeeMessage
     */
    sentAt: Date;
}

/**
* @export
* @enum {string}
*/
export enum EmployeeMessageChannelEnum {
    Mail = 'Mail'
}


/**
 * Check if a given object implements the EmployeeMessage interface.
 */
export function instanceOfEmployeeMessage(value: object): value is EmployeeMessage {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('subject' in value) || value['subject'] === undefined) return false;
    if (!('channel' in value) || value['channel'] === undefined) return false;
    if (!('mailReplyAddress' in value) || value['mailReplyAddress'] === undefined) return false;
    if (!('senderId' in value) || value['senderId'] === undefined) return false;
    if (!('recipientCount' in value) || value['recipientCount'] === undefined) return false;
    if (!('sentAt' in value) || value['sentAt'] === undefined) return false;
    return true;
}

export function EmployeeMessageFromJSON(json: any): EmployeeMessage {
    return EmployeeMessageFromJSONTyped(json, false);
}

export function EmployeeMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeMessage {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'subject': json['subject'],
        'channel': json['channel'],
        'mailReplyAddress': json['mailReplyAddress'],
        'senderId': json['senderId'],
        'recipientCount': json['recipientCount'],
        'sentAt': (new Date(json['sentAt'])),
    };
}

  export function EmployeeMessageToJSON(json: any): EmployeeMessage {
      return EmployeeMessageToJSONTyped(json, false);
  }

  export function EmployeeMessageToJSONTyped(value?: EmployeeMessage | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'subject': value['subject'],
        'channel': value['channel'],
        'mailReplyAddress': value['mailReplyAddress'],
        'senderId': value['senderId'],
        'recipientCount': value['recipientCount'],
        'sentAt': ((value['sentAt']).toISOString()),
    };
}

