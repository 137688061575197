/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { EmployeeLeaveSummary } from './EmployeeLeaveSummary';
import {
    EmployeeLeaveSummaryFromJSON,
    EmployeeLeaveSummaryFromJSONTyped,
    EmployeeLeaveSummaryToJSON,
    EmployeeLeaveSummaryToJSONTyped,
} from './EmployeeLeaveSummary';

/**
 * 
 * @export
 * @interface ListEmployeeLeaveSummariesResponse
 */
export interface ListEmployeeLeaveSummariesResponse {
    /**
     * 
     * @type {Array<EmployeeLeaveSummary>}
     * @memberof ListEmployeeLeaveSummariesResponse
     */
    data: Array<EmployeeLeaveSummary>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListEmployeeLeaveSummariesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListEmployeeLeaveSummariesResponse interface.
 */
export function instanceOfListEmployeeLeaveSummariesResponse(value: object): value is ListEmployeeLeaveSummariesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListEmployeeLeaveSummariesResponseFromJSON(json: any): ListEmployeeLeaveSummariesResponse {
    return ListEmployeeLeaveSummariesResponseFromJSONTyped(json, false);
}

export function ListEmployeeLeaveSummariesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEmployeeLeaveSummariesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(EmployeeLeaveSummaryFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListEmployeeLeaveSummariesResponseToJSON(json: any): ListEmployeeLeaveSummariesResponse {
      return ListEmployeeLeaveSummariesResponseToJSONTyped(json, false);
  }

  export function ListEmployeeLeaveSummariesResponseToJSONTyped(value?: ListEmployeeLeaveSummariesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(EmployeeLeaveSummaryToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

