/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReportCostsByEmployeeRollup
 */
export interface ReportCostsByEmployeeRollup {
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployeeRollup
     */
    shifts: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployeeRollup
     */
    hoursScheduled: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployeeRollup
     */
    hoursWorked: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployeeRollup
     */
    costsScheduled: number;
    /**
     * 
     * @type {number}
     * @memberof ReportCostsByEmployeeRollup
     */
    costsWorked: number;
}

/**
 * Check if a given object implements the ReportCostsByEmployeeRollup interface.
 */
export function instanceOfReportCostsByEmployeeRollup(value: object): value is ReportCostsByEmployeeRollup {
    if (!('shifts' in value) || value['shifts'] === undefined) return false;
    if (!('hoursScheduled' in value) || value['hoursScheduled'] === undefined) return false;
    if (!('hoursWorked' in value) || value['hoursWorked'] === undefined) return false;
    if (!('costsScheduled' in value) || value['costsScheduled'] === undefined) return false;
    if (!('costsWorked' in value) || value['costsWorked'] === undefined) return false;
    return true;
}

export function ReportCostsByEmployeeRollupFromJSON(json: any): ReportCostsByEmployeeRollup {
    return ReportCostsByEmployeeRollupFromJSONTyped(json, false);
}

export function ReportCostsByEmployeeRollupFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportCostsByEmployeeRollup {
    if (json == null) {
        return json;
    }
    return {
        
        'shifts': json['shifts'],
        'hoursScheduled': json['hoursScheduled'],
        'hoursWorked': json['hoursWorked'],
        'costsScheduled': json['costsScheduled'],
        'costsWorked': json['costsWorked'],
    };
}

  export function ReportCostsByEmployeeRollupToJSON(json: any): ReportCostsByEmployeeRollup {
      return ReportCostsByEmployeeRollupToJSONTyped(json, false);
  }

  export function ReportCostsByEmployeeRollupToJSONTyped(value?: ReportCostsByEmployeeRollup | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'shifts': value['shifts'],
        'hoursScheduled': value['hoursScheduled'],
        'hoursWorked': value['hoursWorked'],
        'costsScheduled': value['costsScheduled'],
        'costsWorked': value['costsWorked'],
    };
}

