/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftTemplate } from './ShiftTemplate';
import {
    ShiftTemplateFromJSON,
    ShiftTemplateFromJSONTyped,
    ShiftTemplateToJSON,
    ShiftTemplateToJSONTyped,
} from './ShiftTemplate';

/**
 * 
 * @export
 * @interface ShowShiftTemplateResponse
 */
export interface ShowShiftTemplateResponse {
    /**
     * 
     * @type {ShiftTemplate}
     * @memberof ShowShiftTemplateResponse
     */
    data: ShiftTemplate;
}

/**
 * Check if a given object implements the ShowShiftTemplateResponse interface.
 */
export function instanceOfShowShiftTemplateResponse(value: object): value is ShowShiftTemplateResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowShiftTemplateResponseFromJSON(json: any): ShowShiftTemplateResponse {
    return ShowShiftTemplateResponseFromJSONTyped(json, false);
}

export function ShowShiftTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowShiftTemplateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ShiftTemplateFromJSON(json['data']),
    };
}

  export function ShowShiftTemplateResponseToJSON(json: any): ShowShiftTemplateResponse {
      return ShowShiftTemplateResponseToJSONTyped(json, false);
  }

  export function ShowShiftTemplateResponseToJSONTyped(value?: ShowShiftTemplateResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ShiftTemplateToJSON(value['data']),
    };
}

