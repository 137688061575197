/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftArea } from './ShiftArea';
import {
    ShiftAreaFromJSON,
    ShiftAreaFromJSONTyped,
    ShiftAreaToJSON,
    ShiftAreaToJSONTyped,
} from './ShiftArea';

/**
 * 
 * @export
 * @interface CreateShiftAreaResponse
 */
export interface CreateShiftAreaResponse {
    /**
     * 
     * @type {ShiftArea}
     * @memberof CreateShiftAreaResponse
     */
    data: ShiftArea;
}

/**
 * Check if a given object implements the CreateShiftAreaResponse interface.
 */
export function instanceOfCreateShiftAreaResponse(value: object): value is CreateShiftAreaResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateShiftAreaResponseFromJSON(json: any): CreateShiftAreaResponse {
    return CreateShiftAreaResponseFromJSONTyped(json, false);
}

export function CreateShiftAreaResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftAreaResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ShiftAreaFromJSON(json['data']),
    };
}

  export function CreateShiftAreaResponseToJSON(json: any): CreateShiftAreaResponse {
      return CreateShiftAreaResponseToJSONTyped(json, false);
  }

  export function CreateShiftAreaResponseToJSONTyped(value?: CreateShiftAreaResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ShiftAreaToJSON(value['data']),
    };
}

