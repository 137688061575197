/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Notification } from './Notification';
import {
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
    NotificationToJSONTyped,
} from './Notification';

/**
 * 
 * @export
 * @interface ShowNotificationResponse
 */
export interface ShowNotificationResponse {
    /**
     * 
     * @type {Notification}
     * @memberof ShowNotificationResponse
     */
    data: Notification;
}

/**
 * Check if a given object implements the ShowNotificationResponse interface.
 */
export function instanceOfShowNotificationResponse(value: object): value is ShowNotificationResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowNotificationResponseFromJSON(json: any): ShowNotificationResponse {
    return ShowNotificationResponseFromJSONTyped(json, false);
}

export function ShowNotificationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowNotificationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': NotificationFromJSON(json['data']),
    };
}

  export function ShowNotificationResponseToJSON(json: any): ShowNotificationResponse {
      return ShowNotificationResponseToJSONTyped(json, false);
  }

  export function ShowNotificationResponseToJSONTyped(value?: ShowNotificationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': NotificationToJSON(value['data']),
    };
}

