/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Employee Pay Information
 * @export
 * @interface EmployeePayDetails
 */
export interface EmployeePayDetails {
    /**
     * The ID of the Employee Pay this relates to
     * @type {number}
     * @memberof EmployeePayDetails
     */
    id: number;
    /**
     * The ID of the Company this relates to
     * @type {number}
     * @memberof EmployeePayDetails
     */
    companyId: number;
    /**
     * The ID of the Employee this relates to
     * @type {number}
     * @memberof EmployeePayDetails
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePayDetails
     */
    baseRate: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeePayDetails
     */
    payrollId: string | null;
    /**
     * 
     * @type {Date}
     * @memberof EmployeePayDetails
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof EmployeePayDetails
     */
    updatedAt: Date | null;
}

/**
 * Check if a given object implements the EmployeePayDetails interface.
 */
export function instanceOfEmployeePayDetails(value: object): value is EmployeePayDetails {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('baseRate' in value) || value['baseRate'] === undefined) return false;
    if (!('payrollId' in value) || value['payrollId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function EmployeePayDetailsFromJSON(json: any): EmployeePayDetails {
    return EmployeePayDetailsFromJSONTyped(json, false);
}

export function EmployeePayDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeePayDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'baseRate': json['baseRate'],
        'payrollId': json['payrollId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

  export function EmployeePayDetailsToJSON(json: any): EmployeePayDetails {
      return EmployeePayDetailsToJSONTyped(json, false);
  }

  export function EmployeePayDetailsToJSONTyped(value?: EmployeePayDetails | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'baseRate': value['baseRate'],
        'payrollId': value['payrollId'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

