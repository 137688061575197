/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyToJSONTyped,
} from './Company';

/**
 * 
 * @export
 * @interface ShowCompanyResponse
 */
export interface ShowCompanyResponse {
    /**
     * 
     * @type {Company}
     * @memberof ShowCompanyResponse
     */
    data: Company;
}

/**
 * Check if a given object implements the ShowCompanyResponse interface.
 */
export function instanceOfShowCompanyResponse(value: object): value is ShowCompanyResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowCompanyResponseFromJSON(json: any): ShowCompanyResponse {
    return ShowCompanyResponseFromJSONTyped(json, false);
}

export function ShowCompanyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowCompanyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CompanyFromJSON(json['data']),
    };
}

  export function ShowCompanyResponseToJSON(json: any): ShowCompanyResponse {
      return ShowCompanyResponseToJSONTyped(json, false);
  }

  export function ShowCompanyResponseToJSONTyped(value?: ShowCompanyResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': CompanyToJSON(value['data']),
    };
}

