/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { DocumentAssignment } from './DocumentAssignment';
import {
    DocumentAssignmentFromJSON,
    DocumentAssignmentFromJSONTyped,
    DocumentAssignmentToJSON,
    DocumentAssignmentToJSONTyped,
} from './DocumentAssignment';

/**
 * 
 * @export
 * @interface ListDocumentAssignmentsResponse
 */
export interface ListDocumentAssignmentsResponse {
    /**
     * 
     * @type {Array<DocumentAssignment>}
     * @memberof ListDocumentAssignmentsResponse
     */
    data: Array<DocumentAssignment>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListDocumentAssignmentsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListDocumentAssignmentsResponse interface.
 */
export function instanceOfListDocumentAssignmentsResponse(value: object): value is ListDocumentAssignmentsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListDocumentAssignmentsResponseFromJSON(json: any): ListDocumentAssignmentsResponse {
    return ListDocumentAssignmentsResponseFromJSONTyped(json, false);
}

export function ListDocumentAssignmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDocumentAssignmentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(DocumentAssignmentFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListDocumentAssignmentsResponseToJSON(json: any): ListDocumentAssignmentsResponse {
      return ListDocumentAssignmentsResponseToJSONTyped(json, false);
  }

  export function ListDocumentAssignmentsResponseToJSONTyped(value?: ListDocumentAssignmentsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(DocumentAssignmentToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

