/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Various usage calculations for a potential leave request
 * @export
 * @interface LeaveRequestUsageCalculation
 */
export interface LeaveRequestUsageCalculation {
    /**
     * The total number of days that this request covers
     * @type {number}
     * @memberof LeaveRequestUsageCalculation
     */
    durationInDays: number;
    /**
     * The number of hours or days that would be deducted from an employee's leave balance
     * @type {number}
     * @memberof LeaveRequestUsageCalculation
     */
    leaveAllowanceDeduction: number;
    /**
     * 
     * @type {string}
     * @memberof LeaveRequestUsageCalculation
     */
    leaveTimeUnit: LeaveRequestUsageCalculationLeaveTimeUnitEnum;
}

/**
* @export
* @enum {string}
*/
export enum LeaveRequestUsageCalculationLeaveTimeUnitEnum {
    Days = 'Days',
    Hours = 'Hours'
}


/**
 * Check if a given object implements the LeaveRequestUsageCalculation interface.
 */
export function instanceOfLeaveRequestUsageCalculation(value: object): value is LeaveRequestUsageCalculation {
    if (!('durationInDays' in value) || value['durationInDays'] === undefined) return false;
    if (!('leaveAllowanceDeduction' in value) || value['leaveAllowanceDeduction'] === undefined) return false;
    if (!('leaveTimeUnit' in value) || value['leaveTimeUnit'] === undefined) return false;
    return true;
}

export function LeaveRequestUsageCalculationFromJSON(json: any): LeaveRequestUsageCalculation {
    return LeaveRequestUsageCalculationFromJSONTyped(json, false);
}

export function LeaveRequestUsageCalculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveRequestUsageCalculation {
    if (json == null) {
        return json;
    }
    return {
        
        'durationInDays': json['durationInDays'],
        'leaveAllowanceDeduction': json['leaveAllowanceDeduction'],
        'leaveTimeUnit': json['leaveTimeUnit'],
    };
}

  export function LeaveRequestUsageCalculationToJSON(json: any): LeaveRequestUsageCalculation {
      return LeaveRequestUsageCalculationToJSONTyped(json, false);
  }

  export function LeaveRequestUsageCalculationToJSONTyped(value?: LeaveRequestUsageCalculation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'durationInDays': value['durationInDays'],
        'leaveAllowanceDeduction': value['leaveAllowanceDeduction'],
        'leaveTimeUnit': value['leaveTimeUnit'],
    };
}

