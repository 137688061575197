/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnableCreditRenewalData
 */
export interface EnableCreditRenewalData {
    /**
     * 
     * @type {string}
     * @memberof EnableCreditRenewalData
     */
    type: EnableCreditRenewalDataTypeEnum;
    /**
     * How much the credits should be topped up by on each renewal
     * @type {number}
     * @memberof EnableCreditRenewalData
     */
    renewalValue: number;
}

/**
* @export
* @enum {string}
*/
export enum EnableCreditRenewalDataTypeEnum {
    Referral = 'Referral',
    Sms = 'SMS'
}


/**
 * Check if a given object implements the EnableCreditRenewalData interface.
 */
export function instanceOfEnableCreditRenewalData(value: object): value is EnableCreditRenewalData {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('renewalValue' in value) || value['renewalValue'] === undefined) return false;
    return true;
}

export function EnableCreditRenewalDataFromJSON(json: any): EnableCreditRenewalData {
    return EnableCreditRenewalDataFromJSONTyped(json, false);
}

export function EnableCreditRenewalDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnableCreditRenewalData {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'renewalValue': json['renewalValue'],
    };
}

  export function EnableCreditRenewalDataToJSON(json: any): EnableCreditRenewalData {
      return EnableCreditRenewalDataToJSONTyped(json, false);
  }

  export function EnableCreditRenewalDataToJSONTyped(value?: EnableCreditRenewalData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'type': value['type'],
        'renewalValue': value['renewalValue'],
    };
}

