/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveRequest } from './LeaveRequest';
import {
    LeaveRequestFromJSON,
    LeaveRequestFromJSONTyped,
    LeaveRequestToJSON,
    LeaveRequestToJSONTyped,
} from './LeaveRequest';

/**
 * 
 * @export
 * @interface DenyLeaveRequestCancellationResponse
 */
export interface DenyLeaveRequestCancellationResponse {
    /**
     * 
     * @type {LeaveRequest}
     * @memberof DenyLeaveRequestCancellationResponse
     */
    data: LeaveRequest;
}

/**
 * Check if a given object implements the DenyLeaveRequestCancellationResponse interface.
 */
export function instanceOfDenyLeaveRequestCancellationResponse(value: object): value is DenyLeaveRequestCancellationResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function DenyLeaveRequestCancellationResponseFromJSON(json: any): DenyLeaveRequestCancellationResponse {
    return DenyLeaveRequestCancellationResponseFromJSONTyped(json, false);
}

export function DenyLeaveRequestCancellationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DenyLeaveRequestCancellationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeaveRequestFromJSON(json['data']),
    };
}

  export function DenyLeaveRequestCancellationResponseToJSON(json: any): DenyLeaveRequestCancellationResponse {
      return DenyLeaveRequestCancellationResponseToJSONTyped(json, false);
  }

  export function DenyLeaveRequestCancellationResponseToJSONTyped(value?: DenyLeaveRequestCancellationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': LeaveRequestToJSON(value['data']),
    };
}

