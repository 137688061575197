/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';
import type { BillingEventLine } from './BillingEventLine';
import {
    BillingEventLineFromJSON,
    BillingEventLineFromJSONTyped,
    BillingEventLineToJSON,
    BillingEventLineToJSONTyped,
} from './BillingEventLine';

/**
 * 
 * @export
 * @interface BillingEvent
 */
export interface BillingEvent {
    /**
     * 
     * @type {string}
     * @memberof BillingEvent
     */
    type: BillingEventTypeEnum;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BillingEvent
     */
    date: ShiftiePlainDate;
    /**
     * 
     * @type {number}
     * @memberof BillingEvent
     */
    offers: number;
    /**
     * 
     * @type {number}
     * @memberof BillingEvent
     */
    estimatedCost: number;
    /**
     * 
     * @type {Array<BillingEventLine>}
     * @memberof BillingEvent
     */
    lines: Array<BillingEventLine>;
}

/**
* @export
* @enum {string}
*/
export enum BillingEventTypeEnum {
    Subscription = 'Subscription',
    Usage = 'Usage'
}


/**
 * Check if a given object implements the BillingEvent interface.
 */
export function instanceOfBillingEvent(value: object): value is BillingEvent {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('offers' in value) || value['offers'] === undefined) return false;
    if (!('estimatedCost' in value) || value['estimatedCost'] === undefined) return false;
    if (!('lines' in value) || value['lines'] === undefined) return false;
    return true;
}

export function BillingEventFromJSON(json: any): BillingEvent {
    return BillingEventFromJSONTyped(json, false);
}

export function BillingEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'date': ShiftiePlainDateFromJSON(json['date']),
        'offers': json['offers'],
        'estimatedCost': json['estimatedCost'],
        'lines': ((json['lines'] as Array<any>).map(BillingEventLineFromJSON)),
    };
}

  export function BillingEventToJSON(json: any): BillingEvent {
      return BillingEventToJSONTyped(json, false);
  }

  export function BillingEventToJSONTyped(value?: BillingEvent | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'type': value['type'],
        'date': ShiftiePlainDateToJSON(value['date']),
        'offers': value['offers'],
        'estimatedCost': value['estimatedCost'],
        'lines': ((value['lines'] as Array<any>).map(BillingEventLineToJSON)),
    };
}

