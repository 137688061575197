/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushSubscription
 */
export interface PushSubscription {
    /**
     * 
     * @type {string}
     * @memberof PushSubscription
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PushSubscription
     */
    endpoint: string;
    /**
     * 
     * @type {string}
     * @memberof PushSubscription
     */
    deviceIdentifier: string;
}

/**
 * Check if a given object implements the PushSubscription interface.
 */
export function instanceOfPushSubscription(value: object): value is PushSubscription {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('endpoint' in value) || value['endpoint'] === undefined) return false;
    if (!('deviceIdentifier' in value) || value['deviceIdentifier'] === undefined) return false;
    return true;
}

export function PushSubscriptionFromJSON(json: any): PushSubscription {
    return PushSubscriptionFromJSONTyped(json, false);
}

export function PushSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushSubscription {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'endpoint': json['endpoint'],
        'deviceIdentifier': json['deviceIdentifier'],
    };
}

  export function PushSubscriptionToJSON(json: any): PushSubscription {
      return PushSubscriptionToJSONTyped(json, false);
  }

  export function PushSubscriptionToJSONTyped(value?: PushSubscription | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'endpoint': value['endpoint'],
        'deviceIdentifier': value['deviceIdentifier'],
    };
}

