/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { NotificationSettings } from './NotificationSettings';
import {
    NotificationSettingsFromJSON,
    NotificationSettingsFromJSONTyped,
    NotificationSettingsToJSON,
    NotificationSettingsToJSONTyped,
} from './NotificationSettings';

/**
 * 
 * @export
 * @interface ShowNotificationSettingsResponse
 */
export interface ShowNotificationSettingsResponse {
    /**
     * 
     * @type {NotificationSettings}
     * @memberof ShowNotificationSettingsResponse
     */
    data: NotificationSettings;
}

/**
 * Check if a given object implements the ShowNotificationSettingsResponse interface.
 */
export function instanceOfShowNotificationSettingsResponse(value: object): value is ShowNotificationSettingsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowNotificationSettingsResponseFromJSON(json: any): ShowNotificationSettingsResponse {
    return ShowNotificationSettingsResponseFromJSONTyped(json, false);
}

export function ShowNotificationSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowNotificationSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': NotificationSettingsFromJSON(json['data']),
    };
}

  export function ShowNotificationSettingsResponseToJSON(json: any): ShowNotificationSettingsResponse {
      return ShowNotificationSettingsResponseToJSONTyped(json, false);
  }

  export function ShowNotificationSettingsResponseToJSONTyped(value?: ShowNotificationSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': NotificationSettingsToJSON(value['data']),
    };
}

