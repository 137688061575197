/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A period of rest within a Shift
 * @export
 * @interface ScheduledBreak
 */
export interface ScheduledBreak {
    /**
     * 
     * @type {number}
     * @memberof ScheduledBreak
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledBreak
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledBreak
     */
    shiftId: number;
    /**
     * 
     * @type {Date}
     * @memberof ScheduledBreak
     */
    startsAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledBreak
     */
    durationInMinutes: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledBreak
     */
    paid: boolean;
}

/**
 * Check if a given object implements the ScheduledBreak interface.
 */
export function instanceOfScheduledBreak(value: object): value is ScheduledBreak {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('shiftId' in value) || value['shiftId'] === undefined) return false;
    if (!('startsAt' in value) || value['startsAt'] === undefined) return false;
    if (!('durationInMinutes' in value) || value['durationInMinutes'] === undefined) return false;
    if (!('paid' in value) || value['paid'] === undefined) return false;
    return true;
}

export function ScheduledBreakFromJSON(json: any): ScheduledBreak {
    return ScheduledBreakFromJSONTyped(json, false);
}

export function ScheduledBreakFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduledBreak {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'shiftId': json['shiftId'],
        'startsAt': (json['startsAt'] == null ? null : new Date(json['startsAt'])),
        'durationInMinutes': json['durationInMinutes'],
        'paid': json['paid'],
    };
}

  export function ScheduledBreakToJSON(json: any): ScheduledBreak {
      return ScheduledBreakToJSONTyped(json, false);
  }

  export function ScheduledBreakToJSONTyped(value?: ScheduledBreak | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'shiftId': value['shiftId'],
        'startsAt': (value['startsAt'] == null ? null : (value['startsAt'] as any).toISOString()),
        'durationInMinutes': value['durationInMinutes'],
        'paid': value['paid'],
    };
}

