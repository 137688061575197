/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { PushSubscription } from './PushSubscription';
import {
    PushSubscriptionFromJSON,
    PushSubscriptionFromJSONTyped,
    PushSubscriptionToJSON,
    PushSubscriptionToJSONTyped,
} from './PushSubscription';

/**
 * 
 * @export
 * @interface ListPushSubscriptionsResponse
 */
export interface ListPushSubscriptionsResponse {
    /**
     * 
     * @type {Array<PushSubscription>}
     * @memberof ListPushSubscriptionsResponse
     */
    data: Array<PushSubscription>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListPushSubscriptionsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListPushSubscriptionsResponse interface.
 */
export function instanceOfListPushSubscriptionsResponse(value: object): value is ListPushSubscriptionsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListPushSubscriptionsResponseFromJSON(json: any): ListPushSubscriptionsResponse {
    return ListPushSubscriptionsResponseFromJSONTyped(json, false);
}

export function ListPushSubscriptionsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListPushSubscriptionsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(PushSubscriptionFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListPushSubscriptionsResponseToJSON(json: any): ListPushSubscriptionsResponse {
      return ListPushSubscriptionsResponseToJSONTyped(json, false);
  }

  export function ListPushSubscriptionsResponseToJSONTyped(value?: ListPushSubscriptionsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(PushSubscriptionToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

