/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { Document } from './Document';
import {
    DocumentFromJSON,
    DocumentFromJSONTyped,
    DocumentToJSON,
    DocumentToJSONTyped,
} from './Document';

/**
 * 
 * @export
 * @interface ListDocumentsResponse
 */
export interface ListDocumentsResponse {
    /**
     * 
     * @type {Array<Document>}
     * @memberof ListDocumentsResponse
     */
    data: Array<Document>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListDocumentsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListDocumentsResponse interface.
 */
export function instanceOfListDocumentsResponse(value: object): value is ListDocumentsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListDocumentsResponseFromJSON(json: any): ListDocumentsResponse {
    return ListDocumentsResponseFromJSONTyped(json, false);
}

export function ListDocumentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDocumentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(DocumentFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListDocumentsResponseToJSON(json: any): ListDocumentsResponse {
      return ListDocumentsResponseToJSONTyped(json, false);
  }

  export function ListDocumentsResponseToJSONTyped(value?: ListDocumentsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(DocumentToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

