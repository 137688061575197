/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { WorkPattern } from './WorkPattern';
import {
    WorkPatternFromJSON,
    WorkPatternFromJSONTyped,
    WorkPatternToJSON,
    WorkPatternToJSONTyped,
} from './WorkPattern';

/**
 * 
 * @export
 * @interface ListWorkPatternsResponse
 */
export interface ListWorkPatternsResponse {
    /**
     * 
     * @type {Array<WorkPattern>}
     * @memberof ListWorkPatternsResponse
     */
    data: Array<WorkPattern>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListWorkPatternsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListWorkPatternsResponse interface.
 */
export function instanceOfListWorkPatternsResponse(value: object): value is ListWorkPatternsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListWorkPatternsResponseFromJSON(json: any): ListWorkPatternsResponse {
    return ListWorkPatternsResponseFromJSONTyped(json, false);
}

export function ListWorkPatternsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListWorkPatternsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(WorkPatternFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListWorkPatternsResponseToJSON(json: any): ListWorkPatternsResponse {
      return ListWorkPatternsResponseToJSONTyped(json, false);
  }

  export function ListWorkPatternsResponseToJSONTyped(value?: ListWorkPatternsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(WorkPatternToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

