/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface PayPeriod
 */
export interface PayPeriod {
    /**
     * 
     * @type {number}
     * @memberof PayPeriod
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof PayPeriod
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof PayPeriod
     */
    payCycleId: number;
    /**
     * 
     * @type {boolean}
     * @memberof PayPeriod
     */
    active: boolean;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof PayPeriod
     */
    startsOn: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof PayPeriod
     */
    endsOn: ShiftiePlainDate;
    /**
     * 
     * @type {Date}
     * @memberof PayPeriod
     */
    confirmedAt: Date | null;
}

/**
 * Check if a given object implements the PayPeriod interface.
 */
export function instanceOfPayPeriod(value: object): value is PayPeriod {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('payCycleId' in value) || value['payCycleId'] === undefined) return false;
    if (!('active' in value) || value['active'] === undefined) return false;
    if (!('startsOn' in value) || value['startsOn'] === undefined) return false;
    if (!('endsOn' in value) || value['endsOn'] === undefined) return false;
    if (!('confirmedAt' in value) || value['confirmedAt'] === undefined) return false;
    return true;
}

export function PayPeriodFromJSON(json: any): PayPeriod {
    return PayPeriodFromJSONTyped(json, false);
}

export function PayPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayPeriod {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'payCycleId': json['payCycleId'],
        'active': json['active'],
        'startsOn': ShiftiePlainDateFromJSON(json['startsOn']),
        'endsOn': ShiftiePlainDateFromJSON(json['endsOn']),
        'confirmedAt': (json['confirmedAt'] == null ? null : new Date(json['confirmedAt'])),
    };
}

  export function PayPeriodToJSON(json: any): PayPeriod {
      return PayPeriodToJSONTyped(json, false);
  }

  export function PayPeriodToJSONTyped(value?: PayPeriod | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'payCycleId': value['payCycleId'],
        'active': value['active'],
        'startsOn': ShiftiePlainDateToJSON(value['startsOn']),
        'endsOn': ShiftiePlainDateToJSON(value['endsOn']),
        'confirmedAt': (value['confirmedAt'] == null ? null : (value['confirmedAt'] as any).toISOString()),
    };
}

