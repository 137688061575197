/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScheduleOverviewSummary } from './ScheduleOverviewSummary';
import {
    ScheduleOverviewSummaryFromJSON,
    ScheduleOverviewSummaryFromJSONTyped,
    ScheduleOverviewSummaryToJSON,
    ScheduleOverviewSummaryToJSONTyped,
} from './ScheduleOverviewSummary';

/**
 * 
 * @export
 * @interface ShowScheduleOverviewResponse
 */
export interface ShowScheduleOverviewResponse {
    /**
     * 
     * @type {ScheduleOverviewSummary}
     * @memberof ShowScheduleOverviewResponse
     */
    data: ScheduleOverviewSummary;
}

/**
 * Check if a given object implements the ShowScheduleOverviewResponse interface.
 */
export function instanceOfShowScheduleOverviewResponse(value: object): value is ShowScheduleOverviewResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowScheduleOverviewResponseFromJSON(json: any): ShowScheduleOverviewResponse {
    return ShowScheduleOverviewResponseFromJSONTyped(json, false);
}

export function ShowScheduleOverviewResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowScheduleOverviewResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ScheduleOverviewSummaryFromJSON(json['data']),
    };
}

  export function ShowScheduleOverviewResponseToJSON(json: any): ShowScheduleOverviewResponse {
      return ShowScheduleOverviewResponseToJSONTyped(json, false);
  }

  export function ShowScheduleOverviewResponseToJSONTyped(value?: ShowScheduleOverviewResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ScheduleOverviewSummaryToJSON(value['data']),
    };
}

