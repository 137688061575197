/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimesheetEntrySummary
 */
export interface TimesheetEntrySummary {
    /**
     * A deterministic identifier for the report.
     * @type {string}
     * @memberof TimesheetEntrySummary
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    employeeId: number;
    /**
     * Which pay period the summary relates to.
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    payPeriodId: number;
    /**
     * Total number of timesheet entries for the period.
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    entryCount: number;
    /**
     * Number of completed entries which have been confirmed.
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    confirmedEntryCount: number;
    /**
     * Number of completed entries which have not been confirmed.
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    unconfirmedEntryCount: number;
    /**
     * Number of entries which have not yet been completed.
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    incompleteEntryCount: number;
    /**
     * Number of missing timesheet entries, based on in-progress / finished shifts for the period.
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    missingEntryCount: number;
    /**
     * Total number of shifts for the period, which have already started.
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    shiftCount: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    expectedWorkingTimeInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    actualTimeWorkedInMinutes: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntrySummary
     */
    totalPaidMinutes: number;
    /**
     * A list of all locationIds related to both timesheets and shifts within the period.
     * @type {Array<number>}
     * @memberof TimesheetEntrySummary
     */
    locationIds: Array<number>;
    /**
     * A list of all jobRoleIds related to both timesheets and shifts within the period.
     * @type {Array<number>}
     * @memberof TimesheetEntrySummary
     */
    jobRoleIds: Array<number>;
}

/**
 * Check if a given object implements the TimesheetEntrySummary interface.
 */
export function instanceOfTimesheetEntrySummary(value: object): value is TimesheetEntrySummary {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('payPeriodId' in value) || value['payPeriodId'] === undefined) return false;
    if (!('entryCount' in value) || value['entryCount'] === undefined) return false;
    if (!('confirmedEntryCount' in value) || value['confirmedEntryCount'] === undefined) return false;
    if (!('unconfirmedEntryCount' in value) || value['unconfirmedEntryCount'] === undefined) return false;
    if (!('incompleteEntryCount' in value) || value['incompleteEntryCount'] === undefined) return false;
    if (!('missingEntryCount' in value) || value['missingEntryCount'] === undefined) return false;
    if (!('shiftCount' in value) || value['shiftCount'] === undefined) return false;
    if (!('expectedWorkingTimeInMinutes' in value) || value['expectedWorkingTimeInMinutes'] === undefined) return false;
    if (!('actualTimeWorkedInMinutes' in value) || value['actualTimeWorkedInMinutes'] === undefined) return false;
    if (!('totalPaidMinutes' in value) || value['totalPaidMinutes'] === undefined) return false;
    if (!('locationIds' in value) || value['locationIds'] === undefined) return false;
    if (!('jobRoleIds' in value) || value['jobRoleIds'] === undefined) return false;
    return true;
}

export function TimesheetEntrySummaryFromJSON(json: any): TimesheetEntrySummary {
    return TimesheetEntrySummaryFromJSONTyped(json, false);
}

export function TimesheetEntrySummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimesheetEntrySummary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'employeeId': json['employeeId'],
        'payPeriodId': json['payPeriodId'],
        'entryCount': json['entryCount'],
        'confirmedEntryCount': json['confirmedEntryCount'],
        'unconfirmedEntryCount': json['unconfirmedEntryCount'],
        'incompleteEntryCount': json['incompleteEntryCount'],
        'missingEntryCount': json['missingEntryCount'],
        'shiftCount': json['shiftCount'],
        'expectedWorkingTimeInMinutes': json['expectedWorkingTimeInMinutes'],
        'actualTimeWorkedInMinutes': json['actualTimeWorkedInMinutes'],
        'totalPaidMinutes': json['totalPaidMinutes'],
        'locationIds': json['locationIds'],
        'jobRoleIds': json['jobRoleIds'],
    };
}

  export function TimesheetEntrySummaryToJSON(json: any): TimesheetEntrySummary {
      return TimesheetEntrySummaryToJSONTyped(json, false);
  }

  export function TimesheetEntrySummaryToJSONTyped(value?: TimesheetEntrySummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'employeeId': value['employeeId'],
        'payPeriodId': value['payPeriodId'],
        'entryCount': value['entryCount'],
        'confirmedEntryCount': value['confirmedEntryCount'],
        'unconfirmedEntryCount': value['unconfirmedEntryCount'],
        'incompleteEntryCount': value['incompleteEntryCount'],
        'missingEntryCount': value['missingEntryCount'],
        'shiftCount': value['shiftCount'],
        'expectedWorkingTimeInMinutes': value['expectedWorkingTimeInMinutes'],
        'actualTimeWorkedInMinutes': value['actualTimeWorkedInMinutes'],
        'totalPaidMinutes': value['totalPaidMinutes'],
        'locationIds': value['locationIds'],
        'jobRoleIds': value['jobRoleIds'],
    };
}

