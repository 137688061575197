/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Shift } from './Shift';
import {
    ShiftFromJSON,
    ShiftFromJSONTyped,
    ShiftToJSON,
    ShiftToJSONTyped,
} from './Shift';

/**
 * 
 * @export
 * @interface ShowShiftResponse
 */
export interface ShowShiftResponse {
    /**
     * 
     * @type {Shift}
     * @memberof ShowShiftResponse
     */
    data: Shift;
}

/**
 * Check if a given object implements the ShowShiftResponse interface.
 */
export function instanceOfShowShiftResponse(value: object): value is ShowShiftResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowShiftResponseFromJSON(json: any): ShowShiftResponse {
    return ShowShiftResponseFromJSONTyped(json, false);
}

export function ShowShiftResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowShiftResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ShiftFromJSON(json['data']),
    };
}

  export function ShowShiftResponseToJSON(json: any): ShowShiftResponse {
      return ShowShiftResponseToJSONTyped(json, false);
  }

  export function ShowShiftResponseToJSONTyped(value?: ShowShiftResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ShiftToJSON(value['data']),
    };
}

