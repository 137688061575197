/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { LeaveAdjustment } from './LeaveAdjustment';
import {
    LeaveAdjustmentFromJSON,
    LeaveAdjustmentFromJSONTyped,
    LeaveAdjustmentToJSON,
    LeaveAdjustmentToJSONTyped,
} from './LeaveAdjustment';

/**
 * 
 * @export
 * @interface ListLeaveAdjustmentsResponse
 */
export interface ListLeaveAdjustmentsResponse {
    /**
     * 
     * @type {Array<LeaveAdjustment>}
     * @memberof ListLeaveAdjustmentsResponse
     */
    data: Array<LeaveAdjustment>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListLeaveAdjustmentsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListLeaveAdjustmentsResponse interface.
 */
export function instanceOfListLeaveAdjustmentsResponse(value: object): value is ListLeaveAdjustmentsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListLeaveAdjustmentsResponseFromJSON(json: any): ListLeaveAdjustmentsResponse {
    return ListLeaveAdjustmentsResponseFromJSONTyped(json, false);
}

export function ListLeaveAdjustmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListLeaveAdjustmentsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(LeaveAdjustmentFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListLeaveAdjustmentsResponseToJSON(json: any): ListLeaveAdjustmentsResponse {
      return ListLeaveAdjustmentsResponseToJSONTyped(json, false);
  }

  export function ListLeaveAdjustmentsResponseToJSONTyped(value?: ListLeaveAdjustmentsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(LeaveAdjustmentToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

