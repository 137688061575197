/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { CacheMeta } from './CacheMeta';
import {
    CacheMetaFromJSON,
    CacheMetaFromJSONTyped,
    CacheMetaToJSON,
    CacheMetaToJSONTyped,
} from './CacheMeta';
import type { DigiticketsBranch } from './DigiticketsBranch';
import {
    DigiticketsBranchFromJSON,
    DigiticketsBranchFromJSONTyped,
    DigiticketsBranchToJSON,
    DigiticketsBranchToJSONTyped,
} from './DigiticketsBranch';

/**
 * 
 * @export
 * @interface ListDigiticketsBranchesResponse
 */
export interface ListDigiticketsBranchesResponse {
    /**
     * 
     * @type {CacheMeta}
     * @memberof ListDigiticketsBranchesResponse
     */
    cache: CacheMeta;
    /**
     * 
     * @type {Array<DigiticketsBranch>}
     * @memberof ListDigiticketsBranchesResponse
     */
    data: Array<DigiticketsBranch>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListDigiticketsBranchesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListDigiticketsBranchesResponse interface.
 */
export function instanceOfListDigiticketsBranchesResponse(value: object): value is ListDigiticketsBranchesResponse {
    if (!('cache' in value) || value['cache'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListDigiticketsBranchesResponseFromJSON(json: any): ListDigiticketsBranchesResponse {
    return ListDigiticketsBranchesResponseFromJSONTyped(json, false);
}

export function ListDigiticketsBranchesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDigiticketsBranchesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'cache': CacheMetaFromJSON(json['cache']),
        'data': ((json['data'] as Array<any>).map(DigiticketsBranchFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListDigiticketsBranchesResponseToJSON(json: any): ListDigiticketsBranchesResponse {
      return ListDigiticketsBranchesResponseToJSONTyped(json, false);
  }

  export function ListDigiticketsBranchesResponseToJSONTyped(value?: ListDigiticketsBranchesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cache': CacheMetaToJSON(value['cache']),
        'data': ((value['data'] as Array<any>).map(DigiticketsBranchToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

