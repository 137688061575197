/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents the billing contact details for a company
 * @export
 * @interface UpdateBillingDetailsData
 */
export interface UpdateBillingDetailsData {
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingDetailsData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingDetailsData
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingDetailsData
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingDetailsData
     */
    address1?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateBillingDetailsData
     */
    address2?: string | null;
    /**
     * Represents the postal code, any format e.g. EX2 5AZ or 90210
     * @type {string}
     * @memberof UpdateBillingDetailsData
     */
    postalCode?: string;
}

/**
 * Check if a given object implements the UpdateBillingDetailsData interface.
 */
export function instanceOfUpdateBillingDetailsData(value: object): value is UpdateBillingDetailsData {
    return true;
}

export function UpdateBillingDetailsDataFromJSON(json: any): UpdateBillingDetailsData {
    return UpdateBillingDetailsDataFromJSONTyped(json, false);
}

export function UpdateBillingDetailsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBillingDetailsData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'countryCode': json['countryCode'] == null ? undefined : json['countryCode'],
        'city': json['city'] == null ? undefined : json['city'],
        'address1': json['address1'] == null ? undefined : json['address1'],
        'address2': json['address2'] == null ? undefined : json['address2'],
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
    };
}

  export function UpdateBillingDetailsDataToJSON(json: any): UpdateBillingDetailsData {
      return UpdateBillingDetailsDataToJSONTyped(json, false);
  }

  export function UpdateBillingDetailsDataToJSONTyped(value?: UpdateBillingDetailsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'countryCode': value['countryCode'],
        'city': value['city'],
        'address1': value['address1'],
        'address2': value['address2'],
        'postalCode': value['postalCode'],
    };
}

