/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanySetting } from './CompanySetting';
import {
    CompanySettingFromJSON,
    CompanySettingFromJSONTyped,
    CompanySettingToJSON,
    CompanySettingToJSONTyped,
} from './CompanySetting';

/**
 * 
 * @export
 * @interface UpdateCompanySettingsResponse
 */
export interface UpdateCompanySettingsResponse {
    /**
     * 
     * @type {CompanySetting}
     * @memberof UpdateCompanySettingsResponse
     */
    data: CompanySetting;
}

/**
 * Check if a given object implements the UpdateCompanySettingsResponse interface.
 */
export function instanceOfUpdateCompanySettingsResponse(value: object): value is UpdateCompanySettingsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateCompanySettingsResponseFromJSON(json: any): UpdateCompanySettingsResponse {
    return UpdateCompanySettingsResponseFromJSONTyped(json, false);
}

export function UpdateCompanySettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanySettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CompanySettingFromJSON(json['data']),
    };
}

  export function UpdateCompanySettingsResponseToJSON(json: any): UpdateCompanySettingsResponse {
      return UpdateCompanySettingsResponseToJSONTyped(json, false);
  }

  export function UpdateCompanySettingsResponseToJSONTyped(value?: UpdateCompanySettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': CompanySettingToJSON(value['data']),
    };
}

