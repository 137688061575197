/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { TimesheetEntry } from './TimesheetEntry';
import {
    TimesheetEntryFromJSON,
    TimesheetEntryFromJSONTyped,
    TimesheetEntryToJSON,
    TimesheetEntryToJSONTyped,
} from './TimesheetEntry';

/**
 * 
 * @export
 * @interface SearchTimesheetEntriesResponse
 */
export interface SearchTimesheetEntriesResponse {
    /**
     * 
     * @type {Array<TimesheetEntry>}
     * @memberof SearchTimesheetEntriesResponse
     */
    data: Array<TimesheetEntry>;
    /**
     * 
     * @type {Pagination}
     * @memberof SearchTimesheetEntriesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the SearchTimesheetEntriesResponse interface.
 */
export function instanceOfSearchTimesheetEntriesResponse(value: object): value is SearchTimesheetEntriesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function SearchTimesheetEntriesResponseFromJSON(json: any): SearchTimesheetEntriesResponse {
    return SearchTimesheetEntriesResponseFromJSONTyped(json, false);
}

export function SearchTimesheetEntriesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchTimesheetEntriesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(TimesheetEntryFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function SearchTimesheetEntriesResponseToJSON(json: any): SearchTimesheetEntriesResponse {
      return SearchTimesheetEntriesResponseToJSONTyped(json, false);
  }

  export function SearchTimesheetEntriesResponseToJSONTyped(value?: SearchTimesheetEntriesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(TimesheetEntryToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

