/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Update meta data for an employees job role
 * @export
 * @interface UpdateEmployeesJobRoleData
 */
export interface UpdateEmployeesJobRoleData {
    /**
     * Amount the employee should be paid when performing this job role
     * @type {number}
     * @memberof UpdateEmployeesJobRoleData
     */
    rate?: number | null;
}

/**
 * Check if a given object implements the UpdateEmployeesJobRoleData interface.
 */
export function instanceOfUpdateEmployeesJobRoleData(value: object): value is UpdateEmployeesJobRoleData {
    return true;
}

export function UpdateEmployeesJobRoleDataFromJSON(json: any): UpdateEmployeesJobRoleData {
    return UpdateEmployeesJobRoleDataFromJSONTyped(json, false);
}

export function UpdateEmployeesJobRoleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeesJobRoleData {
    if (json == null) {
        return json;
    }
    return {
        
        'rate': json['rate'] == null ? undefined : json['rate'],
    };
}

  export function UpdateEmployeesJobRoleDataToJSON(json: any): UpdateEmployeesJobRoleData {
      return UpdateEmployeesJobRoleDataToJSONTyped(json, false);
  }

  export function UpdateEmployeesJobRoleDataToJSONTyped(value?: UpdateEmployeesJobRoleData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'rate': value['rate'],
    };
}

