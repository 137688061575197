/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface TimesheetEntryExport
 */
export interface TimesheetEntryExport {
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntryExport
     */
    employeeId: number;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    payrollId?: string;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    employmentType: TimesheetEntryExportEmploymentTypeEnum;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof TimesheetEntryExport
     */
    date: ShiftiePlainDate;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    shiftStartTime: string;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    shiftEndTime: string;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    clockInTime: string;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    clockOutTime: string;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntryExport
     */
    allocatedPaidBreakInHours: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntryExport
     */
    allocatedUnpaidBreakInHours: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntryExport
     */
    actualPaidBreakInHours: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntryExport
     */
    actualUnpaidBreakInHours: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntryExport
     */
    actualTimeWorkedInHours: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntryExport
     */
    expectedWorkingTimeInHours: number;
    /**
     * 
     * @type {number}
     * @memberof TimesheetEntryExport
     */
    overtimeInHours: number;
    /**
     * 
     * @type {boolean}
     * @memberof TimesheetEntryExport
     */
    exemptFromOvertime: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    locationName: string;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    jobRoleName: string;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    tags?: string;
    /**
     * 
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    notes: string | null;
    /**
     * Name of the employee who confirmed the timesheet.
     * @type {string}
     * @memberof TimesheetEntryExport
     */
    confirmedBy: string | null;
    /**
     * 
     * @type {Date}
     * @memberof TimesheetEntryExport
     */
    confirmedAt: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum TimesheetEntryExportEmploymentTypeEnum {
    Agency = 'Agency',
    AnnualisedContract = 'Annualised Contract',
    ApprenticeTrainee = 'Apprentice / Trainee',
    Casual = 'Casual',
    FixedTerm = 'Fixed Term',
    FullTime = 'Full Time',
    PartTime = 'Part Time',
    SelfEmployed = 'Self-Employed',
    Temporary = 'Temporary',
    Volunteer = 'Volunteer'
}


/**
 * Check if a given object implements the TimesheetEntryExport interface.
 */
export function instanceOfTimesheetEntryExport(value: object): value is TimesheetEntryExport {
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('employmentType' in value) || value['employmentType'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('shiftStartTime' in value) || value['shiftStartTime'] === undefined) return false;
    if (!('shiftEndTime' in value) || value['shiftEndTime'] === undefined) return false;
    if (!('clockInTime' in value) || value['clockInTime'] === undefined) return false;
    if (!('clockOutTime' in value) || value['clockOutTime'] === undefined) return false;
    if (!('allocatedPaidBreakInHours' in value) || value['allocatedPaidBreakInHours'] === undefined) return false;
    if (!('allocatedUnpaidBreakInHours' in value) || value['allocatedUnpaidBreakInHours'] === undefined) return false;
    if (!('actualPaidBreakInHours' in value) || value['actualPaidBreakInHours'] === undefined) return false;
    if (!('actualUnpaidBreakInHours' in value) || value['actualUnpaidBreakInHours'] === undefined) return false;
    if (!('actualTimeWorkedInHours' in value) || value['actualTimeWorkedInHours'] === undefined) return false;
    if (!('expectedWorkingTimeInHours' in value) || value['expectedWorkingTimeInHours'] === undefined) return false;
    if (!('overtimeInHours' in value) || value['overtimeInHours'] === undefined) return false;
    if (!('exemptFromOvertime' in value) || value['exemptFromOvertime'] === undefined) return false;
    if (!('locationName' in value) || value['locationName'] === undefined) return false;
    if (!('jobRoleName' in value) || value['jobRoleName'] === undefined) return false;
    if (!('notes' in value) || value['notes'] === undefined) return false;
    if (!('confirmedBy' in value) || value['confirmedBy'] === undefined) return false;
    if (!('confirmedAt' in value) || value['confirmedAt'] === undefined) return false;
    return true;
}

export function TimesheetEntryExportFromJSON(json: any): TimesheetEntryExport {
    return TimesheetEntryExportFromJSONTyped(json, false);
}

export function TimesheetEntryExportFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimesheetEntryExport {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'payrollId': json['payrollId'] == null ? undefined : json['payrollId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'employmentType': json['employmentType'],
        'date': ShiftiePlainDateFromJSON(json['date']),
        'shiftStartTime': json['shiftStartTime'],
        'shiftEndTime': json['shiftEndTime'],
        'clockInTime': json['clockInTime'],
        'clockOutTime': json['clockOutTime'],
        'allocatedPaidBreakInHours': json['allocatedPaidBreakInHours'],
        'allocatedUnpaidBreakInHours': json['allocatedUnpaidBreakInHours'],
        'actualPaidBreakInHours': json['actualPaidBreakInHours'],
        'actualUnpaidBreakInHours': json['actualUnpaidBreakInHours'],
        'actualTimeWorkedInHours': json['actualTimeWorkedInHours'],
        'expectedWorkingTimeInHours': json['expectedWorkingTimeInHours'],
        'overtimeInHours': json['overtimeInHours'],
        'exemptFromOvertime': json['exemptFromOvertime'],
        'locationName': json['locationName'],
        'jobRoleName': json['jobRoleName'],
        'tags': json['tags'] == null ? undefined : json['tags'],
        'notes': json['notes'],
        'confirmedBy': json['confirmedBy'],
        'confirmedAt': (json['confirmedAt'] == null ? null : new Date(json['confirmedAt'])),
    };
}

  export function TimesheetEntryExportToJSON(json: any): TimesheetEntryExport {
      return TimesheetEntryExportToJSONTyped(json, false);
  }

  export function TimesheetEntryExportToJSONTyped(value?: TimesheetEntryExport | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'payrollId': value['payrollId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'employmentType': value['employmentType'],
        'date': ShiftiePlainDateToJSON(value['date']),
        'shiftStartTime': value['shiftStartTime'],
        'shiftEndTime': value['shiftEndTime'],
        'clockInTime': value['clockInTime'],
        'clockOutTime': value['clockOutTime'],
        'allocatedPaidBreakInHours': value['allocatedPaidBreakInHours'],
        'allocatedUnpaidBreakInHours': value['allocatedUnpaidBreakInHours'],
        'actualPaidBreakInHours': value['actualPaidBreakInHours'],
        'actualUnpaidBreakInHours': value['actualUnpaidBreakInHours'],
        'actualTimeWorkedInHours': value['actualTimeWorkedInHours'],
        'expectedWorkingTimeInHours': value['expectedWorkingTimeInHours'],
        'overtimeInHours': value['overtimeInHours'],
        'exemptFromOvertime': value['exemptFromOvertime'],
        'locationName': value['locationName'],
        'jobRoleName': value['jobRoleName'],
        'tags': value['tags'],
        'notes': value['notes'],
        'confirmedBy': value['confirmedBy'],
        'confirmedAt': (value['confirmedAt'] == null ? null : (value['confirmedAt'] as any).toISOString()),
    };
}

