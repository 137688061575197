/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * Create a Work Pattern Override for an employee.
 * @export
 * @interface CreateWorkPatternOverrideData
 */
export interface CreateWorkPatternOverrideData {
    /**
     * 
     * @type {number}
     * @memberof CreateWorkPatternOverrideData
     */
    employeeId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateWorkPatternOverrideData
     */
    date: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof CreateWorkPatternOverrideData
     */
    isWorkingDay?: boolean;
}

/**
 * Check if a given object implements the CreateWorkPatternOverrideData interface.
 */
export function instanceOfCreateWorkPatternOverrideData(value: object): value is CreateWorkPatternOverrideData {
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    return true;
}

export function CreateWorkPatternOverrideDataFromJSON(json: any): CreateWorkPatternOverrideData {
    return CreateWorkPatternOverrideDataFromJSONTyped(json, false);
}

export function CreateWorkPatternOverrideDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWorkPatternOverrideData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'date': ShiftiePlainDateFromJSON(json['date']),
        'isWorkingDay': json['isWorkingDay'] == null ? undefined : json['isWorkingDay'],
    };
}

  export function CreateWorkPatternOverrideDataToJSON(json: any): CreateWorkPatternOverrideData {
      return CreateWorkPatternOverrideDataToJSONTyped(json, false);
  }

  export function CreateWorkPatternOverrideDataToJSONTyped(value?: CreateWorkPatternOverrideData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'date': ShiftiePlainDateToJSON(value['date']),
        'isWorkingDay': value['isWorkingDay'],
    };
}

