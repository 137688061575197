/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PayPeriod } from './PayPeriod';
import {
    PayPeriodFromJSON,
    PayPeriodFromJSONTyped,
    PayPeriodToJSON,
    PayPeriodToJSONTyped,
} from './PayPeriod';

/**
 * 
 * @export
 * @interface ShowPayPeriodResponse
 */
export interface ShowPayPeriodResponse {
    /**
     * 
     * @type {PayPeriod}
     * @memberof ShowPayPeriodResponse
     */
    data: PayPeriod;
}

/**
 * Check if a given object implements the ShowPayPeriodResponse interface.
 */
export function instanceOfShowPayPeriodResponse(value: object): value is ShowPayPeriodResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowPayPeriodResponseFromJSON(json: any): ShowPayPeriodResponse {
    return ShowPayPeriodResponseFromJSONTyped(json, false);
}

export function ShowPayPeriodResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowPayPeriodResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': PayPeriodFromJSON(json['data']),
    };
}

  export function ShowPayPeriodResponseToJSON(json: any): ShowPayPeriodResponse {
      return ShowPayPeriodResponseToJSONTyped(json, false);
  }

  export function ShowPayPeriodResponseToJSONTyped(value?: ShowPayPeriodResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': PayPeriodToJSON(value['data']),
    };
}

