/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateShiftTemplateAreaSessionData } from './CreateShiftTemplateAreaSessionData';
import {
    CreateShiftTemplateAreaSessionDataFromJSON,
    CreateShiftTemplateAreaSessionDataFromJSONTyped,
    CreateShiftTemplateAreaSessionDataToJSON,
    CreateShiftTemplateAreaSessionDataToJSONTyped,
} from './CreateShiftTemplateAreaSessionData';
import type { CreateShiftTemplateBreakData } from './CreateShiftTemplateBreakData';
import {
    CreateShiftTemplateBreakDataFromJSON,
    CreateShiftTemplateBreakDataFromJSONTyped,
    CreateShiftTemplateBreakDataToJSON,
    CreateShiftTemplateBreakDataToJSONTyped,
} from './CreateShiftTemplateBreakData';

/**
 * 
 * @export
 * @interface UpdateShiftTemplateData
 */
export interface UpdateShiftTemplateData {
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftTemplateData
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftTemplateData
     */
    locationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftTemplateData
     */
    jobRoleId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftTemplateData
     */
    startHour?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftTemplateData
     */
    startMinute?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftTemplateData
     */
    endHour?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateShiftTemplateData
     */
    endMinute?: number;
    /**
     * Whether to show the shift end time to non-managerial staff
     * @type {boolean}
     * @memberof UpdateShiftTemplateData
     */
    showEndTime?: boolean;
    /**
     * Whether to show the job role to non-managerial staff
     * @type {boolean}
     * @memberof UpdateShiftTemplateData
     */
    showJobRole?: boolean;
    /**
     * 
     * @type {Array<CreateShiftTemplateBreakData>}
     * @memberof UpdateShiftTemplateData
     */
    shiftTemplateBreaks?: Array<CreateShiftTemplateBreakData>;
    /**
     * 
     * @type {Array<CreateShiftTemplateAreaSessionData>}
     * @memberof UpdateShiftTemplateData
     */
    shiftTemplateAreaSessions?: Array<CreateShiftTemplateAreaSessionData>;
    /**
     * 
     * @type {string}
     * @memberof UpdateShiftTemplateData
     */
    notes?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateShiftTemplateData
     */
    tags?: Array<number> | null;
}

/**
 * Check if a given object implements the UpdateShiftTemplateData interface.
 */
export function instanceOfUpdateShiftTemplateData(value: object): value is UpdateShiftTemplateData {
    return true;
}

export function UpdateShiftTemplateDataFromJSON(json: any): UpdateShiftTemplateData {
    return UpdateShiftTemplateDataFromJSONTyped(json, false);
}

export function UpdateShiftTemplateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateShiftTemplateData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
        'jobRoleId': json['jobRoleId'] == null ? undefined : json['jobRoleId'],
        'startHour': json['startHour'] == null ? undefined : json['startHour'],
        'startMinute': json['startMinute'] == null ? undefined : json['startMinute'],
        'endHour': json['endHour'] == null ? undefined : json['endHour'],
        'endMinute': json['endMinute'] == null ? undefined : json['endMinute'],
        'showEndTime': json['showEndTime'] == null ? undefined : json['showEndTime'],
        'showJobRole': json['showJobRole'] == null ? undefined : json['showJobRole'],
        'shiftTemplateBreaks': json['shiftTemplateBreaks'] == null ? undefined : ((json['shiftTemplateBreaks'] as Array<any>).map(CreateShiftTemplateBreakDataFromJSON)),
        'shiftTemplateAreaSessions': json['shiftTemplateAreaSessions'] == null ? undefined : ((json['shiftTemplateAreaSessions'] as Array<any>).map(CreateShiftTemplateAreaSessionDataFromJSON)),
        'notes': json['notes'] == null ? undefined : json['notes'],
        'tags': json['tags'] == null ? undefined : json['tags'],
    };
}

  export function UpdateShiftTemplateDataToJSON(json: any): UpdateShiftTemplateData {
      return UpdateShiftTemplateDataToJSONTyped(json, false);
  }

  export function UpdateShiftTemplateDataToJSONTyped(value?: UpdateShiftTemplateData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'locationId': value['locationId'],
        'jobRoleId': value['jobRoleId'],
        'startHour': value['startHour'],
        'startMinute': value['startMinute'],
        'endHour': value['endHour'],
        'endMinute': value['endMinute'],
        'showEndTime': value['showEndTime'],
        'showJobRole': value['showJobRole'],
        'shiftTemplateBreaks': value['shiftTemplateBreaks'] == null ? undefined : ((value['shiftTemplateBreaks'] as Array<any>).map(CreateShiftTemplateBreakDataToJSON)),
        'shiftTemplateAreaSessions': value['shiftTemplateAreaSessions'] == null ? undefined : ((value['shiftTemplateAreaSessions'] as Array<any>).map(CreateShiftTemplateAreaSessionDataToJSON)),
        'notes': value['notes'],
        'tags': value['tags'],
    };
}

