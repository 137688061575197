/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyOnboarding } from './CompanyOnboarding';
import {
    CompanyOnboardingFromJSON,
    CompanyOnboardingFromJSONTyped,
    CompanyOnboardingToJSON,
    CompanyOnboardingToJSONTyped,
} from './CompanyOnboarding';

/**
 * 
 * @export
 * @interface UpdateCompanyOnboardingResponse
 */
export interface UpdateCompanyOnboardingResponse {
    /**
     * 
     * @type {CompanyOnboarding}
     * @memberof UpdateCompanyOnboardingResponse
     */
    data: CompanyOnboarding;
}

/**
 * Check if a given object implements the UpdateCompanyOnboardingResponse interface.
 */
export function instanceOfUpdateCompanyOnboardingResponse(value: object): value is UpdateCompanyOnboardingResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateCompanyOnboardingResponseFromJSON(json: any): UpdateCompanyOnboardingResponse {
    return UpdateCompanyOnboardingResponseFromJSONTyped(json, false);
}

export function UpdateCompanyOnboardingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanyOnboardingResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CompanyOnboardingFromJSON(json['data']),
    };
}

  export function UpdateCompanyOnboardingResponseToJSON(json: any): UpdateCompanyOnboardingResponse {
      return UpdateCompanyOnboardingResponseToJSONTyped(json, false);
  }

  export function UpdateCompanyOnboardingResponseToJSONTyped(value?: UpdateCompanyOnboardingResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': CompanyOnboardingToJSON(value['data']),
    };
}

