/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployeeMessageStats
 */
export interface EmployeeMessageStats {
    /**
     * How many employee messages have been sent today.
     * @type {number}
     * @memberof EmployeeMessageStats
     */
    rollingSendCount: number;
    /**
     * Whether the configured daily send limit has been reached.
     * @type {boolean}
     * @memberof EmployeeMessageStats
     */
    sendLimitReached: boolean;
}

/**
 * Check if a given object implements the EmployeeMessageStats interface.
 */
export function instanceOfEmployeeMessageStats(value: object): value is EmployeeMessageStats {
    if (!('rollingSendCount' in value) || value['rollingSendCount'] === undefined) return false;
    if (!('sendLimitReached' in value) || value['sendLimitReached'] === undefined) return false;
    return true;
}

export function EmployeeMessageStatsFromJSON(json: any): EmployeeMessageStats {
    return EmployeeMessageStatsFromJSONTyped(json, false);
}

export function EmployeeMessageStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeMessageStats {
    if (json == null) {
        return json;
    }
    return {
        
        'rollingSendCount': json['rollingSendCount'],
        'sendLimitReached': json['sendLimitReached'],
    };
}

  export function EmployeeMessageStatsToJSON(json: any): EmployeeMessageStats {
      return EmployeeMessageStatsToJSONTyped(json, false);
  }

  export function EmployeeMessageStatsToJSONTyped(value?: EmployeeMessageStats | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'rollingSendCount': value['rollingSendCount'],
        'sendLimitReached': value['sendLimitReached'],
    };
}

