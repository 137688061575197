/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a summary of the current clock-in statuses, showing count of break, late, in, finished and missing employees.
 * @export
 * @interface ClockedInSummary
 */
export interface ClockedInSummary {
    /**
     * 
     * @type {number}
     * @memberof ClockedInSummary
     */
    onBreak: number;
    /**
     * 
     * @type {number}
     * @memberof ClockedInSummary
     */
    clockedIn: number;
    /**
     * 
     * @type {number}
     * @memberof ClockedInSummary
     */
    late: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClockedInSummary
     */
    finished: number;
    /**
     * 
     * @type {number}
     * @memberof ClockedInSummary
     */
    missing: number;
}

/**
 * Check if a given object implements the ClockedInSummary interface.
 */
export function instanceOfClockedInSummary(value: object): value is ClockedInSummary {
    if (!('onBreak' in value) || value['onBreak'] === undefined) return false;
    if (!('clockedIn' in value) || value['clockedIn'] === undefined) return false;
    if (!('late' in value) || value['late'] === undefined) return false;
    if (!('finished' in value) || value['finished'] === undefined) return false;
    if (!('missing' in value) || value['missing'] === undefined) return false;
    return true;
}

export function ClockedInSummaryFromJSON(json: any): ClockedInSummary {
    return ClockedInSummaryFromJSONTyped(json, false);
}

export function ClockedInSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockedInSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'onBreak': json['onBreak'],
        'clockedIn': json['clockedIn'],
        'late': json['late'],
        'finished': json['finished'],
        'missing': json['missing'],
    };
}

  export function ClockedInSummaryToJSON(json: any): ClockedInSummary {
      return ClockedInSummaryToJSONTyped(json, false);
  }

  export function ClockedInSummaryToJSONTyped(value?: ClockedInSummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'onBreak': value['onBreak'],
        'clockedIn': value['clockedIn'],
        'late': value['late'],
        'finished': value['finished'],
        'missing': value['missing'],
    };
}

