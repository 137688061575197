/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PayPeriodsSummary } from './PayPeriodsSummary';
import {
    PayPeriodsSummaryFromJSON,
    PayPeriodsSummaryFromJSONTyped,
    PayPeriodsSummaryToJSON,
    PayPeriodsSummaryToJSONTyped,
} from './PayPeriodsSummary';

/**
 * 
 * @export
 * @interface ShowPayPeriodsSummaryResponse
 */
export interface ShowPayPeriodsSummaryResponse {
    /**
     * 
     * @type {PayPeriodsSummary}
     * @memberof ShowPayPeriodsSummaryResponse
     */
    data: PayPeriodsSummary;
}

/**
 * Check if a given object implements the ShowPayPeriodsSummaryResponse interface.
 */
export function instanceOfShowPayPeriodsSummaryResponse(value: object): value is ShowPayPeriodsSummaryResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowPayPeriodsSummaryResponseFromJSON(json: any): ShowPayPeriodsSummaryResponse {
    return ShowPayPeriodsSummaryResponseFromJSONTyped(json, false);
}

export function ShowPayPeriodsSummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowPayPeriodsSummaryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': PayPeriodsSummaryFromJSON(json['data']),
    };
}

  export function ShowPayPeriodsSummaryResponseToJSON(json: any): ShowPayPeriodsSummaryResponse {
      return ShowPayPeriodsSummaryResponseToJSONTyped(json, false);
  }

  export function ShowPayPeriodsSummaryResponseToJSONTyped(value?: ShowPayPeriodsSummaryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': PayPeriodsSummaryToJSON(value['data']),
    };
}

