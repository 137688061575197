/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PendingEmail } from './PendingEmail';
import {
    PendingEmailFromJSON,
    PendingEmailFromJSONTyped,
    PendingEmailToJSON,
    PendingEmailToJSONTyped,
} from './PendingEmail';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    emailVerifiedAt: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    cookiesAccepted: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    cookiesReviewedAt: Date | null;
    /**
     * A flag to control whether the user has access to the developer settings. This is purely used for UI purposes.
     * @type {boolean}
     * @memberof User
     */
    developerSettings: boolean;
    /**
     * 
     * @type {PendingEmail}
     * @memberof User
     */
    pendingEmail: PendingEmail;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt: Date | null;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): value is User {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('emailVerifiedAt' in value) || value['emailVerifiedAt'] === undefined) return false;
    if (!('cookiesAccepted' in value) || value['cookiesAccepted'] === undefined) return false;
    if (!('cookiesReviewedAt' in value) || value['cookiesReviewedAt'] === undefined) return false;
    if (!('developerSettings' in value) || value['developerSettings'] === undefined) return false;
    if (!('pendingEmail' in value) || value['pendingEmail'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'emailVerifiedAt': (json['emailVerifiedAt'] == null ? null : new Date(json['emailVerifiedAt'])),
        'cookiesAccepted': json['cookiesAccepted'],
        'cookiesReviewedAt': (json['cookiesReviewedAt'] == null ? null : new Date(json['cookiesReviewedAt'])),
        'developerSettings': json['developerSettings'],
        'pendingEmail': PendingEmailFromJSON(json['pendingEmail']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

  export function UserToJSON(json: any): User {
      return UserToJSONTyped(json, false);
  }

  export function UserToJSONTyped(value?: User | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'emailVerifiedAt': (value['emailVerifiedAt'] == null ? null : (value['emailVerifiedAt'] as any).toISOString()),
        'cookiesAccepted': value['cookiesAccepted'],
        'cookiesReviewedAt': (value['cookiesReviewedAt'] == null ? null : (value['cookiesReviewedAt'] as any).toISOString()),
        'developerSettings': value['developerSettings'],
        'pendingEmail': PendingEmailToJSON(value['pendingEmail']),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

