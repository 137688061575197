/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateUploadData
 */
export interface CreateUploadData {
    /**
     * 
     * @type {string}
     * @memberof CreateUploadData
     */
    purpose: CreateUploadDataPurposeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUploadData
     */
    originalFilename: string;
}

/**
* @export
* @enum {string}
*/
export enum CreateUploadDataPurposeEnum {
    Calendar = 'Calendar',
    CompanyLogo = 'CompanyLogo',
    Document = 'Document',
    EmployeeImport = 'EmployeeImport',
    EmployeePhoto = 'EmployeePhoto',
    Invoices = 'Invoices'
}


/**
 * Check if a given object implements the CreateUploadData interface.
 */
export function instanceOfCreateUploadData(value: object): value is CreateUploadData {
    if (!('purpose' in value) || value['purpose'] === undefined) return false;
    if (!('originalFilename' in value) || value['originalFilename'] === undefined) return false;
    return true;
}

export function CreateUploadDataFromJSON(json: any): CreateUploadData {
    return CreateUploadDataFromJSONTyped(json, false);
}

export function CreateUploadDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUploadData {
    if (json == null) {
        return json;
    }
    return {
        
        'purpose': json['purpose'],
        'originalFilename': json['originalFilename'],
    };
}

  export function CreateUploadDataToJSON(json: any): CreateUploadData {
      return CreateUploadDataToJSONTyped(json, false);
  }

  export function CreateUploadDataToJSONTyped(value?: CreateUploadData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'purpose': value['purpose'],
        'originalFilename': value['originalFilename'],
    };
}

