/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateScheduleData
 */
export interface CreateScheduleData {
    /**
     * 
     * @type {number}
     * @memberof CreateScheduleData
     */
    defaultLocationId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduleData
     */
    name: string;
}

/**
 * Check if a given object implements the CreateScheduleData interface.
 */
export function instanceOfCreateScheduleData(value: object): value is CreateScheduleData {
    if (!('defaultLocationId' in value) || value['defaultLocationId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function CreateScheduleDataFromJSON(json: any): CreateScheduleData {
    return CreateScheduleDataFromJSONTyped(json, false);
}

export function CreateScheduleDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateScheduleData {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultLocationId': json['defaultLocationId'],
        'name': json['name'],
    };
}

  export function CreateScheduleDataToJSON(json: any): CreateScheduleData {
      return CreateScheduleDataToJSONTyped(json, false);
  }

  export function CreateScheduleDataToJSONTyped(value?: CreateScheduleData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultLocationId': value['defaultLocationId'],
        'name': value['name'],
    };
}

