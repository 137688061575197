/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';

/**
 * 
 * @export
 * @interface CreateJobRoleResponse
 */
export interface CreateJobRoleResponse {
    /**
     * 
     * @type {JobRole}
     * @memberof CreateJobRoleResponse
     */
    data: JobRole;
}

/**
 * Check if a given object implements the CreateJobRoleResponse interface.
 */
export function instanceOfCreateJobRoleResponse(value: object): value is CreateJobRoleResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateJobRoleResponseFromJSON(json: any): CreateJobRoleResponse {
    return CreateJobRoleResponseFromJSONTyped(json, false);
}

export function CreateJobRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateJobRoleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': JobRoleFromJSON(json['data']),
    };
}

  export function CreateJobRoleResponseToJSON(json: any): CreateJobRoleResponse {
      return CreateJobRoleResponseToJSONTyped(json, false);
  }

  export function CreateJobRoleResponseToJSONTyped(value?: CreateJobRoleResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': JobRoleToJSON(value['data']),
    };
}

