/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListAdditionalScopesResponse
 */
export interface ListAdditionalScopesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListAdditionalScopesResponse
     */
    data: Array<ListAdditionalScopesResponseDataEnum>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListAdditionalScopesResponse
     */
    pagination: Pagination;
}

/**
* @export
* @enum {string}
*/
export enum ListAdditionalScopesResponseDataEnum {
    ApiV1AdditionalNotConfigured = 'api.v1.additional.not-configured'
}


/**
 * Check if a given object implements the ListAdditionalScopesResponse interface.
 */
export function instanceOfListAdditionalScopesResponse(value: object): value is ListAdditionalScopesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListAdditionalScopesResponseFromJSON(json: any): ListAdditionalScopesResponse {
    return ListAdditionalScopesResponseFromJSONTyped(json, false);
}

export function ListAdditionalScopesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAdditionalScopesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListAdditionalScopesResponseToJSON(json: any): ListAdditionalScopesResponse {
      return ListAdditionalScopesResponseToJSONTyped(json, false);
  }

  export function ListAdditionalScopesResponseToJSONTyped(value?: ListAdditionalScopesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': value['data'],
        'pagination': PaginationToJSON(value['pagination']),
    };
}

