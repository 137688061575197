/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ListEmployeesJobRolesResponse,
  ShowEmployeesJobRoleResponse,
  UpdateEmployeesJobRoleData,
  UpdateEmployeesJobRoleResponse,
} from '../models/index';
import {
    ListEmployeesJobRolesResponseFromJSON,
    ListEmployeesJobRolesResponseToJSON,
    ShowEmployeesJobRoleResponseFromJSON,
    ShowEmployeesJobRoleResponseToJSON,
    UpdateEmployeesJobRoleDataFromJSON,
    UpdateEmployeesJobRoleDataToJSON,
    UpdateEmployeesJobRoleResponseFromJSON,
    UpdateEmployeesJobRoleResponseToJSON,
} from '../models/index';

export interface ListEmployeesJobRolesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowEmployeesJobRoleRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateEmployeesJobRoleRequest {
    id: number;
    updateEmployeesJobRoleData?: UpdateEmployeesJobRoleData;
}

/**
 * 
 */
export class EmployeesJobRoleApi extends runtime.BaseAPI {

    /**
     * List all employee-job role associations
     * List Employee Job Roles
     */
    async listEmployeesJobRolesRaw(requestParameters: ListEmployeesJobRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeesJobRolesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-job-role.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-job-roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeesJobRolesResponseFromJSON(jsonValue));
    }

    /**
     * List all employee-job role associations
     * List Employee Job Roles
     */
    async listEmployeesJobRoles(requestParameters: ListEmployeesJobRolesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeesJobRolesResponse> {
        const response = await this.listEmployeesJobRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details of an employee\'s job role
     * Show Employee Job Role
     */
    async showEmployeesJobRoleRaw(requestParameters: ShowEmployeesJobRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEmployeesJobRoleResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showEmployeesJobRole().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-job-role.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-job-roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEmployeesJobRoleResponseFromJSON(jsonValue));
    }

    /**
     * Show details of an employee\'s job role
     * Show Employee Job Role
     */
    async showEmployeesJobRole(requestParameters: ShowEmployeesJobRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEmployeesJobRoleResponse> {
        const response = await this.showEmployeesJobRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the details of an employee\'s job role
     * Update Employee Job Role
     */
    async updateEmployeesJobRoleRaw(requestParameters: UpdateEmployeesJobRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateEmployeesJobRoleResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateEmployeesJobRole().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.employee-job-role.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employee-job-roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployeesJobRoleDataToJSON(requestParameters['updateEmployeesJobRoleData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateEmployeesJobRoleResponseFromJSON(jsonValue));
    }

    /**
     * Update the details of an employee\'s job role
     * Update Employee Job Role
     */
    async updateEmployeesJobRole(requestParameters: UpdateEmployeesJobRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateEmployeesJobRoleResponse> {
        const response = await this.updateEmployeesJobRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
