<template>
  <SlidePanel
    :value="value"
    side="left"
    @input="close"
  >
    <div
      slot="body"
      class="all:bg-gray-50 h-full w-full relative"
    >
      <div class="all:p-4">
        <i
          :class="Icon.Xmark"
          class="w-5 h-5 all:flex justify-center align-center text-gray-400 text-14 ml-auto cursor-pointer"
          @click="close"
        />
      </div>

      <nav class="all:p-4 all:flex flex-col gap-3">
        <ul class="pl-2">
          <li class="text-gray-500 text-14 mb-3">
            {{ $t('nav.helpSupport') }}
          </li>
          <li
            v-for="item in supportItems"
            :key="item.id"
            class="relative nav-item text-gray-700 text-16 all:px-2 py-1 my-1 all:flex gap-4 align-center cursor-pointer hover:bg-purple-300 hover:text-white rounded"
            :class="{
              'bg-purple-300 text-white pointer-events-none':
                item.to && activeRoute(item.to),
            }"
            @click="item.to ? onClick(item.to) : item.action()"
          >
            <i :class="item.icon" />
            {{ item.label }}
            <router-link
              v-if="item.to"
              event=""
              class="absolute top-0 bottom-0 left-0 right-0"
              :to="item.to"
            />
          </li>
        </ul>
      </nav>

      <div
        class="absolute bottom-0 left-0 w-full all:p-4 all:pt-0 all:bg-white lg:bg-gray-50"
      >
        <VersionInformation class="border-t-1 border-purple-50 pt-4" />
      </div>
    </div>
  </SlidePanel>
</template>

<script>
import VersionInformation from '@/components/info/VersionInformation';
import SlidePanel from '@/components/interface/SlidePanel';
import { Icon } from '@/lib/enum/Icon';
import { navItems } from '@/lib/navigation';
import { redirect } from '@/router/router';

export default {
  name: 'SupportMenu',
  components: { VersionInformation, SlidePanel },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Icon,
    };
  },
  computed: {
    supportItems() {
      return [
        navItems.help(this.close),
        navItems.whatsNew(this.close),
        navItems.featuresSuggestions(this.close),
        navItems.integrations(),
        navItems.systemStatus(this.close),
      ].filter((i) => i.enabled);
    },
  },
  methods: {
    onClick(to) {
      redirect(to);
      this.close();
    },

    close() {
      this.$emit('input', false);
    },

    activeRoute(to) {
      return to.name === this.$route.name;
    },
  },
};
</script>

<style scoped>
nav {
  max-height: calc(100vh - calc(75px + 64px));
  overflow: auto;
}
</style>
