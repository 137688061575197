/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTrekksoftAuthenticationData
 */
export interface CreateTrekksoftAuthenticationData {
    /**
     * 
     * @type {string}
     * @memberof CreateTrekksoftAuthenticationData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrekksoftAuthenticationData
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateTrekksoftAuthenticationData
     */
    clientSecret: string;
}

/**
 * Check if a given object implements the CreateTrekksoftAuthenticationData interface.
 */
export function instanceOfCreateTrekksoftAuthenticationData(value: object): value is CreateTrekksoftAuthenticationData {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('clientId' in value) || value['clientId'] === undefined) return false;
    if (!('clientSecret' in value) || value['clientSecret'] === undefined) return false;
    return true;
}

export function CreateTrekksoftAuthenticationDataFromJSON(json: any): CreateTrekksoftAuthenticationData {
    return CreateTrekksoftAuthenticationDataFromJSONTyped(json, false);
}

export function CreateTrekksoftAuthenticationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTrekksoftAuthenticationData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'clientId': json['clientId'],
        'clientSecret': json['clientSecret'],
    };
}

  export function CreateTrekksoftAuthenticationDataToJSON(json: any): CreateTrekksoftAuthenticationData {
      return CreateTrekksoftAuthenticationDataToJSONTyped(json, false);
  }

  export function CreateTrekksoftAuthenticationDataToJSONTyped(value?: CreateTrekksoftAuthenticationData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'clientId': value['clientId'],
        'clientSecret': value['clientSecret'],
    };
}

