/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a simplified representation of an Employee, suitable for displaying an avatar, or similar
 * @export
 * @interface EmployeeSimplified
 */
export interface EmployeeSimplified {
    /**
     * 
     * @type {number}
     * @memberof EmployeeSimplified
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeSimplified
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeSimplified
     */
    userId: number | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSimplified
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSimplified
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSimplified
     */
    phoneNumber: string | null;
    /**
     * A unique reference given to the employee by the company
     * @type {string}
     * @memberof EmployeeSimplified
     */
    reference: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSimplified
     */
    status: EmployeeSimplifiedStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSimplified
     */
    jobTitle: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeSimplified
     */
    photoUrl: string;
}

/**
* @export
* @enum {string}
*/
export enum EmployeeSimplifiedStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted',
    Pending = 'Pending'
}


/**
 * Check if a given object implements the EmployeeSimplified interface.
 */
export function instanceOfEmployeeSimplified(value: object): value is EmployeeSimplified {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('reference' in value) || value['reference'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('jobTitle' in value) || value['jobTitle'] === undefined) return false;
    if (!('photoUrl' in value) || value['photoUrl'] === undefined) return false;
    return true;
}

export function EmployeeSimplifiedFromJSON(json: any): EmployeeSimplified {
    return EmployeeSimplifiedFromJSONTyped(json, false);
}

export function EmployeeSimplifiedFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeSimplified {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'userId': json['userId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'phoneNumber': json['phoneNumber'],
        'reference': json['reference'],
        'status': json['status'],
        'jobTitle': json['jobTitle'],
        'photoUrl': json['photoUrl'],
    };
}

  export function EmployeeSimplifiedToJSON(json: any): EmployeeSimplified {
      return EmployeeSimplifiedToJSONTyped(json, false);
  }

  export function EmployeeSimplifiedToJSONTyped(value?: EmployeeSimplified | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'phoneNumber': value['phoneNumber'],
        'reference': value['reference'],
        'status': value['status'],
        'jobTitle': value['jobTitle'],
        'photoUrl': value['photoUrl'],
    };
}

