/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeGroup } from './EmployeeGroup';
import {
    EmployeeGroupFromJSON,
    EmployeeGroupFromJSONTyped,
    EmployeeGroupToJSON,
    EmployeeGroupToJSONTyped,
} from './EmployeeGroup';

/**
 * 
 * @export
 * @interface UpdateEmployeeGroupResponse
 */
export interface UpdateEmployeeGroupResponse {
    /**
     * 
     * @type {EmployeeGroup}
     * @memberof UpdateEmployeeGroupResponse
     */
    data: EmployeeGroup;
}

/**
 * Check if a given object implements the UpdateEmployeeGroupResponse interface.
 */
export function instanceOfUpdateEmployeeGroupResponse(value: object): value is UpdateEmployeeGroupResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateEmployeeGroupResponseFromJSON(json: any): UpdateEmployeeGroupResponse {
    return UpdateEmployeeGroupResponseFromJSONTyped(json, false);
}

export function UpdateEmployeeGroupResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeeGroupResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeGroupFromJSON(json['data']),
    };
}

  export function UpdateEmployeeGroupResponseToJSON(json: any): UpdateEmployeeGroupResponse {
      return UpdateEmployeeGroupResponseToJSONTyped(json, false);
  }

  export function UpdateEmployeeGroupResponseToJSONTyped(value?: UpdateEmployeeGroupResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': EmployeeGroupToJSON(value['data']),
    };
}

