/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DaySummary } from './DaySummary';
import {
    DaySummaryFromJSON,
    DaySummaryFromJSONTyped,
    DaySummaryToJSON,
    DaySummaryToJSONTyped,
} from './DaySummary';

/**
 * 
 * @export
 * @interface ShowDaySummaryResponse
 */
export interface ShowDaySummaryResponse {
    /**
     * 
     * @type {DaySummary}
     * @memberof ShowDaySummaryResponse
     */
    data: DaySummary;
}

/**
 * Check if a given object implements the ShowDaySummaryResponse interface.
 */
export function instanceOfShowDaySummaryResponse(value: object): value is ShowDaySummaryResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowDaySummaryResponseFromJSON(json: any): ShowDaySummaryResponse {
    return ShowDaySummaryResponseFromJSONTyped(json, false);
}

export function ShowDaySummaryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowDaySummaryResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': DaySummaryFromJSON(json['data']),
    };
}

  export function ShowDaySummaryResponseToJSON(json: any): ShowDaySummaryResponse {
      return ShowDaySummaryResponseToJSONTyped(json, false);
  }

  export function ShowDaySummaryResponseToJSONTyped(value?: ShowDaySummaryResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': DaySummaryToJSON(value['data']),
    };
}

