/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveAdjustment } from './LeaveAdjustment';
import {
    LeaveAdjustmentFromJSON,
    LeaveAdjustmentFromJSONTyped,
    LeaveAdjustmentToJSON,
    LeaveAdjustmentToJSONTyped,
} from './LeaveAdjustment';

/**
 * 
 * @export
 * @interface ShowLeaveAdjustmentResponse
 */
export interface ShowLeaveAdjustmentResponse {
    /**
     * 
     * @type {LeaveAdjustment}
     * @memberof ShowLeaveAdjustmentResponse
     */
    data: LeaveAdjustment;
}

/**
 * Check if a given object implements the ShowLeaveAdjustmentResponse interface.
 */
export function instanceOfShowLeaveAdjustmentResponse(value: object): value is ShowLeaveAdjustmentResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowLeaveAdjustmentResponseFromJSON(json: any): ShowLeaveAdjustmentResponse {
    return ShowLeaveAdjustmentResponseFromJSONTyped(json, false);
}

export function ShowLeaveAdjustmentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowLeaveAdjustmentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LeaveAdjustmentFromJSON(json['data']),
    };
}

  export function ShowLeaveAdjustmentResponseToJSON(json: any): ShowLeaveAdjustmentResponse {
      return ShowLeaveAdjustmentResponseToJSONTyped(json, false);
  }

  export function ShowLeaveAdjustmentResponseToJSONTyped(value?: ShowLeaveAdjustmentResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': LeaveAdjustmentToJSON(value['data']),
    };
}

