/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateShiftTemplateBreakData
 */
export interface CreateShiftTemplateBreakData {
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateBreakData
     */
    startHour: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateBreakData
     */
    startMinute: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateShiftTemplateBreakData
     */
    durationInMinutes: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateShiftTemplateBreakData
     */
    paid: boolean;
}

/**
 * Check if a given object implements the CreateShiftTemplateBreakData interface.
 */
export function instanceOfCreateShiftTemplateBreakData(value: object): value is CreateShiftTemplateBreakData {
    if (!('startHour' in value) || value['startHour'] === undefined) return false;
    if (!('startMinute' in value) || value['startMinute'] === undefined) return false;
    if (!('durationInMinutes' in value) || value['durationInMinutes'] === undefined) return false;
    if (!('paid' in value) || value['paid'] === undefined) return false;
    return true;
}

export function CreateShiftTemplateBreakDataFromJSON(json: any): CreateShiftTemplateBreakData {
    return CreateShiftTemplateBreakDataFromJSONTyped(json, false);
}

export function CreateShiftTemplateBreakDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftTemplateBreakData {
    if (json == null) {
        return json;
    }
    return {
        
        'startHour': json['startHour'],
        'startMinute': json['startMinute'],
        'durationInMinutes': json['durationInMinutes'],
        'paid': json['paid'],
    };
}

  export function CreateShiftTemplateBreakDataToJSON(json: any): CreateShiftTemplateBreakData {
      return CreateShiftTemplateBreakDataToJSONTyped(json, false);
  }

  export function CreateShiftTemplateBreakDataToJSONTyped(value?: CreateShiftTemplateBreakData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'startHour': value['startHour'],
        'startMinute': value['startMinute'],
        'durationInMinutes': value['durationInMinutes'],
        'paid': value['paid'],
    };
}

