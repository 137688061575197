/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface ScheduleOverviewSummary
 */
export interface ScheduleOverviewSummary {
    /**
     * A deterministic identifier for the report.
     * @type {string}
     * @memberof ScheduleOverviewSummary
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ScheduleOverviewSummary
     */
    companyId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof ScheduleOverviewSummary
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof ScheduleOverviewSummary
     */
    endDate: ShiftiePlainDate;
    /**
     * Which schedule this summary applies to. When null, it applies to all schedules.
     * @type {number}
     * @memberof ScheduleOverviewSummary
     */
    scheduleId: number;
    /**
     * The number of unclaimed open shifts within the date range.
     * @type {number}
     * @memberof ScheduleOverviewSummary
     */
    unclaimedOpenShifts: number;
    /**
     * The number of unconfirmed shifts within the date range.
     * @type {number}
     * @memberof ScheduleOverviewSummary
     */
    unconfirmedShifts: number;
    /**
     * The number of unpublished assigned shifts and unpublished open shifts within the date range.
     * @type {number}
     * @memberof ScheduleOverviewSummary
     */
    unpublishedShifts: number;
    /**
     * Number of shifts which are in conflict, e.g. with an existing leave request or unavailability.
     * @type {number}
     * @memberof ScheduleOverviewSummary
     */
    conflictingShifts: number;
}

/**
 * Check if a given object implements the ScheduleOverviewSummary interface.
 */
export function instanceOfScheduleOverviewSummary(value: object): value is ScheduleOverviewSummary {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    if (!('scheduleId' in value) || value['scheduleId'] === undefined) return false;
    if (!('unclaimedOpenShifts' in value) || value['unclaimedOpenShifts'] === undefined) return false;
    if (!('unconfirmedShifts' in value) || value['unconfirmedShifts'] === undefined) return false;
    if (!('unpublishedShifts' in value) || value['unpublishedShifts'] === undefined) return false;
    if (!('conflictingShifts' in value) || value['conflictingShifts'] === undefined) return false;
    return true;
}

export function ScheduleOverviewSummaryFromJSON(json: any): ScheduleOverviewSummary {
    return ScheduleOverviewSummaryFromJSONTyped(json, false);
}

export function ScheduleOverviewSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleOverviewSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'scheduleId': json['scheduleId'],
        'unclaimedOpenShifts': json['unclaimedOpenShifts'],
        'unconfirmedShifts': json['unconfirmedShifts'],
        'unpublishedShifts': json['unpublishedShifts'],
        'conflictingShifts': json['conflictingShifts'],
    };
}

  export function ScheduleOverviewSummaryToJSON(json: any): ScheduleOverviewSummary {
      return ScheduleOverviewSummaryToJSONTyped(json, false);
  }

  export function ScheduleOverviewSummaryToJSONTyped(value?: ScheduleOverviewSummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'scheduleId': value['scheduleId'],
        'unclaimedOpenShifts': value['unclaimedOpenShifts'],
        'unconfirmedShifts': value['unconfirmedShifts'],
        'unpublishedShifts': value['unpublishedShifts'],
        'conflictingShifts': value['conflictingShifts'],
    };
}

