/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingDetails } from './BillingDetails';
import {
    BillingDetailsFromJSON,
    BillingDetailsFromJSONTyped,
    BillingDetailsToJSON,
    BillingDetailsToJSONTyped,
} from './BillingDetails';

/**
 * 
 * @export
 * @interface ShowBillingDetailsResponse
 */
export interface ShowBillingDetailsResponse {
    /**
     * 
     * @type {BillingDetails}
     * @memberof ShowBillingDetailsResponse
     */
    data: BillingDetails;
}

/**
 * Check if a given object implements the ShowBillingDetailsResponse interface.
 */
export function instanceOfShowBillingDetailsResponse(value: object): value is ShowBillingDetailsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowBillingDetailsResponseFromJSON(json: any): ShowBillingDetailsResponse {
    return ShowBillingDetailsResponseFromJSONTyped(json, false);
}

export function ShowBillingDetailsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowBillingDetailsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BillingDetailsFromJSON(json['data']),
    };
}

  export function ShowBillingDetailsResponseToJSON(json: any): ShowBillingDetailsResponse {
      return ShowBillingDetailsResponseToJSONTyped(json, false);
  }

  export function ShowBillingDetailsResponseToJSONTyped(value?: ShowBillingDetailsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': BillingDetailsToJSON(value['data']),
    };
}

