/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterCompanyData
 */
export interface RegisterCompanyData {
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    address: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterCompanyData
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof RegisterCompanyData
     */
    longitude: number;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    locale?: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    timezone: string;
    /**
     * 
     * @type {number}
     * @memberof RegisterCompanyData
     */
    billingPlanId: number;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    referralCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterCompanyData
     */
    terms: boolean;
    /**
     * 
     * @type {string}
     * @memberof RegisterCompanyData
     */
    recaptchaToken: string;
}

/**
 * Check if a given object implements the RegisterCompanyData interface.
 */
export function instanceOfRegisterCompanyData(value: object): value is RegisterCompanyData {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('companyName' in value) || value['companyName'] === undefined) return false;
    if (!('password' in value) || value['password'] === undefined) return false;
    if (!('address' in value) || value['address'] === undefined) return false;
    if (!('latitude' in value) || value['latitude'] === undefined) return false;
    if (!('longitude' in value) || value['longitude'] === undefined) return false;
    if (!('timezone' in value) || value['timezone'] === undefined) return false;
    if (!('billingPlanId' in value) || value['billingPlanId'] === undefined) return false;
    if (!('terms' in value) || value['terms'] === undefined) return false;
    if (!('recaptchaToken' in value) || value['recaptchaToken'] === undefined) return false;
    return true;
}

export function RegisterCompanyDataFromJSON(json: any): RegisterCompanyData {
    return RegisterCompanyDataFromJSONTyped(json, false);
}

export function RegisterCompanyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterCompanyData {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'companyName': json['companyName'],
        'password': json['password'],
        'address': json['address'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'locale': json['locale'] == null ? undefined : json['locale'],
        'timezone': json['timezone'],
        'billingPlanId': json['billingPlanId'],
        'referralCode': json['referralCode'] == null ? undefined : json['referralCode'],
        'terms': json['terms'],
        'recaptchaToken': json['recaptchaToken'],
    };
}

  export function RegisterCompanyDataToJSON(json: any): RegisterCompanyData {
      return RegisterCompanyDataToJSONTyped(json, false);
  }

  export function RegisterCompanyDataToJSONTyped(value?: RegisterCompanyData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
        'companyName': value['companyName'],
        'password': value['password'],
        'address': value['address'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'locale': value['locale'],
        'timezone': value['timezone'],
        'billingPlanId': value['billingPlanId'],
        'referralCode': value['referralCode'],
        'terms': value['terms'],
        'recaptchaToken': value['recaptchaToken'],
    };
}

