/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthenticationDataMetaMfa } from './AuthenticationDataMetaMfa';
import {
    AuthenticationDataMetaMfaFromJSON,
    AuthenticationDataMetaMfaFromJSONTyped,
    AuthenticationDataMetaMfaToJSON,
    AuthenticationDataMetaMfaToJSONTyped,
} from './AuthenticationDataMetaMfa';

/**
 * 
 * @export
 * @interface AuthenticationDataMeta
 */
export interface AuthenticationDataMeta {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationDataMeta
     */
    sessionType: AuthenticationDataMetaSessionTypeEnum;
    /**
     * 
     * @type {AuthenticationDataMetaMfa}
     * @memberof AuthenticationDataMeta
     */
    mfa?: AuthenticationDataMetaMfa;
}

/**
* @export
* @enum {string}
*/
export enum AuthenticationDataMetaSessionTypeEnum {
    EnhancedImpersonate = 'enhancedImpersonate',
    Impersonate = 'impersonate',
    Standard = 'standard'
}


/**
 * Check if a given object implements the AuthenticationDataMeta interface.
 */
export function instanceOfAuthenticationDataMeta(value: object): value is AuthenticationDataMeta {
    if (!('sessionType' in value) || value['sessionType'] === undefined) return false;
    return true;
}

export function AuthenticationDataMetaFromJSON(json: any): AuthenticationDataMeta {
    return AuthenticationDataMetaFromJSONTyped(json, false);
}

export function AuthenticationDataMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationDataMeta {
    if (json == null) {
        return json;
    }
    return {
        
        'sessionType': json['sessionType'],
        'mfa': json['mfa'] == null ? undefined : AuthenticationDataMetaMfaFromJSON(json['mfa']),
    };
}

  export function AuthenticationDataMetaToJSON(json: any): AuthenticationDataMeta {
      return AuthenticationDataMetaToJSONTyped(json, false);
  }

  export function AuthenticationDataMetaToJSONTyped(value?: AuthenticationDataMeta | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'sessionType': value['sessionType'],
        'mfa': AuthenticationDataMetaMfaToJSON(value['mfa']),
    };
}

