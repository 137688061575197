/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { ShiftTemplate } from './ShiftTemplate';
import {
    ShiftTemplateFromJSON,
    ShiftTemplateFromJSONTyped,
    ShiftTemplateToJSON,
    ShiftTemplateToJSONTyped,
} from './ShiftTemplate';

/**
 * 
 * @export
 * @interface ListShiftTemplatesResponse
 */
export interface ListShiftTemplatesResponse {
    /**
     * 
     * @type {Array<ShiftTemplate>}
     * @memberof ListShiftTemplatesResponse
     */
    data: Array<ShiftTemplate>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListShiftTemplatesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListShiftTemplatesResponse interface.
 */
export function instanceOfListShiftTemplatesResponse(value: object): value is ListShiftTemplatesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListShiftTemplatesResponseFromJSON(json: any): ListShiftTemplatesResponse {
    return ListShiftTemplatesResponseFromJSONTyped(json, false);
}

export function ListShiftTemplatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListShiftTemplatesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ShiftTemplateFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListShiftTemplatesResponseToJSON(json: any): ListShiftTemplatesResponse {
      return ListShiftTemplatesResponseToJSONTyped(json, false);
  }

  export function ListShiftTemplatesResponseToJSONTyped(value?: ListShiftTemplatesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ShiftTemplateToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

