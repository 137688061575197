/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ScheduleTemplate } from './ScheduleTemplate';
import {
    ScheduleTemplateFromJSON,
    ScheduleTemplateFromJSONTyped,
    ScheduleTemplateToJSON,
    ScheduleTemplateToJSONTyped,
} from './ScheduleTemplate';

/**
 * 
 * @export
 * @interface UpdateScheduleTemplateResponse
 */
export interface UpdateScheduleTemplateResponse {
    /**
     * 
     * @type {ScheduleTemplate}
     * @memberof UpdateScheduleTemplateResponse
     */
    data: ScheduleTemplate;
}

/**
 * Check if a given object implements the UpdateScheduleTemplateResponse interface.
 */
export function instanceOfUpdateScheduleTemplateResponse(value: object): value is UpdateScheduleTemplateResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateScheduleTemplateResponseFromJSON(json: any): UpdateScheduleTemplateResponse {
    return UpdateScheduleTemplateResponseFromJSONTyped(json, false);
}

export function UpdateScheduleTemplateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateScheduleTemplateResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ScheduleTemplateFromJSON(json['data']),
    };
}

  export function UpdateScheduleTemplateResponseToJSON(json: any): UpdateScheduleTemplateResponse {
      return UpdateScheduleTemplateResponseToJSONTyped(json, false);
  }

  export function UpdateScheduleTemplateResponseToJSONTyped(value?: UpdateScheduleTemplateResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ScheduleTemplateToJSON(value['data']),
    };
}

