/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateEmployeePayData
 */
export interface UpdateEmployeePayData {
    /**
     * 
     * @type {number}
     * @memberof UpdateEmployeePayData
     */
    baseRate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePayData
     */
    payrollId?: string | null;
}

/**
 * Check if a given object implements the UpdateEmployeePayData interface.
 */
export function instanceOfUpdateEmployeePayData(value: object): value is UpdateEmployeePayData {
    return true;
}

export function UpdateEmployeePayDataFromJSON(json: any): UpdateEmployeePayData {
    return UpdateEmployeePayDataFromJSONTyped(json, false);
}

export function UpdateEmployeePayDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeePayData {
    if (json == null) {
        return json;
    }
    return {
        
        'baseRate': json['baseRate'] == null ? undefined : json['baseRate'],
        'payrollId': json['payrollId'] == null ? undefined : json['payrollId'],
    };
}

  export function UpdateEmployeePayDataToJSON(json: any): UpdateEmployeePayData {
      return UpdateEmployeePayDataToJSONTyped(json, false);
  }

  export function UpdateEmployeePayDataToJSONTyped(value?: UpdateEmployeePayData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'baseRate': value['baseRate'],
        'payrollId': value['payrollId'],
    };
}

