/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Review a leave request
 * @export
 * @interface ReviewLeaveRequestData
 */
export interface ReviewLeaveRequestData {
    /**
     * Optional message to send to the requester about why the request was approved or denied
     * @type {string}
     * @memberof ReviewLeaveRequestData
     */
    reviewerNotes?: string;
}

/**
 * Check if a given object implements the ReviewLeaveRequestData interface.
 */
export function instanceOfReviewLeaveRequestData(value: object): value is ReviewLeaveRequestData {
    return true;
}

export function ReviewLeaveRequestDataFromJSON(json: any): ReviewLeaveRequestData {
    return ReviewLeaveRequestDataFromJSONTyped(json, false);
}

export function ReviewLeaveRequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReviewLeaveRequestData {
    if (json == null) {
        return json;
    }
    return {
        
        'reviewerNotes': json['reviewerNotes'] == null ? undefined : json['reviewerNotes'],
    };
}

  export function ReviewLeaveRequestDataToJSON(json: any): ReviewLeaveRequestData {
      return ReviewLeaveRequestDataToJSONTyped(json, false);
  }

  export function ReviewLeaveRequestDataToJSONTyped(value?: ReviewLeaveRequestData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'reviewerNotes': value['reviewerNotes'],
    };
}

