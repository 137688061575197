/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkCreateWorkPatternOverrideData,
  CreateWorkPatternData,
  CreateWorkPatternOverrideData,
  CreateWorkPatternOverrideResponse,
  CreateWorkPatternResponse,
  ListEmployeeWorkPatternOverridesResponse,
  ListWorkPatternsResponse,
  ShowWorkPatternOverrideResponse,
  ShowWorkPatternResponse,
  UpdateWorkPatternData,
  UpdateWorkPatternOverrideData,
  UpdateWorkPatternOverrideResponse,
  UpdateWorkPatternResponse,
} from '../models/index';
import {
    BulkCreateWorkPatternOverrideDataFromJSON,
    BulkCreateWorkPatternOverrideDataToJSON,
    CreateWorkPatternDataFromJSON,
    CreateWorkPatternDataToJSON,
    CreateWorkPatternOverrideDataFromJSON,
    CreateWorkPatternOverrideDataToJSON,
    CreateWorkPatternOverrideResponseFromJSON,
    CreateWorkPatternOverrideResponseToJSON,
    CreateWorkPatternResponseFromJSON,
    CreateWorkPatternResponseToJSON,
    ListEmployeeWorkPatternOverridesResponseFromJSON,
    ListEmployeeWorkPatternOverridesResponseToJSON,
    ListWorkPatternsResponseFromJSON,
    ListWorkPatternsResponseToJSON,
    ShowWorkPatternOverrideResponseFromJSON,
    ShowWorkPatternOverrideResponseToJSON,
    ShowWorkPatternResponseFromJSON,
    ShowWorkPatternResponseToJSON,
    UpdateWorkPatternDataFromJSON,
    UpdateWorkPatternDataToJSON,
    UpdateWorkPatternOverrideDataFromJSON,
    UpdateWorkPatternOverrideDataToJSON,
    UpdateWorkPatternOverrideResponseFromJSON,
    UpdateWorkPatternOverrideResponseToJSON,
    UpdateWorkPatternResponseFromJSON,
    UpdateWorkPatternResponseToJSON,
} from '../models/index';

export interface BulkCreateWorkPatternOverrideRequest {
    bulkCreateWorkPatternOverrideData?: BulkCreateWorkPatternOverrideData;
}

export interface BulkDeleteWorkPatternOverrideRequest {
    limit?: number;
    where?: { [key: string]: object; };
}

export interface CreateWorkPatternRequest {
    createWorkPatternData?: CreateWorkPatternData;
}

export interface CreateWorkPatternOverrideRequest {
    _with?: Array<string>;
    createWorkPatternOverrideData?: CreateWorkPatternOverrideData;
}

export interface DeleteWorkPatternRequest {
    id: number;
}

export interface DeleteWorkPatternOverrideRequest {
    id: number;
}

export interface ListEmployeeWorkPatternOverridesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ListWorkPatternsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface ShowWorkPatternRequest {
    id: number;
}

export interface ShowWorkPatternOverrideRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateWorkPatternRequest {
    id: number;
    updateWorkPatternData?: UpdateWorkPatternData;
}

export interface UpdateWorkPatternOverrideRequest {
    id: number;
    _with?: Array<string>;
    updateWorkPatternOverrideData?: UpdateWorkPatternOverrideData;
}

/**
 * 
 */
export class WorkPatternApi extends runtime.BaseAPI {

    /**
     * Bulk Create Work Pattern Overrides
     */
    async bulkCreateWorkPatternOverrideRaw(requestParameters: BulkCreateWorkPatternOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/work-pattern-overrides/bulk`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkCreateWorkPatternOverrideDataToJSON(requestParameters['bulkCreateWorkPatternOverrideData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk Create Work Pattern Overrides
     */
    async bulkCreateWorkPatternOverride(requestParameters: BulkCreateWorkPatternOverrideRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkCreateWorkPatternOverrideRaw(requestParameters, initOverrides);
    }

    /**
     * Delete one or more Work Pattern Overrides.
     * Bulk Delete Work Pattern Overrides
     */
    async bulkDeleteWorkPatternOverrideRaw(requestParameters: BulkDeleteWorkPatternOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/work-pattern-overrides/bulk`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete one or more Work Pattern Overrides.
     * Bulk Delete Work Pattern Overrides
     */
    async bulkDeleteWorkPatternOverride(requestParameters: BulkDeleteWorkPatternOverrideRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkDeleteWorkPatternOverrideRaw(requestParameters, initOverrides);
    }

    /**
     * Create Work Pattern
     */
    async createWorkPatternRaw(requestParameters: CreateWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateWorkPatternResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-patterns`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkPatternDataToJSON(requestParameters['createWorkPatternData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateWorkPatternResponseFromJSON(jsonValue));
    }

    /**
     * Create Work Pattern
     */
    async createWorkPattern(requestParameters: CreateWorkPatternRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateWorkPatternResponse> {
        const response = await this.createWorkPatternRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new work pattern override
     * Create Work Pattern Override
     */
    async createWorkPatternOverrideRaw(requestParameters: CreateWorkPatternOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateWorkPatternOverrideResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/work-pattern-overrides`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWorkPatternOverrideDataToJSON(requestParameters['createWorkPatternOverrideData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateWorkPatternOverrideResponseFromJSON(jsonValue));
    }

    /**
     * Create a new work pattern override
     * Create Work Pattern Override
     */
    async createWorkPatternOverride(requestParameters: CreateWorkPatternOverrideRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateWorkPatternOverrideResponse> {
        const response = await this.createWorkPatternOverrideRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Work Pattern
     */
    async deleteWorkPatternRaw(requestParameters: DeleteWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteWorkPattern().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-patterns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Work Pattern
     */
    async deleteWorkPattern(requestParameters: DeleteWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteWorkPatternRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a work pattern override
     * Delete Work Pattern Override
     */
    async deleteWorkPatternOverrideRaw(requestParameters: DeleteWorkPatternOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteWorkPatternOverride().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/work-pattern-overrides/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a work pattern override
     * Delete Work Pattern Override
     */
    async deleteWorkPatternOverride(requestParameters: DeleteWorkPatternOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteWorkPatternOverrideRaw(requestParameters, initOverrides);
    }

    /**
     * List Employee Work Pattern Overrides
     * List Employee Work Pattern Overrides
     */
    async listEmployeeWorkPatternOverridesRaw(requestParameters: ListEmployeeWorkPatternOverridesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListEmployeeWorkPatternOverridesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/work-pattern-overrides`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListEmployeeWorkPatternOverridesResponseFromJSON(jsonValue));
    }

    /**
     * List Employee Work Pattern Overrides
     * List Employee Work Pattern Overrides
     */
    async listEmployeeWorkPatternOverrides(requestParameters: ListEmployeeWorkPatternOverridesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListEmployeeWorkPatternOverridesResponse> {
        const response = await this.listEmployeeWorkPatternOverridesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List Work Patterns
     */
    async listWorkPatternsRaw(requestParameters: ListWorkPatternsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListWorkPatternsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-patterns`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListWorkPatternsResponseFromJSON(jsonValue));
    }

    /**
     * List Work Patterns
     */
    async listWorkPatterns(requestParameters: ListWorkPatternsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListWorkPatternsResponse> {
        const response = await this.listWorkPatternsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Work Pattern
     */
    async showWorkPatternRaw(requestParameters: ShowWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowWorkPatternResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showWorkPattern().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-patterns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowWorkPatternResponseFromJSON(jsonValue));
    }

    /**
     * Show Work Pattern
     */
    async showWorkPattern(requestParameters: ShowWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowWorkPatternResponse> {
        const response = await this.showWorkPatternRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details of an existing Work Pattern Override
     * Show Work Pattern Override
     */
    async showWorkPatternOverrideRaw(requestParameters: ShowWorkPatternOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowWorkPatternOverrideResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showWorkPatternOverride().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/work-pattern-overrides/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowWorkPatternOverrideResponseFromJSON(jsonValue));
    }

    /**
     * Show details of an existing Work Pattern Override
     * Show Work Pattern Override
     */
    async showWorkPatternOverride(requestParameters: ShowWorkPatternOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowWorkPatternOverrideResponse> {
        const response = await this.showWorkPatternOverrideRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Work Pattern
     */
    async updateWorkPatternRaw(requestParameters: UpdateWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateWorkPatternResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateWorkPattern().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/work-patterns/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkPatternDataToJSON(requestParameters['updateWorkPatternData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateWorkPatternResponseFromJSON(jsonValue));
    }

    /**
     * Update Work Pattern
     */
    async updateWorkPattern(requestParameters: UpdateWorkPatternRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateWorkPatternResponse> {
        const response = await this.updateWorkPatternRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the details of an existing Work Pattern Override
     * Update Work Pattern Override
     */
    async updateWorkPatternOverrideRaw(requestParameters: UpdateWorkPatternOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateWorkPatternOverrideResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateWorkPatternOverride().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.work-pattern.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/employees/work-pattern-overrides/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWorkPatternOverrideDataToJSON(requestParameters['updateWorkPatternOverrideData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateWorkPatternOverrideResponseFromJSON(jsonValue));
    }

    /**
     * Update the details of an existing Work Pattern Override
     * Update Work Pattern Override
     */
    async updateWorkPatternOverride(requestParameters: UpdateWorkPatternOverrideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateWorkPatternOverrideResponse> {
        const response = await this.updateWorkPatternOverrideRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
