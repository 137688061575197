/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a billing plan
 * @export
 * @interface BillingPlan
 */
export interface BillingPlan {
    /**
     * 
     * @type {number}
     * @memberof BillingPlan
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BillingPlan
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof BillingPlan
     */
    interval: BillingPlanIntervalEnum;
    /**
     * 
     * @type {number}
     * @memberof BillingPlan
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof BillingPlan
     */
    additionalEmployeePrice: number;
    /**
     * The number of employees included in the price of the plan.
     * @type {number}
     * @memberof BillingPlan
     */
    employeeSoftLimit: number;
    /**
     * The maximum number of employees allowed on the plan.
     * @type {number}
     * @memberof BillingPlan
     */
    employeeHardLimit: number;
    /**
     * How many trial days are given when signing up.
     * @type {number}
     * @memberof BillingPlan
     */
    trialPeriodDays: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillingPlan
     */
    features: Array<BillingPlanFeaturesEnum>;
    /**
     * Whether the plans allows switching to another plan.
     * @type {boolean}
     * @memberof BillingPlan
     */
    allowsSwitching: boolean;
    /**
     * Whether the plan allows referrals to be made.
     * @type {boolean}
     * @memberof BillingPlan
     */
    allowsReferrals: boolean;
    /**
     * Whether the plan allows offers to be redeemed.
     * @type {boolean}
     * @memberof BillingPlan
     */
    allowsOffers: boolean;
    /**
     * Whether the plan allows credit renewals to be enabled.
     * @type {boolean}
     * @memberof BillingPlan
     */
    allowsCreditRenewals: boolean;
    /**
     * Whether the plan has a valid upgrade path to a higher tiered plan.
     * @type {boolean}
     * @memberof BillingPlan
     */
    isUpgradable: boolean;
}

/**
* @export
* @enum {string}
*/
export enum BillingPlanIntervalEnum {
    Annual = 'Annual',
    Monthly = 'Monthly'
}
/**
* @export
* @enum {string}
*/
export enum BillingPlanFeaturesEnum {
    Absence = 'Absence',
    Availability = 'Availability',
    ClockInOut = 'Clock In/Out',
    Dashboard = 'Dashboard',
    Documents = 'Documents',
    HrManagement = 'HR Management',
    Integrations = 'Integrations',
    Leave = 'Leave',
    MultiVenue = 'MultiVenue',
    Reports = 'Reports',
    Scheduler = 'Scheduler',
    ShiftAreas = 'Shift Areas',
    TeamMessaging = 'Team Messaging',
    Timesheets = 'Timesheets'
}


/**
 * Check if a given object implements the BillingPlan interface.
 */
export function instanceOfBillingPlan(value: object): value is BillingPlan {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('interval' in value) || value['interval'] === undefined) return false;
    if (!('price' in value) || value['price'] === undefined) return false;
    if (!('additionalEmployeePrice' in value) || value['additionalEmployeePrice'] === undefined) return false;
    if (!('employeeSoftLimit' in value) || value['employeeSoftLimit'] === undefined) return false;
    if (!('employeeHardLimit' in value) || value['employeeHardLimit'] === undefined) return false;
    if (!('trialPeriodDays' in value) || value['trialPeriodDays'] === undefined) return false;
    if (!('features' in value) || value['features'] === undefined) return false;
    if (!('allowsSwitching' in value) || value['allowsSwitching'] === undefined) return false;
    if (!('allowsReferrals' in value) || value['allowsReferrals'] === undefined) return false;
    if (!('allowsOffers' in value) || value['allowsOffers'] === undefined) return false;
    if (!('allowsCreditRenewals' in value) || value['allowsCreditRenewals'] === undefined) return false;
    if (!('isUpgradable' in value) || value['isUpgradable'] === undefined) return false;
    return true;
}

export function BillingPlanFromJSON(json: any): BillingPlan {
    return BillingPlanFromJSONTyped(json, false);
}

export function BillingPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingPlan {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'interval': json['interval'],
        'price': json['price'],
        'additionalEmployeePrice': json['additionalEmployeePrice'],
        'employeeSoftLimit': json['employeeSoftLimit'],
        'employeeHardLimit': json['employeeHardLimit'],
        'trialPeriodDays': json['trialPeriodDays'],
        'features': json['features'],
        'allowsSwitching': json['allowsSwitching'],
        'allowsReferrals': json['allowsReferrals'],
        'allowsOffers': json['allowsOffers'],
        'allowsCreditRenewals': json['allowsCreditRenewals'],
        'isUpgradable': json['isUpgradable'],
    };
}

  export function BillingPlanToJSON(json: any): BillingPlan {
      return BillingPlanToJSONTyped(json, false);
  }

  export function BillingPlanToJSONTyped(value?: BillingPlan | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'interval': value['interval'],
        'price': value['price'],
        'additionalEmployeePrice': value['additionalEmployeePrice'],
        'employeeSoftLimit': value['employeeSoftLimit'],
        'employeeHardLimit': value['employeeHardLimit'],
        'trialPeriodDays': value['trialPeriodDays'],
        'features': value['features'],
        'allowsSwitching': value['allowsSwitching'],
        'allowsReferrals': value['allowsReferrals'],
        'allowsOffers': value['allowsOffers'],
        'allowsCreditRenewals': value['allowsCreditRenewals'],
        'isUpgradable': value['isUpgradable'],
    };
}

