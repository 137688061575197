/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Absence } from './Absence';
import {
    AbsenceFromJSON,
    AbsenceFromJSONTyped,
    AbsenceToJSON,
    AbsenceToJSONTyped,
} from './Absence';

/**
 * 
 * @export
 * @interface CreateAbsenceResponse
 */
export interface CreateAbsenceResponse {
    /**
     * 
     * @type {Absence}
     * @memberof CreateAbsenceResponse
     */
    data: Absence;
}

/**
 * Check if a given object implements the CreateAbsenceResponse interface.
 */
export function instanceOfCreateAbsenceResponse(value: object): value is CreateAbsenceResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateAbsenceResponseFromJSON(json: any): CreateAbsenceResponse {
    return CreateAbsenceResponseFromJSONTyped(json, false);
}

export function CreateAbsenceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateAbsenceResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': AbsenceFromJSON(json['data']),
    };
}

  export function CreateAbsenceResponseToJSON(json: any): CreateAbsenceResponse {
      return CreateAbsenceResponseToJSONTyped(json, false);
  }

  export function CreateAbsenceResponseToJSONTyped(value?: CreateAbsenceResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': AbsenceToJSON(value['data']),
    };
}

