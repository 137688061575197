/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEmployeeMessageData
 */
export interface CreateEmployeeMessageData {
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeMessageData
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeMessageData
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeMessageData
     */
    channel: CreateEmployeeMessageDataChannelEnum;
    /**
     * Configure what address replies should be sent to when using the mail channel.
     * @type {string}
     * @memberof CreateEmployeeMessageData
     */
    mailReplyAddress?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateEmployeeMessageData
     */
    employeeIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateEmployeeMessageData
     */
    groupIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateEmployeeMessageData
     */
    locationIds?: Array<number>;
}

/**
* @export
* @enum {string}
*/
export enum CreateEmployeeMessageDataChannelEnum {
    Mail = 'Mail'
}


/**
 * Check if a given object implements the CreateEmployeeMessageData interface.
 */
export function instanceOfCreateEmployeeMessageData(value: object): value is CreateEmployeeMessageData {
    if (!('subject' in value) || value['subject'] === undefined) return false;
    if (!('body' in value) || value['body'] === undefined) return false;
    if (!('channel' in value) || value['channel'] === undefined) return false;
    return true;
}

export function CreateEmployeeMessageDataFromJSON(json: any): CreateEmployeeMessageData {
    return CreateEmployeeMessageDataFromJSONTyped(json, false);
}

export function CreateEmployeeMessageDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeMessageData {
    if (json == null) {
        return json;
    }
    return {
        
        'subject': json['subject'],
        'body': json['body'],
        'channel': json['channel'],
        'mailReplyAddress': json['mailReplyAddress'] == null ? undefined : json['mailReplyAddress'],
        'employeeIds': json['employeeIds'] == null ? undefined : json['employeeIds'],
        'groupIds': json['groupIds'] == null ? undefined : json['groupIds'],
        'locationIds': json['locationIds'] == null ? undefined : json['locationIds'],
    };
}

  export function CreateEmployeeMessageDataToJSON(json: any): CreateEmployeeMessageData {
      return CreateEmployeeMessageDataToJSONTyped(json, false);
  }

  export function CreateEmployeeMessageDataToJSONTyped(value?: CreateEmployeeMessageData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'subject': value['subject'],
        'body': value['body'],
        'channel': value['channel'],
        'mailReplyAddress': value['mailReplyAddress'],
        'employeeIds': value['employeeIds'],
        'groupIds': value['groupIds'],
        'locationIds': value['locationIds'],
    };
}

