/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateEmployeeOnboardingData
 */
export interface UpdateEmployeeOnboardingData {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeOnboardingData
     */
    hasAddedPersonalDetails?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeOnboardingData
     */
    hasVerifiedPhoneNumber?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateEmployeeOnboardingData
     */
    hasAddedUnavailability?: boolean;
}

/**
 * Check if a given object implements the UpdateEmployeeOnboardingData interface.
 */
export function instanceOfUpdateEmployeeOnboardingData(value: object): value is UpdateEmployeeOnboardingData {
    return true;
}

export function UpdateEmployeeOnboardingDataFromJSON(json: any): UpdateEmployeeOnboardingData {
    return UpdateEmployeeOnboardingDataFromJSONTyped(json, false);
}

export function UpdateEmployeeOnboardingDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeeOnboardingData {
    if (json == null) {
        return json;
    }
    return {
        
        'hasAddedPersonalDetails': json['hasAddedPersonalDetails'] == null ? undefined : json['hasAddedPersonalDetails'],
        'hasVerifiedPhoneNumber': json['hasVerifiedPhoneNumber'] == null ? undefined : json['hasVerifiedPhoneNumber'],
        'hasAddedUnavailability': json['hasAddedUnavailability'] == null ? undefined : json['hasAddedUnavailability'],
    };
}

  export function UpdateEmployeeOnboardingDataToJSON(json: any): UpdateEmployeeOnboardingData {
      return UpdateEmployeeOnboardingDataToJSONTyped(json, false);
  }

  export function UpdateEmployeeOnboardingDataToJSONTyped(value?: UpdateEmployeeOnboardingData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hasAddedPersonalDetails': value['hasAddedPersonalDetails'],
        'hasVerifiedPhoneNumber': value['hasVerifiedPhoneNumber'],
        'hasAddedUnavailability': value['hasAddedUnavailability'],
    };
}

