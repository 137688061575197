/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDocumentReadData
 */
export interface CreateDocumentReadData {
    /**
     * Who read the document. Defaults to the requesting employee.
     * @type {number}
     * @memberof CreateDocumentReadData
     */
    employeeId?: number;
}

/**
 * Check if a given object implements the CreateDocumentReadData interface.
 */
export function instanceOfCreateDocumentReadData(value: object): value is CreateDocumentReadData {
    return true;
}

export function CreateDocumentReadDataFromJSON(json: any): CreateDocumentReadData {
    return CreateDocumentReadDataFromJSONTyped(json, false);
}

export function CreateDocumentReadDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDocumentReadData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'] == null ? undefined : json['employeeId'],
    };
}

  export function CreateDocumentReadDataToJSON(json: any): CreateDocumentReadData {
      return CreateDocumentReadDataToJSONTyped(json, false);
  }

  export function CreateDocumentReadDataToJSONTyped(value?: CreateDocumentReadData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
    };
}

