/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLeavePolicyData
 */
export interface UpdateLeavePolicyData {
    /**
     * Which leave type should be used as the default. Omitting this will use the first available leave type.
     * @type {number}
     * @memberof UpdateLeavePolicyData
     */
    defaultLeaveTypeId?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeavePolicyData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeavePolicyData
     */
    startDateType?: UpdateLeavePolicyDataStartDateTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeavePolicyData
     */
    startDay?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeavePolicyData
     */
    startMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeavePolicyData
     */
    leaveNoticePeriodInDays?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeavePolicyData
     */
    leaveAllowanceCanBeExceeded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeavePolicyData
     */
    excludePublicHolidaysFromWorkingTimeLost?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateLeavePolicyData
     */
    leaveTypeIds?: Array<number>;
}

/**
* @export
* @enum {string}
*/
export enum UpdateLeavePolicyDataStartDateTypeEnum {
    EmployeeJoinDate = 'Employee Join Date',
    SpecificDate = 'Specific Date'
}


/**
 * Check if a given object implements the UpdateLeavePolicyData interface.
 */
export function instanceOfUpdateLeavePolicyData(value: object): value is UpdateLeavePolicyData {
    return true;
}

export function UpdateLeavePolicyDataFromJSON(json: any): UpdateLeavePolicyData {
    return UpdateLeavePolicyDataFromJSONTyped(json, false);
}

export function UpdateLeavePolicyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeavePolicyData {
    if (json == null) {
        return json;
    }
    return {
        
        'defaultLeaveTypeId': json['defaultLeaveTypeId'] == null ? undefined : json['defaultLeaveTypeId'],
        'name': json['name'] == null ? undefined : json['name'],
        'startDateType': json['startDateType'] == null ? undefined : json['startDateType'],
        'startDay': json['startDay'] == null ? undefined : json['startDay'],
        'startMonth': json['startMonth'] == null ? undefined : json['startMonth'],
        'leaveNoticePeriodInDays': json['leaveNoticePeriodInDays'] == null ? undefined : json['leaveNoticePeriodInDays'],
        'leaveAllowanceCanBeExceeded': json['leaveAllowanceCanBeExceeded'] == null ? undefined : json['leaveAllowanceCanBeExceeded'],
        'excludePublicHolidaysFromWorkingTimeLost': json['excludePublicHolidaysFromWorkingTimeLost'] == null ? undefined : json['excludePublicHolidaysFromWorkingTimeLost'],
        'leaveTypeIds': json['leaveTypeIds'] == null ? undefined : json['leaveTypeIds'],
    };
}

  export function UpdateLeavePolicyDataToJSON(json: any): UpdateLeavePolicyData {
      return UpdateLeavePolicyDataToJSONTyped(json, false);
  }

  export function UpdateLeavePolicyDataToJSONTyped(value?: UpdateLeavePolicyData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'defaultLeaveTypeId': value['defaultLeaveTypeId'],
        'name': value['name'],
        'startDateType': value['startDateType'],
        'startDay': value['startDay'],
        'startMonth': value['startMonth'],
        'leaveNoticePeriodInDays': value['leaveNoticePeriodInDays'],
        'leaveAllowanceCanBeExceeded': value['leaveAllowanceCanBeExceeded'],
        'excludePublicHolidaysFromWorkingTimeLost': value['excludePublicHolidaysFromWorkingTimeLost'],
        'leaveTypeIds': value['leaveTypeIds'],
    };
}

