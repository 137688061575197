/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Updates an emergency contact using the data provided.
 * @export
 * @interface UpdateEmergencyContactData
 */
export interface UpdateEmergencyContactData {
    /**
     * The emergency contact's name.
     * @type {string}
     * @memberof UpdateEmergencyContactData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmergencyContactData
     */
    relationship?: UpdateEmergencyContactDataRelationshipEnum;
    /**
     * The contact telephone number of the emergency contact.
     * @type {string}
     * @memberof UpdateEmergencyContactData
     */
    telephoneNumber?: string | null;
    /**
     * The contact mobile number of the emergency contact.
     * @type {string}
     * @memberof UpdateEmergencyContactData
     */
    mobileNumber?: string | null;
    /**
     * A flag to designate the contact as the default emergency contact.
     * @type {boolean}
     * @memberof UpdateEmergencyContactData
     */
    isDefault?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UpdateEmergencyContactDataRelationshipEnum {
    Other = 'Other',
    Parent = 'Parent',
    Partner = 'Partner',
    Relative = 'Relative',
    Sibling = 'Sibling',
    Spouse = 'Spouse'
}


/**
 * Check if a given object implements the UpdateEmergencyContactData interface.
 */
export function instanceOfUpdateEmergencyContactData(value: object): value is UpdateEmergencyContactData {
    return true;
}

export function UpdateEmergencyContactDataFromJSON(json: any): UpdateEmergencyContactData {
    return UpdateEmergencyContactDataFromJSONTyped(json, false);
}

export function UpdateEmergencyContactDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmergencyContactData {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'relationship': json['relationship'] == null ? undefined : json['relationship'],
        'telephoneNumber': json['telephoneNumber'] == null ? undefined : json['telephoneNumber'],
        'mobileNumber': json['mobileNumber'] == null ? undefined : json['mobileNumber'],
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
    };
}

  export function UpdateEmergencyContactDataToJSON(json: any): UpdateEmergencyContactData {
      return UpdateEmergencyContactDataToJSONTyped(json, false);
  }

  export function UpdateEmergencyContactDataToJSONTyped(value?: UpdateEmergencyContactData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'relationship': value['relationship'],
        'telephoneNumber': value['telephoneNumber'],
        'mobileNumber': value['mobileNumber'],
        'isDefault': value['isDefault'],
    };
}

