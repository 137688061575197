/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { Notification } from './Notification';
import {
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
    NotificationToJSONTyped,
} from './Notification';

/**
 * 
 * @export
 * @interface ListNotificationsResponse
 */
export interface ListNotificationsResponse {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof ListNotificationsResponse
     */
    data: Array<Notification>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListNotificationsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListNotificationsResponse interface.
 */
export function instanceOfListNotificationsResponse(value: object): value is ListNotificationsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListNotificationsResponseFromJSON(json: any): ListNotificationsResponse {
    return ListNotificationsResponseFromJSONTyped(json, false);
}

export function ListNotificationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListNotificationsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(NotificationFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListNotificationsResponseToJSON(json: any): ListNotificationsResponse {
      return ListNotificationsResponseToJSONTyped(json, false);
  }

  export function ListNotificationsResponseToJSONTyped(value?: ListNotificationsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(NotificationToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

